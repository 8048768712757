/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {
  getPolicyBillingTransactions,
  PolicyInstallmentTransactions,
  PolicyBasicInfo,
  PolicyInstallment,
  getPolicyInstallments,
} from "api/policy";
import {Card} from "components/Card";
import Loading from "components/Loading";
import {Body, Cell, Header, HeaderCell, Row, Table} from "components/Table";
import {Tab, TabList, Tabs, TabPanels, TabPanel} from "components/Tabs";
import {useApiQuery} from "hooks/api";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {formatAmount, formatDate} from "utils/format";
import PolicyModSelector from "./PolicyModSelector";
import {PolicyPageParams} from "./policyPageParams";
import PolicyPaymentOverview from "./PolicyPaymentOverview";
import SavedPaymentMethods from "./SavedPaymentMethods";
import {permissionsContains} from "utils/auth";
import {Anchor} from "components/elements";

function PolicyBilling({
  params,
  policyModList,
}: {
  params: PolicyPageParams;
  policyModList: PolicyBasicInfo[];
}) {
  const permissions = usePermissions();

  const policyBillingTransactions = useApiQuery(getPolicyBillingTransactions, {
    enabled: permissions.includes(Permissions.VIEW_POLICY_BILLING),
  })(params.policyModIds);
  const policyInstallments = useApiQuery(getPolicyInstallments, {
    enabled: permissions.includes(Permissions.VIEW_POLICY_BILLING),
  })(params.policyModIds);
  return (
    <BillingContainer>
      {permissionsContains(permissions, "PAYMENT") && (
        <div
          css={(theme) => css`
            @media (min-width: ${theme.breakpoint.lg}px) {
              width: 354px;
            }
          `}
        >
          <Card>
            <PolicyPaymentOverview policyModIds={params.policyModIds} />
          </Card>
        </div>
      )}
      <div
        css={css`
          flex: 1 1 auto;
          overflow-y: auto;
        `}
      >
        <Tabs>
          <>
            <div style={{marginBottom: 24}}>
              <TabList>
                {permissions.includes(Permissions.VIEW_POLICY_BILLING) && (
                  <>
                    <Tab>Schedule</Tab>
                    <Tab>Transactions</Tab>
                  </>
                )}
                {permissions.includes(
                  Permissions.VIEW_SAVED_PAYMENT_METHODS
                ) && <Tab>Saved Payment Methods</Tab>}
              </TabList>
            </div>
            <TabPanels>
              {permissions.includes(Permissions.VIEW_POLICY_BILLING) && (
                <>
                  <TabPanel>
                    {policyInstallments.data ? (
                      <PolicyInstallments
                        modNumber={params.policyModIds.mod_number}
                        policyModList={policyModList}
                        policyInstallments={policyInstallments.data}
                      />
                    ) : policyInstallments.error ? (
                      <div>Error</div>
                    ) : (
                      <Loading size="medium" />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {policyBillingTransactions.data ? (
                      <PolicyBillingTransactions
                        modNumber={params.policyModIds.mod_number}
                        policyModList={policyModList}
                        policyBillingTransactions={
                          policyBillingTransactions.data
                        }
                      />
                    ) : policyBillingTransactions.error ? (
                      <div>Error</div>
                    ) : (
                      <Loading size="medium" />
                    )}
                  </TabPanel>
                </>
              )}
              {permissions.includes(Permissions.VIEW_SAVED_PAYMENT_METHODS) && (
                <TabPanel>
                  <SavedPaymentMethods policyModIds={params.policyModIds} />
                </TabPanel>
              )}
            </TabPanels>
          </>
        </Tabs>
      </div>
    </BillingContainer>
  );
}
function IsTransactionsOnly(
  policyBillingTransactions: PolicyInstallmentTransactions[]
) {
  let transactionsOnly = false;
  policyBillingTransactions.map((row) => {
    if (row.transactions_only) {
      transactionsOnly = true;
    }
  });
  return transactionsOnly;
}

function PolicyBillingTransactions({
  policyBillingTransactions,
  modNumber,
  policyModList,
}: {
  modNumber?: string | null;
  policyModList: PolicyBasicInfo[];
  policyBillingTransactions: PolicyInstallmentTransactions[];
}) {
  const transactionOnly = IsTransactionsOnly(policyBillingTransactions);
  return (
    <>
      <PolicyModSelector
        currentModNumber={modNumber}
        policyModList={policyModList}
      />
      {!transactionOnly && (
        <Table>
          <Header>
            <HeaderCell>Transaction Date</HeaderCell>
            <HeaderCell>Transaction Name</HeaderCell>
            <HeaderCell>Notice Amount</HeaderCell>
            <HeaderCell>Transaction Amount</HeaderCell>
            <HeaderCell>Installment Balance</HeaderCell>
          </Header>
          {policyBillingTransactions.map((installment, i) => (
            <PolicyInstallmentTransactionsGroup
              key={i}
              policyInstallment={installment}
            />
          ))}
        </Table>
      )}
      {transactionOnly && (
        <Table>
          <Header>
            <HeaderCell>Entered Date</HeaderCell>
            <HeaderCell>Type</HeaderCell>
            <HeaderCell>Amount</HeaderCell>
            <HeaderCell>Billed Amount</HeaderCell>
            <HeaderCell>Effective Date</HeaderCell>
            <HeaderCell>Balance</HeaderCell>
          </Header>
          {policyBillingTransactions.map((transaction, i) => (
            <PolicyTransactionList key={i} policyInstallment={transaction} />
          ))}
        </Table>
      )}
    </>
  );
}

function PolicyInstallments({
  policyInstallments,
  modNumber,
  policyModList,
}: {
  modNumber?: string | null;
  policyModList: PolicyBasicInfo[];
  policyInstallments: PolicyInstallment[];
}) {
  return (
    <>
      <PolicyModSelector
        currentModNumber={modNumber}
        policyModList={policyModList}
      />
      <div
        css={css`
          margin: 1%;
        `}
      >
        {" "}
        The below schedule reflects all updates to the schedule.
      </div>
      <Table checkeredBackground>
        <Header>
          <HeaderCell>Installment Date</HeaderCell>
          <HeaderCell>Installment Amount</HeaderCell>
        </Header>
        <Body>
          {policyInstallments.map((installment) => (
            <Row>
              <Cell>{formatDate(installment.installment_due_date)}</Cell>
              <Cell>{formatAmount(installment.amount, {showZero: true})}</Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </>
  );
}

function PolicyInstallmentTransactionsGroup({
  policyInstallment,
}: {
  policyInstallment: PolicyInstallmentTransactions;
}) {
  return (
    <Body highlighedBorder>
      <Row highlighedBackground>
        <Cell colSpan={3}>
          <b>
            Installment Date:{" "}
            {formatDate(policyInstallment.installment_due_date)}
          </b>
        </Cell>
        <Cell
          colSpan={2}
          css={css`
            text-align: right;
          `}
        >
          <b>
            Total Balance:{" "}
            {formatAmount(policyInstallment.balance, {showZero: true})}
          </b>
        </Cell>
      </Row>
      {policyInstallment.transactions.map((transaction) => (
        <Row>
          <Cell>{formatDate(transaction.transaction_date)}</Cell>
          <Cell>
            {transaction.transaction_document_url ? (
              <Anchor
                href={transaction.transaction_document_url}
                target="_blank"
              >
                {transaction.transaction_name}
              </Anchor>
            ) : (
              transaction.transaction_name
            )}
          </Cell>
          <Cell>{formatAmount(transaction.notice_amount)}</Cell>
          <Cell>{formatAmount(transaction.amount)}</Cell>
          <Cell>{formatAmount(transaction.balance, {showZero: true})}</Cell>
        </Row>
      ))}
    </Body>
  );
}
function PolicyTransactionList({
  policyInstallment,
}: {
  policyInstallment: PolicyInstallmentTransactions;
}) {
  return (
    <Body highlighedBorder>
      {" "}
      {policyInstallment.transactions.map((transaction) => (
        <Row>
          <Cell>{formatDate(transaction.entered_date)}</Cell>
          <Cell>
            {transaction.transaction_document_url ? (
              <Anchor
                href={transaction.transaction_document_url}
                target="_blank"
              >
                {transaction.transaction_name}
              </Anchor>
            ) : (
              transaction.transaction_name
            )}
          </Cell>
          <Cell>{formatAmount(transaction.amount)}</Cell>
          <Cell>{formatAmount(transaction.notice_amount)}</Cell>
          <Cell>{formatDate(transaction.effective_date)}</Cell>
          <Cell>{formatAmount(transaction.balance, {showZero: true})}</Cell>
        </Row>
      ))}
    </Body>
  );
}
const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    flex-direction: row;
  }
  gap: 30px;
`;

export default PolicyBilling;
