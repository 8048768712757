import {MixedMercantile} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import Field from "../../Field";

export const aggOccurLimits = [
  {label: "$1,000,000 / $2,000,000", value: "2000000"},
  {label: "$500,000 / $1,000,000", value: "1000000"},
  {label: "$300,000 / $600,000", value: "600000"},
];

function MixedMercantileGeneralLiability({
  mixedMercantile,
}: {
  mixedMercantile: MixedMercantile;
}) {
  return (
    <Box mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.DropDown
          options={aggOccurLimits.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          object={mixedMercantile}
          field="agg_occur_limit"
          label="Occurence Limit / Aggregate Limit"
          wrapperProps={{
            grow: 12,
          }}
        />
      </GridContainer>
    </Box>
  );
}

export default MixedMercantileGeneralLiability;
