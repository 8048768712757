/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {H3} from "components/elements";
import {ReactNode} from "react";

export default function GuidelinesSection({
  title,
  svgUrl,
  children,
}: {
  svgUrl?: string;
  title?: string;
  children: ReactNode;
}) {
  return (
    <div
      css={css`
        padding-left: 80px;
        background: url(${svgUrl});
        background-repeat: no-repeat;
        background-size: 40px;
        background-position: top 20px left 0px;
      `}
    >
      <H3>{title}</H3>
      {children}
    </div>
  );
}
