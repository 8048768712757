/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {Control, Controller, Path, RegisterOptions} from "react-hook-form";
import {TextInput, TextInputProps} from "./TextInput";

export default function ControlledTextInput<T extends object>({
  name,
  control,
  rules,
  error: inputErrorProp,
  ...props
}: {
  name: Path<T>;
  control: Control<T>;
  rules?: undefined | RegisterOptions;
} & TextInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <div>
          <TextInput
            {...props}
            onChange={onChange}
            value={value}
            error={Boolean(error) || inputErrorProp}
          />
          {error && error.message && (
            <div
              css={(theme) => css`
                font-size: 12px;
                margin-top: 5px;
                color: ${theme.color.state.red};
                min-height: 20px;
              `}
            >
              {error.message}
            </div>
          )}
        </div>
      )}
      rules={rules}
    />
  );
}
