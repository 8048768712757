/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {ReactNode} from "react";

export function SplitScreen({
  imageurl,
  children,
}: {
  imageurl: string;
  children: ReactNode;
}) {
  return (
    <div
      css={css`
        min-height: 100%;
        display: flex;
        background: white;
      `}
    >
      <div
        css={css`
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
          position: relative;
          width: 0px;
          flex: 1 1 0%;
        `}
      >
        <img
          css={css`
            position: relative;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          `}
          src={imageurl}
          alt=""
        />
      </div>
      <div
        css={(theme) => css`
          flex: 1 1 0%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: auto;
          @media (min-width: ${theme.breakpoint.xxs}px) {
            padding: 10px 20px;
          }
          @media (min-width: ${theme.breakpoint.xs}px) {
            padding: 10px 40px;
          }
          @media (min-width: ${theme.breakpoint.sm}px) {
            padding: 20px 60px;
          }
          @media (min-width: ${theme.breakpoint.md}px) {
            padding: 30px 134px;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default SplitScreen;
