import styled from "@emotion/styled";

const Header = styled.h1`
  ${(props) => props.theme.text.header1}
  color: ${(props) => props.theme.color.brand.primary};
`;

const Header2 = styled.h1`
  ${(props) => props.theme.text.header2}
  color: ${(props) => props.theme.color.brand.primary};
`;

const SubHeader = styled.h3`
  ${(props) => props.theme.text.header3}
  color: ${(props) => props.theme.color.brand.primary};
  margin: 24px 0;
`;

const MiniSubHeader = styled.h3`
  ${(props) => props.theme.text.header3}
  color: ${(props) => props.theme.color.brand.primary};
  margin-top: 24px;
  margin-bottom: 6px;
`;

const FormTitle = {
  Header: Header,
  Header2: Header2,
  SubHeader: SubHeader,
  MiniSubHeader: MiniSubHeader
};

export default FormTitle;
