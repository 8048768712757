/** @jsxImportSource @emotion/react */

import {useMemo, useState} from "react";
import {css} from "@emotion/react";
import {
  SavedPaymentMethodBasicInfo,
  PolicyPaymentInfo,
  RecurringPayment,
  PaymentProviderInfo,
} from "api/payment";
import {Permissions} from "api/auth";
import PaymentAmountSelector from "./PaymentAmountSelector";
import PaymentMethodSelector, {
  getSavedMethodsWithNewMethodsList,
  PaymentMethodType,
} from "./PaymentMethodSelector";
import Button from "components/Button";
import {formatAmount, formatDate, formatEffectiveDates} from "utils/format";
import AlertBar from "components/AlertBar";
import {useAuth, usePermissions} from "hooks/auth";
import {permissionsContains} from "utils/auth";
import {getPaymentAmounts} from "utils/quotePolicy";
import Link from "components/Link";
import {usePayment} from "hooks/payment";
import {Card} from "components/Card";
import styled from "@emotion/styled";
import StatusBadge from "components/StatusBadge";
import {legacyRecurringPaymentsExist} from "utils/payment";
import ReceiptEmail from "components/ReceiptEmail";
import {PolicyModIds, PolicyStatuses} from "api/policy";
import links from "links";

export default function PolicyPayment({
  policyModIds,
  policyPaymentInfo,
  paymentProviderInfo,
  recurringPayment,
  savedMethods,
}: {
  policyModIds: PolicyModIds;
  policyPaymentInfo: PolicyPaymentInfo;
  paymentProviderInfo: PaymentProviderInfo;
  recurringPayment: RecurringPayment | undefined;
  savedMethods: SavedPaymentMethodBasicInfo[];
}) {
  const {isLoggedIn, authSession} = useAuth();
  const permissions = usePermissions();
  const [error, setError] = useState(false);

  const amounts = getPaymentAmounts(policyPaymentInfo);

  const [customAmount, setCustomAmount] = useState(0);
  const [selectedAmountDescription, setSelectedAmountDescription] = useState(
    amounts[0].description
  );
  const selectedAmount = useMemo(() => {
    if (selectedAmountDescription === "customAmount") return customAmount;
    else {
      const selectedAmount = amounts.find(
        (amount) => amount.description === selectedAmountDescription
      );
      if (selectedAmount) return selectedAmount.amount;
      else return 0;
    }
  }, [customAmount, selectedAmountDescription, amounts]);

  const methods = getSavedMethodsWithNewMethodsList(savedMethods);
  const [selectedMethodId, setSelectedMethodId] = useState<string | null>(null);
  const selectedMethod = useMemo(
    () => methods.find((method) => method.id === selectedMethodId),
    [methods, selectedMethodId]
  );

  const isSavedPayment =
    selectedMethod && selectedMethod.type === PaymentMethodType.SAVED_METHOD;

  const [receiptEmail, setReceiptEmail] = useState(
    authSession?.user_email || ""
  );

  const payment = usePayment({
    policyModIds: {
      ...policyModIds,
      mod_number: policyPaymentInfo.due_mod_number || policyModIds.mod_number,
    },
    oneTimePaymentInfo: {
      newPaymentMethodType:
        selectedMethod &&
        (selectedMethod.type === PaymentMethodType.NEW_BANK
          ? "BANK"
          : "CREDIT"),
      savedPaymentMethodData: isSavedPayment
        ? {
            method_id:
              selectedMethod?.saved_method_data?.payment_method_id || "",
            description: selectedMethod?.saved_method_data?.description || "",
          }
        : undefined,
      selectedAmount,
      showSaveOption:
        isLoggedIn && permissionsContains(permissions, "SAVE_PAYMENT_METHOD"),
      isError: error,
      receiptEmail,
    },
    paymentProviderInfo,
    amountDue: policyPaymentInfo.amount_due,
  });

  return (
    <div
      css={(theme) => css`
        display: flex;
        flex-direction: column;
        gap: 40px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 22px;
        `}
      >
        <PolicyInfoCard
          policyPaymentInfo={policyPaymentInfo}
          receiptEmail={receiptEmail}
          setReceiptEmail={setReceiptEmail}
        />
        <RecurringPaymentAlert
          policyModIds={policyModIds}
          recurringPayment={recurringPayment}
        />
        <PendingPaymentsAlert policyPaymentInfo={policyPaymentInfo} />
        {policyPaymentInfo.policy_info.status !== PolicyStatuses.CANCELLED && (
          <DueAlert policyPaymentInfo={policyPaymentInfo} />
        )}
        {policyPaymentInfo.policy_info.status === PolicyStatuses.CANCELLED && (
          <AlertBar status="warning">
            This policy is cancelled. Please note that payment doesn't reinstate
            the policy automatically
          </AlertBar>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 25px;
        `}
      >
        <PaymentAmountSelector
          disabled={payment.isLoading}
          availableAmounts={amounts}
          selectedAmountDescription={selectedAmountDescription}
          customAmount={customAmount}
          setCustomAmount={setCustomAmount}
          onChange={(value) => setSelectedAmountDescription(value)}
          onError={(value) => setError(value)}
        />
        {payment.isUnderpaymentWithoutPermission && (
          <AlertBar status="error">
            Please enter an amount greater than or equal to the amount due.
          </AlertBar>
        )}
        <PaymentMethodSelector
          disabled={payment.isLoading}
          methods={methods}
          selectedMethod={selectedMethodId}
          onChange={(value) => setSelectedMethodId(value)}
          creditCardFee={
            (Number(process.env.REACT_APP_ONE_INC_CONVENIENCE_FEE) / 100) *
            selectedAmount
          }
        />
        <AlertBar status="info">
          The payment will be reflected in the balance within 2 business days.
        </AlertBar>
      </div>
      <div>
        {isSavedPayment ? (
          <Button
            fullwidth
            size="large"
            isLoading={payment.isLoading}
            onClick={payment.makePaymentWithSavedPaymentMethod}
            disabled={payment.paymentDisabled || payment.isLoading}
          >
            Pay {formatAmount(selectedAmount)}
          </Button>
        ) : (
          <Button
            fullwidth
            size="large"
            isLoading={payment.isLoading}
            onClick={payment.makeOneTimePayment}
            disabled={payment.paymentDisabled || payment.isLoading}
          >
            {payment.isLoading ? "Loading" : "Continue"}
          </Button>
        )}
      </div>
    </div>
  );
}

function RecurringPaymentAlert({
  policyModIds,
  recurringPayment,
}: {
  policyModIds: PolicyModIds;
  recurringPayment: RecurringPayment | undefined;
}) {
  const permissions = usePermissions();

  let {
    legacyPaymentsExist,
    bothLegacyPortalsHaveRecurringPayments,
    agentRecurringHasRecurringPayments,
    insuredRecurringHasRecurringPayments,
  } = legacyRecurringPaymentsExist(recurringPayment);

  return recurringPayment &&
    permissions.includes(Permissions.VIEW_RECURRING_PAYMENT) ? (
    <AlertBar status={recurringPayment.enabled ? "warning" : "info"}>
      Recurring payments are{" "}
      {recurringPayment.enabled
        ? `setup with ${recurringPayment.payment_method_description}`
        : bothLegacyPortalsHaveRecurringPayments
        ? "setup in Agent & Insured Portals (Legacy)"
        : agentRecurringHasRecurringPayments
        ? "setup in Agent Portal (Legacy)"
        : insuredRecurringHasRecurringPayments
        ? "setup in Insured Portal (Legacy)"
        : "not setup"}
      .&nbsp;
      {permissions.includes(Permissions.MANAGE_RECURRING_PAYMENT) && (
        <Link
          css={css`
            white-space: nowrap;
          `}
          to={links.recurringPayments({
            policyModIds,
            setupView: !recurringPayment.enabled,
          })}
        >
          {recurringPayment.enabled
            ? "Manage Recurring Payment"
            : legacyPaymentsExist
            ? "Setup in New Portal"
            : "Setup Recurring Payment"}
        </Link>
      )}
    </AlertBar>
  ) : null;
}

function PendingPaymentsAlert({
  policyPaymentInfo,
}: {
  policyPaymentInfo: PolicyPaymentInfo;
}) {
  return policyPaymentInfo.pending_payments_total > 0 ? (
    <AlertBar status="warning">
      There's a total of{" "}
      {formatAmount(policyPaymentInfo.pending_payments_total)} in pending
      payments.
    </AlertBar>
  ) : null;
}

function DueAlert({policyPaymentInfo}: {policyPaymentInfo: PolicyPaymentInfo}) {
  return policyPaymentInfo.amount_due > 0 ? (
    policyPaymentInfo.is_due_today ? (
      <AlertBar status="warning">This policy is due today.</AlertBar>
    ) : policyPaymentInfo.is_past_due &&
      !policyPaymentInfo.cancellation_date ? (
      <AlertBar status="warning">This policy is past due.</AlertBar>
    ) : policyPaymentInfo.is_past_due && policyPaymentInfo.cancellation_date ? (
      <AlertBar status="error">
        This policy is past due. This policy will be cancelled unless the
        balance due is paid by {formatDate(policyPaymentInfo.cancellation_date)}
        .
      </AlertBar>
    ) : null
  ) : null;
}

function PolicyInfoCard({
  policyPaymentInfo,
  receiptEmail,
  setReceiptEmail,
}: {
  policyPaymentInfo: PolicyPaymentInfo;
  receiptEmail: string;
  setReceiptEmail: (email: string) => void;
}) {
  const mod = policyPaymentInfo.policy_info;
  return (
    <StyledPolicyInfoCard>
      <Link
        to={links.policy({
          policyModIds: policyPaymentInfo.policy_info.policy_mod_ids,
          tab: "details",
        })}
      >
        <PolicyNumber>{mod.policy_mod_ids.display_policy_number}</PolicyNumber>
      </Link>
      <span>
        <b>{formatEffectiveDates(mod.effective_date, mod.expiration_date)}</b>
      </span>
      <StatusBadge value={mod.status} />
      <span>
        <Title>Insured:</Title> {mod.insured_name}
      </span>
      <span>
        <Title>Payment plan:</Title> {policyPaymentInfo.payment_plan}
      </span>
      <span>
        <Title>Receipt email:</Title> {receiptEmail ? receiptEmail : "None"}
      </span>
      <ReceiptEmail
        receiptEmail={receiptEmail}
        onSubmit={(email) => setReceiptEmail(email)}
      />
    </StyledPolicyInfoCard>
  );
}

const StyledPolicyInfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PolicyNumber = styled.span`
  display: block;
  font-weight: bold;
  font-size: 24px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
