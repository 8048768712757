import {useTheme} from "@emotion/react";
import {
  Habitationial,
  MixedMercantile,
  Underwriting,
  getMixedMercantileUnitOccupancyTypes,
} from "api/quote";
import {Box} from "components/Box";
import IndentWrapper from "components/IndentWrapper";
import Field from "../../Field";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import {useSectionName} from "pages/RateQuoteBind/contexts/sectionNameContext";
import {useApiQuery} from "hooks/api";

function UnderwritingForm({
  underwriting,
  habitational,
  mixedMercantile,
}: {
  underwriting?: Underwriting;
  habitational?: Habitationial;
  mixedMercantile?: MixedMercantile;
}) {
  const theme = useTheme();
  const sectionName = useSectionName();

  const occupancyTypesQuery = useApiQuery(
    getMixedMercantileUnitOccupancyTypes
  )();

  if (!underwriting) return <></>;

  return (
    <Box background={theme.color.brand.tertiary} py={34} px={32} mb={100}>
      <Box mb={32}>
        <FormTitle.Header2>{sectionName}</FormTitle.Header2>
      </Box>

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_prior_insurance"
          label="Has this building(s) been under current ownership for the past three years with prior insurance?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.has_prior_insurance !== null &&
      Boolean(underwriting.has_prior_insurance) === false ? (
        <Box mb={16}>
          <IndentWrapper>
            <Field.RadioGroup
              object={underwriting}
              field="is_this_new_purchase"
              label="Is this a new purchase?"
              options={[
                {title: "Yes", value: 1},
                {title: "No", value: 0},
              ]}
            />
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="is_outstanding_violation"
          label="Are there any outstanding building violations?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>

      {habitational &&
      !mixedMercantile &&
      new Date().getFullYear() -
        habitational.habitational_buildings[0].year_built >
        25 ? (
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="is_roof_old"
            label="Was the roof, plumbing, wiring, and heating updated within the last 25 years?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
      ) : (
        <></>
      )}

      {!habitational &&
        mixedMercantile &&
        (new Date().getFullYear() -
          mixedMercantile?.mixed_mercantile_buildings[0].year_built >
        25 ? (
          <Box mb={16}>
            <Field.RadioGroup
              object={underwriting}
              field="is_roof_old"
              label="Was the roof, plumbing, wiring, and heating updated within the last 25 years?"
              options={[
                {title: "Yes", value: 1},
                {title: "No", value: 0},
              ]}
            />
          </Box>
        ) : (
          <></>
        ))}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_electric_panels"
          label="Does the building have knob and tube wiring, aluminum wiring or Federal pacific/Stab-Lok/Challenger/Zinsco/Pushmatic electrical panels?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="with_obvious_defects"
          label="For all buildings, are steps, walkways and parking lots free of any obvious defects?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="with_local_codes"
          label="Are the premises in compliance with local codes?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="been_crime"
          label="During the last five years has any applicant been indicted for or convicted of any degree of crime?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="had_judgement"
          label="Has the applicant had a foreclosure, repossession, bankruptcy, judgment, or lien during the last five years?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="none_policy_coverage"
          label="Has any policy coverage been declined, cancelled or non-renewed during the past three years?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="had_loss"
          label="Were any losses for the object(s) reported over the last three years?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.had_loss ? (
        <Box mb={16}>
          <IndentWrapper>
            <Field.TextArea
              object={underwriting}
              field="loss_description"
              outLabel="Please explain the circumstance of the loss(es)"
              placeholder="Message"
            />
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="have_AEIG"
          label="Does the applicant have any insurance with American European Insurance Group or its affiliated companies?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.have_AEIG ? (
        <Box mb={16}>
          <IndentWrapper>
            <Field.TextInput
              object={underwriting}
              field="policy_number"
              outLabel="Please indicate the policy numbers"
              placeholder="Num"
              isFormattedNumber
            />
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="is_occupied"
          label="Are the premises fully occupied?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.is_occupied !== null &&
      Boolean(underwriting.is_occupied) === false ? (
        <Box mb={16}>
          <IndentWrapper>
            <Field.TextInput
              object={underwriting}
              field="vacant_units"
              outLabel="How many units are vacant?"
              placeholder="Num"
              isFormattedNumber
            />
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="require_signed_lease"
          label="Does applicant require a signed lease with all tenants for at least 6 months?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.require_signed_lease ? (
        <Box mb={16}>
          {underwriting.require_signed_lease ? (
            <Box mb={16}>
              <IndentWrapper>
                <Box mb={16}>
                  <Field.DropDown
                    object={underwriting}
                    field="is_harmless_for_owner"
                    outLabel="Is there hold harmless or indemnification language in the lease in favor of the tenant or the applicant (building owner)?"
                    options={[
                      {title: "No", value: 0},
                      {title: "Yes, in favor of the tenant", value: 1},
                      {
                        title:
                          "Yes, in favor of the applicant (building owner)",
                        value: 2,
                      },
                    ]}
                  />
                </Box>
              </IndentWrapper>
            </Box>
          ) : (
            <></>
          )}
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="require_liability_insurance"
                label="Do the leases require tenants to carry liability insurance?"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
            {underwriting.require_liability_insurance ? (
              <IndentWrapper>
                <Box mb={16}>
                  <Field.RadioGroup
                    object={underwriting}
                    field="require_500k"
                    label="Are the occurrence limits of the required liability insurance at least $500,000?"
                    options={[
                      {title: "Yes", value: 1},
                      {title: "No", value: 0},
                    ]}
                  />
                </Box>
                <Box mb={16}>
                  <Field.RadioGroup
                    object={underwriting}
                    field="require_renewal_certificates"
                    label="Does the applicant maintain Tenant Certificate of Insurance on file and require yearly renewal certificates?"
                    options={[
                      {title: "Yes", value: 1},
                      {title: "No", value: 0},
                    ]}
                  />
                </Box>
              </IndentWrapper>
            ) : (
              <></>
            )}
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_policy_prohibiting_pets"
          label="Does the applicant have a policy prohibiting pets?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="have_students"
          label="Is more than 5% of the building occupied by students or subsidized tenants?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_trampoline"
          label="Do any of the buildings have a trampoline?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_swimming_pool"
          label="Do any of the buildings have a swimming pool?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.has_swimming_pool !== null &&
      Boolean(underwriting.has_swimming_pool) === true ? (
        <IndentWrapper>
          <Box mb={16}>
            <Field.RadioGroup
              object={underwriting}
              field="swimming_pool_has_diving_board_or_sliding_board"
              label="Does the swimming pool have a diving board or sliding board?"
              options={[
                {title: "Yes", value: 1},
                {title: "No", value: 0},
              ]}
            />
          </Box>
          <Box mb={16}>
            <Field.RadioGroup
              object={underwriting}
              field="swimming_pool_completely_fenced"
              label="Is the swimming pool completely fenced?"
              options={[
                {title: "Yes", value: 1},
                {title: "No", value: 0},
              ]}
            />
          </Box>
        </IndentWrapper>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="is_entrance_secured"
          label="Are the building entrances secured and do they have exterior lighting?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="have_handrail"
          label="Do all stairways with more than two risers have a handrail?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="have_smoke_detector"
          label="Is the building equipped with smoke and carbon  monoxide detectors?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="have_parking"
          label="Do any of the locations have a parking lot?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.have_parking ? (
        <Box mb={16}>
          <IndentWrapper>
            <Field.DropDown
              object={underwriting}
              field="number_of_parking"
              outLabel="How many parking spaces are there?"
              options={[
                {title: "1", value: 1},
                {title: "2", value: 2},
                {title: "3", value: 3},
                {title: "4", value: 4},
                {title: "5", value: 5},
                {title: "6", value: 6},
                {title: "7", value: 7},
                {title: "8", value: 8},
                {title: "9", value: 9},
                {title: "More than 9", value: 10},
              ]}
            />
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}

      <Box mb={16}>
        <Field.DropDown
          object={underwriting}
          field="snow_removal_person"
          outLabel="Who is responsible for snow  and ice removal?"
          options={[
            {title: "Applicant", value: "applicant"},
            {title: "Tenant", value: "tenant"},
            {title: "Third Party Vendor", value: "vendor"},
          ]}
        />
      </Box>
      {underwriting.snow_removal_person === "applicant" ||
      underwriting.snow_removal_person === "tenant" ? (
        <Box mb={16}>
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="applicant_do_document"
                label="Does the applicant document who is responsible and when surface have been plowed, treated etc.?"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}
      {underwriting.snow_removal_person === "vendor" ? (
        <Box mb={16}>
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="vendor_liability_snow_removal"
                label="Does the contractor carry a minimum of $1,000,000 in liability, indemnify the Applicant in the snow removal contract and add the Applicant as as an Additional Insured on the contractors' policy?"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}
      <Box mb={16}>
        <Field.RadioGroup
          object={underwriting}
          field="has_fuel_tank"
          label="Is there a fuel tank?"
          options={[
            {title: "Yes", value: 1},
            {title: "No", value: 0},
          ]}
        />
      </Box>
      {underwriting.has_fuel_tank ? (
        <Box mb={16}>
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="fuel_tank_location"
                label="Where is the fuel tank?"
                options={[
                  {title: "Above ground", value: "above ground"},
                  {title: "Underground", value: "underground"},
                  {title: "In basement", value: "in basement"},
                ]}
              />
            </Box>
          </IndentWrapper>
        </Box>
      ) : (
        <></>
      )}
      <Box mb={16}>
        {mixedMercantile?.mixed_mercantile_buildings.map(
          (building) =>
            building.mixed_mercantile_units.some(
              (unit) =>
                unit.occupancy_type &&
                occupancyTypesQuery.data &&
                occupancyTypesQuery.data.find(
                  (type) => unit.occupancy_type === type.class_description
                )?.additional_questions_for_mixed_merc
            ) && (
              <FormTitle.Header2>
                Mixed Mercantile Occupancy Requirements
              </FormTitle.Header2>
            )
        )}
        {occupancyTypesQuery.data &&
          mixedMercantile?.mixed_mercantile_buildings.map(
            (mixed_mercantile_building) =>
              mixed_mercantile_building.mixed_mercantile_units.map(
                (unit, index) => {
                  const questionText = occupancyTypesQuery.data.find(
                    (type) => unit.occupancy_type === type.class_description
                  )?.additional_questions_for_mixed_merc;

                  if (unit.occupancy_type && questionText)
                    return (
                      <div>
                        <Box mb={16}>
                          <FormTitle.SubHeader>
                            Mercantile Unit {index + 1}: {unit.occupancy_type}
                          </FormTitle.SubHeader>
                        </Box>
                        <Box mb={16}></Box>
                        <Box mb={16}>
                          <strong>Requirements:</strong>
                        </Box>

                        <Box>
                          {questionText &&
                            questionText
                              .split("?")
                              .slice(1)
                              .map((sentence, index) => (
                                <div key={index}>
                                  {sentence
                                    .split("\n")
                                    .filter((part) => part.trim() !== "") // Remove empty parts
                                    .map((part, partIndex) => (
                                      <Box
                                        key={partIndex}
                                        mb={3}
                                      >{`• ${part.trim()}`}</Box>
                                    ))}
                                </div>
                              ))}
                          {unit.occupancy_type &&
                            occupancyTypesQuery.data.find(
                              (type) =>
                                unit.occupancy_type === type.class_description
                            )?.additional_questions_for_mixed_merc && (
                              <Box mt={16}>
                                <Field.RadioGroup
                                  object={unit}
                                  label="Have or will the above requirements be met?"
                                  field="requirements_fulfilled"
                                  options={[
                                    {title: "Yes", value: 1},
                                    {title: "No", value: 0},
                                  ]}
                                />
                              </Box>
                            )}
                        </Box>
                      </div>
                    );
                  else return <></>;
                }
              )
          )}
      </Box>
    </Box>
  );
}

export default UnderwritingForm;
