import {Box} from "components/Box";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";
import {QuoteForm} from "api/quote";

function SkpAdditonalInterests({quoteForm}: {quoteForm?: QuoteForm | null}) {
  if (!quoteForm) return null;
  if (!quoteForm.skp) return null;

  console.log("quoteform", quoteForm);

  return (
    <Box mb={100}>
      <FormTitle.Header>Additional Interests</FormTitle.Header>
      {quoteForm.skp.skp_additional_interests.map((interest, index) => {
        return (
          <>
            <FormTitle.SubHeader>
              Additional Interest {index + 1}
            </FormTitle.SubHeader>
            <InfoRow label="Interest Name" object={interest.name} />
            <InfoRow
              label="Address"
              object={
                interest.line_1 +
                ", " +
                interest.city +
                ", " +
                interest.state +
                ", " +
                interest.zip
              }
            />
          </>
        );
      })}
    </Box>
  );
}

export default SkpAdditonalInterests;
