import {GeneralLiability as GeneralLiabilityInterface} from "api/policy";
import {Box} from "components/Box";
import {formatAmount} from "utils/format";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";
import {getQuoteDetails, QuoteForm} from "api/quote";
import {useQuery} from "react-query";

function GeneralLiability({
  generalLiability,
  quoteForm,
}: {
  generalLiability?: GeneralLiabilityInterface | null;
  quoteForm?: QuoteForm | null;
}) {
  const {data: policyData} = useQuery(
    ["quote/details", quoteForm?.id.toString()],
    () => getQuoteDetails(quoteForm?.id ? quoteForm.id : ""),
    {
      refetchOnWindowFocus: true,
      useErrorBoundary: true,
      suspense: true,
      refetchInterval: 60000,
    }
  );
  if (!generalLiability && !quoteForm) return null;

  return (
    <Box mb={100}>
      <FormTitle.Header>General Liability</FormTitle.Header>
      <InfoRow
        label="Aggregate Limit"
        object={formatAmount(
          quoteForm?.skp
            ? quoteForm?.skp.agg_occur_limit
            : generalLiability?.aggregate_limit
            ? generalLiability?.aggregate_limit
            : ""
        )}
      />
      <InfoRow
        label="Occurance Limit"
        object={formatAmount(
          quoteForm?.skp
            ? Number(quoteForm?.skp.agg_occur_limit) / 2
            : generalLiability?.occurrence_limit
            ? generalLiability?.occurrence_limit
            : ""
        )}
      />
      {quoteForm?.skp &&
        policyData?.insurance_lines?.commercial_property.terrorism_coverage && (
          <InfoRow
            label="Terrorism Coverage"
            object={formatAmount(
              quoteForm?.skp
                ? policyData?.insurance_lines?.commercial_property
                    .terrorism_coverage_premium
                : generalLiability?.occurrence_limit
                ? generalLiability?.occurrence_limit
                : ""
            )}
          />
        )}
      <InfoRow
        label="Total Premium"
        object={formatAmount(
          policyData?.policy_information.premium_amount
            ? policyData?.policy_information.premium_amount
            : generalLiability?.premium
            ? generalLiability?.premium
            : ""
        )}
      />
    </Box>
  );
}

export default GeneralLiability;
