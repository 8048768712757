/** @jsxImportSource @emotion/react */

import PolicyList from "pages/PolicyList";
import Policy, {PolicyLoading} from "pages/Policy";
import PolicyPayment from "pages/Payments";
import RateQuoteBind, {RateQuoteBindPageHeader} from "pages/RateQuoteBind";
import ViewGridIcon from "@heroicons/react/outline/ViewGridIcon";
import ViewListIcon from "@heroicons/react/outline/ViewListIcon";
import Messaging from "pages/Messaging";
import MailIcon from "@heroicons/react/outline/MailIcon";
import DocumentsIcon from "@heroicons/react/outline/DocumentDuplicateIcon";
import QuoteList from "pages/QuoteList";
import ChartBarIcon from "@heroicons/react/outline/ChartBarIcon";
import PaymentConfirmation from "pages/PaymentConfirmation";
import NewQuote from "pages/NewQuote";
import GuestPayAuth from "pages/GuestPayAuth";
import ClassCoverageSearch from "pages/ClassCoverageSearch";
import {Permissions} from "api/auth";
import {permissionsContains} from "utils/auth";
import InsuredRegister from "pages/InsuredRegister";
import Home from "pages/Home";
import {formatQuoteType} from "utils/format";
import {QuoteTypes} from "api/quote";
import {ReactNode} from "react";
import PolicyPageHeader from "pages/Policy/PolicyPageHeader";
import RecurringPayment from "pages/RecurringPayment";
import PoliciesDocuments from "pages/PoliciesDocuments";
import {
  getDateRelativeToToday,
  toDateDashes,
  getMonthValue,
  getDayOfMonthValue,
} from "utils/date";
import AppShellTitle from "components/AppShellTitle";
import {toBoolean} from "utils";
import {
  getPolicyIdsFromSearchParams,
  getPolicyModIdsFromSearchParams,
} from "utils/quotePolicy";
import {ChatIcon} from "@heroicons/react/solid";
import DiaryComments from "pages/DiaryComments";
import {BookOpenIcon, FolderOpenIcon} from "@heroicons/react/outline";
import {ReportsList} from "pages/ReportsList";
import Report from "pages/Report";
import Reports from "pages/Reports";
import {css} from "@emotion/react";
import Manuals from "pages/Manuals";
import CommissionStatement from "pages/CommissionStatements";
import AgencyExperience from "pages/AgencyExperience";
import FinanceReports from "pages/FinanceReports";

const paramsError = <p>Params Error</p>;

type PathParams = {[index: string]: any};

export interface pageConfig {
  public?: boolean;
  title?: string;
  icon?: (props: {className?: string}) => JSX.Element;
  path: string;
  getTitle?: (
    params: PathParams,
    search: URLSearchParams
  ) => string | undefined;
  getPageHeader?: (params: PathParams, search: URLSearchParams) => ReactNode;
  backUrl?: string;
  render?: (params: PathParams, search: URLSearchParams) => JSX.Element;
  menuLink?: string;
  redirect?: string;
  topBarShell?: boolean;
  topBarShellContainer?: boolean;
  noShell?: boolean;
  hideMenu?: boolean;
  hasPermission?: (permissions: Permissions[]) => boolean;
  exact?: boolean;
}

const home: pageConfig = {
  title: "Home",
  icon: ViewGridIcon,
  path: "/",
  menuLink: "/",
  render: () => <Home />,
};

const policies: pageConfig = {
  title: "Policies",
  icon: ViewListIcon,
  path: "/policy",
  menuLink: "/policy",
  render: (params, search) => {
    return (
      <PolicyList
        searchQuery={search.get("searchQuery")!}
        limit={Number(search.get("limit") || 10)}
        pageNumber={Number(search.get("pageNumber") || 1)}
      />
    );
  },
  hasPermission: (permissions) => permissionsContains(permissions, "POLIC"),
};

const quotes: pageConfig = {
  title: "Quotes",
  icon: ChartBarIcon,
  path: "/quote",
  menuLink: "/quote",
  render: (params, search) => {
    let limit = Number(search.get("limit") || 10);
    if (limit > 100) limit = 100;
    return (
      <QuoteList
        searchQuery={search.get("searchQuery")!}
        agentSearchQuery={search.get("agentSearchQuery")!}
        statusFilter={search.get("statusFilter") ?? undefined}
        underwriterFilter={search.get("underwriterFilter") ?? undefined}
        stateFilter={search.get("stateFilter") ?? undefined}
        quoteTypeFilter={search.get("quoteTypeFilter") ?? undefined}
        ownerFilter={search.get("ownerFilter") ?? undefined}
        limit={limit}
        pageNumber={Number(search.get("pageNumber") || 1)}
      />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_ALL_QUOTES) ||
    permissions.includes(Permissions.VIEW_AGENCY_QUOTES),
};

const messages: pageConfig = {
  title: "Messages",
  icon: MailIcon,
  path: "/messages/:id?/:messageId?",
  menuLink: "/messages",
  exact: false,
  render: (params, search) => {
    const id = params?.id;
    const messageId = params?.messageId;
    return <Messaging params={{id, messageId}} />;
  },
  hasPermission: (permissions) => permissionsContains(permissions, "THREADS"),
};

const reportsList: pageConfig = {
  title: "Reports",
  icon: FolderOpenIcon,
  path: "/report",
  menuLink: "/report",
  render: (params, search) => {
    return (
      <ReportsList
        searchQuery={search.get("searchQuery") || ""}
        pageNumber={Number(search.get("pageNumber") || 1)}
        limit={Number(search.get("limit") || 10)}
      />
    );
  },
  hasPermission: (permissions) => permissionsContains(permissions, "REPORT"),
};

const report: pageConfig = {
  getTitle: (params, search) => search.get("reportTitle") || "Report",
  getPageHeader: (params, search) => {
    return (
      <div
        css={(theme) => css`
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          padding-top: 2px;
          color: ${theme.color.brand.primary};
        `}
      >
        Reports / {search.get("reportTitle")}
      </div>
    );
  },
  backUrl: "/report",
  icon: FolderOpenIcon,
  path: "/report/:reportName",
  render: (params, search) => {
    return (
      <Report
        reportName={params.reportName}
        reportTitle={search.get("reportTitle") || "Report"}
      />
    );
  },
  hasPermission: (permissions) => permissionsContains(permissions, "REPORT"),
};

const legacyReportsPage: pageConfig = {
  title: "Reports",
  icon: FolderOpenIcon,
  path: "/policy/reports",
  menuLink: "/policy/reports",
  render: () => {
    return <Reports />;
  },
  hasPermission: (permissions) =>
    (permissions.includes(Permissions.VIEW_DIARY_COMMENTS) ||
      permissions.includes(Permissions.VIEW_ALL_COMMISSION_STATEMENTS) ||
      permissions.includes(Permissions.VIEW_AGENCY_EXPERIENCE) ||
      permissions.includes(Permissions.VIEW_FINANCE_REPORTS)) &&
    !permissionsContains(permissions, "REPORT"),
};

const manuals: pageConfig = {
  title: "Manuals",
  icon: BookOpenIcon,
  path: "/manuals",
  menuLink: "/manuals",
  render: () => {
    return <Manuals />;
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_MANUALS),
};

const diaryComments: pageConfig = {
  title: "Diary Comments",
  icon: ChatIcon,
  path: "/policy/diaryComments",
  render: (params, search) => {
    return (
      <DiaryComments
        fromDate={
          search.get("fromDate") || toDateDashes(getDateRelativeToToday(0))
        }
        toDate={search.get("toDate") || toDateDashes(getDateRelativeToToday(0))}
        limit={Number(search.get("limit") || 10)}
        pageNumber={Number(search.get("pageNumber") || 1)}
        destinationInitials={search.get("destinationInitials") || ""}
      />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_DIARY_COMMENTS),
};

const documents: pageConfig = {
  title: "Documents",
  icon: DocumentsIcon,
  path: "/policy/documents",
  menuLink: "/policy/documents",
  render: (params, search) => {
    return (
      <PoliciesDocuments
        fromDate={
          search.get("fromDate") || toDateDashes(getDateRelativeToToday(-30))
        }
        toDate={search.get("toDate") || toDateDashes(getDateRelativeToToday(1))}
        limit={Number(search.get("limit") || 10)}
        pageNumber={Number(search.get("pageNumber") || 1)}
      />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_POLICY_DOCUMENTS) &&
    (permissions.includes(Permissions.VIEW_ALL_POLICIES) ||
      permissions.includes(Permissions.VIEW_AGENCY_POLICIES)),
};

const policy: pageConfig = {
  path: "/policy/:policyNumber",
  getTitle: (params, search) => search.get("displayPolicyNumber") || "",
  getPageHeader: (params, search) => {
    const policyIds = getPolicyIdsFromSearchParams(search);
    const status = search.get("status");
    const query = search.get("query");
    if (query && params.policyNumber) {
      return <AppShellTitle>{params.policyNumber}</AppShellTitle>;
    }
    if (!policyIds) return null;
    return <PolicyPageHeader policyIds={policyIds} status={status} />;
  },
  backUrl: "/policy",
  render: (params, search) => {
    const policyModIds = getPolicyModIdsFromSearchParams(search);
    const query = search.get("query");
    if (query && params.policyNumber) {
      return <PolicyLoading policyNumberQuery={params.policyNumber} />;
    }
    if (!policyModIds) return paramsError;

    return (
      <Policy
        params={{
          policyModIds,
          tab: search.get("tab") || "details",
          showAllMods: toBoolean(search.get("showAllMods")),
        }}
      />
    );
  },
  hasPermission: (permissions) => permissionsContains(permissions, "POLIC"),
};

const payments: pageConfig = {
  path: "/payments",
  getTitle: (params, search) =>
    `Payment - ${search.get("displayPolicyNumber")}`,
  render: (params, search) => {
    const policyModIds = getPolicyModIdsFromSearchParams(search);
    if (!policyModIds) return paramsError;
    return <PolicyPayment policyModIds={policyModIds} />;
  },
  topBarShell: true,
  public: true,
  hasPermission: (permissions) => permissionsContains(permissions, "PAYMENT"),
};

const guestPay: pageConfig = {
  path: "/payments/guest",
  title: "Guest Pay",
  render: () => <GuestPayAuth />,
  topBarShell: true,
  public: true,
};

const recurringPay: pageConfig = {
  path: "/recurring-payment",
  getTitle: (params, search) =>
    `Recurring Payment - ${search.get("displayPolicyNumber")}`,
  render: (params, search) => {
    const policyModIds = getPolicyModIdsFromSearchParams(search);
    const setupView = toBoolean(search.get("setupView"));
    if (!policyModIds) return paramsError;
    return (
      <RecurringPayment policyModIds={policyModIds} setupView={setupView} />
    );
  },
  topBarShell: true,
  hasPermission: (permissions) =>
    permissions.includes(Permissions.MANAGE_RECURRING_PAYMENT),
};

const insuredRegister: pageConfig = {
  path: "/insured/register",
  title: "Register Insured",
  render: () => <InsuredRegister />,
  noShell: true,
  public: true,
};

const paymentConfirmation: pageConfig = {
  path: "/payments/confirmation",
  getTitle: (params, search) => {
    const succeeded = search.get("succeeded");
    return `Payment ${
      succeeded === "true" ? "Confirmation" : "Error"
    } - ${search.get("displayPolicyNumber")}`;
  },
  render: (params, search) => {
    const policyModIds = getPolicyModIdsFromSearchParams(search);
    const succeeded = search.get("succeeded");
    const message = decodeURIComponent(
      decodeURIComponent(search.get("message") || "")
    );
    const amount = search.get("amount");
    const paymentMethodDescription =
      search.get("paymentMethodDescription") || "";
    if (!policyModIds || !amount) return paramsError;
    return (
      <PaymentConfirmation
        policyModIds={policyModIds}
        amount={Number(amount)}
        paymentMethodDescription={paymentMethodDescription}
        succeeded={succeeded === "true"}
        message={message}
        transactionId={search.get("transactionId")}
      />
    );
  },
  topBarShell: true,
  public: true,
};

const newQuote: pageConfig = {
  path: "/new-quote",
  getTitle: (params, search) =>
    `New ${formatQuoteType(search.get("quoteType") as QuoteTypes)} Quote`,
  render: (params, search) => {
    const quoteType = search.get("quoteType");
    if (!quoteType) return paramsError;
    return <NewQuote quoteType={quoteType} />;
  },
  topBarShell: true,
  topBarShellContainer: false,
  hasPermission: (permissions) =>
    permissions.includes(Permissions.CREATE_QUOTE),
};

const rateQuoteBind: pageConfig = {
  path: "/rate-quote-bind/:id",
  getTitle: (params, search) =>
    `Rate/Quote/Bind - ${search.get("backendDisplayQuoteNumber")}`,
  render: (params, search) => {
    const id = params.id;
    if (!id) return paramsError;
    return <RateQuoteBind id={id} tab={search.get("tab")} />;
  },
  getPageHeader(params, search) {
    const id = params.id;
    if (!id) return paramsError;
    return <RateQuoteBindPageHeader id={id} />;
  },
  topBarShell: true,
  topBarShellContainer: false,
  hasPermission: (permissions) => permissionsContains(permissions, "QUOTE"),
};

const classCoverageSearch: pageConfig = {
  title: "Class Coverage Search",
  path: "/class-coverage-search",
  exact: false,
  render: (params, search) => {
    return <ClassCoverageSearch />;
  },
  topBarShell: true,
  topBarShellContainer: false,
};

const commissionStatements: pageConfig = {
  title: "Commission Statements",
  icon: ChatIcon,
  path: "/commission-statements",
  render: (params, search) => {
    const year =
      search.get("year") || String(getDateRelativeToToday(-30).getFullYear());
    const month =
      search.get("month") || getMonthValue(getDateRelativeToToday(-30));

    const officeCode = search.get("officeCode") || "";
    return (
      <CommissionStatement year={year} month={month} officeCode={officeCode} />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_ALL_COMMISSION_STATEMENTS),
};

const agencyExperience: pageConfig = {
  title: "Agency Experience",
  icon: ChatIcon,
  path: "/agency-experience",
  render: (params, search) => {
    const year =
      search.get("year") || String(getDateRelativeToToday(-30).getFullYear());
    const month =
      search.get("month") || getMonthValue(getDateRelativeToToday(-30));

    const officeCode = search.get("officeCode") || "";
    return (
      <AgencyExperience year={year} month={month} officeCode={officeCode} />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_AGENCY_EXPERIENCE),
};
const financeReports: pageConfig = {
  title: "Accounting",
  icon: ChatIcon,
  path: "/accounting",
  render: (params, search) => {
    const year =
      search.get("year") || String(getDateRelativeToToday(0).getFullYear());
    const month =
      search.get("month") || getMonthValue(getDateRelativeToToday(0));
    const day =
      search.get("day") || getDayOfMonthValue(getDateRelativeToToday(0));
    const folderName = search.get("folderName") || "";

    return (
      <FinanceReports
        year={year}
        month={month}
        day={day}
        folderName={folderName}
      />
    );
  },
  hasPermission: (permissions) =>
    permissions.includes(Permissions.VIEW_FINANCE_REPORTS),
};
const pageConfigs: pageConfig[] = [
  home,
  policies,
  quotes,
  documents,
  legacyReportsPage,
  reportsList,
  report,
  diaryComments,
  policy,
  payments,
  guestPay,
  paymentConfirmation,
  recurringPay,
  newQuote,
  rateQuoteBind,
  messages,
  manuals,
  classCoverageSearch,
  insuredRegister,
  commissionStatements,
  agencyExperience,
  financeReports,
];

export default pageConfigs;
