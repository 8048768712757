import styled from "@emotion/styled";
import Spinner from "./Spinner";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateRangeWrapper = styled.div`
  display: flex;
  gap: 6px;
  max-width: 500px;
`;

export const LoadingIndicator = styled(Spinner)`
  height: 20px;
  padding: 0px 6px;
`;
