/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {ButtonHTMLAttributes, ReactNode} from "react";
import Spinner from "./Spinner";

export type ButtonProps = {
  children?: ReactNode;
  icon?: ((props: any) => JSX.Element) | null;
  background?: boolean;
  disabled?: boolean;
  title?: string;
  isLoading?: boolean;
  fullwidth?: boolean;
  size?: "medium" | "large" | "small" | "xsmall";
  color?: "primary" | "secondary" | "danger";
  sidesPadding?: number;
  minWidth?: number;
} & ButtonHTMLAttributes<HTMLButtonElement>;

function Button({
  background = true,
  color = "primary",
  children,
  icon: Icon,
  disabled = false,
  isLoading = false,
  size = "medium",
  fullwidth = false,
  sidesPadding,
  minWidth,
  ...props
}: ButtonProps) {
  return (
    <button
      css={(theme) => css`
        ${theme.text.button1}
        height: fit-content;
        white-space: nowrap;
        width: fit-content;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: transparent;
        transition: all 400ms ease-out;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: ${theme.color.grayscale[600]};
        line-height: 16px;
        ${sidesPadding &&
        css`
          padding-left: ${sidesPadding}px;
          padding-right: ${sidesPadding}px;
        `}
        ${fullwidth &&
        css`
          width: 100%;
        `}
        ${size === "xsmall" &&
        css`
          padding: 2px 6px;
          height: 20px;
        `}
        ${size === "small" &&
        css`
          padding: 8px 10px;
          height: 32px;
        `}
        ${size === "large" &&
        css`
          min-width: ${minWidth || 306}px;
          height: 43px;
          border-radius: 6px;
        `}

        ${minWidth &&
        css`
          min-width: ${minWidth}px;
        `}

        ${background &&
        color === "primary" &&
        css`
          background-color: ${theme.color.brand.primary};
          color: ${theme.color.grayscale.white};
        `}

        ${background &&
        color === "secondary" &&
        css`
          background-color: ${theme.color.brand.secondary};
          color: ${theme.color.brand.primary};
        `}

        ${background &&
        color === "danger" &&
        css`
          background-color: ${theme.color.state.red};
          color: ${theme.color.grayscale.white};
        `}

        ${!background &&
        color === "primary" &&
        css`
          color: ${theme.color.brand.primary};
          background-color: transparent;
        `}

        ${!background &&
        color === "secondary" &&
        css`
          color: ${theme.color.brand.secondary};
          background-color: transparent;
        `}

        ${!background &&
        color === "danger" &&
        css`
          color: ${theme.color.state.red};
          background-color: transparent;
        `}

        ${(disabled || isLoading) &&
        css`
          cursor: not-allowed;
        `}

        ${background
          ? (disabled || isLoading) &&
            css`
              background-color: ${theme.color.grayscale[600]};
              color: ${theme.color.grayscale.white};
            `
          : (disabled || isLoading) &&
            css`
              color: ${theme.color.grayscale[600]};
            `}

        ${Icon &&
        !children &&
        css`
          width: 32px;
          padding: 0;
          height: 32px;
          border-radius: 50%;
        `}

        :hover:enabled {
          background-color: #d3d1d1;
          ${background &&
          !disabled &&
          !isLoading &&
          css`
            ${color === "primary" &&
            css`
              background-color: ${theme.color.brand.primary}A0;
            `}
            ${color === "secondary" &&
            css`
              background-color: ${theme.color.brand.secondary}A0;
            `}
          `}
        }
      `}
      disabled={disabled || isLoading}
      {...props}
    >
      {Icon && !isLoading ? (
        <div
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;
          `}
        >
          <Icon
            css={css`
              width: 16px;
            `}
          />
          {children}
        </div>
      ) : isLoading ? (
        <div
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;
          `}
        >
          <Spinner
            css={(theme) => css`
              width: 16px;
              ${color === "secondary" &&
              !disabled &&
              !isLoading &&
              css`
                color: ${theme.color.brand.primary};
              `}
            `}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
