/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "./Button";
import styled from "@emotion/styled";
import {XIcon} from "@heroicons/react/solid";
import {ReactNode} from "react";

export default function Notification({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) {
  return (
    <span
      css={css`
        width: 300px;
      `}
    >
      <span
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span
          css={css`
            width: 270px;
            flex: 1 1 0%;
            word-break: break-word;
          `}
        >
          {children}
        </span>
        <CloseButton background={false} icon={XIcon} onClick={onClose} />
      </span>
    </span>
  );
}

const CloseButton = styled(Button)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 10px;
`;
