import {QuoteForm, QuoteTypes} from "api/quote";
import {Form} from "pages/RateQuoteBind/RatePanel";
import SkpGeneralLiability from "./SkpGeneralLiability";
import LocationsForm from "../shared/LocationsForm";
import UnderwritingForm from "./UnderwritingForm";

export default function skp(quoteForm: QuoteForm): Form {
  return {
    sections: {
      "Risk Location": (
        <LocationsForm
          locations={quoteForm.locations}
          quoteId={`${quoteForm.quote_id}`}
          quoteType={QuoteTypes.SKP}
        />
      ),
      "General Liability": (
        <SkpGeneralLiability
          quoteForm={quoteForm}
          quoteId={`${quoteForm.quote_id}`}
        />
      ),
    },
    "Underwriting Questions": (
      <UnderwritingForm underwriting={quoteForm.underwriting} />
    ),
  };
}
