import {css} from "@emotion/react";
import styled from "@emotion/styled";

export default styled.div<{padding?: string}>`
  width: 100%;
  border-radius: 4px;
  background: white;
  color: black;
  list-style-type: none;
  padding: 0;
  outline: none !important;
  margin: 0;
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  box-shadow: 0px 2px 4px ${(props) => props.theme.color.grayscale[300]};
  overflow: auto;
  ${({padding}) =>
    padding &&
    css`
      padding: ${padding};
    `};
`;
