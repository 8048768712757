import {QuoteStatuses, RateResponse, getQuote, getRate} from "api/quote";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {useQuery, useQueryClient} from "react-query";
import {
  ErrorFilters,
  useSectionErrorContext,
} from "../contexts/sectionErrorContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import {useQuoteContext} from "../contexts/quoteContext";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {inReviewStatus} from "utils/quotePolicy";
import useShowAPIErrors from "../RatePanel/hooks/useShowAPIErrors";

export type RateConextInerface = {
  rateData?: RateResponse;
  fetchingRate: boolean;
  fetchRate: () => void;
  rate?: string;
  showRate: boolean;
  showRateButton: boolean;
  disableRateButton: boolean;
  canNotRateAndSubmitWhenBackendQuoting: boolean;
};

export const RateContext = createContext({
  rateData: undefined,
  fetchingRate: false,
  fetchRate: () => {},
  rate: undefined,
  showRate: false,
  showRateButton: false,
  disableRateButton: false,
  canNotRateAndSubmitWhenBackendQuoting: false,
} as RateConextInerface);

export function RateContextProvider({
  rateIndication,
  children,
}: {
  rateIndication?: string;
  children: ReactNode;
}) {
  const quoteContext = useQuoteContext();
  const permissions = usePermissions();
  const queryClient = useQueryClient();
  const {
    status,
    quoteId,
    dirty,
    setDirty,
    hasReferralPreRate,
    hasImmediateDeclination,
  } = useQuoteContext();

  const {getTotalFieldErrorsCount, getTotalNoneUWFieldErrorsCount} =
    useSectionErrorContext();
  const {
    setFieldsDisabled,
    showRequiredErrors,
    setShowRequiredErrors,
    fieldsDisabled,
  } = useFieldsStatusContext();
  const showAPIErrors = useShowAPIErrors();

  const totalNoneUWRequiredFieldErrors = getTotalNoneUWFieldErrorsCount(
    ErrorFilters.REQUIRED
  );
  const totalNoneUWFieldErrors = getTotalNoneUWFieldErrorsCount();
  const totalNonRequiredFieldErrors = getTotalFieldErrorsCount(
    ErrorFilters.NONREQUIRED
  );

  const {
    data: rateData,
    isFetching: fetchingRate,
    refetch: refetchRate,
  } = useQuery(["rate", quoteId.toString()], () => getRate(quoteId), {
    enabled: false,
    onSuccess: (data) => {
      setDirty(false);
      setFieldsDisabled(false);
      queryClient.invalidateQueries([getQuote.name, quoteId.toString()]);

      if (data.has_errors) {
        showAPIErrors(data.field_rule_results);
      }
    },
  });

  const rate = useMemo(
    () => rateData?.rate || rateIndication,
    [rateData, rateIndication]
  );
  const showRate = useMemo(
    () => !!rate && !dirty && !totalNoneUWFieldErrors,
    [rate, dirty, totalNoneUWFieldErrors]
  );
  const showRateButton = useMemo(
    () =>
      ((!inReviewStatus(status) &&
        permissions.includes(Permissions.EDIT_QUOTE)) ||
        (status !== QuoteStatuses.BOUND &&
          permissions.includes(Permissions.EDIT_QUOTE_ALWAYS) &&
          quoteContext.underwriterIsQuoting)) &&
      !hasReferralPreRate,
    [permissions, status, quoteContext.underwriterIsQuoting, hasReferralPreRate]
  );
  const disableRateButton = useMemo(
    () =>
      fieldsDisabled ||
      hasImmediateDeclination ||
      (!showRequiredErrors
        ? totalNonRequiredFieldErrors > 0
        : totalNoneUWFieldErrors > 0),
    [
      fieldsDisabled,
      showRequiredErrors,
      totalNonRequiredFieldErrors,
      totalNoneUWFieldErrors,
      hasImmediateDeclination,
    ]
  );

  const fetchRate = useCallback(() => {
    if (!showRequiredErrors && totalNoneUWRequiredFieldErrors) {
      setShowRequiredErrors(true);
    } else {
      setFieldsDisabled(true);
      refetchRate();
    }
  }, [
    refetchRate,
    setFieldsDisabled,
    setShowRequiredErrors,
    showRequiredErrors,
    totalNoneUWRequiredFieldErrors,
  ]);
  const canNotRateAndSubmitWhenBackendQuoting = useMemo(
    () =>
      quoteContext.isBackendQuoting &&
      !rate &&
      !permissions.includes(Permissions.BACKEND_QUOTING),
    [quoteContext.isBackendQuoting, rate, permissions]
  );

  return (
    <RateContext.Provider
      value={{
        rateData,
        fetchingRate,
        fetchRate,
        rate,
        showRate,
        showRateButton,
        disableRateButton,
        canNotRateAndSubmitWhenBackendQuoting,
      }}
    >
      {children}
    </RateContext.Provider>
  );
}

export function useRateContext() {
  return useContext(RateContext);
}
