import {
  useState,
  useMemo,
  useEffect,
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
} from "react";
import classNames from "classnames";
import styled from "@emotion/styled";
import {AsYouType} from "libphonenumber-js";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  width: 100%;
`;

const OutLabel = styled.label`
  ${(props) => props.theme.text.p1}
  color: ${(props) => props.theme.color.grayscale[900]};
  pointer-events: none;
  margin-bottom: 8px;
`;

const Label = styled.label`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[600]};
  font-weight: 400;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: calc(100% - 32px);
  transform: translate(16px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  &.prefix {
    transform: translate(57px, 16px) scale(1);
  }
  &.focused,
  &.filled {
    transform: translate(16px, -7px) scale(0.85);
    &.prefix {
      transform: translate(57px, -7px) scale(0.85);
    }
  }
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const RequiredMark = styled.span`
  margin-left: 3px;
  color: ${(props) => props.theme.color.state.red};
`;

const InputWrapper = styled.div`
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  &.disabled {
    background-color: ${(props) => props.theme.color.brand.tertiary};
  }
  &.background {
    background-color: #ffffff;
  }
`;

const Input = styled.input`
  ${(props) => props.theme.text.p2}
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 16px;
  margin: 1px 0 0 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  padding: 16.5px 16px;
  outline: none;
  color: ${(props) => props.theme.color.grayscale[800]};
  &[disabled] {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const Prefix = styled.div`
  ${(props) => props.theme.text.header3}
  height: 51px;
  width: 51px;
  color: ${(props) => props.theme.color.grayscale[600]};
  font-weight: bold;
  background: ${(props) => props.theme.color.brand.tertiary};
  border-radius: 4px 0 0 4px;
  border-right: 1px solid ${(props) => props.theme.color.grayscale[300]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FieldSet = styled.fieldset`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 1px 0 0 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  overflow: hidden;
  min-width: 0%;
  border-width: 1px;
  border-color: ${(props) => props.theme.color.grayscale[500]};
  &.focused {
    border-color: ${(props) => props.theme.color.grayscale[800]};
  }
  &.error {
    border-color: ${(props) => props.theme.color.state.red};
  }
`;

const Legend = styled.legend`
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 12px;
  visibility: hidden;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: 0.01px;
  &.prefix {
    margin-left: 42px;
  }
  &.focused,
  &.filled {
    max-width: 100%;
    padding: 0 8px 0 5px;
    -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    &.required {
      padding-right: 16px;
    }
  }
`;

export type TextInputProps = {
  label?: string;
  outLabel?: string;
  error?: boolean;
  required?: boolean;
  prefix?: string;
  background?: boolean;
  isFormattedNumber?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

let textInputIdNumber = 0;

function formatInputDate(date?: string) {
  if (!date) return "";
  let yourDate = new Date(date);
  return yourDate.toISOString().split("T")[0];
}

function formatInputNumber(inputValue: string) {
  if (["", "0", 0].includes(inputValue)) return "";
  // Remove existing thousands separators (commas)
  const formattedNumber = inputValue.replace(/,/g, "");
  // add new commas
  return Number(formattedNumber).toLocaleString();
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      outLabel,
      error,
      prefix,
      disabled,
      required,
      isFormattedNumber,
      type,
      value,
      defaultValue,
      className,
      placeholder,
      onChange,
      onBlur,
      onFocus,
      background,
      ...props
    }: TextInputProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [internalVal, setInternalValue] = useState<
      string | number | readonly string[] | undefined
    >(
      type === "date" && (value || defaultValue)
        ? formatInputDate((value || defaultValue) as string)
        : value || defaultValue || ""
    );

    const inputId = useMemo(() => `text-input-${textInputIdNumber++}`, []);
    const filled = useMemo(() => {
      if (typeof internalVal === "string") return internalVal.length > 0;
      return !!internalVal;
    }, [internalVal]);

    const updateEvent = useCallback(
      (e: any) => ({
        ...e,
        target: {
          ...e.target,
          value: isFormattedNumber
            ? String(Number(e.target.value.replaceAll(",", "")) || 0)
            : e.target.value,
        },
      }),
      [isFormattedNumber]
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInternalValue(() => {
        const inputValue = String(e.target.value);
        if (type === "tel") {
          // Allows user to backspace on ")" Example -> "(732)"
          if (inputValue.length === 4) return inputValue.slice(1, inputValue.length - 1);
          // Formats phone number to US format: (xxx) xxx-xxxx
          return new AsYouType("US").input(inputValue);
        } else if (isFormattedNumber) {
          return formatInputNumber(inputValue);
        } else return e.target.value;
      });
      if (onChange) onChange(updateEvent(e));
    };
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) onFocus(updateEvent(e));
    };
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (onBlur) onBlur(updateEvent(e));
    };

    useEffect(() => {
      const val = value || defaultValue || "";
      setInternalValue(type === "date" ? formatInputDate(val as string) : val);
      if (isFormattedNumber) {
        const formattedNumber = formatInputNumber(String(val));
        setInternalValue(formattedNumber);
      }
    }, [value, defaultValue, type, isFormattedNumber]);

    return (
      <Wrapper>
        {outLabel && (
          <OutLabel
            className={classNames({
              focused: focused,
              filled: filled || Boolean(placeholder),
              disabled: disabled,
              prefix: Boolean(prefix),
            })}
            htmlFor={inputId}
          >
            {outLabel}
            {required && <RequiredMark>*</RequiredMark>}
          </OutLabel>
        )}
        {label && (
          <Label
            className={classNames({
              focused: focused,
              filled: filled || Boolean(placeholder),
              disabled: disabled,
              prefix: Boolean(prefix),
            })}
            htmlFor={inputId}
          >
            {label}
            {required && <RequiredMark>*</RequiredMark>}
          </Label>
        )}
        <InputWrapper
          className={classNames({
            disabled: disabled,
            background: background,
          })}
        >
          {prefix ? <Prefix>{prefix}</Prefix> : <></>}
          <Input
            ref={ref}
            {...props}
            id={inputId}
            type={type}
            value={
              isFormattedNumber ? internalVal?.toLocaleString() : internalVal
            }
            className={className}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            defaultValue={defaultValue}
          />
          <FieldSet
            className={classNames({
              focused: focused,
              error: error,
            })}
          >
            <Legend
              className={classNames({
                focused: label && focused,
                filled: label && (filled || Boolean(placeholder)),
                required: label && required,
                prefix: Boolean(prefix),
              })}
            >
              {label}
            </Legend>
          </FieldSet>
        </InputWrapper>
      </Wrapper>
    );
  }
);
