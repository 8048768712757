import Button from "components/Button";
import {GuidlinesProps} from ".";

export default function YesNoButtons({
  onYes,
  onNo,
}: Omit<GuidlinesProps, "quoteType">) {
  const padding = 30;
  return (
    <>
      <Button sidesPadding={padding} onClick={onYes}>
        Yes
      </Button>
      <Button sidesPadding={padding} onClick={onNo}>
        No
      </Button>
    </>
  );
}
