/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {useRouteMatch} from "react-router-dom";
import {ReactComponent as AeigLogo} from "assets/images/AeigLogo.svg";
import IconButton from "components/IconButton";
import Link from "components/Link";
import ClearCache, {getCacheRefreshLink} from "components/ClearCache";

export interface MenuPage {
  title?: string;
  link: string;
  icon?: (props: {className?: string}) => JSX.Element;
  exact: boolean;
}

function Menu({padding, menuList}: {padding: number; menuList: MenuPage[]}) {
  return (
    <nav>
      <div
        css={css`
          margin-bottom: ${padding}px;
          padding-top: 10px;
        `}
      >
        <Link
          to="/"
          onClick={(e) => {
            if (e.ctrlKey) {
              window.location.href = getCacheRefreshLink();
            }
          }}
        >
          <AeigLogo />
        </Link>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: calc(100% - 100px);
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding-top: 24px;
          `}
        >
          {menuList.map((page) => (
            <MenuItem
              key={page.title}
              to={page.link}
              title={page.title}
              icon={page.icon}
              exact={page.exact}
            />
          ))}
        </div>
        <ClearCache />
      </div>
    </nav>
  );
}

function MenuItem({
  to,
  title,
  icon: PageIcon,
  exact,
}: {
  to: string;
  title?: string;
  icon?: (props: {className?: string}) => JSX.Element;
  exact: boolean;
}) {
  const match = useRouteMatch({path: to, exact});
  return (
    <Link to={to} aria-label={to}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-items: stretch;
        `}
      >
        <IconButton color={match ? "primary" : "secondary"}>
          {PageIcon && (
            <PageIcon
              css={css`
                width: 16px;
                height: 16px;
              `}
            ></PageIcon>
          )}
        </IconButton>
        <span
          css={(theme) => css`
            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #757676;
            padding-left: 8px;
            ${match &&
            css`
              color: ${theme.color.brand.primary};
            `};
          `}
        >
          {title}
        </span>
      </div>
    </Link>
  );
}

export default Menu;
