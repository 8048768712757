import styled from "@emotion/styled";

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 50px;
  padding-left: 80px;
  padding-bottom: 60px;
`;

export default ButtonsContainer;
