/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {ReactNode} from "react";

export function DetailsTable({children}: {children: ReactNode}) {
  return (
    <table
      css={(theme) => css`
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 10px;
        ${theme.text.p1}
        tr:nth-child(odd) {
          background-color: ${theme.color.grayscale[100]};
        }
        tr:nth-child(even) {
          background-color: ${theme.color.grayscale.white};
        }
      `}
    >
      <tbody>{children}</tbody>
    </table>
  );
}

export const Detail = styled.tr``;

export const Key = styled.td`
  padding: 13px;
  width: 300px;
`;

export const Value = styled.td`
  padding: 13px;
`;
