import {UnderwriterListItem, apiUrl, get, post, put} from "./common";
import {PolicyBasicInfo, PolicyModIds} from "./policy";

export const baseUrl = `${apiUrl}/auth`;

export enum UserRoles {
  AGENT = "AGENT",
  UNDERWRITER = "UNDERWRITER",
}

export interface Agency {
  id: string;
  first_name: string;
  last_name: string;
  office_code: string;
}

export enum Permissions {
  VIEW_AGENCY_POLICIES = "VIEW_AGENCY_POLICIES",
  VIEW_ALL_POLICIES = "VIEW_ALL_POLICIES",
  VIEW_INSURED_POLICIES = "VIEW_INSURED_POLICIES",
  VIEW_POLICY_BY_ID = "VIEW_POLICY_BY_ID",
  VIEW_POLICY_DOCUMENTS = "VIEW_POLICY_DOCUMENTS",
  VIEW_POLICY_BILLING = "VIEW_POLICY_BILLING",
  VIEW_AGENCY_QUOTES = "VIEW_AGENCY_QUOTES",
  VIEW_ALL_QUOTES = "VIEW_ALL_QUOTES",
  CREATE_QUOTE = "CREATE_QUOTE",
  LEGACY_BOP_QUOTES = "LEGACY_BOP_QUOTES",
  LEGACY_HO_QUOTES = "LEGACY_HO_QUOTES",
  LEGACY_HAB_QUOTES = "LEGACY_HAB_QUOTES",
  LEGACY_SKP_QUOTES = "LEGACY_SKP_QUOTES",
  LEGACY_MIXED_MERCANTILE_QUOTES = "LEGACY_MIXED_MERCANTILE_QUOTES",
  EDIT_QUOTE = "EDIT_QUOTE",
  EDIT_QUOTE_ALWAYS = "EDIT_QUOTE_ALWAYS",
  UNDERWRITE_QUOTE = "UNDERWRITE_QUOTE",
  SUBMIT_QUOTE = "SUBMIT_QUOTE",
  UNSUBMIT_QUOTE_ON_APPROVED = "UNSUBMIT_QUOTE_ON_APPROVED",
  UNSUBMIT_QUOTE_ALWAYS = "UNSUBMIT_QUOTE_ALWAYS",
  BIND_QUOTE = "BIND_QUOTE",
  OVERRIDE_QUOTE_RULES = "OVERRIDE_QUOTE_RULES",
  HIDE_QUOTE_FIELDS = "HIDE_QUOTE_FIELDS",
  VIEW_HIDDEN_QUOTE_FIELDS = "VIEW_HIDDEN_QUOTE_FIELDS",
  QUOTE_NOTES = "QUOTE_NOTES",
  VIEW_QUOTE_RULES_RESULTS_ALWAYS = "VIEW_QUOTE_RULES_RESULTS_ALWAYS",
  BACKEND_QUOTING = "BACKEND_QUOTING",
  TURN_BACKEND_QUOTING_OFF = "TURN_BACKEND_QUOTING_OFF",
  VIEW_PAYMENT = "VIEW_PAYMENT",
  MAKE_PAYMENT = "MAKE_PAYMENT",
  VIEW_SAVED_PAYMENT_METHODS = "VIEW_SAVED_PAYMENT_METHODS",
  SAVE_PAYMENT_METHOD_BY_USER_ONLY = "SAVE_PAYMENT_METHOD_BY_USER_ONLY",
  SAVE_PAYMENT_METHOD_BY_AGENCY_AND_POLICY = "SAVE_PAYMENT_METHOD_BY_AGENCY_AND_POLICY",
  SAVE_PAYMENT_METHOD_BY_POLICY_ONLY = "SAVE_PAYMENT_METHOD_BY_POLICY_ONLY",
  MANAGE_RECURRING_PAYMENT = "MANAGE_RECURRING_PAYMENT",
  VIEW_RECURRING_PAYMENT = "VIEW_RECURRING_PAYMENT",
  CREATE_THREAD = "CREATE_THREAD",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  VIEW_MY_THREADS = "VIEW_MY_THREADS",
  VIEW_ALL_THREADS = "VIEW_ALL_THREADS",
  MAKE_PAYMENTS_FOR_LESS_THAN_MINIMUM_DUE = "MAKE_PAYMENTS_FOR_LESS_THAN_MINIMUM_DUE",
  VIEW_QUOTE_INTERNAL_NOTES = "VIEW_QUOTE_INTERNAL_NOTES",
  ADD_QUOTE_INTERNAL_NOTE = "ADD_QUOTE_INTERNAL_NOTE",
  EDIT_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS = "EDIT_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS",
  DELETE_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS = "DELETE_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS",
  MAKE_PAYMENT_ON_CANCELLED_POICY = "MAKE_PAYMENT_ON_CANCELLED_POICY",
  CAN_QUOTE_MAJESCO_ALL_STATES = "CAN_QUOTE_MAJESCO_ALL_STATES",
  VIEW_ALL_UNDERWRITERS = "VIEW_ALL_UNDERWRITERS",
  VIEW_DIARY_COMMENTS = "VIEW_DIARY_COMMENTS",
  VIEW_ALL_REPORTS = "VIEW_ALL_REPORTS",
  VIEW_REPORT = "VIEW_REPORT",
  VIEW_MANUALS = "VIEW_MANUALS",
  VIEW_ALL_COMMISSION_STATEMENTS = "VIEW_ALL_COMMISSION_STATEMENTS",
  CREATE_MIXED_MERCANTILE_QUOTE = "CREATE_MIXED_MERCANTILE_QUOTE",
  VIEW_AGENCY_EXPERIENCE = "VIEW_AGENCY_EXPERIENCE",
  VIEW_FINANCE_REPORTS = "VIEW_FINANCE_REPORTS",
  CREATE_MAJESCO_NY_QUOTE = "CREATE_MAJESCO_NY_QUOTE",
}

export async function login(username: string, password: string) {
  return post(`${baseUrl}/login`, {
    data: {
      username,
      password,
    },
  });
}

export async function logout(): Promise<void> {
  return get(`${baseUrl}/logout`);
}

export async function getAuthSession() {
  return get<{
    auth_session_id: number;
    user_fullname: string;
    user_id: number | null;
    user_email: string;
    roles: UserRoles[];
    permissions: Permissions[];
    has_multiple_agencies: boolean;
    agency_fullname: string;
    agency_office_code: string;
    agency_id: string;
    is_underwriter: boolean;
  }>(`${baseUrl}/auth_session`);
}

export async function getUserByIdName(userId: number) {
  return get<{
    first_name: string;
    last_name: string;
  }>(`${baseUrl}/user/${userId}/name`);
}

export async function getUserAgencies() {
  return get<Agency[]>(`${baseUrl}/user/agencies`);
}

export async function setAgency(agencyId?: string | null) {
  return put(`${baseUrl}/auth_session/agency`, {
    data: {
      agency_id: agencyId || "",
    },
  });
}

export async function postGuestPay(
  email: string,
  policyNumber: string,
  zipCode: string
) {
  return post<PolicyModIds>(`${baseUrl}/guestpay`, {
    data: {
      email,
      policy_number: policyNumber,
      zip_code: zipCode,
    },
  });
}

export async function registerInsured(
  name: string,
  email: string,
  password: string,
  policyNumber: string,
  zipCode: string
) {
  return post<PolicyBasicInfo>(`${baseUrl}/insured_register`, {
    data: {
      name,
      email,
      password,
      policy_number: policyNumber,
      zip_code: zipCode,
    },
  });
}

export async function getAgencyUnderwritersList() {
  return await get<UnderwriterListItem[]>(`${baseUrl}/underwriters`);
}

export async function getUnderwritingStatesAndCompanies() {
  return await get<{
    states: string[];
    companies: string[];
  }>(`${baseUrl}/underwriting_states_and_companies`);
}
