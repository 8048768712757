/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import LoadingScreen from "components/LoadingScreen";
import {useState} from "react";

export default function ClassCoverageSearch() {
  let [loading, setLoading] = useState(true);

  return (
    <div>
      {loading ? <LoadingScreen /> : null}
      <iframe
        title="Class Coverage Search"
        src="https://www.aeiginsurance.com/class-coverage-search-embedded/"
        css={css`
          border: none;
          height: calc(100vh - 64px);
          width: 100%;
        `}
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
}
