import {Combobox as HuiCombobox} from "@headlessui/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {SelectHTMLAttributes, useMemo, useState} from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/solid";
import Center from "./Center";
import Surface from "./Surface";
import {HuiToFC} from "typeUtils";
import Spinner from "./Spinner";
import Button from "./Button";

export type OptionType<T> = {
  value: T;
  title: string;
};

const ClearButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 36px;
`;

const StyledLabel = styled.span`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  border-radius: 4px;
  outline: none;
  padding: 0 8px 0 0;
  &:disabled {
    background-color: ${(props) => props.theme.color.brand.tertiary};
  }
  &.background {
    background-color: #ffffff;
  }
  &.darkBackground {
    background-color: ${(props) => props.theme.color.grayscale[100]};
  }
`;

const OutLabel = styled.label`
  ${(props) => props.theme.text.p1}
  color: ${(props) => props.theme.color.grayscale[900]};
  pointer-events: none;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Label = styled.label`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[600]};
  font-weight: 400;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: calc(100% - 32px);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  &.medium {
    transform: translate(16px, 16px) scale(1);
    &.open,
    &.filled {
      transform: translate(16px, -7px) scale(0.85);
    }
  }
  &.small {
    transform: translate(10px, 8px) scale(1);
    &.open,
    &.filled {
      transform: translate(10px, -4px) scale(0.85);
    }
  }
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const DropDownIcon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.color.brand.primary};
  background: ${(props) => props.theme.color.brand.tertiary};
  padding: 5px;
  border-radius: 100%;
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[600]};
    background: ${(props) => props.theme.color.brand.tertiary} !important;
  }
  &:hover {
    background: ${(props) => props.theme.color.brand.secondary};
  }
`;

const RequiredMark = styled.span`
  margin-left: 3px;
  color: ${(props) => props.theme.color.state.red};
`;

// prettier-ignore
const InputTitle = styled(HuiCombobox.Input as HuiToFC<typeof HuiCombobox.Input>)`
  ${(props) => props.theme.text.p2}
  width: 100%;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 16px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  outline: none;
  text-align: left;
  color: ${(props) => props.theme.color.grayscale[800]};
  &.medium {
    margin: 1px 0 0 0;
    padding: 16.5px 16px;
  }
  &.small {
    margin: 0;
    padding: 10px 10px;
  }
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const FieldSet = styled.fieldset<{border?: boolean}>`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 1px 0 0 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  overflow: hidden;
  min-width: 0%;
  border-width: ${(props) =>
    props.border === undefined || props.border ? "1px" : "0px"};
  border-color: ${(props) => props.theme.color.grayscale[500]};
  &.open {
    border-color: ${(props) => props.theme.color.grayscale[800]};
  }
  &.error {
    border-color: ${(props) => props.theme.color.state.red};
  }
`;

const Legend = styled.legend`
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 12px;
  visibility: hidden;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: 0.01px;
  &.open,
  &.filled {
    max-width: 100%;
    padding: 0 8px 0 5px;
    -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    &.required {
      padding-right: 16px;
    }
  }
`;

const StyledOptions = styled(HuiCombobox.Options)`
  z-index: 2;
  position: absolute;
  top: calc(100% + 5px);
  outline: none !important;
  margin: 0;
  width: 100%;
  padding-inline-start: 0;
` as typeof HuiCombobox.Options;

// prettier-ignore
const StyledOption = styled(HuiCombobox.Option as HuiToFC<typeof HuiCombobox.Option>)`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[800]};
  cursor: pointer;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.grayscale[300]};
  outline: none !important;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: white;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: normal;
  & svg {
    color: ${(props) => props.theme.color.brand.primary};
  }
  padding: 16px 0;
  &.small {
    padding: 10px 0;
  }
  &.selected {
    justify-content: space-between;
  }
  &.active {
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  overflow: show;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export type ComboboxProps<T> = {
  label?: string;
  placeholder?: string;
  outLabel?: string;
  options: OptionType<T>[];
  error?: boolean;
  required?: boolean;
  onSearch?: (value: string) => void;
  handleFilterOptions?: (
    options: OptionType<T>[],
    search: string
  ) => OptionType<T>[];
  onChange: (value?: OptionType<T>) => void;
  size?: "medium" | "small";
  background?: boolean;
  optionLoading?: boolean;
  value?: OptionType<T>;
  chevron?: boolean;
  border?: boolean;
  darkBackground?: boolean;
  onEnterKey?: (value: any) => void;
} & Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  "onChange" | "size" | "value"
>;

let comboboxIdNumber = 0;

export function Combobox<T>({
  label,
  placeholder,
  outLabel,
  error,
  disabled,
  required,
  value,
  options,
  onChange,
  onSearch,
  title,
  size = "medium",
  background,
  optionLoading,
  handleFilterOptions,
  chevron,
  border,
  darkBackground,
  onEnterKey,
}: ComboboxProps<T>) {
  const inputId = useMemo(() => `combobox-${comboboxIdNumber++}`, []);
  const [searchQuery, setSearchQuery] = useState("");
  options = useMemo(() => {
    if (onSearch) return options;
    else if (handleFilterOptions)
      return handleFilterOptions(options, searchQuery);
    else
      return options.filter((option) =>
        option.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
  }, [options, searchQuery, handleFilterOptions, onSearch]);
  return (
    <Wrapper>
      {outLabel && (
        <OutLabel htmlFor={inputId}>
          {outLabel}
          {required && <RequiredMark>*</RequiredMark>}
        </OutLabel>
      )}
      <HuiCombobox
        value={value}
        disabled={disabled}
        onChange={(value) => value && onChange(value)}
      >
        {({open}) => (
          <>
            <StyledLabel
              className={classNames({
                background: background,
                darkBackground: darkBackground,
              })}
            >
              {label && (
                <Label
                  className={classNames({
                    open: open,
                    filled: !!value,
                    disabled: disabled,
                    small: size === "small",
                    medium: size === "medium",
                  })}
                  htmlFor={inputId}
                >
                  {label}
                  {required && <RequiredMark>*</RequiredMark>}
                </Label>
              )}
              <InputTitle
                placeholder={placeholder}
                autoFocus
                className={classNames({
                  small: size === "small",
                  medium: size === "medium",
                  disabled: disabled,
                })}
                onChange={(event) => {
                  if (onSearch) onSearch(event.target.value);
                  else setSearchQuery(event.target.value);
                }}
                displayValue={(value: OptionType<T> | undefined) => {
                  return value !== undefined ? value.title : "";
                }}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    onEnterKey?.(e.target.value);
                  }
                }}
              />
              {value && value.value && (
                <ClearButton
                  background={false}
                  icon={XIcon}
                  onClick={() => {
                    onChange(undefined);
                    onSearch && onSearch("");
                    setSearchQuery("");
                  }}
                />
              )}
              {optionLoading ? (
                <span style={{width: 16, marginRight: 5}}>
                  <Spinner />
                </span>
              ) : chevron !== undefined && !chevron ? (
                <span style={{width: 16, marginRight: 5}} />
              ) : options.length > 0 && !disabled ? (
                <HuiCombobox.Button as={DropDownIcon}>
                  <Center>
                    {open ? (
                      <ChevronUpIcon width="15" />
                    ) : (
                      <ChevronDownIcon width="15" />
                    )}
                  </Center>
                </HuiCombobox.Button>
              ) : (
                <DropDownIcon
                  className={classNames({
                    disabled: true,
                  })}
                >
                  <Center>
                    <ChevronDownIcon width="15" />
                  </Center>
                </DropDownIcon>
              )}
              <FieldSet
                border={border}
                className={classNames({
                  open: open,
                  error: error,
                })}
              >
                <Legend
                  className={classNames({
                    open: label && open,
                    filled: label && !!value,
                    required: label && required,
                  })}
                >
                  {label}
                </Legend>
              </FieldSet>
            </StyledLabel>
            {options.length > 0 && (
              <StyledOptions>
                <Surface
                  style={{
                    maxHeight: "50vh",
                  }}
                >
                  <div
                    style={{padding: size === "medium" ? "0 16px" : "0 8px"}}
                  >
                    {options.map((option, index) => (
                      <StyledOption key={index} value={option}>
                        {({selected, active}) => (
                          <OptionWrapper
                            className={classNames({
                              selected: selected,
                              active: active,
                              small: size === "small",
                            })}
                          >
                            <span>{option.title}</span>
                            {selected && !title && <CheckIcon width="15" />}
                          </OptionWrapper>
                        )}
                      </StyledOption>
                    ))}
                  </div>
                </Surface>
              </StyledOptions>
            )}
          </>
        )}
      </HuiCombobox>
    </Wrapper>
  );
}
