import styled from "@emotion/styled";

const IndentWrapper = styled.div`
  border-left: 1px solid ${(props) => props.theme.color.grayscale[300]};
  padding-left: 18px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export default IndentWrapper;
