/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {useState} from "react";
import buildingsImage from "assets/images/buildings.jpg";
import SplitScreen from "components/SplitScreen";
import {useQuery} from "react-query";
import {getUserAgencies} from "api/auth";
import {H1} from "components/elements";
import {DropDown} from "components/DropDown";
import Loading from "components/Loading";
import {useAuth} from "hooks/auth";
import {useNavigation} from "hooks/navigation";
import {
  SelectionGroupContainer,
  SelectionGroupOption,
} from "components/SelectionGroup";

export default function SelectAgency() {
  const agenciesQuery = useQuery(["user", "agnecies"], getUserAgencies);
  const {agencyId, setAgency, setAgencyLoading} = useAuth();
  const [selectedAgencyId, selectAgencyId] = useState(agencyId || "");
  const {navigate} = useNavigation();
  return (
    <SplitScreen imageurl={buildingsImage}>
      <form
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
        `}
        onSubmit={async (e) => {
          e.preventDefault();
          await setAgency(selectedAgencyId);
          navigate({url: "/", replace: true});
        }}
      >
        {agenciesQuery.data ? (
          <>
            <H1>Select Agency</H1>
            {agenciesQuery.data.length > 3 ? (
              <DropDown
                value={selectedAgencyId}
                options={agenciesQuery.data.map((agency) => ({
                  title: `${agency.first_name || ""} ${
                    agency.last_name || ""
                  } ${" "} ${agency.office_code || ""}`.trim(),
                  value: agency.id,
                }))}
                onChange={(value) => {
                  selectAgencyId(value);
                }}
              />
            ) : (
              <SelectionGroupContainer
                value={selectedAgencyId}
                onChange={selectAgencyId}
              >
                {agenciesQuery.data.map((agency) => (
                  <SelectionGroupOption value={agency.id}>
                    {`${agency.first_name || ""} ${
                      agency.last_name || ""
                    } ${" "} ${agency.office_code || ""}`.trim()}
                  </SelectionGroupOption>
                ))}
              </SelectionGroupContainer>
            )}
            <Button type="submit" fullwidth isLoading={setAgencyLoading}>
              Continue
            </Button>
          </>
        ) : (
          <Loading />
        )}
      </form>
    </SplitScreen>
  );
}
