import {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
  LocationObject,
  SearchParamsObject,
  getUrl,
  LocationObjectWithRequiredPath,
  getMergedInLocationObjectUrl,
} from "utils/url";
import {navigationContext} from "contexts/navigation";

export type To = NavigationObject | LocationObjectWithRequiredPath | string;

export interface NavigationObject {
  url: string | LocationObjectWithRequiredPath;
  replace?: boolean;
}

export function useNavigation() {
  const history = useHistory();
  const {isStartingPoint} = useContext(navigationContext);
  const location = useLocation();
  function getMergedUrl(locationObject: LocationObject) {
    return getMergedInLocationObjectUrl(location, locationObject);
  }
  const currentUrl = getMergedUrl({});
  function navigate(to: To, openInNewTab = false) {
    const {url, replace} = extractUrlAndReplaceFromTo(to);

    if (openInNewTab) window.open(url, "_blank")?.focus();
    else if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      if (!replace) history.push(url);
      else history.replace(url);
    }
  }
  return {
    location: {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    },
    navigate,
    updateSearchParams: (params: SearchParamsObject) =>
      history.replace(getMergedUrl({searchParamsObject: params})),
    removeParams(keys: string[]) {
      const searchParams = new URLSearchParams(location.search);
      keys.forEach((key) => searchParams.delete(key));
      location.search = searchParams.toString();
      history.replace(getMergedInLocationObjectUrl(location, {}));
    },
    setHash: (hash: string) => history.replace(getMergedUrl({hash})),
    currentUrl,
    getMergedUrl,
    back: history.goBack,
    startingPoint: isStartingPoint,
  };
}

export function extractUrlAndReplaceFromTo(to: To) {
  let url = "";
  let replace;
  if (typeof to === "object") {
    if ("url" in to) {
      if (typeof to.url === "object") url = getUrl(to.url);
      else url = to.url;
      replace = to.replace;
    }
    if ("path" in to) {
      url = getUrl(to);
    }
  } else {
    url = to;
  }
  return {url, replace};
}
