/** @jsxImportSource @emotion/react */

import {QuoteForm} from "api/quote";
import Box from "components/Box";
import {GridContainer} from "components/Grid";
import Field from "./Field";
import {companyNameMap} from "./fieldValues";
import {useAuth} from "hooks/auth";
import {DialogBody, Dialog, DialogButtonsContainer} from "components/Dialog";
import {useState} from "react";
import Button from "components/Button";
import {useQuoteContext} from "../contexts/quoteContext";
import {H3} from "components/elements";

export const QUOTE_INFO_SECTION_NAME = "Quote Info";

export default function QuoteInfoSection({quoteForm}: {quoteForm: QuoteForm}) {
  const {assignedCompanies} = useAuth();
  const [showCompanyPopup, setShowCompanyPopup] = useState(false);

  return (
    <Box mt={50} mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.DropDown
          options={companyNameMap.filter((company) => {
            if (quoteForm.mixed_mercantile || quoteForm.skp ) return company.value === "10";
            return assignedCompanies.includes(company.value);
          })}
          object={quoteForm}
          field={"company_number"}
          label="Company"
          wrapperProps={{
            growXXS: 12,
            growMD: 8,
          }}
          onChange={(v) => {
            if (v === "12") {
              setShowCompanyPopup(false);
            }
          }}
        />

        <Field.TextInput
          type="date"
          placeholder="mm/dd/yyyy"
          object={quoteForm}
          field={"effective_date"}
          label="Effective date"
          wrapperProps={{
            growXXS: 12,
            growMD: 4,
          }}
        />
      </GridContainer>
      <CompanyPopup
        quoteForm={quoteForm}
        show={showCompanyPopup}
        onClose={() => setShowCompanyPopup(false)}
      />
    </Box>
  );
}

function CompanyPopup({
  quoteForm,
  show,
  onClose,
}: {
  quoteForm: QuoteForm;
  show: boolean;
  onClose: () => void;
}) {
  const {update} = useQuoteContext();
  function handleClosing() {
    update(
      quoteForm.object_name,
      quoteForm.object_path,
      quoteForm.id,
      "company_number",
      "10"
    );
    onClose();
  }
  return (
    <Dialog open={show} title="" onClose={handleClosing}>
      <DialogBody>
        <H3>
          In order to receive a quote in Falls Lake National Insurance Company
          paper, please proceed to Protosure.
        </H3>
      </DialogBody>
      <DialogButtonsContainer>
        <Button color="secondary" onClick={handleClosing}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (process.env.REACT_APP_NEW_HABITATIONAL_QUOTE_LINK) {
              window.location.href =
                process.env.REACT_APP_NEW_HABITATIONAL_QUOTE_LINK;
            }
          }}
        >
          Proceed to Protosure
        </Button>
      </DialogButtonsContainer>
    </Dialog>
  );
}
