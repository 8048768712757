/** @jsxImportSource @emotion/react */

import {
  getAllPoliciesDiaryCommentsByDate,
  getAllPoliciesDiaryCommentsDestinationInitials,
  PolicyDiaryComment,
} from "api/policy";
import Loading from "components/Loading";
import Pagination from "components/Pagination";
import {Body, Cell, Header, HeaderCell, Row, Table} from "components/Table";
import {TextInput} from "components/TextInput";
import {useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import {useRef} from "react";
import styled from "@emotion/styled";
import Spinner from "components/Spinner";
import {PaginatedResult} from "api/common";
import {UseQueryResult} from "react-query";
import {formatDate} from "utils/format";
import {DropDown} from "components/DropDown";
import Link from "components/Link";
import links from "links";

export interface PoliciesDiaryCommentsPageParams {
  fromDate: string;
  toDate: string;
  limit: number;
  pageNumber: number;
  destinationInitials: string;
}

export default function DiaryComments({
  fromDate,
  toDate,
  limit,
  pageNumber,
  destinationInitials,
}: PoliciesDiaryCommentsPageParams) {
  const {updateSearchParams} = useNavigation();
  const policiesDiaryDestinationInitialsQuery = useApiQuery(
    getAllPoliciesDiaryCommentsDestinationInitials,
    {
      keepPreviousData: true,
    }
  )();
  const initials = policiesDiaryDestinationInitialsQuery.data;
  const policiesDiaryCommentsQuery = useApiQuery(
    getAllPoliciesDiaryCommentsByDate,
    {
      keepPreviousData: true,
    }
  )(
    fromDate,
    toDate,
    limit.toString(),
    pageNumber.toString(),
    destinationInitials
  );
  return (
    <PageContainer>
      <ToolbarContainer>
        <DateRangeWrapper>
          <TextInput
            type="date"
            background
            label="From"
            value={fromDate}
            onChange={(e) =>
              updateSearchParams({fromDate: e.target.value, pageNumber: 1})
            }
          />
          <TextInput
            type="date"
            background
            label="To"
            value={toDate}
            onChange={(e) =>
              updateSearchParams({toDate: e.target.value, pageNumber: 1})
            }
          />
          {initials && (
            <DestinationContainer>
              <DropDown
                background={true}
                options={["Show All", ...initials].map((initial) => ({
                  title: initial,
                  value: initial === "Show All" ? "" : initial,
                }))}
                onChange={(value) =>
                  updateSearchParams({
                    destinationInitials: value,
                    pageNumber: 1,
                  })
                }
                label={"Destination"}
                value={destinationInitials}
              />
            </DestinationContainer>
          )}
        </DateRangeWrapper>
        {policiesDiaryCommentsQuery.isPreviousData && <LoadingIndicator />}
      </ToolbarContainer>
      <PoliciesDiaryCommentsList
        fromDate={fromDate}
        toDate={toDate}
        limit={limit}
        pageNumber={pageNumber}
        policiesDiaryCommentsQuery={policiesDiaryCommentsQuery}
        destinationInitials={destinationInitials}
      />
    </PageContainer>
  );
}

function PoliciesDiaryCommentsList({
  limit,
  pageNumber,
  policiesDiaryCommentsQuery,
}: PoliciesDiaryCommentsPageParams & {
  policiesDiaryCommentsQuery: UseQueryResult<
    PaginatedResult<PolicyDiaryComment[]>
  >;
}) {
  const ref = useRef(null);

  if (policiesDiaryCommentsQuery.data) {
    const policiesDiaryComments = policiesDiaryCommentsQuery.data.result;
    const totalCount = policiesDiaryCommentsQuery.data.total_count;
    if (policiesDiaryComments.length === 0) {
      return <p>No DiaryComments</p>;
    }

    return (
      <>
        <Table ref={ref} disabled={policiesDiaryCommentsQuery.isPreviousData}>
          <Header>
            <HeaderCell>Destination</HeaderCell>
            <HeaderCell>Policy Number</HeaderCell>
            <HeaderCell>Due Date</HeaderCell>
            <HeaderCell>Comment</HeaderCell>
          </Header>
          {policiesDiaryComments.map((diaryComment) => (
            <Body>
              <Row>
                <Cell>{diaryComment.comment_destination}</Cell>
                <Cell>
                  <Link
                    to={links.policy({
                      policyModIds: diaryComment.policy_mod_ids,
                      tab: "details",
                    })}
                  >
                    {diaryComment.policy_mod_ids.display_policy_number}
                  </Link>
                </Cell>
                <Cell>{formatDate(diaryComment.due_date)}</Cell>
                <Cell
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {diaryComment.comment}
                </Cell>
              </Row>
            </Body>
          ))}
        </Table>
        <Pagination
          isCurrentPageLoaded={!policiesDiaryCommentsQuery.isPreviousData}
          scrollOffset={70}
          resultsElementRef={ref}
          pageNumber={pageNumber}
          limit={limit}
          totalCount={totalCount}
        />
      </>
    );
  }

  if (policiesDiaryCommentsQuery.isError) {
    return <p>Error</p>;
  } else {
    return <Loading size="medium" />;
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  gap: 6px;
  max-width: 500px;
`;

const LoadingIndicator = styled(Spinner)`
  height: 20px;
  padding: 0px 6px;
`;

const DestinationContainer = styled.div`
  width: 500px;
`;
