import {ReactNode} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {ThemeProvider} from "@emotion/react";
import theme from "./theme";
import {NavigationContextProvider} from "contexts/navigation";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const reactQueryDevtools = process.env.REACT_APP_REACT_QUERY_DEVTOOLS ===
  "true" && <ReactQueryDevtools initialIsOpen={false} />;

function AppProviders({children}: {children: ReactNode}) {
  return (
    <Router>
      <NavigationContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
          {reactQueryDevtools}
        </QueryClientProvider>
      </NavigationContextProvider>
    </Router>
  );
}

export default AppProviders;
