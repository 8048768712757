import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

type GridContainerProps = {
  gap?: number;
  rowGap?: number;
  columnGap?: number;
  justifyContent?: string;
} & React.HTMLProps<HTMLDivElement>;

export const GridContainer = styled.div<GridContainerProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${(props) =>
    props.rowGap || props.gap
      ? `width: calc(100% + ${props.rowGap || props.gap || 0}px);`
      : ""}
  ${(props) =>
    props.gap || props.rowGap || props.columnGap
      ? `margin: -${(props.columnGap || props.gap || 0) / 2}px -${
          (props.rowGap || props.gap || 0) / 2
        }px -${(props.columnGap || props.gap || 0) / 2}px -${
          (props.rowGap || props.gap || 0) / 2
        }px;`
      : ""}
  ${(props) =>
    props.gap || props.rowGap || props.columnGap
      ? `& > .grid-item { padding: ${
          (props.columnGap || props.gap || 0) / 2
        }px ${(props.rowGap || props.gap || 0) / 2}px ${
          (props.columnGap || props.gap || 0) / 2
        }px ${(props.rowGap || props.gap || 0) / 2}px; }`
      : ""}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
`;

export type GridItemProps = {
  grow?: number;
  growXXS?: number;
  growXS?: number;
  growSM?: number;
  growMD?: number;
  growLG?: number;
  growXL?: number;
  growXXL?: number;
  growXXXL?: number;
} & React.HTMLProps<HTMLDivElement>;

export const GridItemDiv = styled.div<GridItemProps>`
  flex-grow: 0;
  ${(props) => (props.grow ? `max-width: ${100 * (props.grow / 12)}%;` : "")}
  ${(props) => (props.grow ? `flex-basis: ${100 * (props.grow / 12)}%;` : "")}
  ${(props) =>
    props.growXXS
      ? `
    @media (min-width: ${props.theme!.breakpoint.xxs}px) {
      max-width: ${100 * (props.growXXS / 12)}%;
      flex-basis: ${100 * (props.growXXS / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growXS
      ? `
    @media (min-width: ${props.theme!.breakpoint.xs}px) {
      max-width: ${100 * (props.growXS / 12)}%;
      flex-basis: ${100 * (props.growXS / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growSM
      ? `
    @media (min-width: ${props.theme!.breakpoint.sm}px) {
      max-width: ${100 * (props.growSM / 12)}%;
      flex-basis: ${100 * (props.growSM / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growMD
      ? `
    @media (min-width: ${props.theme!.breakpoint.md}px) {
      max-width: ${100 * (props.growMD / 12)}%;
      flex-basis: ${100 * (props.growMD / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growLG
      ? `
    @media (min-width: ${props.theme!.breakpoint.lg}px) {
      max-width: ${100 * (props.growLG / 12)}%;
      flex-basis: ${100 * (props.growLG / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growXL
      ? `
    @media (min-width: ${props.theme!.breakpoint.xl}px) {
      max-width: ${100 * (props.growXL / 12)}%;
      flex-basis: ${100 * (props.growXL / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growXXL
      ? `
    @media (min-width: ${props.theme!.breakpoint.xxl}px) {
      max-width: ${100 * (props.growXXL / 12)}%;
      flex-basis: ${100 * (props.growXXL / 12)}%;
    }
  `
      : ""}
  ${(props) =>
    props.growXXXL
      ? `
    @media (min-width: ${props.theme!.breakpoint.xxxl}px) {
      max-width: ${100 * (props.growXXXL / 12)}%;
      flex-basis: ${100 * (props.growXXXL / 12)}%;
    }
  `
      : ""}
`;

export function GridItem(props: Omit<GridItemProps, "as">) {
  return (
    <GridItemDiv
      {...props}
      className={classNames("grid-item", props.className)}
    >
      {props.children}
    </GridItemDiv>
  );
}
