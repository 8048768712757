/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {FieldRuleResults, QuoteStatuses, RuleResultType} from "api/quote";
import Button from "components/Button";
import {usePermissions} from "hooks/auth";
import {useMemo} from "react";
import {Permissions} from "api/auth";
import {GridContainer, GridItem} from "components/Grid";
import {ruleResultsToList} from ".";

function ReferredContent({
  status,
  userID,
  fieldRuleResults,
  activatingReview,
  submittingReview,
  unsubmittingQuote,
  onActivateReview,
  onSubmitReview,
  onUnSubmitQuote,
}: {
  status: QuoteStatuses;
  uwID: number;
  userID: number;
  fieldRuleResults: FieldRuleResults;
  activatingReview: boolean;
  submittingReview: boolean;
  unsubmittingQuote: boolean;
  onActivateReview: (activate: boolean) => void;
  onSubmitReview: (approve: boolean) => void;
  onUnSubmitQuote: () => void;
}) {
  const permissions = usePermissions();
  const ruleResultsList = useMemo(
    () => ruleResultsToList(fieldRuleResults),
    [fieldRuleResults]
  );
  const referredList = useMemo(
    () =>
      ruleResultsList.filter((item) =>
        [RuleResultType.REFERRED, RuleResultType.REFERRED_PRERATE].includes(
          item.result_type
        )
      ),
    [ruleResultsList]
  );
  const previousDeclinedReferredList = useMemo(
    () => ruleResultsList.filter((item) => item.is_prev_declination),
    [ruleResultsList]
  );
  const previousReferredList = useMemo(
    () => ruleResultsList.filter((item) => item.is_prev_referral),
    [ruleResultsList]
  );

  const previousDeclinedOrReferredText = getPreviousDeclinedOrReferredText(
    previousDeclinedReferredList.length > 0,
    previousReferredList.length > 0
  );

  return (
    <>
      {permissions.includes(Permissions.UNSUBMIT_QUOTE_ALWAYS) ? (
        <Button
          disabled={submittingReview}
          size="large"
          isLoading={unsubmittingQuote}
          css={css`
            margin-bottom: 20px;
          `}
          onClick={() => onUnSubmitQuote()}
        >
          Unsubmit Quote
        </Button>
      ) : (
        <></>
      )}

      {/* Main Part */}
      <div
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          min-height: 300px;
          width: 600px;
          background: ${theme.color.brand.tertiary};
          border-radius: 8px;
          padding: 40px 32px;
        `}
      >
        <h2
          css={(theme) =>
            css`
              ${theme.text.header1}
              margin-top: 0px;
              text-align: center;
            `
          }
        >
          {referredList.length
            ? "This submission was referred to an underwriter for further review for the following reason"
            : "This risk is being reviewed by an underwriter."}
        </h2>
        {referredList.length ? (
          <div
            css={(theme) =>
              css`
                ${theme.text.p2}
                margin: 20px 0px 40px;
                border-radius: 8px;
                min-height: 100px;
                max-height: 300px;
                background: white;
                width: 100%;
                display: flex;
                text-align: center;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                overflow-y: auto;
              `
            }
          >
            {referredList.map((refferedItem, index) => (
              <div key={index}>{refferedItem.message}</div>
            ))}
            {permissions.includes(Permissions.UNDERWRITE_QUOTE) &&
            previousDeclinedOrReferredText ? (
              previousDeclinedOrReferredText
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        {permissions.includes(Permissions.UNDERWRITE_QUOTE) ? (
          !submittingReview ? (
            <GridContainer rowGap={24} columnGap={16}>
              <GridItem grow={6}>
                <Button
                  isLoading={submittingReview}
                  onClick={() => onSubmitReview(true)}
                  fullwidth
                >
                  Approve
                </Button>
              </GridItem>
              <GridItem grow={6}>
                <Button
                  isLoading={submittingReview}
                  onClick={() => onSubmitReview(false)}
                  color="secondary"
                  fullwidth
                >
                  Decline
                </Button>
              </GridItem>
            </GridContainer>
          ) : (
            <Button isLoading={true} color="secondary" fullwidth>
              Submitting Review
            </Button>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

function getPreviousDeclinedOrReferredText(
  prevDeclined: boolean,
  prevReferred: boolean
) {
  if (prevDeclined && prevReferred)
    return "Fields previously declined and referred";
  if (prevDeclined) return "Fields previously declined";
  if (prevReferred) return "Fields previously referred";
  return "";
}

export default ReferredContent;
