import {Dialog} from "@headlessui/react";
import styled from "@emotion/styled";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import Button from "components/Button";
import Box from "components/Box";

const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const DialogBody = styled.div`
  background-color: rgba(255, 255, 255, 1);
  width: 28rem;
  padding: 1.5rem;
  box-shadow: 0px 2px 4px ${(props) => props.theme.color.grayscale[300]};
  border-radius: 10px;
  z-index: 11;
`;

const DialogDescription = styled(Dialog.Description)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
` as typeof Dialog.Description;

export default function UpdateErrorPopup() {
  const {apiErrors, setApiErrors} = useFieldsStatusContext();

  return (
    <Dialog
      open={!!apiErrors?.length}
      onClose={() => setApiErrors(undefined)}
      className="headlessDialog"
    >
      <DialogOverlay />

      <DialogBody>
        <Dialog.Title>Error</Dialog.Title>
        {apiErrors?.map((errorMessage, index) => (
          <DialogDescription key={index}>{errorMessage}</DialogDescription>
        ))}
        <Box width="100%" display="flex" justifyContent="center">
          <Button onClick={() => setApiErrors(undefined)}>Okay</Button>
        </Box>
      </DialogBody>
    </Dialog>
  );
}
