import {snakeCaseToCamelCase} from "./format";

type WithRequired<T, K extends keyof T> = T & {[P in K]-?: T[P]};

export interface SearchParamsObject {
  [key: string]: string | number | null | undefined | boolean;
}

interface Location {
  pathname: string;
  search?: string;
  hash?: string;
}

export interface LocationObject {
  path?: string;
  searchParamsObject?: SearchParamsObject;
  hash?: string;
}

export type LocationObjectWithRequiredPath = WithRequired<
  LocationObject,
  "path"
>;

export function getUrl({path, searchParamsObject, hash}: LocationObject) {
  return computeUrlString({
    pathname: path || "",
    search: updateSearchParamsFromObject(
      new URLSearchParams(),
      searchParamsObject || {}
    ).toString(),
    hash,
  });
}

export function getMergedInLocationObjectUrl(
  location: Location,
  locationObject: LocationObject
) {
  return computeUrlString({
    pathname: locationObject.path || location.pathname || "",
    search: updateSearchParamsFromObject(
      new URLSearchParams(location.search),
      locationObject.searchParamsObject || {}
    ).toString(),
    hash: locationObject.hash || location.hash,
  });
}

export function computeUrlString({pathname, search, hash}: Location) {
  let url = pathname;
  if (search) url += `?${search}`;
  if (hash) url += `#${hash.replace("#", "")}`;
  return url;
}

export function updateSearchParamsFromObject(
  searchParams: URLSearchParams,
  searchParamsObject: SearchParamsObject
) {
  Object.entries(searchParamsObject).forEach(([key, value]) => {
    searchParams.set(key, String(value));
  });
  return searchParams;
}

export function getFromSearchParamsToObject<T extends {[index: string]: any}>(
  searchParams: URLSearchParams,
  object: T
) {
  const params = Object.keys(object);
  if (
    params
      .map((param) => searchParams.has(snakeCaseToCamelCase(param)))
      .some((v) => !v)
  )
    return null;

  params.forEach((param) => {
    object[param as keyof T] = searchParams.get(
      snakeCaseToCamelCase(param)
    )! as T[keyof T];
  });

  return object;
}
