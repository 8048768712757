import {
  deleteQuoteInternalNote,
  getQuoteInternalNotes,
  postQuoteInternalNote,
  editQuoteInternalNote,
} from "api/internalNote";
import {
  addPaymentMethod,
  addRecurringPayment,
  deletePaymentMethod,
  getPaymentMethods,
  getPaymentTransactions,
  getPolicyPaymentInfo,
  getPolicyPaymentProviderInfo,
  getRecurringPayment,
  makePaymentWithSavedMethod,
  removeRecurringPayment,
  updateRecurringPayment,
} from "api/payment";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import {useNotification} from "./notification";

type ApiFunction = (...args: any[]) => Promise<any>;

export function useApiQuery<T extends ApiFunction>(
  apiFunction: T,
  options: Omit<
    UseQueryOptions<Awaited<ReturnType<T>>>,
    "queryKey" | "queryFn"
  > = {}
) {
  return function useApiQueryInternal(...args: Parameters<T>) {
    // TODO: before merge, check if nexted object becomes serialized
    return useQuery(
      [apiFunction.name, ...args],
      () => apiFunction(...args),
      options
    );
  };
}

export function useApiMutation<T extends ApiFunction>(
  apiFunction: T,
  options: UseMutationOptions<
    Awaited<ReturnType<T>>,
    unknown,
    Parameters<T>,
    unknown
  > = {}
) {
  const queryClient = useQueryClient();
  const {notify} = useNotification();
  return useMutation((variables: Parameters<T>) => apiFunction(...variables), {
    ...options,
    onSuccess: (data, variables, context) => {
      const invalidationKeys = invalidationMap[apiFunction.name];
      const promises = [];
      if (invalidationKeys)
        invalidationKeys.forEach((key) =>
          promises.push(queryClient.invalidateQueries([key]))
        );
      if (options.onSuccess)
        promises.push(options.onSuccess(data, variables, context));
      return Promise.all(promises);
    },
    onError: (error: any, variables, context) => {
      if (typeof error === "string" && error.includes("Traceback")) {
        notify("We're sorry, but we encountered an error. Please try again.");
      }

      if (options.onError) return options.onError(error, variables, context);
    },
  });
}

const invalidationMap: {[key: string]: string[]} = {
  [addPaymentMethod.name]: [
    getPaymentMethods.name,
    getPolicyPaymentProviderInfo.name,
  ],
  [deletePaymentMethod.name]: [getPaymentMethods.name],
  [makePaymentWithSavedMethod.name]: [
    getPolicyPaymentInfo.name,
    getPaymentTransactions.name,
  ],
  [addRecurringPayment.name]: [getRecurringPayment.name],
  [updateRecurringPayment.name]: [getRecurringPayment.name],
  [removeRecurringPayment.name]: [getRecurringPayment.name],
  [postQuoteInternalNote.name]: [getQuoteInternalNotes.name],
  [deleteQuoteInternalNote.name]: [getQuoteInternalNotes.name],
  [editQuoteInternalNote.name]: [getQuoteInternalNotes.name],
};
