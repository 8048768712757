import {
  AdditionalInterestType,
  getQuote,
  QuoteForm,
  SkpAdditionalInterest,
} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import FormTitle from "../../../components/FormTitle";
import Field from "../../Field";
import ActionButton from "../../ActionButton";
import styled from "@emotion/styled";
import {PlacesAutocomplete} from "../../PlacesAutoComplete";
import {sortedStates} from "../../fieldValues";
import SkpClassForm from "./SkpClassForm";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {useCallback} from "react";
import {useMutation, useQueryClient} from "react-query";
import {useQuoteContext} from "pages/RateQuoteBind/contexts/quoteContext";
import {ParamType} from "api/common";
import {useState, useEffect} from "react";
import DebitsAndCreditsSection from "../shared/DebitsAndCredits";

const numberOfFullTimeEmployees = [1, 2, 3, 4, 5, 6, 7];

const numberOfPartTimeEmployees = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const aggOccurLimits = [
  {label: "$1,000,000 / $2,000,000", value: "2000000"},
  {label: "$500,000 / $1,000,000", value: "1000000"},
  {label: "$300,000 / $600,000", value: "600000"},
];

const numberOfTotalClasses = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: "More than 3",
    value: 4,
  },
];

const SiteAddress = styled.div`
  padding-left: 10%;
  padding-top: 1%;
  padding-bottom: 1%;
`;
const additionalInterestTypes = [
  {
    label: "Lessor of Leased Equipment",
    value: AdditionalInterestType.LESSOR_OF_LEASED_EQUIPMENT,
  },
  {
    label: "Designated Person or Organization",
    value: AdditionalInterestType.DESIGNATED_PERSON_OR_ORGANIZATION,
  },
  {
    label: "Managers or Lessors of Premises",
    value: AdditionalInterestType.MANAGERS_OR_LESSORS_OF_PREMISES,
  },
];

function AdditionalInterestsForm({
  skpAdditionalInterests,
  quoteId,
}: {
  skpAdditionalInterests: SkpAdditionalInterest[];
  quoteId: string;
}) {
  return (
    <GridContainer rowGap={24} columnGap={16}>
      {skpAdditionalInterests.map((skp_additional_interest, index) => {
        return (
          <Box width="100%" borderBottom="2px solid lightBlue" my={20}>
            <Box width="100%" display="flex">
              <FormTitle.SubHeader>
                Additional Interest {index + 1}
              </FormTitle.SubHeader>
              <Box width="1%"></Box>
              <Field.DropDown
                object={skp_additional_interest}
                options={additionalInterestTypes.map((item) => ({
                  title: item.label,
                  value: item.value,
                }))}
                field={"additional_interest_type"}
                label="Select Additional Interest"
                wrapperProps={{
                  grow: 12,
                }}
              />
              <Box width="5%"></Box>
            </Box>
            <SiteAddress>
              <FormTitle.SubHeader>Address</FormTitle.SubHeader>
              <Box width="83%">
                <PlacesAutocomplete
                  object={skp_additional_interest}
                  quoteId={quoteId}
                />
              </Box>
              <GridContainer rowGap={24} columnGap={16}>
                <Field.TextInput
                  object={skp_additional_interest}
                  field={"name"}
                  label="Name"
                  wrapperProps={{
                    grow: 5,
                  }}
                />
                <Field.TextInput
                  object={skp_additional_interest}
                  field={"line_1"}
                  label="Address"
                  wrapperProps={{
                    grow: 5,
                  }}
                />
                <Field.TextInput
                  object={skp_additional_interest}
                  field={"city"}
                  label="City"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 3,
                  }}
                />
                <Field.DropDown
                  object={skp_additional_interest}
                  options={sortedStates.map((state) => ({
                    title: state.name,
                    value: state.abbreviation,
                  }))}
                  field={"state"}
                  label="Select State"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 3,
                  }}
                />
                <Field.TextInput
                  object={skp_additional_interest}
                  field={"zip"}
                  label="Zip"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 4,
                  }}
                />
                <Box
                  width="100%"
                  my={20}
                  display="flex"
                  justifyContent="center"
                >
                  <Box width="75%"></Box>
                  <ActionButton.Delete
                    objectName="skp_additional_interest"
                    objectId={skp_additional_interest.id}
                    background
                  >
                    Delete Additional Interest {index + 1}
                  </ActionButton.Delete>
                </Box>
              </GridContainer>
            </SiteAddress>
            {skp_additional_interest.site_address ? (
              <SiteAddress>
                <FormTitle.SubHeader>Site Address</FormTitle.SubHeader>
                <GridContainer rowGap={24} columnGap={16}>
                  <Box width="83%">
                    <PlacesAutocomplete
                      object={skp_additional_interest.site_address}
                      quoteId={quoteId}
                    />
                  </Box>
                  <Field.TextInput
                    object={skp_additional_interest.site_address}
                    field={"name"}
                    label="Name"
                    wrapperProps={{
                      grow: 5,
                    }}
                  />
                  <Field.TextInput
                    object={skp_additional_interest.site_address}
                    field={"line_1"}
                    label="Address"
                    wrapperProps={{
                      grow: 5,
                    }}
                  />
                  <Field.TextInput
                    object={skp_additional_interest.site_address}
                    field={"city"}
                    label="City"
                    wrapperProps={{
                      growXXS: 12,
                      growMD: 3,
                    }}
                  />
                  <Field.DropDown
                    object={skp_additional_interest.site_address}
                    options={sortedStates.map((state) => ({
                      title: state.name,
                      value: state.abbreviation,
                    }))}
                    field={"state"}
                    label="Select State"
                    wrapperProps={{
                      growXXS: 12,
                      growMD: 3,
                    }}
                  />
                  <Field.TextInput
                    object={skp_additional_interest.site_address}
                    field={"zip"}
                    label="Zip"
                    wrapperProps={{
                      growXXS: 12,
                      growMD: 4,
                    }}
                  />
                </GridContainer>
              </SiteAddress>
            ) : null}
          </Box>
        );
      })}

      <Box width="100%" my={20} display="flex" justifyContent="center">
        <Box width="75%"></Box>
        <ActionButton.Add objectName="skp_additional_interest" background>
          Other additional interest options
        </ActionButton.Add>
      </Box>
    </GridContainer>
  );
}
function ClassesForm({quoteForm}: {quoteForm: QuoteForm}) {
  const numSuffix: {[key: number]: string} = {
    1: "st",
    2: "nd",
    3: "rd",
  };

  const skpClasses = quoteForm.skp.skp_classes;

  const queryClient = useQueryClient();
  const {add, quoteId} = useQuoteContext();

  const {mutateAsync} = useMutation(
    async (props: {objectName: string; params?: ParamType}) => add(props),
    {
      onSuccess: () =>
        queryClient.prefetchQuery([getQuote.name, quoteId.toString()], () =>
          getQuote(quoteId.toString())
        ),
    }
  );

  const handleSelect = useCallback(
    async (e: any) => {
      const selectedValue = e;
      await mutateAsync({
        objectName: "skp_class",
        params: {
          skp_id: String(quoteForm.skp.id),
          skp_classes: selectedValue,
        },
      });
    },
    [mutateAsync, quoteForm.skp.id]
  );

  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      // Handler to call on window resize
      const handleResize = () => setWindowWidth(window.innerWidth);

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures effect is only run on mount and unmount

    return windowWidth;
  }

  const windowWidth = useWindowWidth();

  console.log("window width", windowWidth);

  return (
    <Box mb={50} mt={20}>
      <FormTitle.SubHeader>
        How many employees does the applicant have?
      </FormTitle.SubHeader>
      <Box display="flex" flexDirection="row" gap={20}>
        <Field.DropDown
          object={quoteForm.skp}
          field={"current_full_time_employees"}
          label={"Number of full time employees"}
          options={numberOfFullTimeEmployees.map((item) => ({
            title: String(item),
            value: item,
          }))}
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.DropDown
          object={quoteForm.skp}
          field={"current_part_time_employees"}
          label={"Number of part time employees"}
          options={numberOfPartTimeEmployees.map((item) => ({
            title: String(item),
            value: item,
          }))}
          wrapperProps={{
            grow: 12,
          }}
        />
      </Box>
      <Box mt={25}>
        <FormTitle.SubHeader>Contractor Classes</FormTitle.SubHeader>
        <Field.DropDown
          object={quoteForm.skp}
          field={"total_classes"}
          label={"In how many classes does the applicant perform work?"}
          onChange={handleSelect}
          options={numberOfTotalClasses.map((item) => ({
            title: String(item.label),
            value: item.value,
          }))}
          wrapperProps={{
            grow: 12,
          }}
        />
      </Box>

      <Box mt={30}>
        <FormTitle.SubHeader>
          Please select the classes the insured will perform business in:
        </FormTitle.SubHeader>
      </Box>
      <Box>
        <GridContainer rowGap={24} columnGap={16}>
          {skpClasses.map((skp_class, index) => (
            <Box mx={10} width={windowWidth < 1520 ? "100%" : "31%"} mt={-15}>
              <FormTitle.SubHeader>
                {index + 1 + numSuffix[index + 1]} Class
              </FormTitle.SubHeader>

              <SkpClassForm skp_class={skp_class} quoteForm={quoteForm} />
            </Box>
          ))}
        </GridContainer>
      </Box>
    </Box>
  );
}

function SkpGeneralLiability({
  quoteId,
  quoteForm,
}: {
  quoteId: string;
  quoteForm: QuoteForm;
}) {
  const skp = quoteForm.skp;
  const permissions = usePermissions();

  return (
    <Box mb={100}>
      <Field.DropDown
        options={aggOccurLimits.map((item: any) => ({
          title: item.label,
          value: item.value,
        }))}
        object={skp}
        field="agg_occur_limit"
        label="Occurence Limit / Aggregate Limit"
        wrapperProps={{
          grow: 12,
        }}
      />

      <ClassesForm quoteForm={quoteForm} />
      <GridContainer rowGap={24} columnGap={16}>
        <FormTitle.SubHeader>Additional Interests</FormTitle.SubHeader>
        {permissions.includes(Permissions.VIEW_ALL_QUOTES) && (
          <Field.CheckBox
            label="Terrorism Coverage"
            object={skp}
            field={"terrorism_coverage_flag"}
            wrapperProps={{
              grow: 12,
            }}
          />
        )}
        <Field.CheckBox
          label="Blanket Additional Insured"
          object={skp}
          field={"additional_insured_blanket_insured"}
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.CheckBox
          label="Completed Operations"
          object={skp}
          field={"completed_operations"}
          wrapperProps={{
            grow: 12,
          }}
        />
        {permissions.includes(Permissions.BACKEND_QUOTING) && (
          <Field.CheckBox
            label="Debits/Credits- Field Only Available for AE Internal - UW"
            object={skp}
            field={"credit_and_debits_flag"}
            wrapperProps={{
              grow: 12,
              style: {
                backgroundColor: "rgba(255, 255, 125)",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottomLeftRadius: !skp.credit_and_debits_flag
                  ? "6px"
                  : "0px",
                borderBottomRightRadius: !skp.credit_and_debits_flag
                  ? "6px"
                  : "0px",
              },
            }}
          />
        )}
        {skp.credit_and_debits_flag &&
          permissions.includes(Permissions.BACKEND_QUOTING) &&
          !skp.debits_and_credits.length && (
            <>
              <Field.TextInput
                object={skp!}
                field={"gl_credit_and_debits_percentage"}
                label="Schedule Rating Percentage"
                prefix="%"
                type="number"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomLeftRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={skp!}
                field={"gl_credit_and_debits_reason"}
                label="Schedule Rating Reason"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomRightRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={skp!}
                field={"pr_credit_and_debits_percentage"}
                label="IRPM Percentage"
                prefix="%"
                type="number"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomLeftRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={skp!}
                field={"pr_credit_and_debits_reason"}
                label="IRPM Reason"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomRightRadius: "6px",
                  },
                }}
              />
            </>
          )}
        {skp.credit_and_debits_flag &&
        permissions.includes(Permissions.BACKEND_QUOTING) &&
        skp.debits_and_credits.length ? (
          <div>
            <FormTitle.Header2>Debits and Credits</FormTitle.Header2>
            <DebitsAndCreditsSection
              debitsAndCredits={skp.debits_and_credits}
            />
          </div>
        ) : null}
        <Box
          my={50}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box></Box>
          <AdditionalInterestsForm
            skpAdditionalInterests={skp.skp_additional_interests}
            quoteId={quoteId}
          />
        </Box>
      </GridContainer>
    </Box>
  );
}

export default SkpGeneralLiability;
