import styled from "@emotion/styled";
import {PlusIcon} from "@heroicons/react/solid";
import {UploadQuoteInternalNote} from "api/internalNote";
import {useEffect, useRef} from "react";
import {
  getStringWithoutHTMLTags,
  setCaretAtEndOfContentEditableElement,
} from "utils/dom";

const QuoteInternalNoteInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  position: sticky;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;
const SendIcon = styled(PlusIcon)`
  width: 18px;
  cursor: pointer;
  margin-right: 20px;
  color: ${(props) => props.theme.color.brand.primary};
`;
const QuoteInternalNoteBox = styled.div`
  ${(props) => props.theme.text.p2}
  width: 100%;
  outline: none;
  background: ${(props) => props.theme.color.brand.tertiary};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  color: ${(props) => props.theme.color.grayscale[800]};
  padding: 12px 20px;
  word-break: break-word;
  :empty:before {
    content: attr(placeholder);
    color: ${(props) => props.theme.color.grayscale[600]};
    cursor: text;
  }
`;
const MainContainer = styled.div``;
const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px;
  position: sticky;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;
const DocumentIconContainer = styled.div`
  position: relative;
`;
function QuoteInternalNoteInput({
  editModeFocus,
  onClickSend,
  quoteId,
}: {
  editModeFocus: boolean;
  onClickSend: (data: UploadQuoteInternalNote) => void;
  quoteId: number;
}) {
  function onInputText(e: React.KeyboardEvent<HTMLDivElement>) {
    const target = e.target as Element;
    if (e.key === "Enter" && !e.shiftKey) {
      const str = getStringWithoutHTMLTags(target.innerHTML);
      if (!str || !str.trim()) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      handleSendClick(target.innerHTML.trim());
    }
  }

  function clearInputMessage() {
    const element = document.getElementById("quoteInternalNoteBox");
    if (element) {
      element.innerHTML = "";
    }
  }

  function handleSendClick(text: string | undefined) {
    if (!text) {
      return;
    }
    let data: UploadQuoteInternalNote = {
      content: text,
      quote_id: quoteId,
    };
    onClickSend(data);
    clearInputMessage();
  }

  function handleSendIconClick() {
    const text = document.getElementById("quoteInternalNoteBox");
    if (!text) return;
    const str = getStringWithoutHTMLTags(text.innerHTML);
    if (!str || !str.trim()) {
      return;
    }
    handleSendClick(text?.innerHTML);
  }

  const inputBoxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const elem = document.getElementById("quoteInternalNoteBox");
    if (!editModeFocus && elem) {
      inputBoxRef.current?.focus();
      setCaretAtEndOfContentEditableElement(elem); // causes the caret to appear at the END of the text
    }
  });

  return (
    <MainContainer>
      <MessageInputContainer>
        <QuoteInternalNoteInputContainer>
          <SendIcon onClick={handleSendIconClick} />
          <QuoteInternalNoteBox
            id="quoteInternalNoteBox"
            contentEditable="true"
            placeholder="Add note"
            onKeyDown={onInputText}
            ref={inputBoxRef}
          />
          <DocumentIconContainer></DocumentIconContainer>
        </QuoteInternalNoteInputContainer>
      </MessageInputContainer>
    </MainContainer>
  );
}

export default QuoteInternalNoteInput;
