/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {LinkIcon} from "@heroicons/react/solid";
import {Permissions} from "api/auth";
import {getPolicyMods, PolicyBasicInfo, PolicyIds} from "api/policy";
import AppShellTitle from "components/AppShellTitle";
import IconButton from "components/IconButton";
import StatusBadge from "components/StatusBadge";
import {Anchor} from "components/elements";
import {useApiQuery} from "hooks/api";
import {usePermissions} from "hooks/auth";
import {getPolicyModInEffectOrLatest} from "utils/quotePolicy";

export default function PolicyPageHeader({
  policyIds,
  status,
}: {
  policyIds: PolicyIds;
  status?: string | null;
}) {
  const {data: modList} = useApiQuery(getPolicyMods, {
    enabled: Boolean(!status || policyIds.policy_number || policyIds.policy_id),
  })(policyIds);
  let policyModInEffectOrLatest: PolicyBasicInfo | null = null;
  if (modList?.length) {
    policyModInEffectOrLatest = getPolicyModInEffectOrLatest(modList);
  }
  return (
    <div
      css={css`
        display: flex;
        gap: 14px;
        align-items: center;
      `}
    >
      <AppShellTitle>{policyIds.display_policy_number || ""}</AppShellTitle>
      {status ||
        (policyModInEffectOrLatest && (
          <StatusBadge value={status || policyModInEffectOrLatest.status} />
        ))}
      <PolicySystemLink policyIds={policyIds} />
    </div>
  );
}

function PolicySystemLink({policyIds}: {policyIds: PolicyIds}) {
  const permissions = usePermissions();
  if (
    !permissions.includes(Permissions.BACKEND_QUOTING) ||
    !process.env.REACT_APP_API_URL ||
    policyIds.pc_provider_name !== "majesco"
  ) {
    return null;
  }
  return (
    <Anchor
      title="Majesco Link"
      href={`${process.env.REACT_APP_API_URL}/majesco/policy/${policyIds.policy_id}`}
      target="_blank"
    >
      <IconButton background={false} icon={LinkIcon} />
    </Anchor>
  );
}
