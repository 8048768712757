import styled from "@emotion/styled";
import {ThreadListFilter, getThreads, Thread} from "api/message";
import SearchBox from "components/SearchBox";
import {useNavigation} from "hooks/navigation";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import ThreadListing from "./ThreadListing";
import ThreadDetails from "./ThreadMessages";

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ThreadListingContainer = styled.div`
  width: 450px;
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const Threads = styled.div`
  background: ${(props) => props.theme.color.grayscale.white};
  overflow-y: auto;
  position: relative;
  box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
  border-radius: 6px;
  flex: auto;
`;

export const ThreadDetailsContainer = styled.div`
  padding-left: 15px;
  height: calc(100vh - 220px);
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
`;

function Messaging({params}: {params: {id: string; messageId?: number}}) {
  const {id, messageId} = params;
  const {navigate} = useNavigation();
  const [pageNumber, setPageNumber] = useState(1);
  const [messageList, setMessageList] = useState<Thread[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(ThreadListFilter.ALL);
  let debounceTimeout: number = 1;
  const threadsQuery = useQuery(
    ["threads", pageNumber, id, selectedFilter],
    () =>
      getThreads({
        pageNumber,
        searchValue,
        selectedFilter: selectedFilter,
      }),
    {
      refetchOnMount: false,
      cacheTime: 1,
    }
  );
  useEffect(() => {
    if (pageNumber === 1 && threadsQuery.data?.items) {
      setMessageList(threadsQuery.data.items);
      return;
    }
    if (
      threadsQuery.data?.items &&
      Number(threadsQuery.data?.total) &&
      messageList.length <= Number(threadsQuery.data?.total)
    ) {
      setMessageList(messageList.concat(threadsQuery.data?.items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadsQuery.data]);

  useEffect(() => {
    if (
      (!id || (id && !getFilteredQuote())) &&
      messageList.length <= 10 &&
      messageList.length <= (threadsQuery.data?.items?.length || 0)
    ) {
      onChangeThread(messageList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, messageList]);

  const getFilteredQuote = () => {
    return threadsQuery.data?.items.find(
      (item: Thread) => item?.id === Number(id)
    );
  };

  const onChangeThread = (thread: Thread) => {
    if (thread?.id) {
      navigate(`/messages/${thread.id}`);
    }
  };

  const handlePageChange = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleFilterChange = (value: ThreadListFilter) => {
    setSelectedFilter(value);
    if (pageNumber === 1) {
      threadsQuery.refetch();
    } else {
      setPageNumber(1);
    }
  };

  const handleSearchInput = useCallback(
    (text: string) => {
      setSearchValue(text);
      window.clearTimeout(debounceTimeout);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounceTimeout = window.setTimeout(() => {
        if (pageNumber === 1) {
          threadsQuery.refetch();
        } else {
          setPageNumber(1);
        }
      }, 500);
    },
    [debounceTimeout, pageNumber]
  );

  const selectedThread = useMemo(
    () => messageList.find((item: Thread) => item?.id === Number(id)),
    [messageList, id]
  );
  return (
    <>
      <MainContainer>
        <ThreadListingContainer>
          <SearchBox
            showAlwaysBackground
            searchValue={searchValue}
            handleSearchInput={handleSearchInput}
            placeholder="Search by Title, Quote Id"
          />
          <Threads>
            {threadsQuery.data ? (
              <ThreadListing
                onChangeThread={onChangeThread}
                selectedThread={selectedThread}
                isMessageTab={true}
                threadsPagination={threadsQuery.data}
                isLoading={threadsQuery.isLoading}
                pageNumber={pageNumber}
                total={threadsQuery.data?.total}
                error={threadsQuery.error}
                handlePageChange={handlePageChange}
                handleFilterChange={handleFilterChange}
                selectedFilter={selectedFilter}
              />
            ) : (
              <></>
            )}
          </Threads>
        </ThreadListingContainer>
        {selectedThread?.id ? (
          <ThreadDetailsContainer>
            <ThreadDetails
              isMessageTab={true}
              messageId={messageId}
              thread={selectedThread}
              refreshThreads={threadsQuery.refetch}
              searchValue={searchValue}
            />
          </ThreadDetailsContainer>
        ) : (
          <></>
        )}
      </MainContainer>
    </>
  );
}

export default Messaging;
