import {BackendErrorDetail, QuoteStatuses} from "api/quote";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {inQuoteStatus} from "utils/quotePolicy";
import {useQuoteContext} from "./quoteContext";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

interface FieldsStatusContextInterface {
  fieldsDisabled: boolean;
  showRequiredErrors: boolean;
  apiErrors?: string[];
  rate_error?: BackendErrorDetail[];
  setFieldsDisabled(flag: boolean): void;
  setShowRequiredErrors(flag: boolean): void;
  setApiErrors(errors?: string[]): void;
}

export const FieldsStatusContext = createContext<FieldsStatusContextInterface>({
  fieldsDisabled: false,
  showRequiredErrors: false,
  apiErrors: [],
  rate_error: undefined,
  setFieldsDisabled: (flag: boolean) => {},
  setShowRequiredErrors: (flag: boolean) => {},
  setApiErrors: (errors?: string[]) => {},
});

export function FieldsStatusContextProvider({
  last_rated_at,
  rate_error,
  children,
}: {
  last_rated_at: string;
  rate_error?: BackendErrorDetail[];
  children: ReactNode;
}) {
  const permissions = usePermissions();
  const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false);
  const [showRequiredErrors, setShowRequiredErrors] = useState<boolean>(
    !!last_rated_at
  );
  const [apiErrors, setApiErrors] = useState<string[]>();
  const {status, underwriterIsQuoting} = useQuoteContext();

  useEffect(() => {
    setShowRequiredErrors(!!last_rated_at);
  }, [last_rated_at]);

  const isInQuoteStatus = inQuoteStatus(status);
  const hasEditQuoteAlwaysPermission = permissions.includes(
    Permissions.EDIT_QUOTE_ALWAYS
  );
  useEffect(() => {
    if (
      status === QuoteStatuses.BOUND ||
      (!isInQuoteStatus && !hasEditQuoteAlwaysPermission) ||
      (!isInQuoteStatus &&
        hasEditQuoteAlwaysPermission &&
        !underwriterIsQuoting)
    ) {
      setFieldsDisabled(true); // Disable form fields.
    } else {
      setFieldsDisabled(false);
    }
  }, [
    status,
    isInQuoteStatus,
    hasEditQuoteAlwaysPermission,
    underwriterIsQuoting,
  ]);

  return (
    <FieldsStatusContext.Provider
      value={{
        fieldsDisabled,
        showRequiredErrors,
        apiErrors,
        rate_error,
        setFieldsDisabled,
        setShowRequiredErrors,
        setApiErrors,
      }}
    >
      {children}
    </FieldsStatusContext.Provider>
  );
}

export function useFieldsStatusContext() {
  return useContext(FieldsStatusContext);
}
