/** @jsxImportSource @emotion/react */

import {QuoteForm} from "api/quote";
import {Box} from "components/Box";
import {formatDate} from "utils/format";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import ActionButton from "./ActionButton";
import Field from "./Field";
import {css} from "@emotion/react";
import UserName from "components/UserName";

export const QUOTE_NOTES_SECTION_NAME = "Notes";

export default function QuoteNotesSection({
  quoteForm,
  viewOnly,
}: {
  quoteForm: QuoteForm;
  viewOnly: boolean;
}) {
  return (
    <Box mb={100}>
      {quoteForm.notes.length > 0 ? (
        <Table
          checkeredBackground
          marginBottom={10}
          borderRadius={6}
          noBodyShadow
        >
          <Header>
            <HeaderCell></HeaderCell>
            <HeaderCell
              css={css`
                min-width: 150px;
              `}
            >
              Note
            </HeaderCell>
            <HeaderCell>Created At</HeaderCell>
            <HeaderCell>Updated At</HeaderCell>
          </Header>
          <Body>
            {quoteForm.notes.map((note, index) => (
              <Row key={index}>
                <Cell>
                  <UserName userId={note.user_id} />
                </Cell>
                <Cell>
                  {viewOnly ? (
                    <div
                      css={css`
                        white-space: pre-wrap;
                      `}
                    >
                      {note.text}
                    </div>
                  ) : (
                    <Field.TextArea
                      background
                      object={note}
                      field="text"
                      disableVisibleControl
                    />
                  )}
                </Cell>
                <Cell>{formatDate(note.created_at)}</Cell>
                <Cell>{formatDate(note.updated_at)}</Cell>
                {!viewOnly && (
                  <Cell>
                    <ActionButton.Delete
                      objectName="quote_note"
                      objectId={note.id.toString()}
                      background={false}
                    />
                  </Cell>
                )}
              </Row>
            ))}
          </Body>
        </Table>
      ) : (
        <div
          css={css`
            margin-bottom: 15px;
          `}
        >
          No notes
        </div>
      )}

      {!viewOnly && (
        <Box my={8}>
          <ActionButton.Add objectName="quote_note" background>
            Add Note
          </ActionButton.Add>
        </Box>
      )}
    </Box>
  );
}
