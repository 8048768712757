import {RefreshIcon} from "@heroicons/react/solid";
import {getAuthSession} from "api/auth";
import {useEffect, useRef, useState} from "react";
import IconButton from "./IconButton";

export const getCacheRefreshLink = () => {
  return window.location.href.split("?").length === 1
    ? `${window.location.href}?refresh=${Date.now()}`
    : `${window.location.href}&refresh=${Date.now()}`;
};

export default function ClearCache() {
  const [manifest, setManifest] = useState(
    localStorage.getItem("aeig_portal_refresh")
  );

  const refreshCache = (e: any) => {
    e.preventDefault();
    localStorage.setItem("aeig_portal_refresh", `${manifest}`);
    window.location.href = getCacheRefreshLink();
  };
  const [anchor, setAnchor] = useState(false);
  const intervalIdRef = useRef<any>();

  useEffect(() => {
    async function getManifest() {
      const data = await fetch(String(process.env.REACT_APP_MANIFEST_FILE));
      const text = await data.text();
      setManifest(text);

      const authCheck = await getAuthSession();
      if (!authCheck) {
        setAnchor(true);
      }
      if (localStorage.getItem("aeig_portal_refresh") !== manifest) {
        setAnchor(true);
      }
    }
    getManifest();
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      getManifest();
    }, 360000);
  }, [manifest]);

  return (
    <>
      {anchor && (
        <IconButton
          background={false}
          icon={RefreshIcon}
          onClick={refreshCache}
          title="Update"
        />
      )}
    </>
  );
}
