import {css} from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

export type BoxProps = {
  m?: number;
  mx?: number;
  my?: number;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  p?: number;
  px?: number;
  py?: number;
  pb?: number;
  pt?: number;
  pl?: number;
  pr?: number;
  borderRadius?: number;
  display?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  width?: string;
  height?: string;
  border?: string;
  borderRight?: string;
  borderLeft?: string;
  borderBottom?: string;
  borderTop?: string;
  background?: string;
  gap?: number;
} & React.HTMLProps<HTMLDivElement>;

export const Box = styled.div<BoxProps>`
  ${(props) => css`
    ${props.gap && `gap: ${props.gap}px;`}
    ${props.display && `display: ${props.display};`}
    ${props.width && `width: ${props.width};`}
    ${props.height && `height: ${props.height};`}
    ${props.borderRadius && `border-radius: ${props.borderRadius}px;`}
    ${props.flexDirection && `flex-direction: ${props.flexDirection};`}
    ${props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props.alignItems && `align-items: ${props.alignItems};`}
    ${props.background && `background: ${props.background};`}
    ${(props.mt || props.my || props.m) &&
    `margin-top: ${props.mt || props.my || props.m || 0}px;`}
    ${(props.mr || props.mx || props.m) &&
    `margin-right: ${props.mr || props.mx || props.m || 0}px;`}
    ${(props.mb || props.my || props.m) &&
    `margin-bottom: ${props.mb || props.my || props.m || 0}px;`}
    ${(props.ml || props.mx || props.m) &&
    `margin-left: ${props.ml || props.mx || props.m || 0}px;`}
    ${(props.pt || props.py || props.p) &&
    `padding-top: ${props.pt || props.py || props.p || 0}px;`}
    ${(props.pr || props.px || props.p) &&
    `padding-right: ${props.pr || props.px || props.p || 0}px;`}
    ${(props.pb || props.py || props.p) &&
    `padding-bottom: ${props.pb || props.py || props.p || 0}px;`}
    ${(props.pl || props.px || props.p) &&
    `padding-left: ${props.pl || props.px || props.p || 0}px;`}
    ${(props.borderTop || props.border) &&
    `border-top: ${props.borderTop || props.border};`}
    ${(props.borderRight || props.border) &&
    `border-right: ${props.borderRight || props.border};`}
    ${(props.borderBottom || props.border) &&
    `border-bottom: ${props.borderBottom || props.border};`}
    ${(props.borderLeft || props.border) &&
    `border-left: ${props.borderLeft || props.border};`}
  `}
`;

export default Box;
