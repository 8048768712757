/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {ReactComponent as PulseLoaderIcon} from "assets/images/pulse-loader.svg";
import {ReactNode} from "react";
import {H1} from "./elements";

export default function Loading({
  text,
  size = "large",
  margin = 100,
  children,
}: {
  text?: string;
  size?: "small" | "medium" | "large";
  margin?: number;
  children?: ReactNode;
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: ${margin}px;
      `}
    >
      <PulseLoaderIcon
        css={css`
          ${size === "medium" &&
          css`
            height: 65px;
          `}
          ${size === "small" &&
          css`
            height: 45px;
          `}
        `}
      />
      {text && (
        <H1
          css={(theme) => css`
            color: ${theme.color.grayscale[800]};
          `}
        >
          {text}
        </H1>
      )}
      {children}
    </div>
  );
}
