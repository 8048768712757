import {Location, Building, QuoteTypes} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import {formatAmount} from "utils/format";
import Field from "../../Field";
import LocationsForm, {BuildingForm} from "./LocationsForm";
import {
  causeOfLoss,
  coinsurancePercents,
  constructionTypes,
  deductables,
} from "../../fieldValues";

export const windHailOptions = [
  {label: "0%", value: 0},
  {label: "1%", value: 1},
  {label: "2%", value: 2},
  {label: "5%", value: 5},
];

export const mixedMercantileClassCodes = [
  {label: "Cannabis Distributors - Other Than Hemp (10011)", value: "10011"},
  {
    label: "Hemp Distributors (10012)",
    value: "10012",
  },
  {label: "Amusement Centers (10015)", value: "10015"},
  {
    label: "Amusement Parks (10020)",
    value: "10020",
  },
  {
    label:
      "Cannabis-Containing Products Distributors - Other Than Hemp (10025)",
    value: "10025",
  },
  {
    label: "Antique Stores (10026)",
    value: "10026",
  },
  {label: "Hemp-Containing Products Distributors (10027)", value: "10027"},
  {
    label: "Anhydrous Ammonia Dealers and Distributors  (10036)",
    value: "10036",
  },
  {label: "Appliance Distributors - household type (10040)", value: "10040"},
  {
    label: "Appliance Stores - household type (10042)",
    value: "10042",
  },
  {
    label: "  Archery Ranges - indoor (10052)",
    value: "10052",
  },
  {
    label: "Archery Ranges - Not Otherwise Classified (10054)",
    value: "10054",
  },
  {label: "Army and Navy Stores (10060)", value: "10060"},
  {label: "Art Galleries (For-Profit) (10065)", value: "10065"},
];

const isBuildingSprinklered = [
  {label: "Fully Sprinklered", value: "Y"},
  {label: "Not Sprinklered", value: "N"},
  {label: "Partially Sprinklered", value: "P"},
];
const buildingValuation = [
  {label: "Replacement Cost", value: "RC"},
  {label: "Actual Cash Value", value: "ACV"},
];
function HabitationalBuildingForm({
  location,
  building,
  quoteType,
}: {
  location: Location;
  building: Building;
  quoteType: QuoteTypes;
}) {
  return (
    <GridContainer rowGap={24} columnGap={16}>
      {quoteType === QuoteTypes.MIXED_MERCANTILE && (
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={mixedMercantileClassCodes.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"class_code"}
          label="Class Code"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
      )}
      <Field.DropDown
        object={building.habitational_building!}
        options={constructionTypes.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        field={"construction_type"}
        label="Construction type"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.DropDown
        object={building.habitational_building!}
        options={isBuildingSprinklered.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        field={"is_building_sprinklered"}
        label="Is Building Sprinklered"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.TextInput
        object={building.habitational_building!}
        field={"number_of_units"}
        label="Number of units"
        isFormattedNumber
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.TextInput
        type="number"
        object={building.habitational_building!}
        field={"year_built"}
        label="Year built"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />

      <Field.TextInput
        object={building.habitational_building!}
        field={"square_footage"}
        label="Square footage"
        isFormattedNumber
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.TextInput
        prefix="$"
        object={building.habitational_building!}
        field={"building_limit"}
        label="Building limit"
        isFormattedNumber
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      {location.type_of_property !== "Condominium Association" && (
        <Field.TextInput
          prefix="$"
          object={building.habitational_building!}
          field={"business_income"}
          label="Business Income"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
      )}
      <Field.DropDown
        options={deductables.map((item) => ({
          title: formatAmount(item),
          value: item,
        }))}
        object={building.habitational_building!}
        field={"deductible"}
        label="Deductible"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.TextInput
        object={building.habitational_building!}
        field={"number_of_stories"}
        label="Number Of Stories"
        isFormattedNumber
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.DropDown
        object={building.habitational_building!}
        options={buildingValuation.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        field={"building_valuation"}
        label="Building Valuation"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.DropDown
        object={building.habitational_building!}
        options={causeOfLoss.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        field={"cause_of_loss"}
        label="Cause Of Loss"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.DropDown
        object={building.habitational_building!}
        options={coinsurancePercents.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        field={"coinsurance_percent"}
        label="Coinsurance"
        wrapperProps={{
          growXXS: 12,
          growMD: 6,
        }}
      />
      <Field.CheckBox
        label="Manual Wind/Hail Deductible - Field Only Available for AE Internal - UW"
        object={building.habitational_building!}
        field={"manual_wind_hail_coverage_flag"}
        wrapperProps={{
          grow: 12,
          style: {
            backgroundColor: "rgba(255, 255, 125)",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            borderBottomLeftRadius: !building.habitational_building!
              .manual_wind_hail_coverage_flag
              ? "6px"
              : "0px",
            borderBottomRightRadius: !building.habitational_building!
              .manual_wind_hail_coverage_flag
              ? "6px"
              : "0px",
          },
        }}
      />
      {building.habitational_building!.manual_wind_hail_coverage_flag && (
        <Field.DropDown
          object={building.habitational_building!}
          field={"manual_wind_hail_coverage_percent"}
          label="Percentage"
          options={windHailOptions.map((option) => ({
            title: option.label,
            value: option.value,
          }))}
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
            style: {
              backgroundColor: "rgba(255, 255, 125)",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
            },
          }}
        />
      )}
      {location.buildings.length > 1 ? (
        <Box mb={16}>
          <Field.RadioGroup
            object={building}
            field="building_within_100_ft_of_another"
            label="Is this building within 100 ft of another building in this location"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
      ) : (
        <></>
      )}
    </GridContainer>
  );
}

export default function HabitationalLocationsForm({
  locations,
  quoteId,
  quoteType = QuoteTypes.HAB,
}: {
  locations: Location[];
  quoteId: any;
  quoteType?: QuoteTypes;
}) {
  return (
    <LocationsForm
      quoteId={quoteId}
      locations={locations}
      quoteType={quoteType}
    >
      {({locationId, location, buildings}) => (
        <>
          {locations.length > 1 && (
            <Box mb={16}>
              <Field.RadioGroup
                object={location}
                field="building_within_100_ft_of_another"
                label="Are any buildings within 100 ft of another building in other location"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
          )}
          <BuildingForm
            locationId={location.id}
            location={location}
            buildings={location.buildings}
          >
            {({locationId, location, building}) => (
              <HabitationalBuildingForm
                location={location}
                building={building}
                quoteType={quoteType}
              />
            )}
          </BuildingForm>
        </>
      )}
    </LocationsForm>
  );
}
