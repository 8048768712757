/** @jsxImportSource @emotion/react */

import {ReactNode} from "react";
import {css} from "@emotion/react";
import {formatAmount} from "utils/format";
import Box from "components/Box";
import Button from "components/Button";
import {useRateContext} from "../contexts/rateContext";
import {useQuoteContext} from "../contexts/quoteContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";

export const underwriterSection = "Underwriter Questions";

interface Sections {
  [key: string]: ReactNode;
}

export interface Form {
  sections: Sections;
  [underwriterSection]: ReactNode;
}

export default function PremiumAmountSideBar() {
  const {
    fetchingRate,
    fetchRate,
    rate,
    showRate,
    showRateButton,
    disableRateButton,
    canNotRateAndSubmitWhenBackendQuoting,
  } = useRateContext();
  const {dirty} = useQuoteContext();
  const {rate_error} = useFieldsStatusContext();

  return (
    <>
      {showRate && rate ? (
        <div
          css={(theme) => css`
            background-color: ${theme.color.brand.secondary};
            padding: 16px;
            border-radius: 4px;
            margin-bottom: 8px;
          `}
        >
          <div
            css={(theme) => css`
              ${theme.text.button1}
              color: ${theme.color.brand.primary};
              font-weight: 700;
              margin-bottom: 4px;
            `}
          >
            Premium amount
          </div>
          <div
            css={(theme) => css`
              ${theme.text.p1}
              color: ${theme.color.brand.primary};
            `}
          >
            {formatAmount(rate)}
          </div>
        </div>
      ) : (
        <></>
      )}

      {showRateButton && (
        <Box width="100%" display="flex" justifyContent="left">
          <Button
            disabled={
              disableRateButton || canNotRateAndSubmitWhenBackendQuoting
            }
            isLoading={fetchingRate}
            color="secondary"
            onClick={fetchRate}
          >
            {!!rate_error && !dirty
              ? "Retry Rate Indication"
              : !showRate
              ? "Get Rate Indication"
              : "Recalculate Rate Indication"}
          </Button>
        </Box>
      )}
    </>
  );
}
