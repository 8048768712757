/** @jsxImportSource @emotion/react */

import {ReactNode} from "react";
import {css} from "@emotion/react";

function LeftSidebar({children}: {children?: ReactNode}) {
  return (
    <div
      css={(theme) => css`
        background: ${theme.color.brand.tertiary};
        padding: 30px;
        height: 100%;
        border-right: 1px solid ${theme.color.brand.secondary};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (min-width: ${theme.breakpoint.xxs}px) {
          display: none;
        }
        @media (min-width: ${theme.breakpoint.lg}px) {
          display: block;
          min-width: 280px;
        }
        @media (min-width: ${theme.breakpoint.xl}px) {
          display: block;
          min-width: 378px;
        }
      `}
    >
      {children}
    </div>
  );
}

export default LeftSidebar;
