import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Dialog} from "@headlessui/react";
import ChevronLeftIcon from "@heroicons/react/outline/ChevronLeftIcon";
import MailIcon from "@heroicons/react/outline/MailIcon";
import {XIcon} from "@heroicons/react/solid";
import {getThreads, Thread, ThreadListFilter, getThread} from "api/message";
import {useApiQuery} from "hooks/api";
import ThreadListing from "pages/Messaging/ThreadListing";
import ThreadDetails from "pages/Messaging/ThreadMessages";
import {useState} from "react";
import IconButton from "./IconButton";
import Loading from "./Loading";

export const ThreadDetailsContainer = styled.div`
  height: 100%;
`;

export const MessageDialog = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  width: 100%;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    background: rgb(0 0 0 / 40%);
    height: 100vh;
    top: 0;
    left: 0;
  }
`;

export const MessageSlideOverContent = styled.div`
  max-width: 600px;
  margin-left: auto;
  background: ${(props) => props.theme.color.grayscale.white};
  position: relative;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const MessageContent = styled.div`
  flex: auto;
  height: calc(100vh - 64px);
`;

const PolicyName = styled.span`
  ${(props) => props.theme.text.header3}
  color: ${(props) => props.theme.color.brand.primary};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const MessageContainerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  position: sticky;
  z-index: 9;
  top: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronLeft = styled(ChevronLeftIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const BackButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export default function MessageSlideOver({
  title,
  threadId,
}: {
  title?: string;
  threadId?: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<ThreadListFilter>(
    ThreadListFilter.ALL
  );
  const [selectedThread, setSelectedThread] = useState<Thread | null>();

  const onChangeThread = (thread: Thread) => {
    setSelectedThread(thread);
  };

  function closeModal() {
    setSelectedThread(null);
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const oneThreadMode = Boolean(threadId);
  const threadsQuery = useApiQuery(getThreads, {enabled: !oneThreadMode})({
    selectedFilter: selectedFilter,
  });
  const threadQuery = useApiQuery(getThread, {
    enabled: oneThreadMode,
  })(threadId!);

  const handleFetchData = (value: ThreadListFilter) => {
    setSelectedFilter(value);
  };
  const visibleThread = selectedThread || threadQuery.data;

  function showContent() {
    if (visibleThread) {
      return (
        <ThreadDetailsContainer>
          <ThreadDetails thread={visibleThread} />
        </ThreadDetailsContainer>
      );
    }
    if (threadsQuery.data)
      return (
        <ThreadListing
          onChangeThread={onChangeThread}
          selectedThread={visibleThread}
          threadsPagination={threadsQuery.data}
          error={threadsQuery.error}
          selectedFilter={selectedFilter}
          handleFilterChange={handleFetchData}
        />
      );
    if (threadsQuery.isLoading || threadQuery.isLoading) {
      return <Loading size="small" />;
    }
    return <div>Error</div>;
  }
  return (
    <>
      <IconButton background={false} icon={MailIcon} onClick={openModal} />
      <Dialog open={isOpen} as="div" onClose={closeModal}>
        <MessageDialog onClick={() => setIsOpen(false)}>
          <MessageSlideOverContent onClick={(e) => e.stopPropagation()}>
            <MessageContainerHeader>
              <PolicyName>
                {!oneThreadMode && visibleThread && (
                  <BackButton onClick={() => setSelectedThread(null)}>
                    <ChevronLeft />
                  </BackButton>
                )}
                {visibleThread ? visibleThread.title : title}
              </PolicyName>
              <Actions>
                <IconButton
                  onClick={closeModal}
                  css={css`
                    cursor: pointer;
                    outline: 0;
                    margin-left: 10px;
                    width: 22px !important;
                    height: 22px !important;
                    padding: 0px !important;
                    color: #000;
                    border-radius: 0;
                    border: 0;
                    background: transparent;
                    &:hover {
                      background: transparent !important;
                    }
                  `}
                >
                  <XIcon />
                </IconButton>
              </Actions>
            </MessageContainerHeader>
            <MessageContent> {showContent()} </MessageContent>
          </MessageSlideOverContent>
        </MessageDialog>
      </Dialog>
    </>
  );
}
