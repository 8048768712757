import {apiUrl, post} from "./common";

const baseUrl = `${apiUrl}/logging`;

export interface LogResponse {
  id: number;
}

export async function createLog(stack_trace: string, page_url: string) {
  const result = await post<LogResponse>(`${baseUrl}`, {
    data: {
      stack_trace,
      page_url,
    },
    params: {},
    headers: {},
  });
  return result;
}
