import {
  useState,
  useMemo,
  useEffect,
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import classNames from "classnames";
import styled from "@emotion/styled";
import CheckMark from "./CheckMark";

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 100%;
`;

const Label = styled.label`
  ${(props) => props.theme.text.header3}
  color: ${(props) => props.theme.color.brand.primary};
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const InputWrapper = styled.div`
  height: 24px;
  margin-right: 7px;
`;

const Input = styled.input`
  width: 24px;
  height: 24px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export type CheckBoxProps = {
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

let checkBoxIdNumber = 0;

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    {
      label,
      disabled,
      checked = false,
      className,
      onChange,
      ...props
    }: CheckBoxProps,
    ref
  ) => {
    const [internalChecked, setInternalChecked] = useState(checked);

    const inputId = useMemo(() => `check-box-${checkBoxIdNumber++}`, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInternalChecked(e.target.checked);
      if (onChange) onChange(e);
    };

    useEffect(() => {
      setInternalChecked(checked);
    }, [checked]);

    return (
      <Wrapper className={className}>
        <InputWrapper>
          <Input
            type="checkbox"
            ref={ref}
            {...props}
            id={inputId}
            checked={internalChecked}
            onChange={handleChange}
            disabled={disabled}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                let element = e.target as HTMLElement;
                element.click();
              }
            }}
          />
          <CheckMark checked={internalChecked} disabled={disabled} />
        </InputWrapper>
        {label && (
          <Label
            className={classNames({
              disabled: disabled,
            })}
            htmlFor={inputId}
          >
            {label}
          </Label>
        )}
      </Wrapper>
    );
  }
);
