/** @jsxImportSource @emotion/react */

import {postGuestPay} from "api/auth";
import Button from "components/Button";
import {Text} from "components/elements";
import {useNavigation} from "hooks/navigation";
import {useMutation, useQueryClient} from "react-query";
import {css} from "@emotion/react";
import ControlledTextInput from "components/ControlledTextInput";
import {useForm} from "react-hook-form";
import AlertBar from "components/AlertBar";
import links from "links";

interface GuestPayFormFields {
  email: string;
  policyNumber: string;
  zipCode: string;
}

export default function GuestPay() {
  const queryClient = useQueryClient();
  const {handleSubmit, control} = useForm<GuestPayFormFields>();
  const {navigate} = useNavigation();
  const guestPayLoginMutation = useMutation(
    (formData: GuestPayFormFields) =>
      postGuestPay(formData.email, formData.policyNumber, formData.zipCode),
    {
      onSuccess: async (policyModIds) => {
        await queryClient.invalidateQueries("auth_session");
        navigate(links.payment({policyModIds}));
      },
    }
  );

  const onSubmit = handleSubmit((formData) =>
    guestPayLoginMutation.mutateAsync(formData)
  );

  return (
    <form
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        width: 100%;
        max-width: 500px;
        margin-top: 30px;
      `}
      onSubmit={onSubmit}
      onFocus={guestPayLoginMutation.reset}
    >
      <Text size={1}>Please enter an email address:</Text>
      <ControlledTextInput
        control={control}
        name="email"
        type="email"
        label="Email"
        required
        rules={{
          required: true,
        }}
      />
      <div
        css={css`
          padding: 12px 0px;
        `}
      >
        <Text size={1}>
          Please enter the policy number and billing zip code to make a payment:
        </Text>
        <div
          css={css`
            display: flex;
            align-content: stretch;
            padding: 16px 0px;
            gap: 10px;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <ControlledTextInput
              control={control}
              name="policyNumber"
              label="Policy Number"
              required
              rules={{
                required: true,
              }}
            />
          </div>
          <div>
            <ControlledTextInput
              control={control}
              name="zipCode"
              label="Zip Code"
              required
              rules={{
                required: true,
              }}
            />
          </div>
        </div>
      </div>
      {guestPayLoginMutation.isError && (
        <AlertBar status="error">Invalid Policy Info</AlertBar>
      )}
      <Button
        fullwidth
        size="large"
        type="submit"
        isLoading={guestPayLoginMutation.isLoading}
      >
        Continue
      </Button>
    </form>
  );
}
