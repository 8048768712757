import {
  apiUrl,
  get,
  patch,
  post,
  del,
  ParamType,
  PaginatedResult,
} from "./common";
import {PolicyDetails, PolicyBasicInfo} from "./policy";

const baseUrl = `${apiUrl}/quote`;
export const citiesWithProtectionClass1 = [
  "Boston",
  "Worcester",
  "Fall River",
  "Rhode Island",
  "Providence",
  "Cranston",
  "New York",
  "NYC",
  "Bronx",
  "Brooklyn",
  "Queens",
  "Staten Island",
  "New Jersey",
  "Jersey City",
  "Newark",
  "Elizabeth",
  "Pennsylvania",
  "Maryland",
];
export enum QuoteTypes {
  HAB = "HAB",
  MIXED_MERCANTILE = "MIXED_MERCANTILE",
  BOP = "BOP",
  HOME = "HOME",
  SKP = "SKP",
}

export enum States {
  NJ = "NJ",
  NY = "NY",
}

export enum PaymentPlan {
  FULL = "FULL",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  MORTGAGE = "MORTGAGE",
}

export enum QuoteStatuses {
  QUICK_QUOTE = "QUICK_QUOTE",
  FULL_QUOTE = "FULL_QUOTE",
  QUOTING_ERROR = "QUOTING_ERROR",
  DECLINED = "DECLINED",
  REFERRED = "REFERRED",
  UNDERWRITER_REVIEW = "UNDERWRITER_REVIEW",
  APPROVED = "APPROVED",
  BINDING_IN_PROGRESS = "BINDING_IN_PROGRESS",
  BINDING_ERROR = "BINDING_ERROR",
  BOUND = "BOUND",
}

export enum RuleResultType {
  SUCCESS = "success",
  REQUIRED = "required",
  DECLINATION = "declination",
  VALIDATION = "validation",
  REFERRED = "referred",
  REFERRED_PRERATE = "referred_prerate",
  IMMEDIATE_DECLINATION = "immediate_declination",
  MESSAGE = "message",
  HIDDEN = "hidden",
}

export enum AdditionalInterestType {
  COMPLETED_OPERATIONS = "COMPLETED_OPERATIONS",
  LESSOR_OF_LEASED_EQUIPMENT = "LESSOR_OF_LEASED_EQUIPMENT",
  OWNERS_LESSEES_OR_CONTRACTORS = "OWNERS_LESSEES_OR_CONTRACTORS",
  ADDITIONAL_BLANKET_INSURED = "ADDITIONAL_BLANKET_INSURED",
  DESIGNATED_PERSON_OR_ORGANIZATION = "DESIGNATED_PERSON_OR_ORGANIZATION",
  MANAGERS_OR_LESSORS_OF_PREMISES = "MANAGERS_OR_LESSORS_OF_PREMISES",
  PRIMARY_AND_NONCONTRIBUTORY = "PRIMARY_AND_NONCONTRIBUTORY",
}

export interface QuoteListItem {
  id: string | number;
  backend_quote_id: string;
  backend_display_quote_number: string;
  insured_name?: string;
  lob: string;
  producer_name: string;
  quote_type: string;
  status: QuoteStatuses;
  rate_indication: string;
  effective_date?: string;
  created_at: string;
  updated_at: string;
  messages_thread_id: number;
  agency_name: string;
  state: string;
}

export interface QuoteBindingForm {
  quote_id: number;
  inspection_contact_name: string;
  inspection_contact_company: string;
  inspection_contact_phone: string;
  payment_plan: PaymentPlan;
  mortgagee_company?: string;
  mortgagee_phone?: string;
  mortgagee_line_1?: string;
  mortgagee_line_2?: string;
  mortgagee_city?: string;
  mortgagee_state?: string;
  mortgagee_country?: string;
  mortgagee_zip?: string;
}

export interface QuoteNote extends ObjectBase {
  text: string;
  user_id: number;
  form_id: number;
  created_at: string;
  updated_at: string;
}

export interface QuoteForm extends ObjectBase {
  quote_id: number;
  insureds: Insured[];
  locations: Location[];
  habitational: Habitationial;
  mixed_mercantile: MixedMercantile;
  skp: Skp;
  bop: Bop;
  underwriting: Underwriting;
  company_number: number;
  effective_date?: string;
  notes: QuoteNote[];
}

export interface Insured extends ObjectBase {
  account_id: number;
  name: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  line_1: string;
  line_2: string;
  legal_entity: string;
  primary_insured: boolean;
}

export interface Underwriting extends ObjectBase {
  has_prior_insurance: boolean;
  is_outstanding_violation: boolean;
  is_roof_old: boolean;
  has_electric_panels: boolean;
  with_obvious_defects: boolean;
  with_local_codes: boolean;
  been_crime: boolean;
  had_judgement: boolean;
  none_policy_coverage: boolean;
  had_loss: boolean;
  loss_description: string;
  have_AEIG: boolean;
  policy_number: number;
  is_occupied: boolean;
  vacant_units: number;
  require_signed_lease: boolean;
  require_liability_insurance: boolean;
  require_500k: boolean;
  require_renewal_certificates: boolean;
  is_harmless_for_owner: number;
  type_of_hold: string;
  has_policy_prohibiting_pets: boolean;
  have_students: boolean;
  has_trampoline: boolean;
  has_swimming_pool: boolean;
  swimming_pool_completely_fenced: boolean;
  swimming_pool_has_diving_board_or_sliding_board: boolean;
  is_entrance_secured: boolean;
  have_handrail: boolean;
  have_smoke_detector: boolean;
  have_parking: boolean;
  number_of_parking: number;
  snow_removal_person: string;
  applicant_do_document: boolean;
  is_this_new_purchase: boolean;
  vendor_liability_snow_removal: boolean;
  do_gross_annual_receipts_exceed: boolean;
  does_the_maximum_gross_annual_payroll_exceed: boolean;
  does_the_applicant_have_subcontractor_exceed_20p: boolean;
  is_this_a_new_venture: boolean;
  have_atleast_3_years_in_the_trade: boolean;
  required_license_in_jurisdictions: boolean;
  applicant_required_license_in_jurisdictions: boolean;
  applicant_have_required_license: boolean;
  perform_work_related_to_the_american_disabilities_act: boolean;
  perform_work_related_to_the_ada_within_5_years: boolean;
  work_related_to_any_of_the_following: boolean;
  engage_in_exterior_work_3_stories: boolean;
  perform_snow_ice_removal: boolean;
  separate_liability_policy_another_carrier: boolean;
  other_carrier_snow_name: string;
  other_carrier_snow_general_liability: string;
  other_carrier_snow_details: string;
  work_new_residential_construction: boolean;
  work_new_developments_20: boolean;
  work_new_developments_5: boolean;
  work_new_single_homes: boolean;
  has_fuel_tank: boolean;
  fuel_tank_location: string;
  annual_gross_receipts: string;
  gross_annual_payroll: string;
  applicant_subcontractor_cost_of_total_receipts: string;
  applicant_performs_work_in_the_following_areas: boolean;
  three_years_current_ownership_and_prior_insurance: boolean;
  any_other_insurance: boolean;
  other_insurance_policy_number: string;
  any_policy_or_coverage_declined_within_3_years: boolean;
  policy_or_coverage_declined_within_3_years_details: string;
  subcontracters_with_no_insurance: boolean;
  subcontracters_with_no_insurance_description: string;
  performed_work_related_to_these_areas_within_5_years: boolean;
  performed_work_with_more_than_20_homes: boolean;
  description_of_performed_work_with_more_than_20_homes: string;
  performs_work_on_property_converted_to_multi_tenant: boolean;
  performs_hot_work: boolean;
  lapse_of_insurance: boolean;
  details_of_lapse_of_insurance: string;
}

export interface HabitationalBuilding extends ObjectBase {
  square_footage: number;
  construction_type: string;
  number_of_units: number;
  year_built: number;
  building_limit: number;
  business_income: number;
  number_of_stories: number;
  is_building_sprinklered: string;
  manual_wind_hail_coverage_percent: number;
  manual_wind_hail_coverage_flag: number;
  coinsurance_percent: string;
  deductible: string;
  building_valuation: string;
  cause_of_loss: string;
  waiting_period: string;
  hab_id: number;
  building_id: number;
  form_id: number;
}

export interface MixedMercantileBuilding extends ObjectBase {
  construction_type: string;
  year_built: number;
  building_limit: number;
  business_income: number;
  number_of_stories: number;
  manual_wind_hail_coverage_percent: number;
  manual_wind_hail_coverage_flag: number;
  is_building_sprinklered: string;
  coinsurance_percent: string;
  mercantile_total_square_footage: number;
  number_of_mercantile_units: string;
  apartment_condo_number_of_units: number;
  total_building_square_footage: number;
  deductible: string;
  building_valuation: string;
  class_code: string;
  cause_of_loss: string;
  waiting_period: string;
  hab_id: number;
  building_id: number;
  form_id: number;
  mixed_mercantile_units: MixedMercantileUnit[];
}

export interface MixedMercantileUnit extends ObjectBase {
  occupancy_type: string;
  requirements_fulfilled: boolean;
}

export interface Bop extends ObjectBase {
  terrorism_coverage_flag: boolean;
  deductible_amount: string;
  agg_occur_limit: string;
  property_enhancement: number;
  credit_and_debits_flag: boolean;
  credit_and_debits_percentage: number;
  credit_and_debits_reason: string;
  manual_wind_hail_coverage_flag: boolean;
  manual_wind_hail_coverage_percent: number;
  form_id: number;
}

export interface BopBuilding extends ObjectBase {
  construction_type: string;
  class_code: string;
  is_building_sprinklered: string;
  occupancy: string;
  bop_tenants: BopTenant[];
  building_limit: number;
  square_footage: number;
  personal_property_limit: number;
  equipment_breakdown: boolean;
  increased_fire_limit: string;
  amount_of_commercial_tenants: number;
  number_of_stories: number;
  bop_id: number;
  building_id: number;
  form_id: number;
}

export interface BopTenant extends ObjectBase {
  tenant_occupancy: string;
  square_footage_by_tenant: number;
  bop_building_id: number;
}

export interface SkpPersonalProperty extends ObjectBase {
  coinsurance_percent: number;
  cause_of_loss: string;
  amount_of_insurance: number;
  deductible: number;
}

export interface Habitationial extends ObjectBase {
  terrorism_coverage_flag: boolean;
  terrorism_coverage: string;
  deductible_amount: string;
  agg_occur_limit: string;
  equipment_breakdown: boolean;
  property_enhancement: boolean;
  credit_and_debits_flag: boolean;
  gl_credit_and_debits_percentage: number;
  gl_credit_and_debits_reason: string;
  pr_credit_and_debits_percentage: number;
  pr_credit_and_debits_reason: string;
  manual_wind_hail_coverage_flag: boolean;
  manual_wind_hail_coverage_percent: number;
  form_id: number;
  habitational_buildings: HabitationalBuilding[];
  debits_and_credits: DebitsAndCredits[];
}
export interface MixedMercantile extends ObjectBase {
  terrorism_coverage_flag: boolean;
  terrorism_coverage: string;
  deductible_amount: string;
  agg_occur_limit: string;
  equipment_breakdown: boolean;
  property_enhancement: boolean;
  credit_and_debits_flag: boolean;
  gl_credit_and_debits_percentage: number;
  gl_credit_and_debits_reason: string;
  pr_credit_and_debits_percentage: number;
  pr_credit_and_debits_reason: string;
  form_id: number;
  mixed_mercantile_buildings: MixedMercantileBuilding[];
  debits_and_credits: DebitsAndCredits[];
}

export interface Skp extends ObjectBase {
  deductible_amount: string;
  agg_occur_limit: string;
  form_id: number;
  class_code: string;
  additional_insured_blanket_insured: boolean;
  completed_operations: boolean;
  skp_classes: SkpClass[];
  skp_additional_interests: SkpAdditionalInterest[];
  credit_and_debits_flag: boolean;
  gl_credit_and_debits_percentage: number;
  gl_credit_and_debits_reason: string;
  pr_credit_and_debits_percentage: number;
  pr_credit_and_debits_reason: string;
  terrorism_coverage_flag: boolean;
  current_full_time_employees: number;
  current_part_time_employees: number;
  total_classes: number;
  debits_and_credits: DebitsAndCredits[];
}
export interface DebitsAndCredits extends ObjectBase {
  risk_characteristics: string;
  override_modifier: number;
  override_reason: string;
  override_min: string;
  override_max: string;
  lob: string;
}

export interface CIMContractorsToolEquipment extends ObjectBase {
  unscheduled_limit: number;
  unscheduled: boolean;
  scheduled: boolean;
  scheduled_deductible: number;
  tool_equipments: ToolEquipment[];
}
export interface ToolEquipment extends ObjectBase {
  description: string;
  make: string;
  serial_number: string;
  limit: number;
}

export interface SkpClass extends ObjectBase {
  form_id: number;
  class_code: string;
  primary_class: number;
}
export interface SkpAdditionalInterest extends ObjectBase {
  form_id: number;
  skp_id: number;
  line_1: string;
  city: string;
  state: string;
  zip: string;
  name: string;
  additional_interest_type: string;
  site_address: AdditionalInterestSiteAddress;
}

export interface AdditionalInterestSiteAddress extends ObjectBase {
  form_id: number;
  line_1: string;
  city: string;
  state: string;
  zip: string;
  name: string;
}

export interface Building extends ObjectBase {
  primary_building: boolean;
  construction_type: string;
  year_built: number;
  total_floor_area: string;
  habitational_building?: HabitationalBuilding;
  mixed_mercantile_building: MixedMercantileBuilding;
  bop_building?: BopBuilding;
  skp_personal_property?: SkpPersonalProperty;
  building_within_100_ft_of_another: boolean;
  number_of_stories: number;
}

export interface Location extends ObjectBase {
  type_of_property: string;
  primary_location: boolean;
  address_info_same_like_primary_insured: boolean;
  city: string;
  state: string;
  zip: string;
  county: string;
  line_1: string;
  line_2: string;
  building_within_100_ft_of_another: boolean;
  residential_unit_type: string;
  mercantile_exposure_class_code: string;
  buildings: Building[];
  protection_class: string;
}

export interface ObjectBase {
  id: string;
  object_name: string;
  object_path: any[];
}

export interface BackendErrorDetail {
  moreinfo: string;
}

export interface FieldMetaPath {
  object_name: string;
  object_id: string;
  field_name: string;
}

export interface FieldPermission {
  field_object_name: string;
  field_name: string;
  hidden: boolean;
  field_object_id?: string;
}

export interface RuleResult {
  result_type: RuleResultType;
  message: string;
  overriden: boolean;
  is_prev_declination: boolean;
  is_prev_referral: boolean;
}

export interface FieldMeta {
  override?: boolean;
  hidden?: boolean;
}

export interface FieldRuleResults {
  [key: string]: {
    [key: string]: {
      [key: string]: RuleResult | undefined;
    };
  };
}

export interface QuoteResponse {
  quote_id: number;
  lob: string;
  quote_type: QuoteTypes;
  field_rule_results: FieldRuleResults;
  field_permissions: FieldPermission[];
  form_data: QuoteForm;
  binding_form?: QuoteBindingForm;
  backend_quote_id: string;
  backend_display_quote_number: string;
  backend_display_policy_number: string;
  agency_name: string;
  producer: string;
  status: QuoteStatuses;
  is_backend_quoting: boolean;
  rate_indication: string;
  rate_error?: BackendErrorDetail[];
  effective_date?: string;
  created_at: string;
  updated_at: string;
  last_rated_at: string;
  user_id: number;
  messages_thread_id: number;
  is_underwriter_quoting: boolean;
  review_underwriter_id: number;
  quote_proposal_download_url: string;
  assigned_states: string[];
  assigned_companies: string[];
  underwriters: string[];
}

export interface RateResponse {
  rate: string;
  field_rule_results: FieldRuleResults;
  backend_errors?: BackendErrorDetail[];
  has_errors: Boolean;
}

export interface ObjectResponse {
  object: any;
  field_rule_results: FieldRuleResults;
}

export interface StatusResponse {
  status: QuoteStatuses;
  field_rule_results: FieldRuleResults;
  backend_errors?: BackendErrorDetail[];
  has_errors: Boolean;
}

export interface BindResponse {
  status: QuoteStatuses;
  has_errors: boolean;
  rate: string;
  binding_result: any;
}

export interface StatusOnlyResponse {
  status: QuoteStatuses;
}

export interface BackendQuotingResponse {
  is_backend_quoting: boolean;
}

function addObjectPath(object: any, path: any[] = []): any {
  if (Array.isArray(object))
    object.forEach((arrayValue, i) => addObjectPath(arrayValue, [...path, i]));
  else if (typeof object === "object" && object !== null) {
    object.object_path = path;
    Object.entries(object).forEach(([key, objValue]) => {
      addObjectPath(objValue, [...path, key]);
    });
    return object;
  }
  return object;
}

export async function getQuoteList(limit: string, pageNumber: string) {
  return await get<PaginatedResult<QuoteListItem[]>>(`${baseUrl}`, {
    limit,
    page_number: pageNumber,
  });
}

export async function searchQuotes(
  limit: string,
  pageNumber: string,
  searchQuery?: string | null,
  agentSearchQuery?: string | null,
  statusFilter?: string | null,
  underwriterFilter?: string | null,
  stateFilter?: string | null,
  quoteTypeFilter?: string | null,
  ownerFilter?: string | null
) {
  return await get<PaginatedResult<QuoteListItem[]>>(`${baseUrl}/search`, {
    search_query: searchQuery || "",
    agent_search_query: agentSearchQuery || "",
    status_filter: statusFilter || "",
    underwriter_filter: underwriterFilter || "",
    state_filter: stateFilter || "",
    quote_type_filter: quoteTypeFilter || "",
    owner_filter: ownerFilter || "",
    limit,
    page_number: pageNumber,
  });
}

export async function createQuote(quote_type: string) {
  return await post<{quote_id: string; backend_display_quote_number: string}>(
    `${baseUrl}`,
    {params: {quote_type}}
  );
}

export async function updateQuoteObject({
  quoteId,
  objectName,
  objectId,
  field,
  value,
}: {
  quoteId: number;
  objectName: string;
  objectId: string;
  field: string;
  value: string | number | boolean;
}) {
  return await patch<{computed_fields: any; field_rule_results: any}>(
    `${baseUrl}/${quoteId}/${objectName}/${objectId}/${field}`,
    {data: {value}}
  );
}

export async function addQuoteObject(
  quoteId: number,
  objectName: string,
  params: ParamType = {}
) {
  return await post<{object: any; field_rule_results: any}>(
    `${baseUrl}/${quoteId}/${objectName}`,
    {
      params,
    }
  );
}

export async function deleteQuoteObject(
  quoteId: number,
  objectName: string,
  objectId: string
) {
  return await del<{}>(`${baseUrl}/${quoteId}/${objectName}/${objectId}`, {});
}

export async function getQuote(id: string) {
  const result = await get<QuoteResponse>(`${baseUrl}/${id}`);
  result.form_data = addObjectPath(result.form_data);
  return result;
}

export async function getRate(id: string | number) {
  return get<RateResponse>(`${baseUrl}/${id}/rate`);
}

export async function getQuoteDetails(id: string | number) {
  return get<PolicyDetails>(`${baseUrl}/${id}/details`);
}

export async function getQuotePolicy(id: string | number) {
  return await get<PolicyBasicInfo>(`${baseUrl}/${id}/policy`);
}

export async function setFullQuote(id: string | number) {
  return post<ObjectResponse>(`${baseUrl}/${id}/full_quote`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function setQuickQuote(id: string | number) {
  return post<ObjectResponse>(`${baseUrl}/${id}/quick_quote`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function updateQuote(id: string | number) {
  return post<ObjectResponse>(`${baseUrl}/${id}/update_quote`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function submitQuote(id: string | number) {
  return post<StatusResponse>(`${baseUrl}/${id}/submit`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function unsubmitQuote(id: string | number) {
  return post<StatusOnlyResponse>(`${baseUrl}/${id}/unsubmit`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function activateQuoteReview(id: string | number, flag: boolean) {
  return post<StatusOnlyResponse>(`${baseUrl}/${id}/start_stop_review`, {
    data: {
      flag,
    },
    params: {},
    headers: {},
  });
}

export async function submitQuoteReview(id: string | number, flag: boolean) {
  return post<StatusOnlyResponse>(`${baseUrl}/${id}/submit_review`, {
    data: {
      flag,
    },
    params: {},
    headers: {},
  });
}

export async function toggleIsUnderwriterQuoting(id: string | number) {
  return post<StatusOnlyResponse>(`${baseUrl}/${id}/underwriter_quoting`, {
    data: {},
    params: {},
    headers: {},
  });
}

export async function setBackendQuotingMode(
  id: string | number,
  flag: boolean
) {
  return post<BackendQuotingResponse>(`${baseUrl}/${id}/backend_quoting_mode`, {
    data: {
      flag,
    },
    params: {},
    headers: {},
  });
}

export async function bindQuote(payload: QuoteBindingForm) {
  return post<BindResponse>(`${baseUrl}/${payload.quote_id}/bind`, {
    data: {
      ...payload,
    },
    params: {},
    headers: {},
  });
}

export async function updateQuoteFieldMeta(
  quoteId: string | number,
  objectName: string,
  objectId: string,
  objectField: string,
  payload: FieldMeta
) {
  return patch<FieldMeta>(
    `${baseUrl}/${quoteId}/${objectName}/${objectId}/${objectField}/meta`,
    {
      data: {
        ...payload,
      },
      params: {},
      headers: {},
    }
  );
}

export async function getMixedMercantileUnitOccupancyTypes() {
  return get<
    {
      class_description: string;
      additional_questions_for_mixed_merc: string;
    }[]
  >(`${baseUrl}/mixed_mercantile_unit_occupancy_types`);
}
