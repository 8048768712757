import {extractUrlAndReplaceFromTo, To, useNavigation} from "hooks/navigation";
import {DOMAttributes, ReactNode} from "react";
import {Anchor} from "./elements";

export interface LinkProps {
  to: To;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}

export default function Link({
  to,
  children,
  disabled,
  onClick,
  ...props
}: LinkProps & DOMAttributes<HTMLAnchorElement>) {
  const {navigate} = useNavigation();
  const {url, replace} = extractUrlAndReplaceFromTo(to);
  let optionalHref = {};
  if (url && !disabled) {
    optionalHref = {href: url};
  }
  return (
    <Anchor
      onClick={(e) => {
        if (onClick) onClick(e);
        if (url && !disabled && !e.defaultPrevented) {
          e.preventDefault();
          navigate({url, replace});
        }
      }}
      {...props}
      {...optionalHref}
    >
      {children}
    </Anchor>
  );
}
