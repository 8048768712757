import {QuoteForm, QuoteTypes} from "api/quote";
import {Form} from "..";
import habitational from "./habitational";
import skp from "./skp";
import bop from "./bop";
import mixedMercantile from "./mixedMercantile";

export const forms: {
  [key in QuoteTypes]?: (quoteForm: QuoteForm) => Form;
} = {
  [QuoteTypes.HAB]: habitational,
  [QuoteTypes.SKP]: skp,
  [QuoteTypes.BOP]: bop,
  [QuoteTypes.MIXED_MERCANTILE]: mixedMercantile,
};
