import styled from "@emotion/styled";

export const Anchor = styled.a`
  text-decoration: inherit;
  cursor: pointer;
  color: ${(props) => props.theme.color.brand.primary};
  :hover {
    color: ${(props) => props.theme.color.grayscale[600]};
  }
`;

export const AnchorButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.brand.primary};
  :hover {
    color: ${(props) => props.theme.color.grayscale[600]};
  }
`;

export const Text = styled.span<{size: 1 | 2 | 3}>`
  ${(props) => props.size === 1 && props.theme.text.p1};
  ${(props) => props.size === 2 && props.theme.text.p2};
  ${(props) => props.size === 3 && props.theme.text.p3};
`;

export const H1 = styled.h1`
  font-size: 28px;
  line-height: 120%;
`;

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 120%;
`;

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 120%;
`;

export const H4 = styled.h4``;

export const Small = styled.small``;
