import {ObjectBase} from "api/quote";
import {useEffect, useRef} from "react";
import {useQuoteContext} from "../../contexts/quoteContext";
import {useFieldRuleResult} from "./useFieldRuleResult";

export function useTextInputUpdate<T extends ObjectBase>(
  object: T,
  field: keyof T
) {
  const {update} = useQuoteContext();
  const {handleAPIError} = useFieldRuleResult(object, field);
  const inputTimeout = useRef(0);
  const inputRef = useRef<any | null>(null);
  const isFirstRender = useRef(true);
  const value: any = object[field];

  useEffect(() => {
    const inputElement = inputRef.current as any;
    if (!isFirstRender) inputElement.value = value;
    else isFirstRender.current = false;
  }, [value]);

  function onBlur(e: {target: any}) {
    const newValue = e.target.value;

    if (newValue !== value) {
      window.clearTimeout(inputTimeout.current);
      update(
        object.object_name,
        object.object_path,
        object.id,
        field.toString(),
        newValue
      ).catch((error) => {
        handleAPIError(error, field.toString());
        e.target.value = value;
      });
    }
  }

  function onChange(e: {target: any}) {
    const newValue = e.target.value;
    if (newValue !== value) {
      window.clearTimeout(inputTimeout.current);
      inputTimeout.current = window.setTimeout(() => {
        update(
          object.object_name,
          object.object_path,
          object.id,
          field.toString(),
          newValue
        ).catch((error) => {
          handleAPIError(error, field.toString());
          e.target.value = value;
        });
      }, 5000);
    }
  }

  return {
    value,
    inputRef,
    onBlur,
    onChange,
  };
}
