import {Listbox} from "@headlessui/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {SelectHTMLAttributes, useState, useMemo, useEffect} from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import Center from "./Center";
import Surface from "./Surface";
import {HuiToFC} from "typeUtils";

export type OptionType = {
  value: string | number | boolean;
  title: string;
  selectedTitle?: string;
};

const StyledLabel = styled(Listbox.Button)`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  border-radius: 4px;
  outline: none;
  padding: 0 8px 0 0;
  &:disabled {
    background-color: ${(props) => props.theme.color.brand.tertiary};
  }
  &.background {
    background-color: #ffffff;
  }
` as typeof Listbox.Button;

const OutLabel = styled.label`
  ${(props) => props.theme.text.p1}
  color: ${(props) => props.theme.color.grayscale[900]};
  pointer-events: none;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Label = styled.label`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[600]};
  font-weight: 400;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: calc(100% - 32px);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  &.medium,
  &.large {
    transform: translate(16px, 16px) scale(1);
    &.open,
    &.filled {
      transform: translate(16px, -7px) scale(0.85);
    }
  }
  &.small {
    transform: translate(8px, 8px) scale(1);
    &.open,
    &.filled {
      transform: translate(8px, -4px) scale(0.85);
    }
  }
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const DropDownIcon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.color.brand.primary};
  background: ${(props) => props.theme.color.brand.tertiary};
  padding: 5px;
  border-radius: 100%;
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[600]};
    background: ${(props) => props.theme.color.brand.tertiary} !important;
  }
  &:hover {
    background: ${(props) => props.theme.color.brand.secondary};
  }
`;

const RequiredMark = styled.span`
  margin-left: 3px;
  color: ${(props) => props.theme.color.state.red};
`;

const InputTitle = styled.p`
  ${(props) => props.theme.text.p2}
  border: 0px;
  box-sizing: content-box;
  background: none;
  min-height: 16px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  outline: none;
  text-align: left;
  color: ${(props) => props.theme.color.grayscale[800]};
  &.medium {
    margin: 1px 0 0 0;
    padding: 16.5px 16px;
  }
  &.small {
    margin: 0;
    padding: 8px 10px;
  }
  &.large {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -5px 0;
    padding: 20px 16px;
  }
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

const FieldSet = styled.fieldset`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 1px 0 0 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  overflow: hidden;
  min-width: 0%;
  border-width: 1px;
  border-color: ${(props) => props.theme.color.grayscale[500]};
  &.open {
    border-color: ${(props) => props.theme.color.grayscale[800]};
  }
  &.error {
    border-color: ${(props) => props.theme.color.state.red};
  }
`;

const Legend = styled.legend`
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 12px;
  visibility: hidden;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: 0.01px;
  &.open,
  &.filled {
    max-width: 100%;
    padding: 0 8px 0 5px;
    -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    &.required {
      padding-right: 16px;
    }
  }
`;

const StyledOptions = styled(Listbox.Options)`
  z-index: 2;
  position: absolute;
  top: calc(100% + 5px);
  outline: none !important;
  margin: 0;
  width: 100%;
  padding-inline-start: 0;
` as typeof Listbox.Options;

const StyledOption = styled(Listbox.Option as HuiToFC<typeof Listbox.Option>)`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[800]};
  cursor: pointer;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.grayscale[300]};
  outline: none !important;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: white;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: normal;
  & svg {
    color: ${(props) => props.theme.color.brand.primary};
  }
  padding: 16px 0;
  &.small {
    padding: 8px 0;
  }
  &.selected {
    justify-content: space-between;
  }
  &.active {
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  overflow: show;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export type DropDownProps = {
  label?: string;
  outLabel?: string;
  options: OptionType[];
  error?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  switchableTitle?: boolean;
  size?: "medium" | "small" | "large";
  background?: boolean;
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, "onChange" | "size">;

let dropDownIdNumber = 0;

export function DropDown({
  label,
  outLabel,
  error,
  disabled,
  required,
  value,
  options,
  onChange,
  title,
  switchableTitle,
  size = "medium",
  background,
}: DropDownProps) {
  const [internalVal, setInternalValue] = useState(value);

  const inputId = useMemo(() => `drop-down-${dropDownIdNumber++}`, []);
  const selectedTitle = useMemo(() => {
    const foundOption = options.find((option) => option.value === internalVal);
    if (foundOption?.selectedTitle) return foundOption.selectedTitle;
    return foundOption?.title;
  }, [options, internalVal]);

  const handleChange = (value: string) => {
    setInternalValue(value);
    if (onChange) onChange(value);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Wrapper>
      {outLabel && (
        <OutLabel htmlFor={inputId}>
          {outLabel}
          {required && <RequiredMark>*</RequiredMark>}
        </OutLabel>
      )}
      <Listbox value={internalVal} disabled={disabled} onChange={handleChange}>
        {({open}) => (
          <>
            <StyledLabel
              className={classNames({
                background: background,
              })}
              // {...props}
            >
              {label && (
                <Label
                  className={classNames({
                    open: open,
                    filled: !!selectedTitle,
                    disabled: disabled,
                    small: size === "small",
                    medium: size === "medium",
                    large: size === "large",
                  })}
                  htmlFor={inputId}
                >
                  {label}
                  {required && <RequiredMark>*</RequiredMark>}
                </Label>
              )}
              <InputTitle
                className={classNames({
                  small: size === "small",
                  medium: size === "medium",
                  large: size === "large",
                  disabled: disabled,
                })}
              >
                {switchableTitle
                  ? selectedTitle || title
                  : title || selectedTitle}
              </InputTitle>
              <DropDownIcon
                className={classNames({
                  disabled: disabled,
                })}
              >
                <Center>
                  {open ? (
                    <ChevronUpIcon width="15" />
                  ) : (
                    <ChevronDownIcon width="15" />
                  )}
                </Center>
              </DropDownIcon>
              <FieldSet
                className={classNames({
                  open: open,
                  error: error,
                })}
              >
                <Legend
                  className={classNames({
                    open: label && open,
                    filled: label && !!selectedTitle,
                    required: label && required,
                  })}
                >
                  {label}
                </Legend>
              </FieldSet>
            </StyledLabel>
            <StyledOptions>
              <Surface
                style={{
                  maxHeight: "50vh",
                }}
              >
                <div
                  style={{
                    padding:
                      size === "medium"
                        ? "0 16px"
                        : size === "small"
                        ? "0 8px"
                        : "0 24px",
                  }}
                >
                  {options.map((option, index) => (
                    <StyledOption key={index} value={option.value}>
                      {({selected, active}) => (
                        <OptionWrapper
                          className={classNames({
                            selected: selected,
                            active: active,
                            small: size === "small",
                          })}
                        >
                          <span>{option.title}</span>
                          {selected && !title && <CheckIcon width="15" />}
                        </OptionWrapper>
                      )}
                    </StyledOption>
                  ))}
                </div>
              </Surface>
            </StyledOptions>
          </>
        )}
      </Listbox>
    </Wrapper>
  );
}
