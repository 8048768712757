/** @jsxImportSource @emotion/react */

import {getPaymentTransactions} from "api/payment";
import {Body, Cell, Header, HeaderCell, Row, Table} from "components/Table";
import {useApiQuery} from "hooks/api";
import {PolicyModIds} from "api/policy";
import {toDateDashes} from "utils/date";
import {formatAmount} from "utils/format";

function PaymentTransactionHistory({
  policyModIds,
}: {
  policyModIds: PolicyModIds;
}) {
  const payment_transactions = useApiQuery(getPaymentTransactions)(
    policyModIds
  );

  return (
    <>
      {payment_transactions.data && payment_transactions.data.length > 0 && (
        <Table checkeredBackground>
          <Header>
            <HeaderCell>Date</HeaderCell>
            <HeaderCell>Payment Amount</HeaderCell>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell>Emailed Receipt To</HeaderCell>
            <HeaderCell>Status</HeaderCell>
            <HeaderCell>Last Four</HeaderCell>
            <HeaderCell>Payer</HeaderCell>
            <HeaderCell>Transaction ID</HeaderCell>
            <HeaderCell>Type</HeaderCell>
          </Header>
          <Body>
            {payment_transactions.data.map((transaction, index) => (
              <Row>
                <Cell>
                  {toDateDashes(new Date(Date.parse(transaction.date)))}
                </Cell>
                <Cell>{formatAmount(transaction.amount)}</Cell>
                <Cell>{transaction.name_holder}</Cell>
                <Cell>{transaction.emails}</Cell>
                <Cell>
                  {transaction.provider_transaction_error_status
                    ? transaction.provider_transaction_error_status
                    : "Successful"}
                </Cell>
                <Cell>{transaction.last_four_digits}</Cell>
                <Cell>
                  {transaction.authorized_by_user_info.email
                    ? transaction.authorized_by_user_info.email
                    : !transaction.recurring
                    ? "Guest Pay"
                    : "Recurring Payment"}
                </Cell>
                <Cell>{transaction.provider_transaction_id}</Cell>
                <Cell>
                  {!transaction.recurring
                    ? "One Time Payment"
                    : "Recurring Payment"}
                </Cell>
              </Row>
            ))}
          </Body>
        </Table>
      )}
    </>
  );
}

export default PaymentTransactionHistory;
