/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import AlertBar from "components/AlertBar";
import Box from "components/Box";

function QuotingErrorContent({
  unsubmittingQuote,
  onUnSubmitQuote,
}: {
  unsubmittingQuote: boolean;
  onUnSubmitQuote: () => void;
}) {
  const permissions = usePermissions();

  return (
    <>
      <Box width="600px" my={20}>
        <AlertBar status="error">
          There was a quoting error. Underwriter was notified.
        </AlertBar>
      </Box>
      {permissions.includes(Permissions.UNSUBMIT_QUOTE_ON_APPROVED) ||
      permissions.includes(Permissions.UNSUBMIT_QUOTE_ALWAYS) ? (
        <Button
          size="large"
          isLoading={unsubmittingQuote}
          css={css`
            margin-bottom: 20px;
          `}
          onClick={() => onUnSubmitQuote()}
        >
          Unsubmit Quote
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}

export default QuotingErrorContent;
