import {toast} from "react-hot-toast";
import Notification from "components/Notification";
import {ReactNode} from "react";

export function useNotification() {
  return {
    notify: (content: string | ReactNode) =>
      toast((t) => (
        <Notification onClose={() => toast.dismiss(t.id)}>
          {content}
        </Notification>
      )),
  };
}
