import styled from "@emotion/styled";

const AppShellTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 2px;
  color: ${({theme}) => theme.color.brand.primary};
`;

export default AppShellTitle;
