/** @jsxImportSource @emotion/react */

import Loading from "components/Loading";
import {css} from "@emotion/react";
import {useApiMutation, useApiQuery} from "hooks/api";
import {
  addRecurringPayment,
  getPaymentMethods,
  getRecurringPayment,
  RecurringPayment as RecurringPaymentInterface,
  removeRecurringPayment,
  SavedPaymentMethodBasicInfo,
  updateRecurringPayment,
} from "api/payment";
import Button from "components/Button";
import {useState} from "react";
import {useNavigation} from "hooks/navigation";
import {H2, H3} from "components/elements";
import Spacer from "components/Spacer";
import {useUser} from "hooks/auth";
import {
  SelectionGroupContainer,
  SelectionGroupOption,
  SelectionGroupOptionTitle,
} from "components/SelectionGroup";
import {usePayment} from "hooks/payment";
import AlertBar from "components/AlertBar";
import ReceiptEmail from "components/ReceiptEmail";
import {PolicyIds, PolicyModIds} from "api/policy";

const NEW_METHOD_PLACEHOLDER = "NEW_METHOD";

export default function RecurringPayment({
  policyModIds,
  setupView,
}: RecurringPaymentPageParams) {
  const recurringPayment = useApiQuery(getRecurringPayment)(policyModIds);
  const paymentMethods = useApiQuery(getPaymentMethods)(policyModIds);
  return (
    <div>
      <H2>Recurring Payment - {policyModIds.display_policy_number}</H2>
      <Spacer height={10} />
      {recurringPayment.data && paymentMethods.data ? (
        !setupView ? (
          <RecurringPaymentOverview
            policyIds={policyModIds}
            recurringPayment={recurringPayment.data}
          />
        ) : (
          <RecurringPaymentSetup
            policyModIds={policyModIds}
            recurringPayment={recurringPayment.data}
            paymentMethods={paymentMethods.data}
          />
        )
      ) : (
        <Loading size="medium" />
      )}
    </div>
  );
}

function RecurringPaymentOverview({
  policyIds,
  recurringPayment,
}: {
  policyIds: PolicyIds;
  recurringPayment: RecurringPaymentInterface;
}) {
  const removeRecurringPaymentMutation = useApiMutation(removeRecurringPayment);
  const {updateSearchParams} = useNavigation();
  return (
    <div>
      <AlertBar status={recurringPayment.enabled ? "success" : "info"}>
        Recurring payment is{" "}
        {recurringPayment.enabled
          ? `setup with ${recurringPayment.payment_method_description}. Receipt email is set to ${recurringPayment.receipt_email}`
          : "not setup"}
      </AlertBar>

      <Spacer height={30} />
      {recurringPayment.enabled && (
        <>
          <Button
            isLoading={removeRecurringPaymentMutation.isLoading}
            onClick={() => removeRecurringPaymentMutation.mutate([policyIds])}
          >
            Turn off
          </Button>
          <Spacer height={8} />
        </>
      )}
      <Button onClick={() => updateSearchParams({setupView: true})}>
        {recurringPayment.enabled
          ? "Change Payment Method"
          : "Setup Recurring Payment"}
      </Button>
    </div>
  );
}

function RecurringPaymentSetup({
  policyModIds,
  recurringPayment,
  paymentMethods,
}: {
  policyModIds: PolicyModIds;
  recurringPayment: RecurringPaymentInterface;
  paymentMethods: SavedPaymentMethodBasicInfo[];
}) {
  const {updateSearchParams} = useNavigation();
  const [confirmView, setConfirmView] = useState(false);
  const closeSetupView = () => updateSearchParams({setupView: false});

  const [selectedPaymentMethod, selectPaymentMethod] = useState(
    recurringPayment.payment_method_id ||
      (paymentMethods.length > 0 && paymentMethods[0].payment_method_id) ||
      NEW_METHOD_PLACEHOLDER
  );
  const addRecurringPaymentMutation = useApiMutation(addRecurringPayment);
  const updateRecurringPaymentMutation = useApiMutation(updateRecurringPayment);

  const isRecurringLoading =
    addRecurringPaymentMutation.isLoading ||
    updateRecurringPaymentMutation.isLoading;

  const newMethodIsSelected = selectedPaymentMethod === NEW_METHOD_PLACEHOLDER;

  const user = useUser();
  const [receiptEmail, setReceiptEmail] = useState(
    recurringPayment.receipt_email || user.userEmail
  );
  const payment = usePayment({
    policyModIds,
    newPaymentMethodInfo: {
      userId: user.userId,
      agencyId: user.agencyId,
      onSaveComplete: (token) => selectPaymentMethod(token),
    },
  });

  async function onSetupOrUpdateRecurringPayment(setup: boolean) {
    const apiCall = setup
      ? addRecurringPaymentMutation.mutateAsync
      : updateRecurringPaymentMutation.mutateAsync;
    await apiCall([policyModIds, selectedPaymentMethod!, receiptEmail]);
    closeSetupView();
  }

  return !payment.isLoading ? (
    <div>
      {!confirmView ? (
        <div>
          <H3>Receipt Email:</H3>
          <div
            css={css`
              padding-bottom: 6px;
            `}
          >
            {receiptEmail}
          </div>
          <ReceiptEmail
            receiptEmail={receiptEmail}
            onSubmit={(email) => setReceiptEmail(email)}
          />
          <H3>Select Payment Method:</H3>
          <SelectionGroupContainer
            disabled={isRecurringLoading}
            value={selectedPaymentMethod}
            onChange={(value) => selectPaymentMethod(String(value))}
          >
            {paymentMethods.map((paymentMethod) => (
              <SelectionGroupOption value={paymentMethod.payment_method_id}>
                <SelectionGroupOptionTitle
                  title={paymentMethod.description}
                  secondary={paymentMethod.name_holder}
                />
              </SelectionGroupOption>
            ))}
            <SelectionGroupOption value={NEW_METHOD_PLACEHOLDER}>
              <SelectionGroupOptionTitle title="New Method" />
            </SelectionGroupOption>
          </SelectionGroupContainer>
        </div>
      ) : (
        <p>
          By clicking Submit, you are authorizing American European Insurance
          Company to use the{" "}
          {
            paymentMethods.find(
              (m) => m.payment_method_id === selectedPaymentMethod
            )?.description
          }{" "}
          for recurring payment on policy {policyModIds.display_policy_number}.
        </p>
      )}
      <Spacer height={30} />
      <div
        css={css`
          display: flex;
          gap: 8px;
        `}
      >
        {!isRecurringLoading && (
          <Button color="secondary" onClick={closeSetupView} fullwidth>
            Cancel
          </Button>
        )}
        <Button
          isLoading={isRecurringLoading}
          onClick={() => {
            if (newMethodIsSelected) payment.addNewPaymentMethod();
            else if (!confirmView) setConfirmView(true);
            else onSetupOrUpdateRecurringPayment(!recurringPayment.enabled);
          }}
          fullwidth
        >
          {!confirmView ? "Continue" : "Submit"}
        </Button>
      </div>
    </div>
  ) : (
    <Loading size="medium" />
  );
}

export interface RecurringPaymentPageParams {
  policyModIds: PolicyModIds;
  setupView: boolean;
}
