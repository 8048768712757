/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Box from "components/Box";
import {GridContainer, GridItem} from "components/Grid";
import {formatAmount} from "utils/format";

export default function PremiumAmountSection({
  rate,
  show,
  backendQuoteID,
}: {
  rate?: string | number | null;
  show: boolean;
  backendQuoteID: string;
}) {
  if (!show || !rate) {
    return <></>;
  }

  return (
    <Box mb={40}>
      <GridContainer
        css={(theme) => css`
          background-color: ${theme.color.brand.tertiary};
        `}
      >
        <GridItem growXXS={12} growMD={7}>
          <div
            css={css`
              padding: 40px 32px;
              border-radius: 4px 0 0 4px;
            `}
          >
            <div
              css={(theme) => css`
                ${theme.text.p2}
                color: ${theme.color.grayscale[900]};
              `}
            >
              Premium amount
            </div>
            <div
              css={(theme) => css`
                font-size: 38px;
                font-weight: 700;
                line-height: 45.6px;
                color: ${theme.color.grayscale[900]};
              `}
            >
              {formatAmount(rate)}
            </div>
          </div>
        </GridItem>
        <GridItem growXXS={12} growMD={5}>
          <div
            css={(theme) => css`
              background-color: ${theme.color.brand.secondary};
              padding: 40px 32px;
              border-radius: 0 4px 4px 0;
            `}
          >
            <div
              css={(theme) => css`
                ${theme.text.p2}
                color: ${theme.color.grayscale[900]};
                overflow: hidden;
              `}
            >
              Quote number
            </div>
            <div
              css={(theme) => css`
                ${theme.text.header2}
                font-weight: 700;
                color: ${theme.color.grayscale[900]};
                line-height: 45.6px;
                overflow: hidden;
              `}
            >
              {backendQuoteID}
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </Box>
  );
}
