/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {PolicyStatuses} from "api/policy";
import {QuoteStatuses} from "api/quote";
import {AeigTheme} from "theme";
import {Status} from "./types";

export default function StatusBadge({
  value,
  status,
}: {
  value: string;
  status?: Status;
}) {
  return (
    <div
      css={(theme) => css`
        display: inline-block;
        background-color: ${status !== undefined
          ? computeStatusColor(status, theme.color.state)
          : computeStatusColorByValue(value, theme.color.state)};
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        color: ${theme.color.grayscale.white};
        padding: 4px 8px;
        height: fit-content;
        width: fit-content;
      `}
    >
      <div
        css={css`
          height: 18px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: initial;
        `}
      >
        {value && value.replaceAll("_", " ")}
      </div>
    </div>
  );
}

function computeStatusColor(
  status: Status,
  statusColors: AeigTheme["color"]["state"]
) {
  if (status === "error") return statusColors.red;
  if (status === "warning") return statusColors.orange;
  if (status === "success") return statusColors.green;
  return statusColors.blue;
}

function computeStatusColorByValue(
  statusValue: string,
  statusColors: AeigTheme["color"]["state"]
) {
  if (statusValue === PolicyStatuses.IN_FORCE) return statusColors.green;
  if (
    [PolicyStatuses.RENEWED, PolicyStatuses.RENEWAL]
      .map(String)
      .includes(statusValue)
  )
    return statusColors.blue;
  if (statusValue === PolicyStatuses.CANCELLED) return statusColors.red;
  if (statusValue === PolicyStatuses.NON_RENEWED) return statusColors.orange;

  if (
    [QuoteStatuses.APPROVED, QuoteStatuses.BOUND]
      .map(String)
      .includes(statusValue)
  )
    return statusColors.green;
  if (
    [
      QuoteStatuses.QUICK_QUOTE,
      QuoteStatuses.FULL_QUOTE,
      QuoteStatuses.BINDING_IN_PROGRESS,
    ]
      .map(String)
      .includes(statusValue)
  )
    return statusColors.blue;
  if (
    [QuoteStatuses.REFERRED, QuoteStatuses.UNDERWRITER_REVIEW]
      .map(String)
      .includes(statusValue)
  )
    return statusColors.orange;
  if (
    [
      QuoteStatuses.BINDING_ERROR,
      QuoteStatuses.DECLINED,
      QuoteStatuses.QUOTING_ERROR,
    ]
      .map(String)
      .includes(statusValue)
  )
    return statusColors.red;

  return statusColors.orange;
}
