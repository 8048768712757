import {Box} from "components/Box";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";
import {QuoteForm} from "api/quote";

function SkpClasses({quoteForm}: {quoteForm?: QuoteForm | null}) {
  if (!quoteForm) return null;
  if (!quoteForm.skp) return null;

  console.log("quoteform", quoteForm);

  return (
    <Box mb={100}>
      <FormTitle.Header>Contractor Classes</FormTitle.Header>
      {quoteForm.skp.skp_classes.map((classItem, index) => (
        <InfoRow
          key={index}
          label={`Class ${index + 1}`}
          object={classItem.class_code.slice(6)}
        />
      ))}
    </Box>
  );
}

export default SkpClasses;