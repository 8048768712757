export function downloadFile(url: string, name?: string) {
  return fetch(url, {
    credentials: "include",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement("a");
      a.href = blobURL;
      if (name && name.length) a.download = name;
      else a.download = "";
      document.body.appendChild(a);
      a.click();
    });
}

export function getFilenameFromUrl(url: string) {
  const filename = (url && new URL(url).pathname.split("/").pop()) || "";
  const [name, extension] = filename.split(".");
  return `${name || ""}.${(extension || "").toLowerCase()}` || null;
}

export function getStringWithoutHTMLTags(text: string) {
  return text
    .trim()
    .replace(/&nbsp;/g, "")
    .replace(/\n|<.*?>/g, "");
}

export function setCaretAtEndOfContentEditableElement(
  contentEditableElement: HTMLElement
) {
  let range, selection;
  if (document.createRange) {
    range = document.createRange();
    if (contentEditableElement)
      range.selectNodeContents(contentEditableElement);
    range.collapse(false);
    selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}

export function elementIsCoveredOnBottom(elem: HTMLElement) {
  let rect = elem.getBoundingClientRect();
  let elemBottom = rect.bottom;
  let isCoveredOnBottom = elemBottom >= window.innerHeight - 70;
  return isCoveredOnBottom;
}
