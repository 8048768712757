/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {HTMLAttributes, ReactNode} from "react";

export function List({
  border = true,
  children,
  ...props
}: {
  border?: boolean;
  children: ReactNode;
} & HTMLAttributes<HTMLUListElement>) {
  return (
    <ul
      css={css`
        padding: 10px;
        list-style-position: inside;
        list-style-type: none;
        padding: 0px;
        ${border &&
        css`
          border: solid #efefef 1px;
          border-radius: 4px;
        `}

        li:not(:last-child) {
          border-bottom: solid #efefef 1px;
        }
      `}
      {...props}
    >
      {children}
    </ul>
  );
}

export const ListItem = styled.li`
  padding: 15px;
`;
