import {useContext, useMemo} from "react";
import {useEffect} from "react";
import {Redirect, useParams} from "react-router-dom";
import {MenuPage} from "./Menu";
import routes, {pageConfig} from "routes";
import AppShell from "./AppShell";
import {getHtmlTitle} from "utils";
import {useAuth, usePermissions} from "hooks/auth";
import ErrorScreen from "components/ErrorScreen";
import TopbarShell from "./TopbarShell";
import {navigationContext} from "contexts/navigation";
import {useNavigation} from "hooks/navigation";

function PageRoute({
  page,
  searchParams,
  isLoggedIn,
  currentUrl,
}: {
  page: pageConfig;
  searchParams: URLSearchParams;
  isLoggedIn: boolean;
  currentUrl: string;
}) {
  const params = useParams();
  const authSession = useAuth();
  const permissions = usePermissions();
  const title = useMemo(() => {
    if (page.getTitle !== undefined) return page.getTitle(params, searchParams);
    else return page?.title;
  }, [page, searchParams, params]);
  useEffect(() => {
    window.document.title = getHtmlTitle(title);
  }, [title]);

  const {setLastShellUrl} = useContext(navigationContext);
  const navigation = useNavigation();
  const noShell =
    (page.public && !page.topBarShell) || (!page.public && page.noShell);
  const topBarShell = !noShell && page.topBarShell;
  const appShell = !topBarShell;
  useEffect(() => {
    if (appShell) setLastShellUrl(navigation.currentUrl);
  }, [appShell, setLastShellUrl, navigation.currentUrl]);

  if (page.redirect) {
    return <Redirect to={page.redirect} />;
  }
  if (!page.public && !isLoggedIn) {
    return <Redirect to={`/login?returnUrl=${currentUrl}`} />;
  }
  if (authSession.redirectToSelectAgency) {
    return <Redirect to={`/selectAgency?returnUrl=${currentUrl}`} />;
  }
  if (page.hasPermission && !page.hasPermission(permissions)) {
    return <ErrorScreen>No permission</ErrorScreen>;
  }
  if (page.render) {
    const pageElement = page.render(params, searchParams);
    if (noShell) {
      return pageElement;
    } else if (topBarShell) {
      return (
        <TopbarShell
          title={title ?? ""}
          containered={
            page.topBarShellContainer !== undefined
              ? page.topBarShellContainer
              : true
          }
          loggedInMode={isLoggedIn}
          pageHeader={
            page.getPageHeader !== undefined
              ? page.getPageHeader(params, searchParams)
              : undefined
          }
        >
          {pageElement}
        </TopbarShell>
      );
    } else if (appShell) {
      return (
        <AppShell
          hideMenu={page.hideMenu}
          backLink={page.backUrl}
          title={title ?? ""}
          pageHeader={
            page.getPageHeader !== undefined &&
            page.getPageHeader(params, searchParams)
          }
          menuList={routes
            .filter((page) => page.menuLink)
            .filter(
              (page) => !page.hasPermission || page.hasPermission(permissions)
            )
            .map<MenuPage>((page) => ({
              title: page.title,
              link: page.menuLink ?? "",
              icon: page.icon,
              exact: page.exact !== undefined ? page.exact : true,
            }))}
        >
          {pageElement}
        </AppShell>
      );
    }
  }
  return null;
}

export default PageRoute;
