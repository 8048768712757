/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button, {ButtonProps} from "./Button";

function IconButton({isLoading, icon, children, ...props}: ButtonProps) {
  return (
    <Button
      css={css`
        width: 32px;
        padding: 0;
        height: 32px;
        border-radius: 50%;
      `}
      {...props}
      isLoading={isLoading}
      icon={isLoading ? null : icon}
    >
      {isLoading ? null : children}
    </Button>
  );
}

export default IconButton;
