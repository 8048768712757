export function getDateRelativeToToday(days: number) {
  return new Date(new Date().setDate(new Date().getDate() + days));
}

export function toDateDashes(date: Date) {
  return date.toISOString().split("T")[0];
}

export function getMonthValue(date: Date) {
  return ("0" + String(date.getMonth() + 1)).slice(-2);
}
export function getDayOfMonthValue(date: Date) {
  return ("0" + String(date.getDate())).slice(-2);
}
