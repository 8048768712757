import {
  getPolicyPaymentInfo,
  getPaymentMethods,
  getRecurringPayment,
  getPolicyPaymentProviderInfo,
} from "api/payment";
import PolicyPayment from "./PolicyPayment";
import Loading from "components/Loading";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {H1} from "components/elements";
import Center from "components/Center";
import {useApiQuery} from "hooks/api";
import {isPaymentOnCancelledPolicyWithoutPermission} from "utils/payment";
import {PolicyModIds} from "api/policy";

function PolicyPayments({policyModIds}: {policyModIds: PolicyModIds}) {
  const permissions = usePermissions();

  const methods = useApiQuery(getPaymentMethods, {
    enabled: permissions.includes(Permissions.VIEW_SAVED_PAYMENT_METHODS),
  })(policyModIds);
  const recurringPayment = useApiQuery(getRecurringPayment, {
    enabled: permissions.includes(Permissions.VIEW_RECURRING_PAYMENT),
  })(policyModIds);
  const {data: policyPaymentInfo} =
    useApiQuery(getPolicyPaymentInfo)(policyModIds);
  const {data: paymentProviderInfo} = useApiQuery(
    getPolicyPaymentProviderInfo,
    {enabled: !!policyPaymentInfo}
  )(policyModIds.company_number);

  if (!policyPaymentInfo || !paymentProviderInfo)
    return <Loading size="medium" />;

  if (!policyPaymentInfo.remaining_balance)
    return (
      <Center>
        <H1>No balance</H1>
      </Center>
    );

  if (
    isPaymentOnCancelledPolicyWithoutPermission(
      policyPaymentInfo.policy_info,
      permissions
    )
  )
    return (
      <Center>
        <H1>This policy is cancelled. Not authorized to make payment.</H1>
      </Center>
    );

  return (
    <PolicyPayment
      policyModIds={policyModIds}
      policyPaymentInfo={policyPaymentInfo}
      paymentProviderInfo={paymentProviderInfo}
      recurringPayment={recurringPayment.data}
      savedMethods={methods.data || []}
    />
  );
}

export default PolicyPayments;
