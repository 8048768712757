/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";

function SubTopBar({title, width}: {title: string; width?: number}) {
  return (
    <div
      css={(theme) => css`
        background: ${theme.color.brand.tertiary};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 65px;
      `}
    >
      <h2
        css={(theme) => css`
          ${theme.text.button1}
          color: ${theme.color.brand.primary};
          font-weight: 700;
          display: flex;
          width: ${width || 500}px;
          padding: 0 45px;
        `}
      >
        {title}
      </h2>
    </div>
  );
}

export default SubTopBar;
