import {PolicyDetails} from "api/policy";
import {Box} from "components/Box";
import {formatEffectiveDates} from "utils/format";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";

const companyNameMap = {
  "05": "Rutgers Casualty Insurance Company",
  "10": "American European Insurance Company",
  "12": "Falls Lake Insurance Company",
};

function SummaryInfo({policy}: {policy: PolicyDetails}) {
  return (
    <Box mb={100}>
      <FormTitle.Header>Summary</FormTitle.Header>
      <InfoRow
        label="Insured"
        object={policy.policy_information.insured_info.name}
      />
      <InfoRow
        label="Agent"
        object={policy.policy_information.agent_info.name}
      />
      <InfoRow
        label="Company"
        object={
          companyNameMap[
            policy.policy_mod_ids.company_number as keyof typeof companyNameMap
          ]
        }
      />
      <InfoRow
        label="Commercial Package"
        object={policy.policy_information.lob_name}
      />
      <InfoRow
        label="Effective Date"
        object={formatEffectiveDates(
          policy.policy_information.effective_date,
          policy.policy_information.expiration_date
        )}
      />

      <InfoRow
        label="Mailing Address"
        object={`${
          policy.policy_information.insured_info.address_info.line_1
        } ${
          policy.policy_information.insured_info.address_info.city &&
          `${policy.policy_information.insured_info.address_info.city}, 
                  ${policy.policy_information.insured_info.address_info.state} ${policy.policy_information.insured_info.address_info.zip}`
        }`}
      />
    </Box>
  );
}

export default SummaryInfo;
