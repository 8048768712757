import styled from "@emotion/styled";

const QuestionTitle = styled.p`
  color: ${({theme}) => theme.color.grayscale[800]};
  font-size: 24px;
  line-height: 140%;
  font-weight: 700;
`;

export default QuestionTitle;
