/** @jsxImportSource @emotion/react */

import {ReactComponent as ExternalIcon} from "assets/images/external.svg";
import styled from "@emotion/styled";
import {
  getThreadMessages,
  markAsRead,
  postMessage,
  Thread,
  ThreadMessage,
  UploadMessage,
} from "api/message";
import {useNavigation} from "hooks/navigation";
import {useCallback, useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import Message from "./Message";
import MessageInput from "./MessageInput";
import * as helpers from "./utils";
import Loading from "components/Loading";
import Link from "components/Link";
import {css} from "@emotion/react";

const MainContainer = styled.div`
  background: ${(props) => props.theme.color.grayscale.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const ExternalsIcon = styled(ExternalIcon)`
  margin-left: 5px;
  max-width: 10px;
`;

const MessageHead = styled.div`
  ${(props) => props.theme.text.header3}
  padding: 20px 15px;
  position: sticky;
  top: 0;
  width: 100%;
  font-weight: bold;
  background: ${(props) => props.theme.color.grayscale.white};
  color: ${(props) => props.theme.color.brand.primary};
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const MessageContainerBody = styled.div`
  flex: auto;
  position: relative;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.color.grayscale.white};
  background-color: rgba(0, 32, 110, 0.03);
  background-image: url(chat-watermark.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  overflow-y: auto;
`;

const GotoLastUnreadMsg = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.color.brand.primary};
  border-radius: 50px;
  padding: 8px 15px;
  color: #e5e5e5;
  text-align: center;
  max-width: 230px;
  font-size: 15px;
  position: fixed;
  width: auto;
  right: 5%;
  bottom: 21%;
`;

const LastReadContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
  span {
    border-bottom: 1px solid ${(props) => props.theme.color.brand.primary};
    min-height: 5px;
    width: 100%;
    &:nth-child(2) {
      border: 0;
      width: auto;
      min-width: 85px;
      text-align: center;
      font-size: 14px;
      color: ${(props) => props.theme.color.brand.primary};
    }
  }
`;

function ThreadMessages({
  thread,
  isMessageTab,
  refreshThreads,
  messageId,
  searchValue,
}: {
  thread: Thread;
  isMessageTab?: boolean;
  refreshThreads?: () => void;
  messageId?: number;
  searchValue?: string;
}) {
  const [page, _setPage] = useState(1);
  const setPage = (pageNumber: number) =>
    !page || page < 0 ? _setPage(1) : _setPage(pageNumber);
  const [initialLoad, setInitialLoad] = useState(true);
  const [scrollDirection, setScrollDirection] = useState("");
  const [scrollDownCounter, setScrollDown] = useState(0);
  const [scrollUpCounter, setScrollUp] = useState(0);
  const [previousScrollMsgId, setPreviousScrollMsgId] = useState("");
  const scrollUpObserver = useRef<IntersectionObserver>();
  const [messages, setMessages] = useState<ThreadMessage[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<number[]>([]);
  const [previousParamMessageID, setPreviousParamMessageID] =
    useState<number>();
  const visible = useRef<boolean>(false);
  const {navigate} = useNavigation();

  var unReadMsgs: number[] = [];
  let debounceTimeout: number;

  const threadMessagesQuery = useQuery(
    ["message_list", page, messageId],
    () => {
      let messageIdArg = undefined;
      let pageNumberArg = undefined;
      if (
        (messageId && messageId !== previousParamMessageID) ||
        (initialLoad && thread.last_unread_message_id)
      ) {
        messageIdArg = messageId || thread.last_unread_message_id;
        visible.current = false;
        if (messageId) {
          setPreviousParamMessageID(messageId);
        }
      } else {
        pageNumberArg = page;
        visible.current = false;
      }
      return getThreadMessages({
        threadId: thread.id,
        messageId: messageIdArg,
        pageNo: pageNumberArg,
      });
    },
    {
      refetchOnMount: false,
      cacheTime: 1,
      refetchOnWindowFocus: false,
    }
  );

  const unreadMessagesQuery = useQuery(
    ["mark_as_read", unreadMessages.length],
    () => {
      if (!unreadMessages.length) {
        return;
      }
      return markAsRead({messagesIds: unreadMessages});
    },
    {
      refetchOnMount: false,
      cacheTime: 1,
    }
  );

  useEffect(() => {
    return () => {
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    unReadMsgs = [];
    setUnreadMessages([]);
  }, [unreadMessagesQuery.data]);

  useEffect(() => {
    setInitialLoad(true);
    setTimeout(() => {
      setScrollDown(0);
      setScrollUp(0);
      if (page === 1) {
        threadMessagesQuery.refetch();
      } else {
        setPage(1);
      }
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread.id]);

  useEffect(() => {
    if (
      !threadMessagesQuery.data?.items ||
      threadMessagesQuery.data?.items?.length === 0
    ) {
      setMessages([]);
      return;
    } else {
      if (
        threadMessagesQuery.data?.items &&
        Number(threadMessagesQuery.data?.total) &&
        threadMessagesQuery.data?.items[0]?.id !== messages[0]?.id
      ) {
        if (initialLoad) {
          setMessages(threadMessagesQuery.data?.items);
          setScrollDown(threadMessagesQuery.data?.page);
          setScrollUp(threadMessagesQuery.data?.page);
          setTimeout(() => setInitialLoad(false), 0);
          return;
        } else if (scrollDirection === "up") {
          setMessages(messages.concat(threadMessagesQuery.data?.items));
        } else {
          setMessages(threadMessagesQuery.data?.items.concat(messages));
        }
      } else if (searchValue) {
        setMessages(threadMessagesQuery.data?.items);
        setScrollDown(threadMessagesQuery.data?.page);
        setScrollUp(threadMessagesQuery.data?.page);
        setTimeout(() => setInitialLoad(false), 0);
        return;
      }
    }
    // eslint-disable-next-line
  }, [threadMessagesQuery.data]);

  useEffect(() => {
    if (thread.last_unread_message_id && initialLoad) {
      const scrollTo = messageId ? messageId : thread.last_unread_message_id;
      helpers.scrollIntoView(`${scrollTo}`);
      setTimeout(() => (visible.current = true), 3000);
      return;
    } else if (messageId && initialLoad) {
      helpers.scrollIntoView(`${messageId}`);
      setTimeout(() => (visible.current = true), 3000);
      return;
    }
    if (messages?.length) {
      if (threadMessagesQuery.data?.page === 1 && messages?.length <= 10) {
        if (thread.last_unread_message_id) {
          let urMessagesId = messages.map((el) => el.id).slice(0, 5);
          setUnreadMessages(urMessagesId);
        }
        helpers.scrollIntoView(`${threadMessagesQuery.data?.items[0].id}`);
      } else if (scrollDirection === "up") {
        helpers.scrollIntoView(previousScrollMsgId);
      } else {
        helpers.scrollToEnd(previousScrollMsgId);
      }
      setTimeout(() => (visible.current = true), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const moreResult = () => {
    const elem: any = document.getElementById("containerBody");
    if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight) {
      setScrollDirection("down");
      let pageNumber = scrollDownCounter;
      if (scrollDownCounter === 1) {
        console.log("down EOF reached");
      } else {
        setScrollDown(scrollDownCounter - 1);
        setPreviousScrollMsgId(`${messages[0]?.id}`);
        if (pageNumber === 2) {
          threadMessagesQuery.refetch();
        } else {
          setPage(pageNumber - 1);
        }
      }
    }
  };

  const scrollUp = useCallback(
    (node: any) => {
      if (threadMessagesQuery.isLoading) return;
      if (scrollUpObserver.current) scrollUpObserver.current.disconnect();
      scrollUpObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setScrollDirection("up");
            let pageNumber = scrollUpCounter;
            if (
              threadMessagesQuery.data &&
              threadMessagesQuery.data.limit * (pageNumber + 1) <
                threadMessagesQuery.data.total
            ) {
              setScrollUp(pageNumber + 1);
              setPreviousScrollMsgId(`${messages[messages.length - 1]?.id}`);
              setPage(pageNumber + 1);
            } else {
              console.log("up EOF reached");
            }
          }
        },
        {threshold: 0.05}
      );
      if (node) scrollUpObserver.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadMessagesQuery.isLoading, messages, threadMessagesQuery.data]
  );

  const checkUnreadMessages = () => {
    messages.forEach((el: any) => {
      let ele = document.getElementById(el.id);
      if (
        ele &&
        helpers.isInViewport(ele) &&
        thread.last_unread_message_id &&
        el.id >= thread.last_unread_message_id &&
        !el["read"] &&
        !unreadMessages.includes(Number(el.id))
      ) {
        el["read"] = true;
        unReadMsgs = [...unReadMsgs, Number(el.id)];
      }
    });
    window.clearTimeout(debounceTimeout);
    debounceTimeout = window.setTimeout(() => {
      setUnreadMessages([...unreadMessages, ...unReadMsgs].sort());
    }, 1000);
  };

  const onscroll = () => {
    moreResult();
    checkUnreadMessages();
  };

  async function onClickSend(uploadMessage: UploadMessage) {
    await postMessage(uploadMessage);
    if (isMessageTab) {
      refreshThreads?.();
    }
    setScrollDirection("down");
    setInitialLoad(true);
    if (page === 1) {
      threadMessagesQuery.refetch();
    } else {
      setPage(1);
    }
    setInitialLoad(true);
  }

  async function gotoLastUnreadMsg() {
    setInitialLoad(true);

    navigate(`/messages/${thread.id}/${thread.last_unread_message_id}`);
  }

  function showContent() {
    if (threadMessagesQuery.data) {
      return (
        <>
          {visible.current ? <div ref={scrollUp}></div> : ""}
          {messages
            .map((message: ThreadMessage, index: number) => {
              return (
                <div key={index}>
                  {message.id === thread.last_unread_message_id ? (
                    <LastReadContainer id="lastUnread">
                      <span></span>
                      <span>Last read</span>
                      <span></span>
                    </LastReadContainer>
                  ) : (
                    ""
                  )}
                  <Message message={message} />
                </div>
              );
            })
            .reverse()}
        </>
      );
    }
    if (threadMessagesQuery.isLoading) {
      return <Loading size="small" />;
    }
    if (!messages?.length) {
      return <>No messages found.</>;
    }
    return <div>Error</div>;
  }

  const threadTitle = getThreadTitle(thread);
  return (
    <MainContainer>
      {isMessageTab && (
        <MessageHead>
          {threadTitle}{" "}
          {thread.quote && (
            <Link
              css={css`
                ${threadTitle &&
                css`
                  font-size: 14px;
                `}
                display: flex;
                align-items: center;
                gap: 3px;
              `}
              to={`/rate-quote-bind/${thread.quote.id}`}
            >
              Quote {thread.quote.backend_display_quote_number}
              <ExternalsIcon />
            </Link>
          )}
        </MessageHead>
      )}
      <MessageContainerBody onScroll={onscroll} id="containerBody">
        {showContent()}
        {messageId &&
          thread.last_unread_message_id &&
          messageId !== thread.last_unread_message_id && (
            <GotoLastUnreadMsg onClick={gotoLastUnreadMsg}>
              Last read
            </GotoLastUnreadMsg>
          )}
      </MessageContainerBody>
      <MessageInput onClickSend={onClickSend} threadId={thread.id} />
    </MainContainer>
  );
}

function getThreadTitle(thread: Thread) {
  if (thread.title) {
    if (
      thread.quote &&
      thread.title === thread.quote.backend_display_quote_number
    )
      return "";
    return thread.title;
  }
  return "";
}

export default ThreadMessages;
