import styled from "@emotion/styled";
import {QuoteInternalNote} from "api/internalNote";
import React, {useEffect, useRef} from "react";
import {
  getStringWithoutHTMLTags,
  elementIsCoveredOnBottom,
  setCaretAtEndOfContentEditableElement,
} from "utils/dom";

export const EditDiv = styled.div`
  outline: 0px solid transparent;
`;

function ContentAndEditContentInput({
  note,
  editMode,
  setEditMode,
  iconHover,
  onNoteChange,
  onEditSubmit,
  editNoteContent,
  isEditSubmitLoading,
}: {
  note: QuoteInternalNote;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  iconHover: boolean;
  onNoteChange: (value: string) => void;
  onEditSubmit: () => void;
  editNoteContent: string;
  isEditSubmitLoading: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let elem = document.getElementById("myDiv");
    if (editMode && elem) {
      setCaretAtEndOfContentEditableElement(elem); // causes the caret to appear at the END of the text
      if (elementIsCoveredOnBottom(elem)) {
        elem?.scrollIntoView({
          block: "end",
        });
      }
    }
  }, [editMode]);

  function onInputText(e: React.KeyboardEvent<HTMLDivElement>) {
    const target = e.target as Element;
    if (e.key === "Enter" && !e.shiftKey) {
      const str = getStringWithoutHTMLTags(target.innerHTML);
      if (!str || !str.trim()) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      onEditSubmit();
    }
  }

  const editBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (!iconHover) setEditMode(false);
    const target = e.target as Element;
    const str = getStringWithoutHTMLTags(target.innerHTML.trim());

    if (!str || !str.trim()) {
      e.preventDefault();
      ref.current?.focus(); // Keeps caret focused on empty input box when clicking check
      return;
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as Element;
    onNoteChange(target.innerHTML.trim());
  };

  return (
    <>
      {!editMode && isEditSubmitLoading ? (
        <div dangerouslySetInnerHTML={{__html: editNoteContent}} />
      ) : !editMode && !isEditSubmitLoading ? (
        <div dangerouslySetInnerHTML={{__html: note.content}} />
      ) : (
        <EditDiv
          contentEditable="true"
          id="myDiv"
          onKeyUp={onKeyUp}
          onKeyDown={onInputText}
          onBlur={(e) => {
            editBlur(e);
          }}
          ref={ref}
          dangerouslySetInnerHTML={{__html: note.content}}
        />
      )}
    </>
  );
}

export default ContentAndEditContentInput;
