import {createContext, ReactNode, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

export const navigationContext = createContext<{
  isStartingPoint: boolean;
  lastShellUrl: string;
  setLastShellUrl: (url: string) => void;
}>({isStartingPoint: true, lastShellUrl: "", setLastShellUrl(url) {}});

export function NavigationContextProvider({children}: {children: ReactNode}) {
  const [lastShellUrl, setLastShellUrl] = useState("");
  const [isStartingPoint, setIsStartingPoint] = useState(true);
  const {listen} = useHistory();
  useEffect(
    () =>
      listen(() => {
        if (isStartingPoint) setIsStartingPoint(false);
      }),
    [isStartingPoint, listen]
  );
  return (
    <navigationContext.Provider
      value={{
        isStartingPoint,
        lastShellUrl,
        setLastShellUrl,
      }}
    >
      {children}
    </navigationContext.Provider>
  );
}
