/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {QuoteStatuses, QuoteTypes, States} from "api/quote";
import {getAllUnderwritersList} from "api/admin";
import {getAgencyUnderwritersList} from "api/auth";
import {useNavigation} from "hooks/navigation";
import {formatQuoteStatus, formatQuoteType} from "utils/format";
import {useMemo} from "react";
import {OptionType} from "components/DropDown";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {useApiQuery} from "hooks/api";
import SearchBox from "components/SearchBox";
import {FilterDropDown} from "components/Filter";
import {LoadingIndicator} from "components/listPageUtils";

const statusFilterOptions: OptionType[] = Object.keys(QuoteStatuses).map(
  (status) => ({
    title: formatQuoteStatus(status),
    value: status,
  })
);
statusFilterOptions.splice(0, 0, {title: "All", value: ""});

const excludedTypes = ["BOP", "SKP", "HOME"];

const quoteTypeFilterOptions: OptionType[] = Object.keys(QuoteTypes)
  .filter((type) => !excludedTypes.includes(type))
  .map((type) => ({
    title: formatQuoteType(type as QuoteTypes),
    value: type,
  }));
quoteTypeFilterOptions.unshift({title: "All", value: ""});

const stateFilterOptions: OptionType[] = Object.keys(States).map((state) => ({
  title: state,
  value: state,
}));
stateFilterOptions.splice(0, 0, {title: "All", value: ""});

function FilterBar({
  searchQuery = "",
  agentSearchQuery = "",
  statusFilter = "",
  underwriterFilter = "",
  stateFilter = "",
  quoteTypeFilter = "",
  paginateLoading = false,
  fetchSearch,
}: {
  searchQuery?: string;
  agentSearchQuery?: string;
  statusFilter?: string;
  underwriterFilter?: string;
  stateFilter?: string;
  quoteTypeFilter?: string;
  ownerFilter?: string;
  paginateLoading?: boolean;
  fetchSearch: () => void;
}) {
  const {updateSearchParams} = useNavigation();
  const permissions = usePermissions();
  const {data: allUnderwritersList} = useApiQuery(getAllUnderwritersList, {
    enabled: permissions.includes(Permissions.VIEW_ALL_UNDERWRITERS),
  })();
  const {data: agencyUnderwritersList} = useApiQuery(
    getAgencyUnderwritersList,
    {
      enabled: permissions.includes(Permissions.VIEW_AGENCY_QUOTES),
    }
  )();
  const underwriterOptions = useMemo(() => {
    const underwritersList = allUnderwritersList
      ? allUnderwritersList
      : agencyUnderwritersList
      ? agencyUnderwritersList
      : [];
    return [
      {title: "All", value: ""},
      ...(underwritersList.map((underwriter) => ({
        title: underwriter.full_name,
        value: underwriter.id.toString(),
      })) ?? []),
    ];
  }, [allUnderwritersList, agencyUnderwritersList]);

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          flex-grow: 1;
          max-width: 350px;
        `}
      >
        <SearchBox
          placeholder="Search quote number, name etc."
          searchValue={searchQuery}
          handleSearchInput={(v) => {
            updateSearchParams({searchQuery: v, pageNumber: 1});
            fetchSearch();
          }}
          height={38}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 3px;
        `}
      >
        {paginateLoading && <LoadingIndicator />}
        {permissions.includes(Permissions.VIEW_ALL_QUOTES) && (
          <SearchBox
            placeholder="Search agency"
            searchValue={agentSearchQuery}
            handleSearchInput={(v) => {
              updateSearchParams({agentSearchQuery: v, pageNumber: 1});
              fetchSearch();
            }}
            height={38}
          />
        )}
        <FilterDropDown
          title="State"
          options={stateFilterOptions}
          value={stateFilter}
          onChange={(value) => {
            updateSearchParams({stateFilter: value, pageNumber: 1});
            fetchSearch();
          }}
        />
        <FilterDropDown
          title="Quote Type"
          options={quoteTypeFilterOptions}
          value={quoteTypeFilter}
          onChange={(value) => {
            updateSearchParams({quoteTypeFilter: value, pageNumber: 1});
            fetchSearch();
          }}
        />
        <FilterDropDown
          title="Status"
          options={statusFilterOptions}
          value={statusFilter}
          onChange={(value) => {
            updateSearchParams({statusFilter: value, pageNumber: 1});
            fetchSearch();
          }}
        />
        {permissions.includes(Permissions.VIEW_ALL_UNDERWRITERS) &&
        underwriterOptions.length ? (
          <FilterDropDown
            title="Underwriter"
            value={underwriterFilter}
            options={underwriterOptions}
            onChange={(value) => {
              updateSearchParams({underwriterFilter: value, pageNumber: 1});
              fetchSearch();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default FilterBar;
