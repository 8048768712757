/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {useNavigation} from "hooks/navigation";
import ArrowLeftIcon from "@heroicons/react/outline/ArrowLeftIcon";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import UserButton from "components/UserButton";
import RateQuoteBindDropDown from "./RateQuoteBindDropDown";
import {ReactNode} from "react";
import IconButton from "components/IconButton";
import AppShellTitle from "components/AppShellTitle";
import PolicyNumberCombobox from "components/PolicyNumberCombobox";
import links from "links";

function Topbar({
  title,
  children,
  backLink,
}: {
  title?: string;
  children?: ReactNode;
  backLink?: string | null;
  username?: string;
}) {
  const {navigate} = useNavigation();
  const permissions = usePermissions();
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 10px;
        `}
      >
        {backLink && (
          <IconButton background={false} onClick={() => navigate(backLink)}>
            <ArrowLeftIcon
              css={css`
                width: 20px;
              `}
            />
          </IconButton>
        )}
        {title && !children && <AppShellTitle>{title}</AppShellTitle>}
        {children}
      </div>
      <div
        css={css`
          display: flex;
          gap: 20px;
          align-items: center;
        `}
      >
        <PolicyLookup />
        {permissions.includes(Permissions.CREATE_QUOTE) ? (
          <RateQuoteBindDropDown />
        ) : (
          <></>
        )}
        <div
          css={css`
            display: flex;
            gap: 16px;
          `}
        >
          <UserButton background={false} />
        </div>
      </div>
    </div>
  );
}

function PolicyLookup() {
  const {navigate} = useNavigation();
  const permissions = usePermissions();
  if (!permissions.includes(Permissions.VIEW_ALL_POLICIES)) {
    return null;
  }
  return (
    <PolicyNumberCombobox
      placeholder="Go to policy..."
      size="small"
      chevron={false}
      border={false}
      darkBackground
      onEnterKey={(value) => {
        navigate(links.policyQuery(value));
      }}
      selectedOption={undefined}
      setSelectedOption={(option) => {
        if (option?.value)
          navigate(
            links.policy({
              policyModIds: {
                policy_number: option.value.policy_number,
                display_policy_number: option.value.display_policy_number,
                pc_provider_name: option.value.pc_provider_name,
                policy_id: option.value.policy_id,
                company_number: option.value.company_number,
                lob: option.value.lob,
                mod_number: option.value.mod_number,
              },
            })
          );
      }}
    />
  );
}

export default Topbar;
