import styled from "@emotion/styled";
import {useAuth, useUser} from "hooks/auth";
import UserIcon from "@heroicons/react/outline/UserIcon";
import LogoutIcon from "@heroicons/react/outline/LogoutIcon";
import Divider from "components/Divider";
import StatusBadge from "components/StatusBadge";
import {useNavigation} from "hooks/navigation";
import {
  MenuButton,
  MenuButtonItem,
  MenuContainer,
  MenuItems,
  MenuTextItem,
} from "components/Menu";
const StyledLogoutIcon = styled(LogoutIcon)`
  width: 16px;
`;

export default function UserButton({background}: {background?: boolean}) {
  const {logout} = useAuth();
  const {
    userFullName,
    isUnderwriter,
    hasMultipleAgencies,
    agencyFullname,
    agencyOfficeCode,
    agencyId,
  } = useUser();
  const {currentUrl} = useNavigation();
  return (
    <MenuContainer>
      <MenuButton size="small" background={background} icon={UserIcon}>
        {userFullName}
      </MenuButton>
      <MenuItems>
        {(isUnderwriter || agencyId) && (
          <>
            <MenuTextItem>
              {agencyId && (
                <>
                  <StatusBadge value="AGENT" status="success" />
                  {agencyFullname} ({agencyOfficeCode})
                </>
              )}
              {isUnderwriter && (
                <StatusBadge value="UNDERWRITER" status="success" />
              )}
            </MenuTextItem>
            <Divider />
          </>
        )}
        {hasMultipleAgencies && (
          <MenuButtonItem
            icon={LogoutIcon}
            to={`/selectAgency?returnUrl=${currentUrl}`}
          >
            Switch Agency
          </MenuButtonItem>
        )}
        <MenuButtonItem onClick={() => logout()}>
          <StyledLogoutIcon />
          Logout
        </MenuButtonItem>
      </MenuItems>
    </MenuContainer>
  );
}
