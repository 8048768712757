import {useState} from "react";
import Avatar from "components/Avatar";
import styled from "@emotion/styled";
import ContentAndEditContentInput from "./ContentAndEditContentInput";
import {editQuoteInternalNote, QuoteInternalNote} from "api/internalNote";
import UserIcon from "assets/images/user.svg";
import {formatDateTime, formatName} from "utils/format";
import {usePermissions, useUser} from "hooks/auth";
import DeleteAndEditButtons from "./DeleteAndEditButtons";
import {useApiMutation} from "hooks/api";
import {Permissions} from "api/auth";

const NameTimeAndButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  margin: 12px 26px 17px 23px;
`;

const UserContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 9.94px;
`;

const NameAndTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
`;

const NameContainer = styled.div`
  color: #00206e;
`;

const TimeContainer = styled.div`
  color: #757676;
`;

const ContentContainer = styled.div`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #474747;
  margin: 0px 95px 12px 27px;
  overflow-wrap: anywhere;
`;

function InternalNote({
  note,
  setEditModeFocus,
}: {
  note: QuoteInternalNote;
  setEditModeFocus: (value: boolean) => void;
}) {
  const [editNoteContent, setEditNoteContent] = useState(note.content);
  const [editMode, setEditMode] = useState(false);
  const [iconHover, setIconHover] = useState(false);

  const user = useUser();

  const editQuoteInternalNoteMutation = useApiMutation(editQuoteInternalNote);

  let note_id = note.id;

  async function onEditSubmit(content: string) {
    if (editNoteContent.length > 0) {
      setEditModeFocus(false); // Focuses caret on new note input box.
      await editQuoteInternalNoteMutation.mutateAsync([{note_id, content}]);
      setEditMode(false);
    } else {
      setEditNoteContent(note.content); // sets original state if empty string
    }
  }

  const permissions = usePermissions();

  const hasEditOwnQuoteInternalNoteWithin24HoursPermission =
    permissions.includes(
      Permissions.EDIT_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS
    );

  const hasDeleteOwnQuoteInternalNoteWithin24HoursPermission =
    permissions.includes(
      Permissions.DELETE_OWN_QUOTE_INTERNAL_NOTE_WITHIN_24_HOURS
    );

  function getUserDisplayName() {
    if (note.user.first_name && note.user.last_name) {
      return (
        formatName(note.user.first_name) + " " + formatName(note.user.last_name)
      );
    } else if (note.user.first_name) {
      return formatName(note.user.first_name);
    } else {
      return formatName(note.user.last_name);
    }
  }

  function isWithin24Hours(noteCreatedAtDate: string) {
    const currentDate = Date.now();
    const twentyFourHours = 1000 * 60 * 60 * 24;
    const notePostingDate = Date.parse(noteCreatedAtDate);

    return currentDate - notePostingDate < twentyFourHours;
  }

  return (
    <>
      <NameTimeAndButtonsContainer>
        <UserContainer>
          <Avatar src={UserIcon}></Avatar>
          <NameAndTimeContainer>
            <NameContainer>{getUserDisplayName()}</NameContainer>
            <TimeContainer>{formatDateTime(note.created_at)}</TimeContainer>
          </NameAndTimeContainer>
        </UserContainer>
        {user.userId === note.user.id &&
          isWithin24Hours(note.created_at) &&
          hasEditOwnQuoteInternalNoteWithin24HoursPermission &&
          hasDeleteOwnQuoteInternalNoteWithin24HoursPermission && (
            <DeleteAndEditButtons
              note={note}
              editMode={editMode}
              setEditMode={(value) => setEditMode(value)}
              setEditModeFocus={(value) => setEditModeFocus(value)}
              onIconHover={(value) => setIconHover(value)}
              onEditSubmit={() => onEditSubmit(editNoteContent)}
              isEditSubmitLoading={editQuoteInternalNoteMutation.isLoading}
              editNoteContent={editNoteContent}
              onNoteChange={(value) => {
                setEditNoteContent(value);
              }}
            />
          )}
      </NameTimeAndButtonsContainer>
      <ContentContainer>
        <ContentAndEditContentInput
          note={note}
          editMode={editMode}
          setEditMode={(value) => {
            setEditMode(value);
          }}
          iconHover={iconHover}
          onNoteChange={(value) => {
            setEditNoteContent(value);
          }}
          onEditSubmit={() => {
            onEditSubmit(editNoteContent);
          }}
          editNoteContent={editNoteContent}
          isEditSubmitLoading={editQuoteInternalNoteMutation.isLoading}
        />
      </ContentContainer>
    </>
  );
}

export default InternalNote;
