import {deleteQuoteInternalNote, QuoteInternalNote} from "api/internalNote";
import Button from "components/Button";
import {useApiMutation} from "hooks/api";
import styled from "@emotion/styled";
import {PencilIcon, TrashIcon, XIcon} from "@heroicons/react/solid";
import {CheckIcon} from "@heroicons/react/outline";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;
`;

export const EditAndDeleteContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SubmitAndCancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;
`;

function DeleteAndEditButtons({
  note,
  editMode,
  setEditMode,
  setEditModeFocus,
  onIconHover,
  onEditSubmit,
  isEditSubmitLoading,
  editNoteContent,
  onNoteChange,
}: {
  note: QuoteInternalNote;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  setEditModeFocus: (value: boolean) => void;
  onIconHover: (value: boolean) => void;
  onEditSubmit: () => void;
  isEditSubmitLoading: boolean;
  editNoteContent: string;
  onNoteChange: (value: string) => void;
}) {
  const deleteQuoteInternalNoteMutation = useApiMutation(
    deleteQuoteInternalNote
  );

  async function deleteNote(note: QuoteInternalNote) {
    const deleteConfirmation = window.confirm(
      `Are you sure you'd like to delete this note?`
    );
    if (deleteConfirmation)
      await deleteQuoteInternalNoteMutation.mutateAsync([note.id]);
    setEditMode(false);
  }

  return (
    <>
      <MainContainer>
        <>
          {!editMode ? (
            <>
              <Button
                background={false}
                icon={PencilIcon}
                onClick={() => {
                  setEditMode(true);
                  setEditModeFocus(true); // prevents removal of focus from edit input box to new note input box
                  onNoteChange(note.content); // insures that check button is not disabled after having deleted all editNoteContent
                }}
              />
              <Button
                background={false}
                icon={TrashIcon}
                onClick={() => deleteNote(note)}
                isLoading={deleteQuoteInternalNoteMutation.isLoading}
              />
            </>
          ) : (
            <>
              {
                <SubmitAndCancelContainer>
                  <Button
                    background={false}
                    icon={CheckIcon}
                    type="submit"
                    disabled={editNoteContent.length === 0}
                    onClick={() => {
                      onEditSubmit();
                    }}
                    isLoading={isEditSubmitLoading}
                    onMouseOver={() => {
                      onIconHover(true);
                    }}
                    onMouseOut={() => onIconHover(false)}
                  />
                  <Button
                    background={false}
                    icon={XIcon}
                    onClick={() => {
                      setEditMode(false);
                    }}
                    onMouseOver={() => onIconHover(true)}
                    onMouseLeave={() => onIconHover(false)}
                  />
                </SubmitAndCancelContainer>
              }
            </>
          )}
        </>
      </MainContainer>
    </>
  );
}

export default DeleteAndEditButtons;
