/** @jsxImportSource @emotion/react */

import {useNavigation} from "hooks/navigation";
import {createQuote, getQuote, QuoteTypes} from "api/quote";
import {css} from "@emotion/react";
import SubTopBar from "components/SubTopBar";
import {useMutation, useQueryClient} from "react-query";
import Loading from "components/Loading";
import {H2} from "components/elements";
import HabitationalGuidelines from "./HabitationalGuidelines";
import SkpGuidelines from "./SkpGuidelines";
import BopGuidelines from "./BopGuidelines";
import MixedMercantileGuidelines from "./MixedMercantileGuidelines";
import {formatQuoteType} from "utils/format";

export default function NewQuote({quoteType}: {quoteType: string}) {
  const queryClient = useQueryClient();
  const {navigate, back} = useNavigation();
  const newQuote = useMutation(async () => createQuote(quoteType), {
    onSuccess: async (data) => {
      await queryClient.prefetchQuery(
        [getQuote.name, data.quote_id.toString()],
        () => getQuote(data.quote_id)
      );
      navigate({
        url: {
          path: `/rate-quote-bind/${data.quote_id}`,
          searchParamsObject: {
            backendDisplayQuoteNumber: data.backend_display_quote_number,
          },
        },
        replace: true,
      });
    },
  });
  return (
    <div>
      {!newQuote.isLoading ? (
        <Guidelines
          quoteType={quoteType}
          onYes={newQuote.mutateAsync}
          onNo={back}
        />
      ) : (
        <Loading>
          <H2>Just a sec while we get the quote form ready...</H2>
        </Loading>
      )}
    </div>
  );
}

export interface GuidlinesProps {
  onYes: () => void;
  onNo: () => void;
}

function Guidelines({
  quoteType,
  onYes,
  onNo,
}: {quoteType: string} & GuidlinesProps) {
  const contentWidth = 800;
  return (
    <div>
      <SubTopBar
        title={`${formatQuoteType(quoteType as QuoteTypes)} Guidelines`}
        width={contentWidth}
      />
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            width: ${contentWidth}px;
            padding: 30px 45px;
          `}
        >
          {quoteType === QuoteTypes.HAB && (
            <HabitationalGuidelines onYes={onYes} onNo={onNo} />
          )}
          {quoteType === QuoteTypes.MIXED_MERCANTILE && (
            <MixedMercantileGuidelines onYes={onYes} onNo={onNo} />
          )}
          {quoteType === QuoteTypes.SKP && (
            <SkpGuidelines onYes={onYes} onNo={onNo} />
          )}
          {quoteType === QuoteTypes.BOP && (
            <BopGuidelines onYes={onYes} onNo={onNo} />
          )}
        </div>
      </div>
    </div>
  );
}
