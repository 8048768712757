/** @jsxImportSource @emotion/react */

import {DebitsAndCredits} from "api/quote";
import {Box} from "components/Box";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import Field from "../../Field";

export default function DebitsAndCreditsSection({
  debitsAndCredits,
}: {
  debitsAndCredits: DebitsAndCredits[];
}) {
  return (
    <Box mb={100}>
      <Table
        checkeredBackground
        marginBottom={10}
        borderRadius={6}
        noBodyShadow
      >
        <Header>
          <HeaderCell>LOB</HeaderCell>
          <HeaderCell>Risk Characteristics</HeaderCell>
          <HeaderCell>Min</HeaderCell>
          <HeaderCell>Max</HeaderCell>
          <HeaderCell>Override Modifier</HeaderCell>
          <HeaderCell>Override Reason</HeaderCell>
        </Header>
        <Body>
          {debitsAndCredits.map((debitAndCredit, index) => (
            <Row key={index}>
              <Cell>{debitAndCredit.lob}</Cell>
              <Cell>{debitAndCredit.risk_characteristics}</Cell>
              <Cell>{`${debitAndCredit.override_min}%`}</Cell>
              <Cell>{`${debitAndCredit.override_max}%`}</Cell>
              <Cell>
                <Field.TextInput
                  object={debitAndCredit}
                  field={"override_modifier"}
                />
              </Cell>
              <Cell>
                <Field.TextInput
                  object={debitAndCredit}
                  field={"override_reason"}
                />
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </Box>
  );
}
