import {PolicyIds, PolicyInfo as PolicyInfoInterface} from "api/policy";
import {DetailsTable, Detail, Key, Value} from "components/DetailsTable";
import {formatAmount, formatDate} from "utils/format";

function PolicyInfo({
  policyIds,
  policyInfo,
}: {
  policyIds: PolicyIds;
  policyInfo: PolicyInfoInterface;
}) {
  return (
    <>
      <DetailsTable>
        <Detail>
          <Key>Policy number</Key>
          <Value>{policyIds.display_policy_number}</Value>
        </Detail>
        <Detail>
          <Key>Status</Key>
          <Value>{policyInfo.status}</Value>
        </Detail>
        <Detail>
          <Key>Policy period</Key>
          <Value>
            {formatDate(policyInfo.effective_date)} -{" "}
            {formatDate(policyInfo.expiration_date)}
          </Value>
        </Detail>
        <Detail>
          <Key>Line of business</Key>
          <Value>{policyInfo.lob_name}</Value>
        </Detail>
        <Detail>
          <Key>Insured information</Key>
          <Value>
            {policyInfo.insured_info.name}
            <br />
            {policyInfo.insured_info.address_info.line_1}
            <br />
            {policyInfo.insured_info.address_info.city},{" "}
            {policyInfo.insured_info.address_info.state}{" "}
            {policyInfo.insured_info.address_info.zip}
            <br />
            {policyInfo.insured_info.phone_number}
          </Value>
        </Detail>
        {policyInfo.insured_info.doing_bussines && (
          <Detail>
            <Key>Doing business as</Key>
            <Value>{policyInfo.insured_info.doing_bussines}</Value>
          </Detail>
        )}
        <Detail>
          <Key>Agent</Key>
          <Value>{policyInfo.agent_info.name}</Value>
        </Detail>
        <Detail>
          <Key>Premium amount</Key>
          <Value>{formatAmount(policyInfo.premium_amount)}</Value>
        </Detail>
        <Detail>
          <Key>Payment plan</Key>
          <Value>{policyInfo.payment_plan}</Value>
        </Detail>
      </DetailsTable>
    </>
  );
}

export default PolicyInfo;
