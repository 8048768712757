import {Permissions} from "api/auth";

export function permissionsContains(
  permissions: Permissions[],
  fragment: string
) {
  let contains = false;
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i].includes(fragment)) {
      contains = true;
      break;
    }
  }
  return contains;
}

export function validatePasswordStrength(password: string) {
  const validationErrorList: string[] = [];
  if (password.length < 8)
    validationErrorList.push("Password must have at least 8 letters");
  if (password.search("[0-9]") === -1)
    validationErrorList.push("Password must have a number");
  if (password.search("[A-Z]") === -1)
    validationErrorList.push("Password must have a capital letter");
  if (password.search("[^a-zA-Z0-9]") === -1)
    validationErrorList.push("Password must have a special character");

  return validationErrorList;
}
