import {useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {Dialog} from "@headlessui/react";
import {Underwriting} from "api/quote";
import {Box} from "components/Box";
import Button from "components/Button";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import {useSectionName} from "pages/RateQuoteBind/contexts/sectionNameContext";
import {useState} from "react";
import Field from "../../Field";
import IndentWrapper from "components/IndentWrapper";

const generalLiability = [
  {label: "$1,000,000 / $2,000,000", value: "2000000"},
  {label: "$500,000 / $1,000,000", value: "1000000"},
  {label: "$300,000 / $600,000", value: "300000"},
  {label: "Other", value: "other"},
];

const AnchorLink = styled.a`
  margin: 4px;
  color: ${(props) => props.theme.color.brand.primary};
  text-decoration: underline;
  &:hover {
    color: ${(props) =>
      props.theme.color.brand
        .primary}; // Adjust this as per the desired hover effect.
  }
`;

const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DialogBody = styled.div`
  background-color: ${(props) => props.theme.color.grayscale.white};
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0px 1px 3px ${(props) => props.theme.color.grayscale[300]};
  border-radius: 12px;
  z-index: 11;
  margin: 2rem auto;
`;

const DialogDescriptionWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
  margin-bottom: 1rem;
  padding-right: 1rem;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.grayscale[300]};
    border-radius: 10px;
  }
`;

const DialogTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
`;

export function DoesApplicantEngagInAnyFollowingOperationsActivitiesModal() {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(e: any) {
    e.preventDefault();
    setIsOpen(true);
  }

  return (
    <>
      Does the applicant perform work related to any of
      <AnchorLink href="#" onClick={openModal}>
        these
      </AnchorLink>
      areas?
      <Dialog
        open={isOpen}
        as="div"
        onClose={closeModal}
        className="headlessDialog"
      >
        <DialogOverlay />
        <DialogBody>
          <DialogTitle>Areas of work performed by the applicant</DialogTitle>
          <DialogDescriptionWrapper>
            <Dialog.Description>
              Worked performed by subcontractors who carry no general liability
              insurance, or carry general liability insurance with limits of
              liability less than the insured's limits of liability, or do not
              carry workers compensation insurance.
            </Dialog.Description>
            <Dialog.Description>
              Aerospace, airport work, railroads.
            </Dialog.Description>
            <Dialog.Description>
              Asbestos, mold, pollution or lead remediation.
            </Dialog.Description>
            <Dialog.Description>Amusement rides.</Dialog.Description>
            <Dialog.Description>
              Bridges, overpasses, tunnels, highways.
            </Dialog.Description>
            <Dialog.Description>
              Cell Towers / antennae more than 125 feet.
            </Dialog.Description>
            <Dialog.Description>
              Chemical, industrial, steam, or natural gas.
            </Dialog.Description>
            <Dialog.Description>
              Computer data center facilities.
            </Dialog.Description>
            <Dialog.Description>
              Dams, dikes, levees and reservoirs.
            </Dialog.Description>
            <Dialog.Description>
              Drilling, other than water wells.
            </Dialog.Description>
            <Dialog.Description>Earthquake reinforcement.</Dialog.Description>
            <Dialog.Description>
              Elevator installation or repair.
            </Dialog.Description>
            <Dialog.Description>
              Exterior Insulation Finishing Systems (EIFS).
            </Dialog.Description>
            <Dialog.Description>
              Fire suppression, proofing, escapes.
            </Dialog.Description>
            <Dialog.Description>
              Flood control or water damage restoration.
            </Dialog.Description>
            <Dialog.Description>
              Handling of fuel, oil, gas or petrochemicals.
            </Dialog.Description>
            <Dialog.Description>
              Hazardous substances or waste.
            </Dialog.Description>
            <Dialog.Description>
              Hospital or medical services.
            </Dialog.Description>
            <Dialog.Description>
              Hydronic or radiant heating.
            </Dialog.Description>
            <Dialog.Description>
              Mining, refining, oil field work.
            </Dialog.Description>
            <Dialog.Description>
              Nuclear facilities or operations.
            </Dialog.Description>
            <Dialog.Description>
              Pressure boilers and pressure vessels.
            </Dialog.Description>
            <Dialog.Description>
              Refrigeration systems in laboratories.
            </Dialog.Description>
            <Dialog.Description>
              Ship building, piers, wharves, seawalls.
            </Dialog.Description>
            <Dialog.Description>
              Street or road construction.
            </Dialog.Description>
            <Dialog.Description>
              Structural demolition or blasting.
            </Dialog.Description>
            <Dialog.Description>Tanks, water towers, silos.</Dialog.Description>
            <Dialog.Description>
              Underground pipelines or tanks.
            </Dialog.Description>
            <Dialog.Description>
              Water or sewage treatment plants.
            </Dialog.Description>
          </DialogDescriptionWrapper>
          <Box width="100%" display="flex" justifyContent="center">
            <Button onClick={closeModal}>Close</Button>
          </Box>
        </DialogBody>
      </Dialog>
    </>
  );
}

function UnderwritingForm({underwriting}: {underwriting?: Underwriting}) {
  const theme = useTheme();
  const sectionName = useSectionName();

  if (!underwriting) return <></>;

  return (
    <Box background={theme.color.brand.tertiary} py={34} px={32} mb={100}>
      <Box mb={32}>
        <FormTitle.Header2>{sectionName}</FormTitle.Header2>

        <Box mb={16}>
          <Field.TextInput
            object={underwriting}
            field="annual_gross_receipts"
            outLabel="What are your annual gross receipts?"
            isFormattedNumber
          />
        </Box>
        <Box mb={16}>
          <Field.TextInput
            object={underwriting}
            field="gross_annual_payroll"
            outLabel="What is the gross annual payroll (including compensation to owners and partners)?"
            isFormattedNumber
          />
        </Box>
        <Box mb={16}>
          <Field.TextInput
            object={underwriting}
            field="applicant_subcontractor_cost_of_total_receipts"
            outLabel="What is the applicant subcontractor cost of total receipts?"
            isFormattedNumber
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="have_atleast_3_years_in_the_trade"
            label="Does the applicant have at least three years of experience in the trade applicant is requesting coverage for?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="applicant_required_license_in_jurisdictions"
            label="Are license(s) required in the jurisdictions where the applicant performs work?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.applicant_required_license_in_jurisdictions ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="applicant_have_required_license"
                label="Does the applicant have the required license(s) in those jurisdictions?"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="perform_work_related_to_the_american_disabilities_act"
            label="Does the applicant perform work related to the American Disabilities Act involving installation or repair of chair lifts?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <DoesApplicantEngagInAnyFollowingOperationsActivitiesModal />
          <Field.RadioGroup
            object={underwriting}
            field="work_related_to_any_of_the_following"
            label=""
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="engage_in_exterior_work_3_stories"
            label="Does the business engage in exterior work involving more than 3 stories?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="perform_snow_ice_removal"
            label="Does applicant perform snow and/or ice removal?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.perform_snow_ice_removal ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.RadioGroup
                object={underwriting}
                field="separate_liability_policy_another_carrier"
                label="For applicants performing snow and/ or ice removal, does the applicant have a separate liability policy with another carrier?"
                options={[
                  {title: "Yes", value: 1},
                  {title: "No", value: 0},
                ]}
              />
            </Box>
          </IndentWrapper>
        ) : null}
        {underwriting.separate_liability_policy_another_carrier &&
        underwriting.perform_snow_ice_removal ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="other_carrier_snow_name"
                outLabel="Carrier Name"
              />
            </Box>
          </IndentWrapper>
        ) : null}
        {underwriting.separate_liability_policy_another_carrier &&
        underwriting.perform_snow_ice_removal ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.DropDown
                object={underwriting}
                field="other_carrier_snow_general_liability"
                outLabel="General Liability Limits"
                options={generalLiability.map((item) => ({
                  title: item.label,
                  value: item.value,
                }))}
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="applicant_performs_work_in_the_following_areas"
            label="Does the applicant perform any work in any of the following areas: new residential construction, new single family homes, condominiums, cooperatives, or town house involving foundation work, framing, or roofing, without insured subcontractors with proper risk transfer in favor of applicant; or new residentials developments of more than five (5) single family homes, condominiums, cooperatives, or townhouses in the same development involving plastering or stucco, siding or metal door and window installation; or new residential developments of more than twenty (20) single family homes, condominiums, cooperatives, or town houses in the same development? (Does not apply to new apartments or apartment buildings.)"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="three_years_current_ownership_and_prior_insurance"
            label="Is Insured's business three years under the current ownership with three years prior insurance?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="any_other_insurance"
            label="Does the applicant have any insurance with American European Insurance Group or its affiliated companies?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.any_other_insurance ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="other_insurance_policy_number"
                outLabel="Please provide the policy number."
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="any_policy_or_coverage_declined_within_3_years"
            label="Any Policy or coverage declined, cancelled or non-renewed during the prior 3 years?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.any_policy_or_coverage_declined_within_3_years ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="policy_or_coverage_declined_within_3_years_details"
                label="Please provide details."
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="subcontracters_with_no_insurance"
            label="Within the last 5 years, has the applicant been involved with work performed by subcontractors who carry no GL insurance; carry GL with limits less than the Applicant's; or who do not carry workers compensation insurance?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.subcontracters_with_no_insurance ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="subcontracters_with_no_insurance_description"
                label="Please describe in remarks."
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="perform_work_related_to_the_ada_within_5_years"
            label="Within the last 5 years, has the applicant performed any work related to the American Disabilities Act involving installation or repair of chair lifts?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="performed_work_related_to_these_areas_within_5_years"
            label="Within the last 5 years, has the applicant performed work related to any of the areas listed in this list?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="performed_work_with_more_than_20_homes"
            label="Within the last 5 years, has applicant performed any new residential developments of more than 20 single family homes, condos, cooperatives, or townhouses in the same development?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.performed_work_with_more_than_20_homes ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="description_of_performed_work_with_more_than_20_homes"
                outLabel="Please describe."
              />
            </Box>
          </IndentWrapper>
        ) : null}
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="performs_work_on_property_converted_to_multi_tenant"
            label="Does the applicant perform work on any property that is converted to multi-tenant residential ownership including townhouses, cooperatives, or condominiums (does not include multi-tenant apartments)?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="performs_hot_work"
            label='Does the applicant perform any "Hot Work" involving welding, torching, soldering, etc.?'
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        <Box mb={16}>
          <Field.RadioGroup
            object={underwriting}
            field="lapse_of_insurance"
            label="Has the applicant had a lapse of insurance coverage during the previous three years of 30 days or longer?"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
        {underwriting.lapse_of_insurance ? (
          <IndentWrapper>
            <Box mb={16}>
              <Field.TextInput
                object={underwriting}
                field="details_of_lapse_of_insurance"
                outLabel="Please provide details."
              />
            </Box>
          </IndentWrapper>
        ) : null}
      </Box>
    </Box>
  );
}

export default UnderwritingForm;
