import {
  getPolicyDetails,
  getPolicyForms,
  CommercialProperty,
  GeneralLiability,
  PolicyBasicInfo,
  CommercialAuto,
  INSURANCE_LINES_CODES,
  Named,
  PolicyModIds,
} from "api/policy";
import {Tabs, Tab, TabList, TabPanels, TabPanel} from "components/Tabs";
import {DetailsTable, Detail, Key, Value} from "components/DetailsTable";
import PolicyInfo from "./PolicyInfo";
import PolicyModSelector from "./PolicyModSelector";
import {formatAmount} from "utils/format";
import {H3, H4} from "components/elements";
import {Accordian, AccordianButton, AccordianPanel} from "components/Accordian";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import {List, ListItem} from "components/List";
import {useMemo} from "react";
import {PolicyPageParams} from "./policyPageParams";
import Loading from "components/Loading";
import {useApiQuery} from "hooks/api";

const insuranceLinesOrder = [
  INSURANCE_LINES_CODES.GL,
  INSURANCE_LINES_CODES.PR,
  INSURANCE_LINES_CODES.CA,
  INSURANCE_LINES_CODES.CR,
  INSURANCE_LINES_CODES.BO,
];

function PolicyDetails({
  params,
  policyModList,
}: {
  params: PolicyPageParams;
  policyModList: PolicyBasicInfo[];
}) {
  const {error, data: policyDetails} = useApiQuery(getPolicyDetails)(
    params.policyModIds
  );

  const insuranceLinesArray = useMemo(
    () =>
      policyDetails &&
      policyDetails.insurance_lines &&
      Object.values(policyDetails.insurance_lines)
        .filter(Boolean)
        .sort(
          (a, b) =>
            insuranceLinesOrder.indexOf(a.code) -
            insuranceLinesOrder.indexOf(b.code)
        ),
    [policyDetails]
  );

  return (
    <>
      <PolicyModSelector
        currentModNumber={params.policyModIds.mod_number}
        policyModList={policyModList}
      />
      {(policyDetails && (
        <Tabs>
          <div style={{marginBottom: 20}}>
            <TabList>
              <Tab>Policy Information</Tab>
              {insuranceLinesArray &&
                insuranceLinesArray.map((line) => <Tab>{line.name}</Tab>)}
              <Tab>Forms</Tab>
            </TabList>
          </div>
          <TabPanels>
            <TabPanel>
              <PolicyInfo
                policyIds={policyDetails.policy_mod_ids}
                policyInfo={policyDetails.policy_information}
              />
            </TabPanel>
            {insuranceLinesArray && (
              <InsuranceLinesTabs lines={insuranceLinesArray} />
            )}
            <TabPanel>
              <PolicyForms policyModIds={params.policyModIds} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )) ||
        (error && <div>Error</div>) || <Loading size="medium" />}
    </>
  );
}

function InsuranceLinesTabs({lines}: {lines: Named[]}) {
  return (
    <>
      {lines.map((line, index) => {
        if (line.code === INSURANCE_LINES_CODES.GL)
          return (
            <TabPanel key={index}>
              <GeneralLiabilityTab
                generalLiability={line as GeneralLiability}
              />
            </TabPanel>
          );

        if (line.code === INSURANCE_LINES_CODES.PR)
          return (
            <TabPanel key={index}>
              <CommercialPropertyTab
                commercialProperty={line as CommercialProperty}
              />
            </TabPanel>
          );

        if (line.code === INSURANCE_LINES_CODES.CA)
          return (
            <TabPanel key={index}>
              <CommercialAutoTab commercialAuto={line as CommercialAuto} />
            </TabPanel>
          );

        return <TabPanel />;
      })}
    </>
  );
}

function GeneralLiabilityTab({
  generalLiability,
}: {
  generalLiability: GeneralLiability;
}) {
  return (
    <>
      <DetailsTable>
        <Detail>
          <Key>Premium</Key>
          <Value>{formatAmount(generalLiability.premium)}</Value>
        </Detail>
        <Detail>
          <Key>Occurrence Limit</Key>
          <Value>{formatAmount(generalLiability.occurrence_limit)}</Value>
        </Detail>
        <Detail>
          <Key>Aggregate Limit</Key>
          <Value>{formatAmount(generalLiability.aggregate_limit)}</Value>
        </Detail>
      </DetailsTable>
    </>
  );
}

function CommercialPropertyTab({
  commercialProperty,
}: {
  commercialProperty: CommercialProperty;
}) {
  return (
    <>
      <DetailsTable>
        <Detail>
          <Key>Premium</Key>
          <Value>{formatAmount(commercialProperty.premium)}</Value>
        </Detail>
        <Detail>
          <Key>Terrorism coverage</Key>
          <Value>{commercialProperty.terrorism_coverage ? "Yes" : "No"}</Value>
        </Detail>
      </DetailsTable>
      {commercialProperty.locations.length > 0 && <H3>Location</H3>}
      {commercialProperty.locations.map((location) => (
        <Accordian>
          <AccordianButton css={{lineHeight: 1.5}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 50px 0px 10px",
                fontSize: 14,
              }}
            >
              <span style={{fontWeight: "Bold"}}>{location.number}</span>
              <span style={{fontWeight: "Bold"}}>
                {`${location.address_info.line_1}, ${
                  location.address_info.city &&
                  `${location.address_info.city}, 
                ${location.address_info.state} ${location.address_info.zip}`
                }`}
              </span>
              <span></span>
            </div>
          </AccordianButton>
          <AccordianPanel>
            <DetailsTable>
              <Detail>
                <Key>Deductible</Key>
                <Value>{formatAmount(location.deductible)}</Value>
              </Detail>
              {location.windhail_deductible && (
                <Detail>
                  <Key>Windhail Deductible</Key>
                  <Value>{formatAmount(location.windhail_deductible)}</Value>
                </Detail>
              )}
            </DetailsTable>
            {location.buildings.map((building) => (
              <>
                <H3>Building #{building.number}</H3>
                <DetailsTable>
                  <Detail>
                    <Key>Building Valuation</Key>
                    <Value>{building.building_valuation}</Value>
                  </Detail>
                  <Detail>
                    <Key>Cause of loss</Key>
                    <Value>{building.cause_of_loss}</Value>
                  </Detail>
                  <Detail>
                    <Key>Coinsurance</Key>
                    <Value>{building.coinsusrance}%</Value>
                  </Detail>
                </DetailsTable>
                <Table>
                  <Header>
                    <HeaderCell>Coverage</HeaderCell>
                    <HeaderCell>Limit</HeaderCell>
                    <HeaderCell>Premium</HeaderCell>
                  </Header>
                  <Body>
                    {building.building_coverages.map((coverage) => (
                      <Row>
                        <Cell>{coverage.name}</Cell>
                        <Cell>{formatAmount(coverage.limit)}</Cell>
                        <Cell>{formatAmount(coverage.premium)}</Cell>
                      </Row>
                    ))}
                  </Body>
                </Table>
              </>
            ))}
          </AccordianPanel>
        </Accordian>
      ))}
    </>
  );
}

function CommercialAutoTab({commercialAuto}: {commercialAuto: CommercialAuto}) {
  return (
    <>
      <DetailsTable>
        <Detail>
          <Key>Premium</Key>
          <Value>{formatAmount(commercialAuto.premium)}</Value>
        </Detail>
      </DetailsTable>
      {commercialAuto.drivers.length > 0 && (
        <>
          <H3>Drivers</H3>
          <List>
            {commercialAuto.drivers.map((driver) => (
              <ListItem>{driver.name}</ListItem>
            ))}
          </List>
        </>
      )}
      {commercialAuto.liab_coverages && (
        <Table>
          <Header>
            <HeaderCell>Coverage</HeaderCell>
            <HeaderCell>Limit</HeaderCell>
            <HeaderCell>Premium</HeaderCell>
          </Header>
          <Body>
            {commercialAuto.liab_coverages.map((coverage) => (
              <Row>
                <Cell>{coverage.name}</Cell>
                <Cell>{formatAmount(coverage.limit)}</Cell>
                <Cell>{formatAmount(coverage.premium)}</Cell>
              </Row>
            ))}
          </Body>
        </Table>
      )}
      {commercialAuto.vehicles.length > 0 && <H3>Vehicles</H3>}
      {commercialAuto.vehicles.map((vehicle) => (
        <Accordian>
          <AccordianButton css={{lineHeight: 1.5}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 50px 0px 10px",
              }}
            >
              <span style={{color: "#808080"}}>{vehicle.number}</span>
              <span>
                {vehicle.year} {vehicle.make_and_model}
              </span>
              <span>VIN #: {vehicle.vin_number}</span>
              <span>{formatAmount(vehicle.premium)}</span>
            </div>
          </AccordianButton>
          <AccordianPanel>
            <DetailsTable>
              <Detail>
                <Key>Garage address</Key>
                <Value>
                  {vehicle.address_info.line_1}
                  <br />
                  {vehicle.address_info.city}, {vehicle.address_info.state}{" "}
                  {vehicle.address_info.zip}
                </Value>
              </Detail>
              <Detail>
                <Key>Registered state</Key>
                <Value>{vehicle.state}</Value>
              </Detail>
              <Detail>
                <Key>Plate number</Key>
                <Value>{vehicle.dmv_plate_number}</Value>
              </Detail>
              <Detail>
                <Key>Vehicle type</Key>
                <Value>{vehicle.type}</Value>
              </Detail>
              <Detail>
                <Key>Class code</Key>
                <Value>{vehicle.class_code}</Value>
              </Detail>
            </DetailsTable>
            {vehicle.vehicle_coverages.map((coverage) => (
              <div>
                <H4>{coverage.name} Coverage</H4>
                <DetailsTable>
                  {coverage.limit && (
                    <Detail>
                      <Key>Limit</Key>
                      <Value>
                        {formatAmount(coverage.limit)}{" "}
                        {coverage.limit_desc ? coverage.limit_desc : ""}
                      </Value>
                    </Detail>
                  )}
                  {coverage.deductible && (
                    <Detail>
                      <Key>Deductible</Key>
                      <Value>{formatAmount(coverage.deductible)}</Value>
                    </Detail>
                  )}
                  {coverage.premium && (
                    <Detail>
                      <Key>Premium</Key>
                      <Value>{formatAmount(coverage.premium)}</Value>
                    </Detail>
                  )}
                </DetailsTable>
              </div>
            ))}
          </AccordianPanel>
        </Accordian>
      ))}
    </>
  );
}

function PolicyForms({policyModIds}: {policyModIds: PolicyModIds}) {
  const {error, data} = useApiQuery(getPolicyForms)(policyModIds);
  return (
    <>
      {(data && (
        <Table checkeredBackground>
          <Header>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell>Description</HeaderCell>
            <HeaderCell>Edition date</HeaderCell>
          </Header>
          <Body>
            {data.map((form) => (
              <Row>
                <Cell>{form.name}</Cell>
                <Cell>{form.description}</Cell>
                <Cell>{form.edition}</Cell>
              </Row>
            ))}
          </Body>
        </Table>
      )) ||
        (error && <div>Error</div>) || <Loading size="medium" />}
    </>
  );
}

export default PolicyDetails;
