import {DEFAULT_PORTAL_TITLE} from "appConstants";
import {snakeCaseToCamelCase} from "./format";

export function getHtmlTitle(title: string | undefined) {
  return title ? `${title} - ${DEFAULT_PORTAL_TITLE}` : DEFAULT_PORTAL_TITLE;
}

export function objectKeysToCamelCase(object: Object) {
  const params: {[index: string]: any} = {};
  Object.entries(object).forEach(([key, value]) => {
    params[snakeCaseToCamelCase(key)] = value;
  });
  return params;
}

export function toBoolean(value: any): boolean {
  return ["true", "1"].includes(value);
}
