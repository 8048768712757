/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/solid/ChevronUpIcon";
import IconButton from "./IconButton";

function ChevronToggle({up, onClick}: {up: boolean; onClick: () => void}) {
  return (
    <IconButton
      background={true}
      color="secondary"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {up ? (
        <ChevronUpIcon
          css={css`
            width: 20px;
          `}
        />
      ) : (
        <ChevronDownIcon
          css={css`
            width: 20px;
          `}
        />
      )}
    </IconButton>
  );
}

export default ChevronToggle;
