/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {formatDateTime} from "utils/format";
import {MouseEventHandler} from "react";
import {Thread} from "api/message";

const MainContainer = styled.div`
  padding: 20px 16px 20px 28px;
  cursor: pointer;
  background: ${(props) => props.theme.color.grayscale.white};
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  &.active,
  &:hover {
    border-color: ${(props) => props.theme.color.state.blue};
  }
`;

const PolicyName = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${(props) => props.theme.color.brand.primary};
  position: absolute;
  left: -10px;
  top: 6px;
`;

const Name = styled.div`
  ${(props) => props.theme.text.p2}
  margin-bottom: 2px;
  color: ${(props) => props.theme.color.grayscale[800]};
`;

const Description = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[600]};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

function ThreadListItem({
  thread,
  onClickThread,
  active,
  quoteNumber,
}: {
  thread: Thread;
  onClickThread: MouseEventHandler<HTMLDivElement>;
  active: Boolean;
  quoteNumber?: string;
}) {
  return (
    <MainContainer
      className={`${active ? "active" : ""}`}
      onClick={onClickThread}
      key={thread.id}
    >
      <PolicyName>
        {thread?.unread_messages_count ? <Dot></Dot> : <> </>}
        <h3
          css={(theme) => css`
            ${theme.text.header3}
            margin: 0 0 4px 0;
            color: ${theme.color.grayscale[800]};
            word-break: break-word;
          `}
        >
          {!quoteNumber
            ? thread.title
            : quoteNumber === thread.title
            ? `Quote ${quoteNumber}`
            : thread.title}
        </h3>

        <Description>
          {thread?.last_message?.created_at
            ? formatDateTime(thread?.last_message?.created_at)
            : "-"}
        </Description>
      </PolicyName>
      {quoteNumber && quoteNumber !== thread.title && (
        <Description>Quote {quoteNumber}</Description>
      )}
      <Name>{thread?.last_message?.user?.first_name}</Name>
      <Description
        dangerouslySetInnerHTML={{__html: thread?.last_message?.content || ""}}
      />
    </MainContainer>
  );
}

export default ThreadListItem;
