/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {
  getPolicyPaymentInfo,
  getRecurringPayment,
  RecurringPayment,
} from "api/payment";
import {Permissions} from "api/auth";
import Button from "components/Button";
import {useNavigation} from "hooks/navigation";
import {formatAmount, formatDate} from "utils/format";
import Loading from "components/Loading";
import styled from "@emotion/styled";
import {getPaymentAmounts} from "utils/quotePolicy";
import {useApiQuery} from "hooks/api";
import Spacer from "components/Spacer";
import Link from "components/Link";
import {usePermissions} from "hooks/auth";
import {legacyRecurringPaymentsExist} from "utils/payment";
import {PolicyModIds, PolicyStatuses} from "api/policy";
import links from "links";
import AlertBar from "components/AlertBar";
import {useState} from "react";
import {DialogBody, DialogButtonsContainer} from "components/Dialog";
import PaymentTransactionHistory from "./PaymentTransactionHistory";
import {AnchorButton} from "components/elements";
import {DialogFullWidthPanel} from "components/DialogFullWidthPanel";

function PaymentTransactionPopUp({policyModIds}: {policyModIds: PolicyModIds}) {
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal(e: any) {
    e.preventDefault();
    setIsOpen(true);
  }
  return (
    <div
      css={css`
        margin-top: 18px;
        text-align: center;
        width: 100%;
      `}
    >
      <AnchorButton onClick={openModal}>Payment History</AnchorButton>
      <DialogFullWidthPanel
        css={css`
          width: 100%;
        `}
        title="Payment History"
        open={isOpen}
        onClose={closeModal}
      >
        <DialogBody
          css={css`
            width: 100%;
          `}
        >
          <DialogButtonsContainer
            css={css`
              width: 100%;
            `}
          >
            <PaymentTransactionHistory policyModIds={policyModIds} />
          </DialogButtonsContainer>
        </DialogBody>
      </DialogFullWidthPanel>
    </div>
  );
}
function PolicyPaymentOverview({policyModIds}: {policyModIds: PolicyModIds}) {
  const {data: policyPaymentInfo} =
    useApiQuery(getPolicyPaymentInfo)(policyModIds);
  const permissions = usePermissions();
  const recurringPayment = useApiQuery(getRecurringPayment, {
    enabled: permissions.includes(Permissions.VIEW_RECURRING_PAYMENT),
  })(policyModIds);
  const {navigate} = useNavigation();
  const isPaymentOnCancelledPolicyWithoutPermission =
    policyPaymentInfo?.policy_info.status === PolicyStatuses.CANCELLED &&
    !permissions.includes(Permissions.MAKE_PAYMENT_ON_CANCELLED_POICY);
  return (
    <div>
      <OverviewHeader>Payment Overview</OverviewHeader>
      {policyPaymentInfo ? (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 28px;
            `}
          >
            <span>
              <SectionTitle>Payment Plan</SectionTitle>
              <div>{policyPaymentInfo.payment_plan}</div>
            </span>

            <span>
              <SectionTitle>Balances</SectionTitle>
              <DataList>
                {getPaymentAmounts(policyPaymentInfo).map(
                  ({description, amount}) => (
                    <DataField
                      description={description}
                      value={formatAmount(amount, {showZero: true})}
                    />
                  )
                )}
                {policyPaymentInfo.pending_payments_total > 0 && (
                  <DataField
                    description="Total pending payments"
                    value={formatAmount(
                      policyPaymentInfo.pending_payments_total
                    )}
                  />
                )}
              </DataList>
            </span>
            {recurringPayment.data && (
              <span>
                <RecurringPaymentSection
                  policyModIds={policyModIds}
                  recurringPayment={recurringPayment.data}
                />
              </span>
            )}
          </div>
          {policyPaymentInfo.remaining_balance > 0 &&
            permissions.includes(Permissions.MAKE_PAYMENT) &&
            !isPaymentOnCancelledPolicyWithoutPermission && (
              <Button
                fullwidth
                onClick={() => navigate(links.payment({policyModIds}))}
                css={css`
                  margin-top: 18px;
                `}
              >
                Make Payment
              </Button>
            )}
        </>
      ) : (
        <Loading size="small" margin={15} />
      )}
      <PaymentTransactionPopUp policyModIds={policyModIds} />
    </div>
  );
}

function RecurringPaymentSection({
  policyModIds,
  recurringPayment,
}: {
  policyModIds: PolicyModIds;
  recurringPayment: RecurringPayment;
}) {
  const permissions = usePermissions();

  const AlertBarContainer = styled.div`
    margin-bottom: 16px; /* Adjust the value as needed */
  `;

  let {
    legacyPaymentsExist,
    bothLegacyPortalsHaveRecurringPayments,
    agentRecurringHasRecurringPayments,
    insuredRecurringHasRecurringPayments,
  } = legacyRecurringPaymentsExist(recurringPayment);
  console.log(recurringPayment);
  return (
    <span>
      <SectionTitle>Recurring Payment</SectionTitle>
      {recurringPayment.failed_recurring_transaction && (
        <AlertBarContainer>
          <AlertBar status="error">
            {recurringPayment.failed_recurring_transaction}
          </AlertBar>
        </AlertBarContainer>
      )}

      <div>
        {recurringPayment.enabled
          ? `Setup with ${recurringPayment.payment_method_description}`
          : bothLegacyPortalsHaveRecurringPayments
          ? "On in Agent & Insured Portals (Legacy)"
          : agentRecurringHasRecurringPayments
          ? "On in Agent Portal (Legacy)"
          : insuredRecurringHasRecurringPayments
          ? "On in Insured Portal (Legacy)"
          : "Off"}
      </div>
      {recurringPayment.enabled && recurringPayment.signup_date && (
        <div>as of {formatDate(recurringPayment.signup_date)}</div>
      )}
      {permissions.includes(Permissions.MANAGE_RECURRING_PAYMENT) && (
        <>
          <Spacer height={8} />
          <Link
            to={links.recurringPayments({
              policyModIds,
              setupView: !recurringPayment.enabled,
            })}
          >
            {recurringPayment.enabled
              ? "Manage Recurring Payment"
              : legacyPaymentsExist
              ? "Setup in new portal"
              : "Setup Recurring Payment"}
          </Link>
        </>
      )}
    </span>
  );
}

const OverviewHeader = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  margin: 0;
  margin-bottom: 25px;
  color: ${({theme}) => theme.color.brand.primary};
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const DataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

function DataField({description, value}: {description: string; value: string}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 120%;
      `}
      key={description}
    >
      <span
        css={css`
          max-width: 200px;
        `}
      >
        {description}
      </span>
      <span>{value}</span>
    </div>
  );
}

export default PolicyPaymentOverview;
