import {
  useState,
  useMemo,
  useEffect,
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
} from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  width: 100%;
`;

const Label = styled.label`
  ${(props) => props.theme.text.p1}
  color: ${(props) => props.theme.color.grayscale[900]};
  pointer-events: none;
  margin-bottom: 16px;
  font-weight: bold;
`;

const RequiredMark = styled.span`
  margin-left: 3px;
  color: ${(props) => props.theme.color.state.red};
`;

const InputWrapper = styled.div`
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  position: relative;
  border-radius: 4px;
  margin: 8px 0;
  &.disabled {
    background-color: ${(props) => props.theme.color.brand.tertiary};
  }
`;

const Input = styled.input`
  height: 23px;
  width: 23px;
  position: relative;

  &:before {
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 100%;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid ${(props) => props.theme.color.brand.primary};
  }

  &:checked:after {
    width: 15px;
    height: 15px;
    position: absolute;
    content: "";
    background: ${(props) => props.theme.color.brand.primary};
    visibility: visible;
    border-radius: 100%;
    left: 4px;
    top: 4px;
  }

  &:not(:checked):hover:after {
    width: 19px;
    height: 19px;
    position: absolute;
    content: "";
    background: ${(props) => props.theme.color.brand.secondary};
    visibility: visible;
    border-radius: 100%;
    left: 2px;
    top: 2px;
    cursor: pointer;
  }

  &:checked:hover:after {
    width: 15px;
    height: 15px;
    position: absolute;
    content: "";
    background: ${(props) => props.theme.color.brand.primary}C9;
    visibility: visible;
    border-radius: 100%;
    left: 4px;
    top: 4px;
    cursor: pointer;
  }

  &[disabled]:before {
    border: 2px solid ${(props) => props.theme.color.grayscale[500]};
  }

  &[disabled]:checked:after {
    background: ${(props) => props.theme.color.grayscale[500]};
  }
  &[disabled]:not(:checked):hover:after {
    background: unset;
  }
`;

const InputLabel = styled.label`
  ${(props) => props.theme.text.header3}
  font-weight: bold;
  margin-left: 7px;
  cursor: pointer;
  color: ${(props) => props.theme.color.brand.primary};
  &.disabled {
    color: ${(props) => props.theme.color.grayscale[500]};
  }
`;

export type RadioOptionType = {
  value: string | number;
  title: string | object;
};

export type RadioGroupProps = {
  label?: string;
  required?: boolean;
  options: RadioOptionType[];
  onChange?: (value: string | number) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

let radioInputIdNumber = 0;

export const RadioGroup = ({
  label,
  disabled,
  required,
  value,
  options,
  className,
  onChange,
  onBlur,
  onFocus,
  ...props
}: RadioGroupProps) => {
  const [focused, setFocused] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  const inputId = useMemo(() => `radio-input-${radioInputIdNumber++}`, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const foundOption = options.find(
      // eslint-disable-next-line eqeqeq
      (item) => item.value == e.target.value
    );
    if (foundOption) {
      setInternalValue(foundOption.value);
      if (onChange) onChange(foundOption.value);
    }
  };
  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Wrapper>
      {label && (
        <Label
          className={classNames({
            focused: focused,
            disabled: disabled,
          })}
        >
          {label}
          {required && <RequiredMark>*</RequiredMark>}
        </Label>
      )}
      {options.map((option, index) => (
        <InputWrapper key={index}>
          <Input
            type="radio"
            {...props}
            id={inputId + "-" + index}
            name={inputId}
            value={option.value}
            className={className}
            // eslint-disable-next-line eqeqeq
            checked={internalValue == option.value}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <InputLabel
            htmlFor={inputId + "-" + index}
            className={classNames({
              disabled: disabled,
            })}
          >
            {option.title as string}
          </InputLabel>
        </InputWrapper>
      ))}
    </Wrapper>
  );
};
