/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {GuidlinesProps} from ".";
import GuidelinesSection from "./GuidelinesSection";
import YesNoButtons from "./YesNoButtons";
import approvedSvg from "assets/images/approved.svg";
import workerSvg from "assets/images/worker.svg";
import Ul from "./Ul";
import ButtonsContainer from "./ButtonsContainer";
import QuestionTitle from "./QuestionTitle";

export default function BopGuidelines({onYes, onNo}: GuidlinesProps) {
  return (
    <div
      css={css`
        display: flex;
        gap: 5px;
        flex-direction: column;
      `}
    >
      <QuestionTitle>
        Does this risk location meet the guidelines outlined below?{" "}
      </QuestionTitle>
      <GuidelinesSection title="Eligibility requirements" svgUrl={approvedSvg}>
        <Ul></Ul>
      </GuidelinesSection>
      <GuidelinesSection title="Contractor Classes" svgUrl={workerSvg}>
        <Ul></Ul>
      </GuidelinesSection>
      <ButtonsContainer>
        <YesNoButtons onYes={onYes} onNo={onNo} />
      </ButtonsContainer>
    </div>
  );
}
