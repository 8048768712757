import {ReactNode} from "react";
import {useMutation, useQueryClient} from "react-query";
import {
  QuoteStatuses,
  getQuote,
  setFullQuote,
  setQuickQuote,
  submitQuote,
  submitQuoteReview,
} from "api/quote";
import styled from "@emotion/styled";
import {QuoteForm, QuoteTypes} from "api/quote";
import {SectionNameProvider} from "../contexts/sectionNameContext";
import FormTitle from "../components/FormTitle";
import {forms} from "./forms";
import {useSectionErrorContext} from "../contexts/sectionErrorContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import Button from "components/Button";
import Box from "components/Box";
import PremiumAmountSection from "./PremiumAmountSection";
import {useQuoteContext} from "../contexts/quoteContext";
import UpdateErrorPopup from "./UpdateErrorPopup";
import {RateTabs} from "../components/RateQuoteBindTopBar";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {
  inApprovedStatus,
  inQuoteStatus,
  inReviewStatus,
} from "utils/quotePolicy";
import QuoteNotesSection, {QUOTE_NOTES_SECTION_NAME} from "./QuoteNotesSection";
import AlertBar from "components/AlertBar";
import RatePanelLeftBar from "./RatePanelLeftBar";
import QuoteInfoSection, {QUOTE_INFO_SECTION_NAME} from "./QuoteInfoSection";
import useShowAPIErrors from "./hooks/useShowAPIErrors";
import ScrollToSection, {useSection} from "../components/ScrollToSection";
import {useRateContext} from "../contexts/rateContext";
import {useNavigation} from "hooks/navigation";
import {css} from "@emotion/react";
import InsuredInfoForm, {INSURED_INFO_SECTION_NAME} from "./InsuredInfoForm";

export const UNDERWRITER_SECTION_NAME = "Underwriting Questions";
export function parseRateError(rate_error: any) {
  const rateErrorString = String(rate_error);
  const joinArray = [];
  if (rateErrorString.includes("ProtectionClass")) {
    joinArray.push("Select Protection Class In Risk Location");
  }
  if (rateErrorString.includes("ZipCode")) {
    joinArray.push("Check Zip Code");
  }
  if (rateErrorString.includes("WindHailDeduPercent")) {
    joinArray.push("Windhail Deductible");
  }
  joinArray.push(
    "There was a rating error. Underwriter was notified. Please try again."
  );
  return joinArray.join(",");
}
interface Sections {
  [key: string]: ReactNode;
}

export interface Form {
  sections: Sections;
  [UNDERWRITER_SECTION_NAME]: ReactNode;
}

function allowToRateAndSubmit(
  status: QuoteStatuses,
  permissions: Permissions[],
  underwriterIsQuoting: boolean
) {
  // Checks to see if quote has been submitted yet. If not, anyone can see rate and submit buttons.
  // Alternatively, if the quote has already been submitted, underwriters can still see the rate
  // and submit buttons UNLESS the quote has already been bound.
  return (
    inQuoteStatus(status) ||
    (permissions.includes(Permissions.EDIT_QUOTE_ALWAYS) &&
      status !== QuoteStatuses.BOUND &&
      underwriterIsQuoting)
  );
}

export default function RatePanel({
  quoteForm,
  quoteType,
  backendQuoteID,
  hasReferralPreRate,
  hasImmediateDeclination,
}: {
  quoteForm: QuoteForm;
  quoteType: QuoteTypes;
  backendQuoteID: string;
  hasReferralPreRate: boolean;
  hasImmediateDeclination: boolean;
}) {
  useSection();
  useFieldsStatusContext();
  const permissions = usePermissions();
  const {status, isBackendQuoting, dirty} = useQuoteContext();
  const {rate, showRate, canNotRateAndSubmitWhenBackendQuoting} =
    useRateContext();
  const {rate_error} = useFieldsStatusContext();
  const formFunction = forms[quoteType];

  if (!formFunction) {
    return null;
  }
  const form = formFunction(quoteForm);
  const formSections = form.sections;

  const showNotesSection =
    quoteForm.notes.length > 0 || permissions.includes(Permissions.QUOTE_NOTES);

  return (
    <Wrapper>
      <RatePanelLeftBar
        showNotesSection={showNotesSection}
        formSections={formSections}
      />
      <BodyWrapper>
        {status === QuoteStatuses.BOUND && (
          <AlertBar status="success">This is a bound quote.</AlertBar>
        )}
        <SectionNameProvider value={QUOTE_INFO_SECTION_NAME}>
          <ScrollToSection sectionName={QUOTE_INFO_SECTION_NAME}>
            <QuoteInfoSection quoteForm={quoteForm} />
          </ScrollToSection>
        </SectionNameProvider>
        {isBackendQuoting && (
          <AlertBar status="warning">
            The fields may be out of sync as this quote is being backend quoted.
          </AlertBar>
        )}
        <SectionNameProvider value={INSURED_INFO_SECTION_NAME}>
          <ScrollToSection sectionName={INSURED_INFO_SECTION_NAME}>
            <FormTitle.Header>{INSURED_INFO_SECTION_NAME}</FormTitle.Header>
            <InsuredInfoForm
              insureds={quoteForm.insureds}
              quoteId={`${quoteForm.quote_id}`}
            />
          </ScrollToSection>
        </SectionNameProvider>
        {Object.entries(formSections).map(([sectionName, element], index) => (
          <SectionNameProvider value={sectionName} key={index}>
            <ScrollToSection sectionName={sectionName}>
              <FormTitle.Header>{sectionName}</FormTitle.Header>
              {element}
            </ScrollToSection>
          </SectionNameProvider>
        ))}
        {showNotesSection && (
          <SectionNameProvider value={QUOTE_NOTES_SECTION_NAME}>
            <ScrollToSection sectionName={QUOTE_NOTES_SECTION_NAME}>
              <FormTitle.Header>{QUOTE_NOTES_SECTION_NAME}</FormTitle.Header>
              <QuoteNotesSection
                quoteForm={quoteForm}
                viewOnly={!permissions.includes(Permissions.QUOTE_NOTES)}
              />
            </ScrollToSection>
          </SectionNameProvider>
        )}
        <PremiumAmountSection
          rate={rate}
          show={showRate}
          backendQuoteID={backendQuoteID}
        />

        {!hasReferralPreRate &&
          !hasImmediateDeclination &&
          !!rate_error &&
          status !== QuoteStatuses.QUOTING_ERROR &&
          !dirty && (
            <Box width="100%" my={20}>
              <AlertBar status="error">{parseRateError(rate_error)}</AlertBar>
            </Box>
          )}
        {hasReferralPreRate && (
          <Box width="100%" my={20}>
            <AlertBar status="warning">
              No rate available at this time. You may submit for further
              underwriter review.
            </AlertBar>
          </Box>
        )}
        {hasImmediateDeclination && (
          <Box width="100%" my={20}>
            <AlertBar status="error">This quote is declined.</AlertBar>
          </Box>
        )}
        {canNotRateAndSubmitWhenBackendQuoting && (
          <Box width="100%" my={20}>
            <AlertBar status="warning">
              You do not have permission to rate and submit this quote. The
              underwriter is currently working on this quote.
            </AlertBar>
          </Box>
        )}
        <RateButton />
        {status !== QuoteStatuses.QUICK_QUOTE && (
          <SectionNameProvider value={UNDERWRITER_SECTION_NAME}>
            <ScrollToSection sectionName={UNDERWRITER_SECTION_NAME}>
              {form[UNDERWRITER_SECTION_NAME]}
            </ScrollToSection>
          </SectionNameProvider>
        )}
        <QuickQuoteButton />
        <FullQuoteButton />
        <SubmitButton />
      </BodyWrapper>
      <UpdateErrorPopup />
    </Wrapper>
  );
}

function RateButton() {
  const {
    fetchingRate,
    fetchRate,
    showRate,
    showRateButton,
    disableRateButton,
    canNotRateAndSubmitWhenBackendQuoting,
  } = useRateContext();
  const {dirty, status, underwriterIsQuoting} = useQuoteContext();
  const {rate_error} = useFieldsStatusContext();
  const permissions = usePermissions();

  if (
    !showRateButton ||
    !allowToRateAndSubmit(status, permissions, underwriterIsQuoting)
  ) {
    return null;
  }

  return (
    <Box width="100%" display="flex" justifyContent="center" mb={30}>
      <Button
        size="large"
        disabled={disableRateButton || canNotRateAndSubmitWhenBackendQuoting}
        isLoading={fetchingRate}
        onClick={fetchRate}
      >
        {rate_error && !dirty
          ? "Retry Rate Indication"
          : !showRate
          ? "Get Rate Indication"
          : "Recalculate Rate Indication"}
      </Button>
    </Box>
  );
}

function QuickQuoteButton() {
  const queryClient = useQueryClient();
  const {quoteId, status} = useQuoteContext();
  const {fieldsDisabled} = useFieldsStatusContext();
  const {getTotalFieldErrorsCount} = useSectionErrorContext();
  const totalFieldErrors = getTotalFieldErrorsCount();

  const {mutateAsync: mutateSetQuickQuote, isLoading: loadingQuickQuote} =
    useMutation(() => setQuickQuote(quoteId), {
      onSuccess: () =>
        queryClient.invalidateQueries([getQuote.name, quoteId.toString()]),
    });

  if (status !== QuoteStatuses.FULL_QUOTE || totalFieldErrors === 0) {
    return null;
  }

  return (
    <Box width="100%" display="flex" justifyContent="center" mt={24}>
      <Button
        background
        size="large"
        isLoading={loadingQuickQuote}
        onClick={() => mutateSetQuickQuote()}
        disabled={fieldsDisabled}
      >
        Switch back to Quick Quote
      </Button>
    </Box>
  );
}

function FullQuoteButton() {
  const {showRate} = useRateContext();
  const queryClient = useQueryClient();
  const {quoteId, status} = useQuoteContext();
  const {getTotalFieldErrorsCount} = useSectionErrorContext();
  const totalFieldErrors = getTotalFieldErrorsCount();

  const {mutateAsync: mutateSetFullQuote, isLoading: loadingFullQuote} =
    useMutation(() => setFullQuote(quoteId), {
      onSuccess: () =>
        queryClient.invalidateQueries([getQuote.name, quoteId.toString()]),
    });

  if (status !== QuoteStatuses.QUICK_QUOTE) {
    return null;
  }

  return (
    <Box width="100%" display="flex" justifyContent="center" mt={24}>
      <Button
        background={showRate}
        size="large"
        disabled={totalFieldErrors > 0}
        isLoading={loadingFullQuote}
        onClick={() => mutateSetFullQuote()}
      >
        Continue with full Submission
      </Button>
    </Box>
  );
}

function SubmitButton() {
  const queryClient = useQueryClient();
  const {
    quoteId,
    status,
    underwriterIsQuoting,
    setDirty,
    hasImmediateDeclination,
  } = useQuoteContext();
  const {canNotRateAndSubmitWhenBackendQuoting} = useRateContext();
  const permissions = usePermissions();
  const {updateSearchParams, setHash} = useNavigation();
  const showAPIErrors = useShowAPIErrors();
  const {getTotalFieldErrorsCount} = useSectionErrorContext();
  const {setFieldsDisabled} = useFieldsStatusContext();
  const totalFieldErrors = getTotalFieldErrorsCount();

  const {mutateAsync: mutateSubmitQuote, isLoading: loadingSubmitQuote} =
    useMutation(() => submitQuote(quoteId), {
      onSuccess: async (data) => {
        setDirty(false);
        await queryClient.invalidateQueries([
          getQuote.name,
          quoteId.toString(),
        ]);

        if (!inQuoteStatus(data.status)) {
          setHash("");
          updateSearchParams({
            tab: inReviewStatus(data.status)
              ? RateTabs.UNDERWRITING
              : RateTabs.SUMMARY,
          });
        }
        if (data.has_errors) {
          setFieldsDisabled(false);
          showAPIErrors(data.field_rule_results);
        }
      },
    });

  if (
    hasImmediateDeclination ||
    status === QuoteStatuses.QUICK_QUOTE ||
    !permissions.includes(Permissions.SUBMIT_QUOTE) ||
    !allowToRateAndSubmit(status, permissions, underwriterIsQuoting)
  ) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bolder",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        Any person who includes any false or misleading information on an
        application for an insurance policy is subject to criminal and civil
        penalties
      </div>
      <Box width="100%" display="flex" justifyContent="center" mt={24}>
        <Button
          background
          size="large"
          disabled={
            totalFieldErrors > 0 || canNotRateAndSubmitWhenBackendQuoting
          }
          isLoading={loadingSubmitQuote}
          onClick={() => {
            setFieldsDisabled(true);
            mutateSubmitQuote();
          }}
        >
          Submit
        </Button>
      </Box>
    </div>
  );
}

export function DirectApproveButton() {
  const {showRate} = useRateContext();
  const queryClient = useQueryClient();
  const {quoteId, status} = useQuoteContext();
  const permissions = usePermissions();
  const {updateSearchParams} = useNavigation();

  const {mutateAsync: mutateSubmitQuoteReview, isLoading: submittingReview} =
    useMutation((flag: boolean) => submitQuoteReview(quoteId, flag), {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          getQuote.name,
          quoteId.toString(),
        ]);

        if (data.status === QuoteStatuses.APPROVED) {
          updateSearchParams({tab: RateTabs.SUMMARY});
        }
      },
    });

  if (
    !showRate ||
    inApprovedStatus(status) ||
    !permissions.includes(Permissions.UNDERWRITE_QUOTE)
  ) {
    return null;
  }

  return (
    <Box width="100%" display="flex" justifyContent="center" mt={24}>
      <Button
        background
        size="large"
        isLoading={submittingReview}
        onClick={() => mutateSubmitQuoteReview(true)}
      >
        Approve
      </Button>
    </Box>
  );
}

const Wrapper = styled.div`
  background: white;
  display: flex;
  width: 100%;
  height: calc(100vh - 125px);
`;

const BodyWrapper = styled.div`
  ${({theme}) => css`
    flex-grow: 1;
    background: white;
    overflow: auto;
    @media (min-width: ${theme.breakpoint.xxs}px) {
      padding: 10px 20px;
    }
    @media (min-width: ${theme.breakpoint.xs}px) {
      padding: 10px 40px;
    }
    @media (min-width: ${theme.breakpoint.sm}px) {
      padding: 20px 60px;
    }
    @media (min-width: ${theme.breakpoint.md}px) {
      padding: 30px 134px;
    }
  `}
`;
