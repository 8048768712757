import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Popover} from "@headlessui/react";
import {DocumentTextIcon, PlusIcon} from "@heroicons/react/outline";
import {XIcon} from "@heroicons/react/solid";
import {UploadAttachments} from "api/message";
import IconButton from "components/IconButton";
import {ReactComponent as FileIcon} from "assets/images/doc-icon.svg";
import {ChangeEvent, MutableRefObject} from "react";
import {base64toBlob} from "pages/Messaging/utils";

const AttachmentsContainer = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  min-width: 400px;
  background: ${(props) => props.theme.color.grayscale.white};
  bottom: 70px;
  padding: 20px;
  box-shadow: 0 0 6px 1px ${(props) => props.theme.color.grayscale[500]};
  border-radius: 5px;
`;

const AttachmentsList = styled.div`
  background: ${(props) => props.theme.color.grayscale.white};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  justify-content: space-between;
  margin: 0 0px 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
`;
const AttachmentName = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.brand.primary};
  font-weight: bold;
  padding-left: 10px;
`;

const Badge = styled.span`
  border: 0;
  position: absolute;
  width: 15px;
  height: 15px;
  background: ${(props) => props.theme.color.brand.primary};
  border-radius: 50px;
  font-size: 9px;
  text-align: center;
  color: ${(props) => props.theme.color.grayscale.white};
  cursor: pointer;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  right: -6px;
  bottom: 0;
`;

const DocIcon = styled(FileIcon)`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
  color: ${(props) => props.theme.color.brand.primary};
`;

const FilePicker = styled.input`
  display: none;
`;

const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.brand.primary};
  font-size: 14px;
  cursor: pointer;
`;

const PopoverButton = styled(Popover.Button)`
  background: transparent;
  padding: 0;
  border: 0;
` as typeof Popover.Button;

const IconContainer = styled.div`
  width: 20px;
  margin-right: 5px;
`;

export default function DocumentsPopover({
  attachments,
  handleRemoveAttachmentClick,
  inputFile,
  onChangeFile,
  onAddItems,
}: {
  attachments: UploadAttachments[];
  handleRemoveAttachmentClick: (index: number) => void;
  inputFile: MutableRefObject<null | HTMLInputElement>;
  onChangeFile: (event: ChangeEvent<HTMLInputElement>) => void;
  onAddItems: () => void;
}) {
  function handleAttachmentClick(singleAttachment: any) {
    const content_type = singleAttachment?.content_type || "";
    var blob = base64toBlob(singleAttachment.file, content_type);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
  return (
    <Popover>
      <PopoverButton>
        <DocIcon />
        <Badge>{attachments.length}</Badge>
      </PopoverButton>
      <Popover.Panel>
        <AttachmentsContainer>
          {attachments.map((singleAttachment, index) => {
            return (
              <AttachmentsList key={index}>
                <NameContainer
                  onClick={() => handleAttachmentClick(singleAttachment)}
                >
                  <IconButton
                    css={(theme) => css`
                      cursor: pointer;
                      outline: 0;
                      width: 24px !important;
                      height: 24px !important;
                      border-radius: 0;
                      border: 0;
                      color: ${theme.color.brand.primary};
                      background: transparent;
                      padding: 0px !important;
                      &:hover {
                        background: transparent !important;
                      }
                    `}
                  >
                    <DocumentTextIcon />
                  </IconButton>
                  <AttachmentName>{singleAttachment.filename}</AttachmentName>
                </NameContainer>
                <IconButton
                  onClick={() => handleRemoveAttachmentClick(index)}
                  css={(theme) => css`
                    cursor: pointer;
                    outline: 0;
                    width: 24px !important;
                    padding: 0px !important;
                    height: 24px !important;
                    border-radius: 0;
                    color: ${theme.color.brand.primary};
                    border: 0;
                    background: transparent;
                    &:hover {
                      background: transparent !important;
                    }
                  `}
                >
                  <XIcon />
                </IconButton>
              </AttachmentsList>
            );
          })}
          <AddButtonContainer onClick={() => onAddItems()}>
            <IconButton
              css={(theme) => css`
                cursor: pointer;
                outline: 0;
                padding: 0px !important;
                border-radius: 0;
                color: ${theme.color.brand.primary};
                border: 0;
                background: transparent;
                margin-right: 5px;
                flex-direction: initial;
                width: 100% !important;
                justify-content: flex-start;
                &:hover {
                  background: transparent !important;
                }
              `}
            >
              <IconContainer>
                <PlusIcon />
              </IconContainer>
              Add New Document
            </IconButton>
          </AddButtonContainer>
          <FilePicker
            type="file"
            id="file"
            ref={inputFile}
            multiple
            onChange={onChangeFile}
          />
        </AttachmentsContainer>
      </Popover.Panel>
    </Popover>
  );
}
