/** @jsxImportSource @emotion/react */

import {getUserByIdName} from "api/auth";
import {useQuery} from "react-query";
import UserIcon from "assets/images/user.svg";
import Avatar from "./Avatar";
import {css} from "@emotion/react";
import Spinner from "./Spinner";

export default function UserName({userId}: {userId: number}) {
  const {data: userName, isLoading} = useQuery(
    ["auth", "user", userId, "name"],
    () => getUserByIdName(userId)
  );
  if (userName) {
    return (
      <span
        css={css`
          display: flex;
          align-items: center;
          gap: 6px;
        `}
      >
        <Avatar src={UserIcon} />
        <span>{`${userName.first_name} ${userName.last_name}`}</span>
      </span>
    );
  }
  if (isLoading) return <Spinner />;
  return null;
}