import {PolicyInfo} from "api/policy";
import {Box} from "components/Box";
import {formatAmount} from "utils/format";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";

function Fees({policy_info}: {policy_info?: PolicyInfo}) {
  if (!policy_info || !policy_info.total_annual_surcharge) return null;

  let feeTitle = "Fee";

  if (policy_info.insured_info.address_info.state === "NJ") feeTitle = "Pliga";
  else if (policy_info.insured_info.address_info.state === "NY") {
    feeTitle = "New York Fire Fee";
  }

  return (
    <Box mb={100}>
      <FormTitle.Header>Fees</FormTitle.Header>
      <InfoRow
        label={feeTitle}
        object={formatAmount(policy_info.total_annual_surcharge)}
      />
    </Box>
  );
}

export default Fees;
