/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {formatAmount} from "utils/format";
import {useNavigation} from "hooks/navigation";
import {useAuth} from "hooks/auth";
import AlertBar from "components/AlertBar";
import DocumentTextIcon from "@heroicons/react/outline/DocumentTextIcon";
import CurrencyDollarIcon from "@heroicons/react/outline/CurrencyDollarIcon";
import links from "links";
import {PolicyModIds} from "api/policy";

export default function PaymentConfirmation({
  policyModIds,
  amount,
  paymentMethodDescription,
  succeeded,
  message,
  transactionId,
}: PaymentConfirmationPageParams) {
  const {isLoggedIn} = useAuth();
  const {navigate} = useNavigation();

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          width: 100%;
          max-width: 500px;
          margin-top: 30px;
        `}
      >
        <AlertBar status={succeeded ? "success" : "error"}>
          {succeeded
            ? `Paid ${formatAmount(
                amount
              )} with ${paymentMethodDescription}. Confirmation number is ${transactionId}`
            : `${message}`}
        </AlertBar>
        <div
          css={css`
            display: flex;
            gap: 5px;
          `}
        >
          <Button
            icon={CurrencyDollarIcon}
            fullwidth
            onClick={() => navigate(links.payment({policyModIds}))}
          >
            {succeeded ? "Make Another Payment" : "Payment"}
          </Button>
          {isLoggedIn && (
            <Button
              icon={DocumentTextIcon}
              fullwidth
              onClick={() =>
                navigate(links.policy({policyModIds, tab: "details"}))
              }
            >
              View Policy
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export interface PaymentConfirmationPageParams {
  policyModIds: PolicyModIds;
  amount: number;
  paymentMethodDescription: string;
  succeeded: boolean;
  message: string;
  transactionId: string | null;
}
