/** @jsxImportSource @emotion/react */

import {ReactNode} from "react";
import {css} from "@emotion/react";
import Menu, {MenuPage} from "./Menu";
import Topbar from "./Topbar";

function AppShell({
  title,
  pageHeader,
  backLink,
  hideMenu = false,
  menuList,
  children,
}: {
  title: string;
  pageHeader: ReactNode;
  backLink?: string | null;
  hideMenu?: boolean | null;
  menuList: MenuPage[];
  children: ReactNode;
}) {
  const space = 32;
  const computedSpacing = space * 2 + 32;
  const menuWidth = 200;

  return (
    <>
      {!hideMenu && (
        <div
          css={(theme) => css`
            position: fixed;
            width: ${menuWidth}px;
            top: 0;
            left: 0;
            background-color: ${theme.color.grayscale.white};
            height: 100%;
            padding-top: ${space}px;
            padding-left: ${space}px;
            z-index: 1000;
          `}
        >
          <Menu padding={space} menuList={menuList} />
        </div>
      )}
      <div
        css={css`
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          padding: ${space}px;
          ${!hideMenu &&
          css`
            padding-left: ${menuWidth}px;
          `}
        `}
      >
        <Topbar title={title} backLink={backLink}>
          {pageHeader}
        </Topbar>
      </div>
      <div
        css={(theme) => css`
          min-height: 100%;
          background-color: ${theme.color.grayscale.white};
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={(theme) => css`
            flex: 1;
            margin: ${space}px;
            margin-top: ${computedSpacing}px;
            ${!hideMenu &&
            css`
              margin-left: ${menuWidth}px;
            `}
            background-color: ${theme.color.brand.tertiary};
            border-radius: 8px;
            padding: 32px;
          `}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default AppShell;
