import {Habitationial} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import Field from "../../Field";

export const aggOccurLimits = [
  {label: "$1,000,000 / $2,000,000", value: "2000000"},
  {label: "$500,000 / $1,000,000", value: "1000000"},
  {label: "$300,000 / $600,000", value: "600000"},
];

export const mixedMercantileClassCodes = [
  {label: "Cannabis Distributors - Other Than Hemp (10011)", value: "10011"},
  {
    label: "Hemp Distributors (10012)",
    value: "10012",
  },
  {label: "Amusement Centers (10015)", value: "10015"},
  {
    label: "Amusement Parks (10020)",
    value: "10020",
  },
  {
    label:
      "Cannabis-Containing Products Distributors - Other Than Hemp (10025)",
    value: "10025",
  },
  {
    label: "Antique Stores (10026)",
    value: "10026",
  },
  {label: "Hemp-Containing Products Distributors (10027)", value: "10027"},
  {
    label: "Anhydrous Ammonia Dealers and Distributors  (10036)",
    value: "10036",
  },
  {label: "Appliance Distributors - household type (10040)", value: "10040"},
  {
    label: "Appliance Stores - household type (10042)",
    value: "10042",
  },
  {
    label: "  Archery Ranges - indoor (10052)",
    value: "10052",
  },
  {
    label: "Archery Ranges - Not Otherwise Classified (10054)",
    value: "10054",
  },
  {label: "Army and Navy Stores (10060)", value: "10060"},
  {label: "Art Galleries (For-Profit) (10065)", value: "10065"},
];

// -------

// 0321
// Apartments with Mercantile Occupancies - Up to 10 Units
// Apartments - units occupied by long term tenants for habitational purposes with self-contained living facilities (including cooking facilities) occupied by one or more persons. For 1 to 4 family dwellings written in conjunction with a commercial risk or written under a commercial package policy, refer to the Dwellings classifications in this Classification Table. Also refer to "Boarding and Lodging Houses", "Condominiums", "Convents or Monasteries", etc.
// 0322
// Apartments with Mercantile Occupancies - 11 to 30 Units
// Apartments - units occupied by long term tenants for habitational purposes with self-contained living facilities (including cooking facilities) occupied by one or more persons. Also refer to "Boarding and Lodging Houses", "Condominiums", "Convents or Monasteries", etc.
// 0323
// Apartments with Mercantile Occupancies - Over 30 Units
// Apartments - units occupied by long term tenants for habitational purposes with self-contained living facilities (including cooking facilities) occupied by one or more persons. Also refer to "Boarding and Lodging Houses", "Condominiums", "Convents or Monasteries", etc.
// 0341
// Condominiums - residential (association risk only) - with mercantile occupancies - Up to 10 units
// 0342
// Condominiums - residential (association risk only) - with mercantile occupancies - 11 to 30 units
// 0343
// Condominiums - residential (association risk only) - with mercantile occupancies - Over 30 units]

function HabitationalGeneralLiability({
  habitational,
}: {
  habitational: Habitationial;
}) {
  return (
    <Box mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.DropDown
          options={aggOccurLimits.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          object={habitational}
          field="agg_occur_limit"
          label="Occurence Limit / Aggregate Limit"
          wrapperProps={{
            grow: 12,
          }}
        />
      </GridContainer>
    </Box>
  );
}

export default HabitationalGeneralLiability;
