/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {Disclosure} from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/solid/ChevronUpIcon";
import {createContext, Fragment, useContext} from "react";
import Center from "./Center";

const styledContext = createContext(true);

type DisclosureProps = Parameters<typeof Disclosure>[0];

export function Accordian({
  children,
  as,
  styled = true,
  ...props
}: DisclosureProps & {styled?: boolean}) {
  return (
    <styledContext.Provider value={styled}>
      <Disclosure
        {...props}
        as="div"
        css={
          styled &&
          css`
            padding: 15px;
            border: solid #efefef 1px;
            border-radius: 4px;
            margin-bottom: 10px;
          `
        }
      >
        {children}
      </Disclosure>
    </styledContext.Provider>
  );
}

type DisclosureButtonProps = Parameters<typeof Disclosure.Button>[0];
type DisclosurePanelProps = Parameters<typeof Disclosure.Panel>[0];

export function AccordianButton({
  children,
  height,
  ...props
}: DisclosureButtonProps & {height?: number}) {
  const styled = useContext(styledContext);
  return (
    <Disclosure.Button as={Fragment} {...props}>
      {({open}) => (
        <div
          css={css`
            width: 100%;
            border: none;
            cursor: pointer;
            position: relative;
            ${styled
              ? css`
                  padding: 10px;
                  border-radius: 4px;
                  background-color: #f7f7f7;

                  :hover {
                    background-color: #efefef;
                  }
                `
              : css`
                  display: flex;
                  ${height &&
                  css`
                    min-height: 50px;
                  `}
                `}
          `}
        >
          {typeof children === "function" ? children({open}) : children}
          <span
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              padding-right: 15px;
            `}
          >
            <Center>
              {open ? (
                <ChevronUpIcon
                  css={css`
                    width: 24px;
                    height: 24px;
                  `}
                />
              ) : (
                <ChevronDownIcon
                  css={css`
                    width: 24px;
                    height: 24px;
                  `}
                />
              )}
            </Center>
          </span>
        </div>
      )}
    </Disclosure.Button>
  );
}
export function AccordianPanel({children, ...props}: DisclosurePanelProps) {
  const styled = useContext(styledContext);
  return (
    <Disclosure.Panel
      {...props}
      as="div"
      css={
        styled &&
        css`
          padding-top: 12px;
        `
      }
    >
      {children}
    </Disclosure.Panel>
  );
}
