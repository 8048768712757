import {apiUrl, del, get, post, put} from "./common";

const baseUrl = `${apiUrl}/internal_notes`;
const QUOTE = "quote";

export async function getQuoteInternalNotes(quoteId: number) {
  return get<QuoteInternalNote[]>(`${baseUrl}/${QUOTE}/${quoteId}`);
}

export async function postQuoteInternalNote(data: UploadQuoteInternalNote) {
  return post<{quote_internal_note_id: number}>(
    `${baseUrl}/${QUOTE}/${data.quote_id}`,
    {
      data,
    }
  );
}

export async function deleteQuoteInternalNote(noteId: number) {
  return await del<string>(`${baseUrl}/${noteId}`);
}

export async function editQuoteInternalNote(data: EditQuoteInternalNote) {
  return await put<{quote_internal_note_id: number}>(
    `${baseUrl}/${data.note_id}`,
    {
      data,
    }
  );
}

export interface QuoteInternalNote {
  content: string;
  created_at: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
  };
  id: number;
}

export type UploadQuoteInternalNote = {
  content: string;
  quote_id: number;
};

export type EditQuoteInternalNote = {
  note_id: number;
  content: string;
};
