import styled from "@emotion/styled";
import {
  deleteAttachment,
  UploadAttachments,
  uploadAttachments,
  uploadFileToS3,
  UploadMessage,
} from "api/message";
import {ReactComponent as FileIcon} from "assets/images/doc-icon.svg";
import {ReactComponent as AeroIcon} from "assets/images/send-icon.svg";
import DocumentsPopover from "components/DocumentsPopover";
import {useAuth} from "hooks/auth";
import {
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {getBase64, getStringWithoutHTMLTags} from "./utils";

const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  position: sticky;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;
const SendIcon = styled(AeroIcon)`
  width: 18px;
  cursor: pointer;
  margin-right: 20px;
  color: ${(props) => props.theme.color.brand.primary};
`;
const DocIcon = styled(FileIcon)`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
  color: ${(props) => props.theme.color.brand.primary};
`;
const MessageBox = styled.div`
  ${(props) => props.theme.text.p2}
  width: 100%;
  outline: none;
  background: ${(props) => props.theme.color.brand.tertiary};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  color: ${(props) => props.theme.color.grayscale[800]};
  padding: 12px 20px;
  word-break: break-word;
  :empty:before {
    content: attr(placeholder);
    color: ${(props) => props.theme.color.grayscale[600]};
    cursor: text;
  }
`;
const MainContainer = styled.div``;
const DocumentIconContainer = styled.div`
  position: relative;
`;
const FilePicker = styled.input`
  display: none;
`;

function MessageInput({
  onClickSend,
  threadId,
}: {
  onClickSend: (data: UploadMessage) => void;
  threadId: number;
}) {
  const inputFile: MutableRefObject<null | HTMLInputElement> = useRef(null);
  const [attachments, setAttachments] = useState<UploadAttachments[]>([]);
  const {authSession} = useAuth();

  useEffect(() => {
    getDraftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  function saveDraftMessage(msg: any) {
    const oldData = JSON.parse(localStorage.getItem("draft") || "{}");
    let obj: any = {};
    if (oldData?.thread_id === threadId) {
      obj = {
        ...oldData,
        msg: msg,
        thread_id: threadId,
        user_id: authSession?.user_id,
      };
    } else {
      obj = {
        msg: msg,
        thread_id: threadId,
        user_id: authSession?.user_id,
      };
    }
    localStorage.setItem(`draft`, JSON.stringify(obj));
  }

  function onInputText(e: KeyboardEvent<HTMLDivElement>) {
    const target = e.target as Element;
    if (e.key === "Enter" && !e.shiftKey) {
      const str = getStringWithoutHTMLTags(target.innerHTML);
      if (!str || !str.trim()) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      handleSendClick(target.innerHTML.trim());
    }
    setTimeout(() => {
      saveDraftMessage(target.innerHTML.trim());
    }, 0);
  }

  useEffect(() => {
    const obj: any = {
      ...JSON.parse(localStorage.getItem("draft") || "{}"),
      attachments: attachments,
    };
    localStorage.setItem(`draft`, JSON.stringify(obj));
  }, [attachments]);

  function getDraftData() {
    const draftMessage: any = JSON.parse(localStorage.getItem("draft") || "{}");
    const element = document.getElementById("messageBox");
    if (
      draftMessage?.user_id === authSession?.user_id &&
      draftMessage?.thread_id === threadId
    ) {
      if (element) {
        element.innerHTML = draftMessage.msg || "";
      }
      if (draftMessage?.attachments) {
        setAttachments(draftMessage?.attachments);
      } else {
        setAttachments([]);
      }
    } else if (element) {
      element.innerHTML = "";
      setAttachments([]);
    }
  }

  function clearInputMessage() {
    const element = document.getElementById("messageBox");
    if (element) {
      element.innerHTML = "";
    }
    setAttachments([]);
    localStorage.removeItem("draft");
  }

  function handleSendClick(text: string | undefined) {
    if (!text) {
      return;
    }
    let data: UploadMessage = {
      content: text,
      attachments: attachments.map((file: UploadAttachments) => {
        return {file_path: file.key, file_name: file.filename};
      }),
      thread_id: threadId,
    };
    onClickSend(data);
    clearInputMessage();
  }

  function handleSendIconClick() {
    const text = document.getElementById("messageBox");
    if (!text) return;
    const str = getStringWithoutHTMLTags(text.innerHTML);
    if (!str || !str.trim()) {
      return;
    }
    
    handleSendClick(text?.innerHTML);
  }
  function onButtonClick() {
    inputFile.current?.click();
  }

  function getFile(fileList: any, name: string) {
    let filteredFile = Object.keys(fileList).filter(
      (singleFile: any) => fileList[singleFile].name === name
    );
    return fileList[filteredFile[0]];
  }

  async function onChangeFile(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    var fileList: FileList = target.files as FileList;
    var prevFiles = [...attachments];
    var base64: any = {};
    let contentType: any = {};
    const files: string[] = [];
    Object.keys(fileList).map((index: any) => files.push(fileList[index].name));
    for (let index in Object.keys(fileList)) {
      contentType[fileList[index].name] = fileList[index].type;
      base64[fileList[index].name] = (await getBase64(
        fileList[index]
      )) as string;
    }
    const data: any = await uploadAttachments({files});
    if (data.length) {
      data?.forEach(async (el: UploadAttachments) => {
        el["file"] = base64?.[el.filename].split(",")[1];
        el["content_type"] = contentType[el.filename];
        try {
          if (base64?.[el.filename]) {
            await uploadFileToS3(el.url, getFile(fileList, el.filename));
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
    setAttachments([...prevFiles, ...data]);
    event.target.value = "";
  }

  async function handleRemoveAttachmentClick(index: number) {
    let files = [...attachments];
    let removedItem: UploadAttachments[] = files.splice(index, 1);
    setAttachments(files);
    await deleteAttachment(removedItem[0]?.key);
  }
  
  const inputBoxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => inputBoxRef.current?.focus());

  return (
    <MainContainer>
      <MessageInputContainer>
        <SendIcon onClick={handleSendIconClick} />
        <MessageBox
          id="messageBox"
          contentEditable="true"
          placeholder="Type your message here..."
          onKeyDown={onInputText}
          ref={inputBoxRef}
        />
        <DocumentIconContainer>
          {attachments.length ? (
            <DocumentsPopover
              attachments={attachments}
              handleRemoveAttachmentClick={handleRemoveAttachmentClick}
              inputFile={inputFile}
              onChangeFile={onChangeFile}
              onAddItems={onButtonClick}
            />
          ) : (
            <>
              <DocIcon onClick={onButtonClick} />
              <FilePicker
                type="file"
                id="file"
                ref={inputFile}
                multiple
                onChange={onChangeFile}
              />
            </>
          )}
        </DocumentIconContainer>
      </MessageInputContainer>
    </MainContainer>
  );
}

export default MessageInput;
