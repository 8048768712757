import {Bop} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import Field from "../../Field";

function BopCoverageForm({
  bop,
}: {
  bop: Bop;
}) {
  return (
    <Box mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.CheckBox
          label="Terrorism Coverage"
          object={bop}
          field={"terrorism_coverage_flag"}
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.CheckBox
          label="Property Enhancement"
          object={bop}
          field={"property_enhancement"}
          wrapperProps={{
            grow: 12,
          }}
        />
      </GridContainer>
    </Box>
  );
}

export default BopCoverageForm;
