import {SavedPaymentMethodBasicInfo} from "api/payment";
import {
  SelectionGroupContainer,
  SelectionGroupLabel,
  SelectionGroupOption,
  SelectionGroupOptionTitle,
} from "components/SelectionGroup";

export enum PaymentMethodType {
  NEW_CARD,
  NEW_BANK,
  SAVED_METHOD,
}

export interface PaymentMethod {
  type: PaymentMethodType;
  title: string;
  id: string;
  saved_method_data?: SavedPaymentMethodBasicInfo | null;
}

export default function PaymentMethodSelector({
  methods,
  selectedMethod,
  onChange,
  creditCardFee,
  disabled,
}: {
  methods: PaymentMethod[];
  selectedMethod: string | null;
  onChange: (value: string) => void;
  creditCardFee: string | number;
  disabled: boolean;
}) {
  return (
    <SelectionGroupContainer
      disabled={disabled}
      value={selectedMethod}
      onChange={onChange}
    >
      <SelectionGroupLabel>Choose Method:</SelectionGroupLabel>
      {methods.map((method) => (
        <SelectionGroupOption value={method.id}>
          <SelectionGroupOptionTitle
            title={method.title}
            secondary={method.saved_method_data?.name_holder}
            message={
              method.type === PaymentMethodType.NEW_CARD
                ? `Please note: a processing fee will be added to the final
                  charge by the processing company and will be displayed prior
                  to payment approval.`
                : ""
            }
          />
        </SelectionGroupOption>
      ))}
    </SelectionGroupContainer>
  );
}

export function getSavedMethodsWithNewMethodsList(
  savedMethods: SavedPaymentMethodBasicInfo[]
): PaymentMethod[] {
  const newMethodsPrefix = savedMethods.length > 0 ? "New" : "";
  const methods: PaymentMethod[] = [
    {
      title: `${newMethodsPrefix} Credit Card`,
      id: "NEW_CARD",
      type: PaymentMethodType.NEW_CARD,
    },
    {
      title: `${newMethodsPrefix} Bank Account`,
      id: "NEW_BANK",
      type: PaymentMethodType.NEW_BANK,
    },
  ];
  if (savedMethods) {
    methods.unshift(
      ...savedMethods
        .reverse()
        .map((savedPaymentMethod: SavedPaymentMethodBasicInfo) => {
          return {
            title: `${savedPaymentMethod.description}`,
            id: savedPaymentMethod.payment_method_id,
            type: PaymentMethodType.SAVED_METHOD,
            saved_method_data: {
              ...savedPaymentMethod,
            },
          };
        })
    );
  }
  return methods;
}
