import {Box} from "components/Box";
import {TextInput} from "components/TextInput";
import {useForm, Controller, Control} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useMemo} from "react";
import {useTheme} from "@emotion/react";
import {
  bindQuote,
  getQuote,
  PaymentPlan,
  QuoteBindingForm,
  QuoteForm,
  QuoteStatuses,
} from "api/quote";
import {useMutation, useQueryClient} from "react-query";
import Button from "components/Button";
import {GridContainer, GridItem} from "components/Grid";
import {DropDown} from "components/DropDown";
import styled from "@emotion/styled";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";
import {Permissions} from "api/auth";
import {usePermissions} from "hooks/auth";
import states from "assets/data/state.json";
import {useNavigation} from "hooks/navigation";
import {RateTabs} from "../components/RateQuoteBindTopBar";

const sortedStates = states.sort((a, b) => a.name.localeCompare(b.name));

const FieldMessage = styled.span`
  margin-top: 5px;
  display: block;
  color: ${(props) => props.theme.color.state.red};
`;

const PaymentPlanOptions = [
  {
    title: "Full",
    value: PaymentPlan.FULL,
  },
  {
    title: "Quarterly",
    value: PaymentPlan.QUARTERLY,
  },
  {
    title: "Monthly (8 Installments)",
    value: PaymentPlan.MONTHLY,
  },
  {
    title: "Mortgagee",
    value: PaymentPlan.MORTGAGE,
  },
];

function BindingSection({
  quoteForm,
  bindingForm,
  status,
}: {
  quoteForm: QuoteForm;
  bindingForm?: QuoteBindingForm;
  status: QuoteStatuses;
}) {
  const permissions = usePermissions();
  return (
    <>
      {status === QuoteStatuses.BOUND ? (
        <BindingView bindingForm={bindingForm} />
      ) : permissions.includes(Permissions.BIND_QUOTE) ? (
        <BindingForm
          quoteForm={quoteForm}
          status={status}
          bindingForm={bindingForm}
        />
      ) : (
        <></>
      )}
    </>
  );
}

function BindingForm({
  quoteForm,
  bindingForm,
  status,
}: {
  quoteForm: QuoteForm;
  bindingForm?: QuoteBindingForm;
  status: QuoteStatuses;
}) {
  const {updateSearchParams} = useNavigation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const defaults: QuoteBindingForm = {
    quote_id: quoteForm.quote_id,
    inspection_contact_name: bindingForm?.inspection_contact_name ?? "",
    inspection_contact_company: bindingForm?.inspection_contact_company ?? "",
    inspection_contact_phone: bindingForm?.inspection_contact_phone ?? "",
    payment_plan: bindingForm?.payment_plan ?? ("" as PaymentPlan),
    mortgagee_company: bindingForm?.mortgagee_company ?? "",
    mortgagee_phone: bindingForm?.mortgagee_phone ?? "",
    mortgagee_line_1: bindingForm?.mortgagee_line_1 ?? "",
    mortgagee_line_2: bindingForm?.mortgagee_line_2 ?? "",
    mortgagee_city: bindingForm?.mortgagee_city ?? "",
    mortgagee_state: bindingForm?.mortgagee_state ?? "",
    mortgagee_zip: bindingForm?.mortgagee_zip ?? "",
    mortgagee_country: bindingForm?.mortgagee_country ?? "",
  };
  const mortgageeFieldsSchema = yup.string().when("payment_plan", {
    is: PaymentPlan.MORTGAGE,
    then: yup.string().required(""),
  });
  const bindingFormSchema = yup.object().shape({
    quote_id: yup.number(),
    inspection_contact_name: yup.string().required(""),
    inspection_contact_company: yup.string().required(""),
    inspection_contact_phone: yup.string().required(""),
    payment_plan: yup.string().required(""),
    mortgagee_company: mortgageeFieldsSchema,
    mortgagee_phone: mortgageeFieldsSchema,
    mortgagee_line_1: mortgageeFieldsSchema,
    mortgagee_line_2: yup.string(),
    mortgagee_city: mortgageeFieldsSchema,
    mortgagee_state: mortgageeFieldsSchema,
    mortgagee_zip: mortgageeFieldsSchema,
    mortgagee_country: yup.string(),
  });
  const {handleSubmit, formState, control, watch} = useForm<QuoteBindingForm>({
    mode: "all",
    defaultValues: defaults,
    resolver: yupResolver(bindingFormSchema),
  });

  const paymentPlan = watch("payment_plan");

  const {mutate: mutateBindAsync, isLoading: isBinding} = useMutation(
    bindQuote,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          "quote/policy",
          quoteForm.quote_id.toString(),
        ]);
        queryClient.invalidateQueries([
          getQuote.name,
          quoteForm.quote_id.toString(),
        ]);
        queryClient.setQueryData(
          ["rate", quoteForm.quote_id.toString()],
          (oldData: any) => ({
            ...oldData,
            has_errors: data.has_errors,
            rate: data.rate,
          })
        );

        if (data.status === QuoteStatuses.FULL_QUOTE) {
          updateSearchParams({tab: RateTabs.RATE});
        }
      },
    }
  );

  const lockForm = useMemo(
    () =>
      isBinding ||
      status === QuoteStatuses.BINDING_IN_PROGRESS ||
      status === QuoteStatuses.BINDING_ERROR,
    [isBinding, status]
  );

  return (
    <Box
      background={theme.color.brand.tertiary}
      borderRadius={8}
      py={34}
      px={32}
      mb={100}
    >
      <FormTitle.Header>Binding</FormTitle.Header>
      <form onSubmit={handleSubmit((formData) => mutateBindAsync(formData))}>
        <FormTitle.SubHeader>Inspection Contact</FormTitle.SubHeader>

        <GridContainer rowGap={24} columnGap={16}>
          <GridItem grow={12}>
            <FormInput
              control={control}
              name="inspection_contact_name"
              label="Name"
              disabled={lockForm}
            />
          </GridItem>
          <GridItem grow={6}>
            <FormInput
              control={control}
              name="inspection_contact_company"
              label="Company name"
              disabled={lockForm}
            />
          </GridItem>
          <GridItem grow={6}>
            <FormInput
              control={control}
              name="inspection_contact_phone"
              label="Phone number"
              disabled={lockForm}
            />
          </GridItem>
        </GridContainer>
        <FormTitle.SubHeader>Payment Plan</FormTitle.SubHeader>

        <GridContainer rowGap={24} columnGap={16}>
          <GridItem grow={6}>
            <Controller
              control={control}
              name="payment_plan"
              render={({field, fieldState: {error}}) => (
                <>
                  <DropDown
                    {...field}
                    label="Payment Plan"
                    options={PaymentPlanOptions}
                    error={Boolean(error)}
                    disabled={lockForm}
                    background
                  />
                  <FieldMessage>{error?.message}</FieldMessage>
                </>
              )}
            />
          </GridItem>
        </GridContainer>

        {paymentPlan === PaymentPlan.MORTGAGE && (
          <>
            <FormTitle.SubHeader>Mortgagee Info</FormTitle.SubHeader>

            <GridContainer rowGap={24} columnGap={16}>
              <GridItem grow={6}>
                <FormInput
                  control={control}
                  name="mortgagee_company"
                  label="Company Name"
                  disabled={lockForm}
                  required
                />
              </GridItem>
              <GridItem grow={6}>
                <FormInput
                  control={control}
                  name="mortgagee_phone"
                  label="Phone"
                  disabled={lockForm}
                  required
                />
              </GridItem>
              <GridItem grow={12}>
                <FormInput
                  control={control}
                  name="mortgagee_line_1"
                  label="Address Line 1"
                  disabled={lockForm}
                  required
                />
              </GridItem>
              <GridItem grow={12}>
                <FormInput
                  control={control}
                  name="mortgagee_line_2"
                  label="Address Line 2"
                  disabled={lockForm}
                />
              </GridItem>
              <GridItem grow={4}>
                <FormInput
                  control={control}
                  name="mortgagee_city"
                  label="City"
                  disabled={lockForm}
                  required
                />
              </GridItem>
              <GridItem grow={5}>
                <Controller
                  control={control}
                  name="mortgagee_state"
                  render={({field, fieldState: {error}}) => (
                    <>
                      <DropDown
                        {...field}
                        options={sortedStates.map((state) => ({
                          title: state.name,
                          value: state.abbreviation,
                        }))}
                        label="State"
                        error={Boolean(error)}
                        disabled={lockForm}
                        required
                        background
                      />
                      <FieldMessage>{error?.message}</FieldMessage>
                    </>
                  )}
                />
              </GridItem>
              <GridItem grow={3}>
                <FormInput
                  control={control}
                  name="mortgagee_zip"
                  label="Zip"
                  disabled={lockForm}
                  required
                />
              </GridItem>
            </GridContainer>
          </>
        )}

        <Box mt={24}>
          <Button disabled={!formState.isValid} isLoading={isBinding}>
            {computeBindingButtonText(status, isBinding)}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

function computeBindingButtonText(status: QuoteStatuses, isBinding: boolean) {
  if (isBinding) return "Binding";
  else
    return [
      QuoteStatuses.BINDING_IN_PROGRESS,
      QuoteStatuses.BINDING_ERROR,
    ].includes(status)
      ? "Retry"
      : "Bind";
}

function BindingView({bindingForm}: {bindingForm?: QuoteBindingForm}) {
  return (
    <Box mb={100}>
      <FormTitle.Header>Binding</FormTitle.Header>

      <FormTitle.Header2>Inspection Contact</FormTitle.Header2>
      <InfoRow label="Name" object={bindingForm?.inspection_contact_name} />
      <InfoRow
        label="Company name"
        object={bindingForm?.inspection_contact_company}
      />
      <InfoRow
        label="Phone number"
        object={bindingForm?.inspection_contact_phone}
      />

      <FormTitle.Header2>Payment Plan</FormTitle.Header2>
      <InfoRow label="Payment Plan" object={bindingForm?.payment_plan} />
    </Box>
  );
}

function FormInput({
  name,
  label,
  control,
  disabled,
  required,
}: {
  name: keyof QuoteBindingForm;
  label: string;
  control: Control<QuoteBindingForm>;
  disabled?: boolean;
  required?: boolean;
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field, fieldState: {error}}) => (
        <>
          <TextInput
            {...field}
            label={label}
            error={Boolean(error)}
            disabled={disabled}
            required={required}
            background
          />
          <FieldMessage>{error?.message}</FieldMessage>
        </>
      )}
    />
  );
}

export default BindingSection;
