import {Location, Building} from "api/quote";
import {Box} from "components/Box";
import {GridContainer, GridItem} from "components/Grid";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import ActionButton from "../../ActionButton";
import Field from "../../Field";
import LocationsForm, {BuildingForm} from "../shared/LocationsForm";
import {classCodes} from "./bopClassCodes";

const occupancyOptions = [
  {label: "Lessor", value: "lessor"},
  {label: "Owner occupant", value: "owner_occupant"},
  {label: "Occupant", value: "occupant"},
];

const increasedFireLimit = [
  {label: "0", value: "0"},
  {label: "200000", value: "200000"},
  {label: "500000", value: "500000"},
  {label: "700000", value: "700000"},
];
const constructionTypes = [
  {label: "Fire Resistive", value: "6"},
  {label: "Frame", value: "1"},
  {label: "Joisted Masonry", value: "2"},
  {label: "Masonry Non-Combustible", value: "4"},
  {label: "Modified Fire Resistive", value: "5"},
  {label: "Non-Combustible", value: "3"},
];

const isBuildingSprinklered = [
  {label: "Fully Sprinklered", value: "Y"},
  {label: "Not Sprinklered", value: "N"},
  {label: "Partially Sprinklered", value: "P"},
];

function BopBuildingForm({
  location,
  building,
}: {
  location: Location;
  building: Building;
}) {
  // Boolean for disabling "add tenant" button if ten tenants have been added.
  let tenTenants;
  if (building.bop_building) {
    tenTenants = building.bop_building?.bop_tenants.length >= 10;
  }

  let addTenantMessage =
    building.bop_building?.occupancy === "lessor"
      ? "Add Tenant"
      : "Add Tenant (excluding owner occupancy)";
  return (
    <div>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.DropDown
          object={building.bop_building!}
          options={constructionTypes.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"construction_type"}
          label="Construction type"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.bop_building!}
          field={"square_footage"}
          label="Square footage"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.bop_building!}
          options={classCodes.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"class_code"}
          label="Select Class"
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.DropDown
          object={building.bop_building!}
          options={isBuildingSprinklered.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"is_building_sprinklered"}
          label="Is Building Sprinklered"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          prefix="$"
          object={building.bop_building!}
          field={"personal_property_limit"}
          label="Personal Property Limit"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.bop_building!}
          field={"number_of_stories"}
          label="Number Of Stories"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.bop_building!}
          options={increasedFireLimit.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"increased_fire_limit"}
          label="Increased Fire Limit"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.bop_building!}
          options={occupancyOptions.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"occupancy"}
          label="Occupancy"
          wrapperProps={{
            grow: 12,
          }}
        />
        {(building.bop_building?.occupancy === "owner_occupant" ||
          building.bop_building?.occupancy === "lessor") && (
          <Field.TextInput
            prefix="$"
            object={building.bop_building!}
            field={"building_limit"}
            label="Building limit"
            isFormattedNumber
            wrapperProps={{
              growXXS: 12,
              growMD: 6,
            }}
          />
        )}
      </GridContainer>
      {(building.bop_building?.occupancy === "owner_occupant" ||
        building.bop_building?.occupancy === "lessor") &&
        building.bop_building?.bop_tenants.map((bop_tenant, index) => (
          <Box key={index} py={16}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormTitle.SubHeader>Tenant {index + 1}</FormTitle.SubHeader>
              <ActionButton.Delete
                objectName="bop_tenant"
                objectId={bop_tenant.id}
                background
              >
                Delete Tenant {index + 1}
              </ActionButton.Delete>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="space-between"
              justifyContent="space-between"
            >
              <GridContainer rowGap={24} columnGap={16}>
                <GridItem growXXS={12} growMD={6}>
                  <Field.TextInput
                    object={building.bop_building?.bop_tenants[index]!}
                    field={"tenant_occupancy"}
                    label="Tenant Occupancy"
                  />
                </GridItem>
                <GridItem growXXS={12} growMD={6}>
                  <Field.TextInput
                    object={building.bop_building?.bop_tenants[index]!}
                    field={"square_footage_by_tenant"}
                    label="Square Footage"
                    isFormattedNumber
                  />
                </GridItem>
              </GridContainer>
            </Box>
          </Box>
        ))}
      <p>
        {(building.bop_building?.occupancy === "owner_occupant" ||
          building.bop_building?.occupancy === "lessor") && (
          <GridContainer>
            <GridItem>
              {building.bop_building && (
                <ActionButton.Add
                  objectName="bop_tenant"
                  params={{bop_building_id: building.bop_building.id}}
                  background
                  disabled={tenTenants}
                >
                  {addTenantMessage}
                </ActionButton.Add>
              )}
            </GridItem>
          </GridContainer>
        )}
        <Box mt={44}>
          <GridContainer>
            <Field.CheckBox
              object={building.bop_building!}
              field={"equipment_breakdown"}
              label="Equipment Breakdown"
              wrapperProps={{
                growXXS: 12,
                growMD: 6,
              }}
            />
          </GridContainer>
        </Box>
        {location.buildings.length > 1 ? (
          <Box mb={16}>
            <Field.RadioGroup
              object={building}
              field="building_within_100_ft_of_another"
              label="Is this building within 100 ft of another building in this location"
              options={[
                {title: "Yes", value: 1},
                {title: "No", value: 0},
              ]}
            />
          </Box>
        ) : (
          <></>
        )}
      </p>
    </div>
  );
}

export default function BopLocationsForm({
  locations,
  quoteId,
}: {
  locations: Location[];
  quoteId: any;
}) {
  return (
    <LocationsForm quoteId={quoteId} locations={locations}>
      {({locationId, location, buildings}) => (
        <>
          <BuildingForm
            locationId={location.id}
            location={location}
            buildings={location.buildings}
          >
            {({locationId, location, building}) => (
              <BopBuildingForm location={location} building={building} />
            )}
          </BuildingForm>
        </>
      )}
    </LocationsForm>
  );
}
