/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {GuidlinesProps} from ".";
import GuidelinesSection from "./GuidelinesSection";
import YesNoButtons from "./YesNoButtons";
import buildingSvg from "assets/images/building.svg";
import dollarDocumentSvg from "assets/images/dollar-document.svg";
import financeDurationSvg from "assets/images/finance-duration.svg";
import buildingConstructionSvg from "assets/images/building-construction.svg";
import coastalSvg from "assets/images/coastal.svg";
import Ul from "./Ul";
import ButtonsContainer from "./ButtonsContainer";
import QuestionTitle from "./QuestionTitle";

export default function MixedMercantileGuidelines({
  onYes,
  onNo,
}: GuidlinesProps) {
  return (
    <div
      css={css`
        display: flex;
        gap: 5px;
        flex-direction: column;
      `}
    >
      <QuestionTitle>
        Does this risk location meet the guidelines outlined below?
      </QuestionTitle>
      <GuidelinesSection title="Lines of Business" svgUrl={buildingSvg}>
        <Ul>
          <li>
            Apartment buildings or condominium associations with up to 50%
            mercantile occupancy
          </li>
        </Ul>
      </GuidelinesSection>
      <GuidelinesSection title="TIV Limits" svgUrl={dollarDocumentSvg}>
        <Ul>
          <li>
            Non-sprinklered frame structures do not exceed $2M per location
          </li>
          <li>Sprinklered frame structures do not exceed $5M per location</li>
          <li>All other construction types do not exceed $10M per location</li>
        </Ul>
      </GuidelinesSection>
      <GuidelinesSection title="GL Limits" svgUrl={financeDurationSvg}>
        <Ul>
          <li>Primary Limits $1M/$2M if written as a commercial package</li>
        </Ul>
      </GuidelinesSection>
      <GuidelinesSection
        title="Construction Types"
        svgUrl={buildingConstructionSvg}
      >
        <Ul>
          <li>
            No frame construction in the boroughs of NYC with the exception of
            Staten Island
          </li>
        </Ul>
      </GuidelinesSection>
      <GuidelinesSection title="Coastal Restrictions" svgUrl={coastalSvg}>
        <Ul>
          <li>Up to 1000 feet from water</li>
          <li>No Barrier Islands or Peninsula</li>
        </Ul>
      </GuidelinesSection>
      <ButtonsContainer>
        <YesNoButtons onYes={onYes} onNo={onNo} />
      </ButtonsContainer>
      <div
        css={css`
          margin-top: -30px;
        `}
      >
        If you have a risk that does not meet these guidelines and would still
        like it to be considered, please contact your underwriter.
      </div>
    </div>
  );
}
