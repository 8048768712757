/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {GridContainer, GridItem} from "components/Grid";
import {ReactNode} from "react";

export default function InfoRow<T>({
  object,
  label,
  boolean = false,
  labelGrow = 7,
}: {
  object: T;
  label: ReactNode;
  boolean?: boolean;
  labelGrow?: number;
}) {
  return (
    <GridContainer
      rowGap={8}
      css={(theme) => css`
        background: ${theme.color.brand.tertiary};
        padding: 14px 16px;
        min-height: 56px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:nth-child(even) {
          background: ${theme.color.brand.tertiary}88;
        }
      `}
    >
      <GridItem
        growXXS={12}
        growMD={labelGrow}
        css={(theme) => css`
          ${theme.text.header3}
          color: ${theme.color.grayscale[800]};
          font-weight: bold;
          display: flex;
          align-items: center;
        `}
      >
        {label}
      </GridItem>
      <GridItem
        growXXS={12}
        growMD={12 - labelGrow}
        css={(theme) => css`
          color: ${theme.color.grayscale[800]};
          display: flex;
          align-items: center;
        `}
      >
        <>{boolean ? (object ? "Yes" : "No") : object}</>
      </GridItem>
    </GridContainer>
  );
}
