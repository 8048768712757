/** @jsxImportSource @emotion/react */

import {
  getPolicyDocuments,
  PolicyDocument as PolicyDocumentInterface,
  PolicyBasicInfo,
} from "api/policy";
import {FilterTabs} from "components/Tabs";
import {useMemo} from "react";
import Document from "components/Document";
import PolicyModSelector from "./PolicyModSelector";
import {formatDate, formatEffectiveDates, toTitleCase} from "utils/format";
import {Accordian, AccordianButton, AccordianPanel} from "components/Accordian";
import Center from "components/Center";
import {PolicyPageParams} from "./policyPageParams";
import Loading from "components/Loading";
import {H3} from "components/elements";
import {useApiQuery} from "hooks/api";

type PolicyDocumentModGroup = {
  mod: string;
  effectiveDates: string;
  docs: PolicyDocumentInterface[];
};

function PolicyDocuments({
  params,
  policyModList,
}: {
  params: PolicyPageParams;
  policyModList: PolicyBasicInfo[];
}) {
  const {error, data: policyDocuments} = useApiQuery(getPolicyDocuments)(
    params.policyModIds
  );

  return (
    <>
      <PolicyModSelector
        currentModNumber={params.policyModIds.mod_number}
        policyModList={policyModList}
        showAllModsButton
        showAllMods={params.showAllMods}
      />
      {policyDocuments ? (
        <PolicyDocumentList
          policyDocuments={policyDocuments}
          policyModList={policyModList}
          params={params}
        />
      ) : error ? (
        <div>Error</div>
      ) : (
        <Loading size="medium" />
      )}
    </>
  );
}

function useGroupedDocsByMod(
  policyDocuments: PolicyDocumentInterface[],
  policyModList: PolicyBasicInfo[]
) {
  return useMemo(() => {
    return policyDocuments.reduce<PolicyDocumentModGroup[]>((groups, doc) => {
      const mod = policyModList.find(
        (mod) => mod.policy_mod_ids.mod_number === doc.policy_mod_ids.mod_number
      );
      const modGroup = groups.find(
        (group) => group.mod === doc.policy_mod_ids.mod_number
      );
      if (modGroup) modGroup.docs.push(doc);
      else
        groups.push({
          mod: doc.policy_mod_ids.mod_number,
          effectiveDates: mod
            ? formatEffectiveDates(mod.effective_date, mod.expiration_date)
            : "",
          docs: [doc],
        });

      groups.sort((a, b) => Number(b.mod) - Number(a.mod));
      groups.map((group) =>
        group.docs.sort(
          (a, b) =>
            Date.parse(b.effective_date) - Date.parse(a.effective_date) ||
            a.url.localeCompare(b.url)
        )
      );
      return groups;
    }, []);
  }, [policyDocuments, policyModList]);
}

function useFilteredDocsByMod(
  groupedDocsByMod: PolicyDocumentModGroup[],
  params: PolicyPageParams
) {
  return useMemo(() => {
    if (params.policyModIds.mod_number && !params.showAllMods) {
      return groupedDocsByMod.filter(
        (group) => group.mod === params.policyModIds.mod_number
      );
    } else return groupedDocsByMod;
  }, [groupedDocsByMod, params.policyModIds.mod_number, params.showAllMods]);
}

function useDocumentCategoryTabs(filteredDocsByMod: PolicyDocumentModGroup[]) {
  return useMemo(() => {
    const tabs = ["All"];
    filteredDocsByMod
      .map((modGroup) => modGroup.docs)
      .flat()
      .forEach((document) => {
        if (document.catergory && !tabs.includes(document.catergory))
          tabs.push(document.catergory);
      });
    return tabs;
  }, [filteredDocsByMod]);
}

function PolicyDocumentList({
  policyDocuments,
  policyModList,
  params,
}: {
  policyDocuments: PolicyDocumentInterface[];
  policyModList: PolicyBasicInfo[];
  params: PolicyPageParams;
}) {
  const groupedDocsByMod = useGroupedDocsByMod(policyDocuments, policyModList);
  const filteredDocsByMod = useFilteredDocsByMod(groupedDocsByMod, params);
  const tabs = useDocumentCategoryTabs(filteredDocsByMod);
  return (
    <FilterTabs tabs={tabs}>
      {(tab) =>
        filteredDocsByMod.map((policyDocumentModGroup) => {
          const documents =
            tab === "All"
              ? policyDocumentModGroup.docs
              : policyDocumentModGroup.docs.filter(
                  (policyDocument) => policyDocument.catergory === tab
                );
          const documentsElements = documents.map((policyDocument) => (
            <Document
              name={`${policyDocument.type} / ${toTitleCase(
                policyDocument.version
              )}`}
              footer={`Date ${
                policyDocument.generated_date_label
              }: ${formatDate(policyDocument.effective_date)}`}
              url={policyDocument.url}
            />
          ));
          if (params.showAllMods) {
            return (
              documents.length > 0 && (
                <Accordian defaultOpen={true} styled={false}>
                  <AccordianButton>
                    <H3>{policyDocumentModGroup.effectiveDates}</H3>
                  </AccordianButton>
                  <AccordianPanel>{documentsElements}</AccordianPanel>
                </Accordian>
              )
            );
          } else {
            if (documents.length > 0) return documentsElements;
            else return <Center>No documents</Center>;
          }
        })
      }
    </FilterTabs>
  );
}

export default PolicyDocuments;
