/** @jsxImportSource @emotion/react */

import {Fragment, useState} from "react";
import {css} from "@emotion/react";
import Button from "components/Button";
import {AnchorButton} from "components/elements";
import {
  PopoverButton,
  PopoverContainer,
  PopoverPanel,
} from "components/Popover";
import {TextInput} from "components/TextInput";
import * as yup from "yup";
import styled from "@emotion/styled";

export default function ReceiptEmail({
  receiptEmail,
  onSubmit,
}: {
  receiptEmail: string;
  onSubmit: (email: string) => void;
}) {
  const [receiptEmailInputValue, setReceiptEmailInputValue] =
    useState(receiptEmail);
  const isValid = yup.string().email().isValidSync(receiptEmailInputValue);
  return (
    <span
      css={css`
        display: flex;
        gap: 12px;
      `}
    >
      <PopoverContainer>
        <PopoverButton as={Fragment}>
          <AnchorButton onClick={(e) => e.stopPropagation()}>
            {!receiptEmail ? "Add" : "Change"} email
          </AnchorButton>
        </PopoverButton>
        <PopoverPanel
          padding="15px"
          width="fit-content"
          offset={20}
          direction="left"
          unmount
          focus
        >
          {({close}) => {
            return (
              <ReceiptEmailPanel>
                <TextInput
                  error={!isValid}
                  defaultValue={receiptEmailInputValue}
                  label="Receipt Email"
                  onChange={(e) => setReceiptEmailInputValue(e.target.value)}
                  ref={(el) => el?.focus()}
                />
                <ReceiptEmailPanelButtonsContainer>
                  <Button
                    disabled={!isValid}
                    size="small"
                    type="submit"
                    onClick={() => {
                      onSubmit(receiptEmailInputValue);
                      close();
                    }}
                  >
                    Ok
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => close()}
                  >
                    Cancel
                  </Button>
                </ReceiptEmailPanelButtonsContainer>
              </ReceiptEmailPanel>
            );
          }}
        </PopoverPanel>
      </PopoverContainer>
    </span>
  );
}

const ReceiptEmailPanel = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0;
`;

const ReceiptEmailPanelButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;
