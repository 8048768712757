import {QuoteForm} from "api/quote";
import {Form} from "pages/RateQuoteBind/RatePanel";
import BopCoverageForm from "./BopCoverageForm";
import BopGeneralLiability from "./BopGeneralLiability";
import BopLocationForm from "./BopLocationForm";

export default function bop(quoteForm: QuoteForm): Form {
  return {
    sections: {
      "Risk Location": (
        <BopLocationForm
          locations={quoteForm.locations}
          quoteId={`${quoteForm.quote_id}`}
        />
      ),
      "General Liability": <BopGeneralLiability bop={quoteForm.bop} />,
      "Coverage Options": <BopCoverageForm bop={quoteForm.bop} />,
    },
    "Underwriting Questions": <div></div>,
  };
}
