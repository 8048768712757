import {RuleResult, RuleResultType} from "api/quote";
import {createContext, ReactNode, useContext, useState} from "react";
import {UNDERWRITER_SECTION_NAME} from "../RatePanel";

export enum ErrorFilters {
  ALL = "all",
  REQUIRED = "required",
  NONREQUIRED = "non-required",
}

export const SectionErrorContext = createContext({
  getSectionErrorsCount(
    sectionName: string,
    errorFilter?: ErrorFilters
  ): number {
    return 1;
  },
  getTotalFieldErrorsCount(errorFilter?: ErrorFilters): number {
    return 1;
  },
  getTotalNoneUWFieldErrorsCount(errorFilter?: ErrorFilters): number {
    return 1;
  },
  addSectionError(
    sectionName: string,
    objectPath: string,
    error: RuleResult
  ) {},
  removeSectionError(sectionName: string, objectPath: string) {},
});

export function SectionErrorContextProvider({children}: {children: ReactNode}) {
  const [sectionErrors, setSectionErrors] = useState<{
    [key: string]: {[key: string]: RuleResult | undefined};
  }>({});

  function getSectionErrorsCount(
    sectionName: string,
    errorFilter: ErrorFilters = ErrorFilters.ALL
  ) {
    return !sectionErrors[sectionName]
      ? 0
      : Object.keys(sectionErrors[sectionName]).filter((sectionKey) => {
          if (!sectionErrors[sectionName][sectionKey]) return false;
          if (
            errorFilter === ErrorFilters.REQUIRED &&
            sectionErrors[sectionName][sectionKey]?.result_type !==
              RuleResultType.REQUIRED
          )
            return false;
          if (
            errorFilter === ErrorFilters.NONREQUIRED &&
            sectionErrors[sectionName][sectionKey]?.result_type ===
              RuleResultType.REQUIRED
          )
            return false;
          return true;
        }).length;
  }

  function getTotalFieldErrorsCount(
    errorFilter: ErrorFilters = ErrorFilters.ALL
  ) {
    let count = 0;
    for (let section in sectionErrors) {
      count += getSectionErrorsCount(section, errorFilter);
    }
    return count;
  }

  function getTotalNoneUWFieldErrorsCount(
    errorFilter: ErrorFilters = ErrorFilters.ALL
  ) {
    let count = 0;
    for (let section in sectionErrors) {
      if (section !== UNDERWRITER_SECTION_NAME)
        count += getSectionErrorsCount(section, errorFilter);
    }
    return count;
  }

  function addSectionError(
    sectionName: string,
    objectPath: string,
    error: RuleResult
  ) {
    setSectionErrors((sectionErrors) => {
      let errors = {...sectionErrors};
      if (!errors[sectionName]) {
        errors[sectionName] = {};
      }
      errors[sectionName][objectPath] = error;
      return errors;
    });
  }

  function removeSectionError(sectionName: string, objectPath: string) {
    setSectionErrors((sectionErrors) => {
      let errors = {...sectionErrors};
      if (!errors[sectionName]) {
        errors[sectionName] = {};
      }
      delete errors[sectionName][objectPath];
      return errors;
    });
  }

  return (
    <SectionErrorContext.Provider
      value={{
        getSectionErrorsCount,
        getTotalFieldErrorsCount,
        getTotalNoneUWFieldErrorsCount,
        addSectionError,
        removeSectionError,
      }}
    >
      {children}
    </SectionErrorContext.Provider>
  );
}

export function useSectionErrorContext() {
  return useContext(SectionErrorContext);
}
