import {QuoteTypes} from "api/quote";

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function formatAmount(
  amount: string | number,
  options: {
    hideFraction?: boolean;
    showZero?: boolean;
    hideNegativeSign?: boolean;
  } = {
    hideFraction: false,
    showZero: false,
    hideNegativeSign: false,
  }
) {
  amount = Number(amount);
  if (!options.showZero && amount === 0) return "";
  return new Intl.NumberFormat([], {
    style: "currency",
    signDisplay: options.hideNegativeSign ? "never" : "auto",
    maximumFractionDigits: options.hideFraction ? 0 : 2,
    minimumFractionDigits: options.hideFraction ? 0 : 2,
    currency: "USD",
    currencySign: "accounting",
  }).format(amount);
}

export function formatDate(date: string | Date) {
  if (!date) return "";
  return new Date(date).toLocaleDateString([], {timeZone: "UTC"});
}

export function formatEffectiveDates(
  effectiveDate: string,
  expirationDate: string
) {
  return `${formatDate(effectiveDate)} - ${formatDate(expirationDate)}`;
}

export function datesAreOnSameDay(first: any, second: any) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export function formatDateTime(dateString: string, formatToWeekday = true) {
  if (!dateString) return "";

  const date = new Date(dateString);

  if (datesAreOnSameDay(date, new Date())) {
    return date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  if (formatToWeekday && date > oneWeekAgo) {
    return DAYS[date.getDay()];
  }

  return date.toLocaleDateString([], {timeZone: "UTC"});
}

export function formatQuoteStatus(status: string) {
  return status.replaceAll("_", " ");
}

export function formatQuoteType(quote_type: QuoteTypes) {
  const formatMap: {[key: string]: string} = {
    [QuoteTypes.HAB]: "Habitational",
    [QuoteTypes.SKP]: "Skp",
    [QuoteTypes.BOP]: "Bop",
    [QuoteTypes.MIXED_MERCANTILE]: "Mixed Mercantile",
  };
  return formatMap[quote_type] || quote_type;
}

export function toTitleCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
}

export function formatName(name: string) {
  return name
    .toLowerCase()
    .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
}

export function snakeCaseToCamelCase(snakeCaseString: string) {
  return snakeCaseString.replace(/_([a-z])/g, (match, letter) =>
    letter.toUpperCase()
  );
}
