import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Dialog} from "@headlessui/react";
import {XIcon} from "@heroicons/react/solid";
import {ReactNode, useState} from "react";
import IconButton from "./IconButton";

export const DetailsContainer = styled.div`
  height: 100%;
`;

export const DialogContainer = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  width: 100%;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    background: rgb(0 0 0 / 40%);
    height: 100vh;
    top: 0;
    left: 0;
  }
`;

export const SlideOverContent = styled.div`
  max-width: 600px;
  margin-left: auto;
  background: ${(props) => props.theme.color.grayscale.white};
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: auto;
  height: calc(100vh - 64px);
`;

const ContainerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
  position: sticky;
  z-index: 9;
  top: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.span`
  ${(props) => props.theme.text.header3}
  color: ${(props) => props.theme.color.brand.primary};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export default function InternalNotesSlideOver({
  icon,
  title,
  content,
}: {
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  title: string;
  content: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <IconButton
        title={title}
        background={false}
        icon={icon}
        onClick={openModal}
      />
      <Dialog open={isOpen} as="div" onClose={closeModal}>
        <DialogContainer onClick={closeModal}>
          <SlideOverContent onClick={(e) => e.stopPropagation()}>
            <ContainerHeader>
              <Header>{title}</Header>
              <Actions>
                <IconButton
                  onClick={closeModal}
                  css={css`
                    cursor: pointer;
                    outline: 0;
                    margin-left: 10px;
                    width: 22px !important;
                    height: 22px !important;
                    padding: 0px !important;
                    color: #000;
                    border-radius: 0;
                    border: 0;
                    background: transparent;
                    &:hover {
                      background: transparent !important;
                    }
                  `}
                >
                  <XIcon />
                </IconButton>
              </Actions>
            </ContainerHeader>
            <ContentContainer>{content}</ContentContainer>
          </SlideOverContent>
        </DialogContainer>
      </Dialog>
    </>
  );
}
