/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {forwardRef, ReactNode} from "react";

interface Props {
  children: ReactNode;
  marginBottom?: number;
  noBodyShadow?: boolean;
  borderRadius?: number;
  rowShadow?: boolean;
  container?: boolean;
  checkeredBackground?: boolean;
  disabled?: boolean;
}

export const Table = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      checkeredBackground = false,
      container = false,
      disabled = false,
    },
    ref
  ) => {
    const borderRadius = 4;
    let rowShadow = 0;
    return (
      <div
        css={(theme) => css`
          overflow-x: auto;
          ${container &&
          css`
            background-color: ${theme.color.brand.tertiary};
            border-radius: 4px;
            padding: 8px 16px;
          `}
          ${disabled &&
          css`
            opacity: 0.5;
            pointer-events: none;
          `}
        `}
        ref={ref}
      >
        <table
          css={(theme) => css`
            min-width: 100%;
            border-spacing: 0px;

            tbody::after {
              content: "";
              display: block;
              height: 8px;
            }

            th {
              border: 1px solid transparent;
              padding: 10px;
            }

            td {
              border: 1px solid transparent;
              border-style: solid none none none;
              padding: 10px;
              /* box-shadow: ${rowShadow
                ? "0px 2px 8px rgba(127, 134, 160, 0.05)"
                : "none"}; */
            }
            tr:last-child > td {
              border-bottom-style: solid;
            }

            tr > td:first-of-type {
              border-left-style: solid;
            }
            tr > td:last-child {
              border-right-style: solid;
            }
            tr:first-of-type > td:first-of-type {
              border-left-style: solid;
              border-top-left-radius: ${borderRadius}px;
            }
            tr:first-of-type > td:last-child {
              border-right-style: solid;
              border-top-right-radius: ${borderRadius}px;
            }
            tr:last-child > td:first-of-type {
              border-left-style: solid;
              border-bottom-left-radius: ${borderRadius}px;
            }
            tr:last-child > td:last-child {
              border-right-style: solid;
              border-bottom-right-radius: ${borderRadius}px;
            }
            tbody {
              box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
            }
            tbody:hover {
              box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.1);
            }
            tbody tr {
              background: ${theme.color.grayscale.white};
            }
            ${checkeredBackground &&
            css`
          tbody tr:nth-child(odd) {
            background-color: ${theme.color.brand.tertiary};
          }
          tbody tr:nth-child(even) {
            background-color: ${theme.color.brand.tertiary}88};
          }
          `}
          `}
        >
          {children}
        </table>
      </div>
    );
  }
);

export function Header({children}: {children: ReactNode}) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}

export function Body({
  children,
  highlighedBorder,
}: {
  children: ReactNode;
  highlighedBorder?: boolean;
}) {
  return (
    <tbody
      css={(theme) => css`
        ${highlighedBorder &&
        css`
          td {
            border: 1px solid ${theme.color.brand.primary};
            border-style: solid none none none;
            border-top-color: #e5e5e5;
          }
          tr:first-of-type > td {
            border-top-color: ${theme.color.brand.primary};
          }
        `}
      `}
    >
      {children}
    </tbody>
  );
}

export function Row({
  children,
  onClick,
  headerRow,
  highlighedBackground,
}: {
  children: ReactNode;
  onClick?: () => void;
  headerRow?: boolean;
  highlighedBackground?: boolean;
}) {
  return (
    <tr
      css={(theme) => css`
        height: 56px;
        ${onClick &&
        css`
          cursor: pointer;
        `};

        ${highlighedBackground &&
        css`
          td {
            background-color: ${theme.color.brand.tertiary} !important;
          }
        `}

        ${headerRow &&
        css`
          height: 10px;

          td {
            ${theme.text.table}
            color: ${theme.color.grayscale[500]};
            background-color: ${theme.color.brand.tertiary} !important;
            font-size: 10px;
          }
        `}
      `}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}

export const HeaderCell = styled.th`
  ${(props) => props.theme.text.table}
  white-space: nowrap;
  color: ${(props) => props.theme.color.grayscale[500]};
  text-align: start;
`;

export const Cell = styled.td`
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
`;

export function IconButtonsCellContainer({
  children,
  direction,
}: {
  children: ReactNode;
  direction: "start" | "end";
}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-${direction};
        gap: 5px;
      `}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}
