import stateCountiesMap from "assets/data/state-counties.json";
import {Location, Building, QuoteTypes} from "api/quote";
import {Box} from "components/Box";
import {GridContainer, GridItem} from "components/Grid";
import {ReactNode, useCallback} from "react";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import ActionButton from "../../ActionButton";
import Field from "../../Field";
import {PlacesAutocomplete} from "../../PlacesAutoComplete";
import {protectionClasses, sortedStates} from "../../fieldValues";
import {useAuth} from "hooks/auth";
import {useFieldsStatusContext} from "../../../contexts/fieldsStatusContext";

const typeOfProperties = ["Apartment Building", "Condominium Association"];

export function BuildingForm({
  locationId,
  location,
  buildings,
  children,
}: {
  locationId: string;
  location: Location;
  buildings: Building[];
  children: (props: {
    locationId: string;
    location: Location;
    building: Building;
  }) => ReactNode;
}) {
  return (
    <>
      <FormTitle.Header2>Building Info</FormTitle.Header2>
      {buildings.map((building, index) => (
        <Box
          key={index}
          mx={buildings.length > 1 ? 32 : 0}
          pb={16}
          borderBottom={buildings.length > 1 ? "1px solid #ccc" : undefined}
        >
          {buildings.length > 1 ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormTitle.SubHeader>Building {index + 1}</FormTitle.SubHeader>
              {!building.primary_building ? (
                <ActionButton.Delete
                  objectName="building"
                  objectId={building.id}
                  background
                >
                  Delete Building
                </ActionButton.Delete>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <></>
          )}
          {children({
            locationId,
            location,
            building,
          })}
        </Box>
      ))}
      {!buildings[0].bop_building && (
        <Box my={16} mx={buildings.length > 1 ? 32 : 0}>
          <ActionButton.Add
            objectName="building"
            params={{location_id: locationId}}
            background
          >
            Add Additional Building
          </ActionButton.Add>
        </Box>
      )}
    </>
  );
}

function LocationsForm({
  locations,
  quoteId,
  quoteType,
  children,
}: {
  locations: Location[];
  quoteId: any;
  quoteType?: QuoteTypes;
  children?: (props: {
    locationId: string;
    location: Location;
    buildings: Building[];
  }) => ReactNode;
}) {
  const getCounties = useCallback(
    (state: string) =>
      state ? stateCountiesMap[state as keyof typeof stateCountiesMap] : [],
    []
  );
  const {assignedStates} = useAuth();

  const assignedStateObjects = sortedStates.filter((state) => {
    return assignedStates.includes(state.abbreviation);
  });
  const {rate_error} = useFieldsStatusContext();
  return (
    <Box mb={100}>
      {locations.map((location, index) => (
        <Box
          key={index}
          mb={16}
          borderBottom={locations.length > 1 ? "1px solid #ccc" : undefined}
        >
          {locations.length > 1 ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormTitle.SubHeader>Location {index + 1}</FormTitle.SubHeader>
              {!location.primary_location ? (
                <ActionButton.Delete
                  objectName="location"
                  objectId={location.id}
                  background
                >
                  Delete Location
                </ActionButton.Delete>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <></>
          )}
          <GridContainer rowGap={24} columnGap={16}>
            {location.primary_location ? (
              <Field.CheckBox
                label="Address is same as mailing address"
                object={location}
                field={"address_info_same_like_primary_insured"}
                wrapperProps={{
                  grow: 12,
                }}
              />
            ) : (
              <></>
            )}
            {quoteType !== QuoteTypes.SKP &&
              quoteType !== QuoteTypes.MIXED_MERCANTILE && (
                <Field.DropDown
                  object={location}
                  options={typeOfProperties.map((item) => ({
                    title: item,
                    value: item,
                  }))}
                  field={"type_of_property"}
                  label="Type of property"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 6,
                  }}
                />
              )}
            {String(rate_error).includes(
              "Select Protection Class In Risk Location"
            ) && (
              <Field.DropDown
                object={location}
                options={protectionClasses.map((item) => ({
                  title: item.label,
                  value: item.value,
                }))}
                field={"protection_class"}
                label="Protection Class"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                }}
              />
            )}
            {!location.primary_location ||
            !location.address_info_same_like_primary_insured ? (
              <>
                <GridItem grow={12}>
                  <PlacesAutocomplete object={location} quoteId={quoteId} />
                </GridItem>
                <Field.TextInput
                  object={location}
                  field={"line_1"}
                  label="Address line 1"
                  wrapperProps={{
                    grow: 12,
                  }}
                />
                <Field.TextInput
                  object={location}
                  field={"line_2"}
                  label="Address line 2"
                  wrapperProps={{
                    grow: 12,
                  }}
                />
                <Field.TextInput
                  object={location}
                  field={"city"}
                  label="City"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 6,
                  }}
                />
                <Field.DropDown
                  object={location}
                  options={assignedStateObjects.map((state) => ({
                    title: state.name,
                    value: state.abbreviation,
                  }))}
                  field={"state"}
                  label="Select State"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 6,
                  }}
                />
                <Field.DropDown
                  object={location}
                  options={
                    getCounties(location.state)?.map((county: string) => ({
                      title: `${county} county`,
                      value: county,
                    })) ?? []
                  }
                  disabled={!getCounties(location.state)?.length}
                  field={"county"}
                  label="Select County"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 8,
                  }}
                />
                <Field.TextInput
                  object={location}
                  field={"zip"}
                  label="Zip"
                  wrapperProps={{
                    growXXS: 12,
                    growMD: 4,
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </GridContainer>
          {children
            ? children({
                locationId: location.id,
                location,
                buildings: location.buildings,
              })
            : null}
        </Box>
      ))}
      <Box my={8}>
        <ActionButton.Add
          objectName="location"
          background
          disabled={
            locations.length >= 3 &&
            locations[0].buildings[0].bop_building !== null
          }
        >
          Add Additional Location
        </ActionButton.Add>
      </Box>
    </Box>
  );
}

export default LocationsForm;
