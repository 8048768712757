import usePlacesAutocomplete, {getGeocode} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import {GridContainer, GridItem} from "components/Grid";
import {ObjectBase, getQuote} from "api/quote";
import styled from "@emotion/styled";
import {useQueryClient} from "react-query";
import {TextInput} from "components/TextInput";
import {useState} from "react";
import {useQuoteContext} from "../contexts/quoteContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import {useAuth} from "hooks/auth";
import {Permissions} from "api/auth";
import AlertBar from "components/AlertBar";

const ListItem = styled.li`
  flex: 1;
  outline: none;
  height: 45px;
  background: ${(props) => props.theme.color.brand.tertiary};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  color: ${(props) => props.theme.color.grayscale[600]};
  padding: 5px 25px;
  &::placeholder {
    color: ${(props) => props.theme.color.grayscale[600]};
  }
  cursor: pointer;
  list-style-type: none;
`;

const Ul = styled.ul`
  padding-inline-start: 0px;
`;

interface AddressObject extends ObjectBase {
  city: string;
  state: string;
  zip: string;
  county?: string;
  line_1: string;
  line_2?: string;
}

export function PlacesAutocomplete<T extends AddressObject>({
  object,
  quoteId,
}: {
  object: T;
  quoteId: string;
}) {
  const [refreshing, setRefreshing] = useState(false);
  const {update, isBackendQuoting} = useQuoteContext();
  const queryClient = useQueryClient();
  const {fieldsDisabled} = useFieldsStatusContext();
  const {authSession} = useAuth();
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  const handleInput = (e: any) => {
    setValue(e.target.value);
  };
  async function onSuggestionSelect({description}: {description: string}) {
    setValue(description, false);
    clearSuggestions();
    setRefreshing(true);
    try {
      const results = await getGeocode({address: description});
      const address_components = results[0].address_components;
      let houseNumber = "";
      let street = "";
      let state = "";
      let zip = "";
      let county = "";
      let city = "";
      address_components.forEach((element) => {
        if (element.types.includes("street_number")) {
          houseNumber = element.long_name;
        } else if (element.types.includes("route")) {
          street = element.long_name;
        } else if (element.types.includes("administrative_area_level_1")) {
          state = element.short_name;
        } else if (element.types.includes("postal_code")) {
          zip = element.long_name;
        } else if (element.types.includes("administrative_area_level_2")) {
          county = element.long_name.replace("County", "").trim();
        } else if (
          element.types.includes("locality") ||
          element.types.includes("sublocality")
        ) {
          city = element.long_name
            .replace("Township", "")
            .replace("City of", "")
            .trim();
        }
      });
      const addressObject: Partial<AddressObject> = {
        line_1: `${houseNumber} ${street}`,
        city: `${city}`,
        state: `${state}`,
        zip: `${zip}`,
      };
      if ("county" in object) {
        addressObject["county"] = county;
      }
      await Promise.all(
        Object.entries(addressObject).map(([key, value]) =>
          update(
            object.object_name,
            object.object_path,
            object.id,
            key,
            value as any
          )
        )
      );
      await queryClient.invalidateQueries([getQuote.name, quoteId.toString()]);
      setRefreshing(false);
      setValue("");
    } catch (e: any) {
      setRefreshing(false);
      setValue("");
    }
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        structured_formatting: {main_text, secondary_text},
      } = suggestion;

      return (
        <ListItem onClick={() => onSuggestionSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </ListItem>
      );
    });

  return (
    <div ref={ref}>
      <GridContainer rowGap={24} columnGap={16}>
        <GridItem grow={12}>
          <TextInput
            value={value}
            onChange={handleInput}
            disabled={
              !ready ||
              fieldsDisabled ||
              isBackendQuoting ||
              !authSession?.permissions.includes(Permissions.EDIT_QUOTE)
            }
            label="Address Search"
            autoComplete="false"
          />
          {status === "OK" && <Ul>{renderSuggestions()}</Ul>}
          {refreshing && (
            <p>
              <AlertBar status="warning">
                Address auto fill in progress...
              </AlertBar>
            </p>
          )}
        </GridItem>
        <hr />
      </GridContainer>
    </div>
  );
}
