/** @jsxImportSource @emotion/react */

import {ReactNode} from "react";
import {QuoteStatuses} from "api/quote";
import LeftSidebar from "../components/LeftSidebar";
import SectionsList from "../components/SectionsList";
import {
  ErrorFilters,
  useSectionErrorContext,
} from "../contexts/sectionErrorContext";
import {useQuoteContext} from "../contexts/quoteContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import PremiumAmountSideBar from "./PremiumAmountSideBar";
import {QUOTE_NOTES_SECTION_NAME} from "./QuoteNotesSection";
import {QUOTE_INFO_SECTION_NAME} from "./QuoteInfoSection";
import {INSURED_INFO_SECTION_NAME} from "./InsuredInfoForm";

const UNDERWRITER_SECTION_NAME = "Underwriting Questions";

interface Sections {
  [key: string]: ReactNode;
}

export interface Form {
  sections: Sections;
  [UNDERWRITER_SECTION_NAME]: ReactNode;
}

export default function RatePanelLeftBar({
  formSections,
  showNotesSection,
}: {
  formSections: Sections;
  showNotesSection: boolean;
}) {
  const {status} = useQuoteContext();
  const {getSectionErrorsCount} = useSectionErrorContext();
  const {showRequiredErrors} = useFieldsStatusContext();

  return (
    <LeftSidebar>
      <SectionsList
        sections={[QUOTE_INFO_SECTION_NAME, INSURED_INFO_SECTION_NAME]
          .concat(Object.keys(formSections))
          .map((section) => ({
            title: section,
            id: formatSectionNameId(section),
            errors: getSectionErrorsCount(
              section,
              showRequiredErrors ? ErrorFilters.ALL : ErrorFilters.NONREQUIRED
            ),
          }))}
      />
      {showNotesSection && (
        <SectionsList
          sections={[
            {
              title: QUOTE_NOTES_SECTION_NAME,
              id: formatSectionNameId(QUOTE_NOTES_SECTION_NAME),
            },
          ]}
        />
      )}
      <PremiumAmountSideBar />
      {status !== QuoteStatuses.QUICK_QUOTE && (
        <SectionsList
          sections={[
            {
              title: UNDERWRITER_SECTION_NAME,
              id: formatSectionNameId(UNDERWRITER_SECTION_NAME),
              errors: getSectionErrorsCount(
                UNDERWRITER_SECTION_NAME,
                showRequiredErrors ? ErrorFilters.ALL : ErrorFilters.NONREQUIRED
              ),
            },
          ]}
        />
      )}
    </LeftSidebar>
  );
}

function formatSectionNameId(sectionName: string) {
  return sectionName.replace(" ", "-");
}
