/** @jsxImportSource @emotion/react */

import {getQuoteDetails, QuoteBindingForm, QuoteStatuses} from "api/quote";
import {css} from "@emotion/react";
import {QuoteForm} from "api/quote";
import LeftSidebar from "../components/LeftSidebar";
import SectionsList from "../components/SectionsList";
import {useQuery} from "react-query";
import Button from "components/Button";
import PrinterIcon from "@heroicons/react/outline/PrinterIcon";
import SummaryInfo from "./SummaryInfo";
import UnderwritingInfo from "./UnderwritingInfo";
import CommercialProperty from "./CommercialProperty";
import GeneralLiability from "./GeneralLiability";
import BindingSection from "./BindingSection";
import FormListSection from "./FormListSection";
import PremiumAmountSection from "./PremiumAmountSection";
import Link from "components/Link";
import DocumentTextIcon from "@heroicons/react/outline/DocumentTextIcon";
import CurrencyDollarIcon from "@heroicons/react/outline/CurrencyDollarIcon";
import {
  CommercialPackageLines,
  getPolicyModIds,
  PolicyDetails,
} from "api/policy";
import ScrollToSection, {
  formatSectionNameId,
  useSection,
} from "../components/ScrollToSection";
import BoundLogo from "assets/images/bound-message.png";
import styled from "@emotion/styled";
import AlertBar from "components/AlertBar";
import Fees from "./Fees";
import {Anchor} from "components/elements";
import {useApiQuery} from "hooks/api";
import links from "links";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import NotesSection from "./NotesSections";
import SkpAdditonalInterests from "./SkpAdditionalInterests";
import SkpClasses from "./SkpClasses";

const BoundImageTag = styled.img`
  width: 160px;
  height: 160px;
`;

function getSummaryPanelSections(
  policy: PolicyDetails,
  quoteForm: QuoteForm,
  status: QuoteStatuses,
  bindingForm?: QuoteBindingForm
) {
  const insuranceLines = policy.insurance_lines as
    | CommercialPackageLines
    | undefined
    | null;
  return {
    Summary: <SummaryInfo policy={policy} />,
    "Commercial Property": (
      <CommercialProperty
        locations={insuranceLines?.commercial_property.locations}
        commercialProperty={insuranceLines?.commercial_property}
      />
    ),
    "General Liability": (
      <GeneralLiability
        generalLiability={insuranceLines?.general_liability}
        quoteForm={quoteForm}
      />
    ),
    "Contractor Classes": (
      <SkpClasses
        quoteForm={quoteForm}
      />
    ),
    "Additional Interests": (
      <SkpAdditonalInterests
        quoteForm={quoteForm}
      />
    ),
    Fees: <Fees policy_info={policy.policy_information} />,
    "Underwriting Questions ": (
      <UnderwritingInfo
        underwriting={quoteForm.underwriting}
        habitational={quoteForm.habitational}
        skp={quoteForm.skp}
      />
    ),
    Binding: (
      <BindingSection
        quoteForm={quoteForm}
        bindingForm={bindingForm}
        status={status}
      />
    ),
    ...(policy.forms ? {Forms: <FormListSection forms={policy.forms} />} : {}),
    ...(quoteForm.notes.length
      ? {Notes: <NotesSection notes={quoteForm.notes} />}
      : {}),
  };
}

export default function SummaryPanel({
  quoteID,
  quoteForm,
  bindingForm,
  status,
  policyNumber,
  quoteProposalLink,
}: {
  quoteID: number;
  quoteForm: QuoteForm;
  bindingForm?: QuoteBindingForm;
  status: QuoteStatuses;
  policyNumber: string;
  quoteProposalLink: string;
}) {
  useSection();
  const {data: policyData} = useQuery(
    ["quote/details", quoteID.toString()],
    () => getQuoteDetails(quoteID),
    {
      refetchOnWindowFocus: true,
      useErrorBoundary: true,
      suspense: true,
      refetchInterval: 60000,
    }
  );

  if (!policyData) return null;

  const policyDetailsSections = getSummaryPanelSections(
    policyData,
    quoteForm,
    status,
    bindingForm
  );

  const fullySprinkleredAlert = () => {
    if (quoteForm && quoteForm.mixed_mercantile) {
      for (let i = 0; i < quoteForm.locations.length; i++) {
        const location = quoteForm.locations[i];

        const buildings = location.buildings;

        for (let j = 0; j < buildings.length; j++) {
          const building = buildings[j];

          if (
            building.mixed_mercantile_building.is_building_sprinklered === "Y"
          ) {
            return "Tentative rating has been applied and if specific rating available, premium subject to change.";
          }
        }
      }
    }
    return false;
  };

  return (
    <div
      css={css`
        background: white;
        display: flex;
        width: 100%;
        height: calc(100vh - 125px);
      `}
    >
      <LeftSidebar>
        <SectionsList
          sections={Object.keys(policyDetailsSections).map((section) => ({
            title: section,
            id: formatSectionNameId(section),
          }))}
        />
      </LeftSidebar>
      <div
        css={(theme) => css`
          flex-grow: 1;
          background: white;
          overflow: auto;
          @media (min-width: ${theme.breakpoint.xxs}px) {
            padding: 10px 20px;
          }
          @media (min-width: ${theme.breakpoint.xs}px) {
            padding: 10px 40px;
          }
          @media (min-width: ${theme.breakpoint.sm}px) {
            padding: 20px 60px;
          }
          @media (min-width: ${theme.breakpoint.md}px) {
            padding: 30px 134px;
          }
        `}
      >
        {status === QuoteStatuses.BOUND && (
          <AlertBar status="success">This is a bound quote.</AlertBar>
        )}

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 30px 0px 60px;
          `}
        >
          <QuoteProposalButton quoteProposalLink={quoteProposalLink} />

          {status === QuoteStatuses.BOUND && (
            <>
              <BoundImageTag src={BoundLogo} />
              <PolicyAndPaymentButtons policyNumber={policyNumber} />
            </>
          )}
        </div>
        {fullySprinkleredAlert()}
        <PremiumAmountSection
          rate={policyData.policy_information.premium_amount}
          policyID={policyData.policy_mod_ids.display_policy_number}
        />
        {Object.entries(policyDetailsSections).map(([sectionName, element]) => (
          <ScrollToSection sectionName={sectionName}>{element}</ScrollToSection>
        ))}
      </div>
    </div>
  );
}

function QuoteProposalButton({quoteProposalLink}: {quoteProposalLink: string}) {
  return (
    <Anchor href={quoteProposalLink} target="_blank">
      <Button icon={PrinterIcon}>Quote Proposal</Button>
    </Anchor>
  );
}

function PolicyAndPaymentButtons({policyNumber}: {policyNumber: string}) {
  const {data: policyModIds} = useApiQuery(getPolicyModIds)(policyNumber);
  const permissions = usePermissions();
  if (!policyModIds || !policyModIds.length) return null;
  const policy = policyModIds[0];
  const disabled = !policy;
  return (
    <>
      <Link
        disabled={disabled}
        to={policy ? links.policy({policyModIds: policy}) : ""}
      >
        <Button disabled={disabled} icon={DocumentTextIcon}>
          Policy Details
        </Button>
      </Link>
      {permissions.includes(Permissions.MAKE_PAYMENT) && (
        <Link
          disabled={disabled}
          to={policy ? links.payment({policyModIds: policy}) : ""}
        >
          <Button disabled={disabled} icon={CurrencyDollarIcon}>
            Make Payment
          </Button>
        </Link>
      )}
    </>
  );
}
