/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Loading from "./Loading";

export default function LoadingScreen() {
  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
      `}
    >
      <span
        css={css`
          font-size: 35px;
          font-weight: bolder;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #484848;
        `}
      >
        <Loading text="Loading..." />
      </span>
    </div>
  );
}
