/** @jsxImportSource @emotion/react */

import {QuoteTypes} from "api/quote";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import styled from "@emotion/styled";
import {
  MenuButton,
  MenuButtonItem,
  MenuContainer,
  MenuItems,
} from "components/Menu";
import Divider from "components/Divider";
import {css} from "@emotion/react";
import {Permissions} from "api/auth";
import {usePermissions} from "hooks/auth";

const quoteTypes = [
  {
    title: "Habitational Commercial",
    value: QuoteTypes.HAB,
    legacyPermission: Permissions.LEGACY_HAB_QUOTES,
    legacyQuoteLink: process.env.REACT_APP_NEW_HABITATIONAL_QUOTE_LINK,
  },
  {
    title: "SkillPac Artisan Policy (SKP)",
    value: QuoteTypes.SKP,
    legacyPermission: Permissions.LEGACY_SKP_QUOTES,
    legacyQuoteLink: process.env.REACT_APP_SKP_NEW_QUOTE_LINK,
  },
  {
    title: "Mixed Mercantile",
    value: QuoteTypes.MIXED_MERCANTILE,
    legacyPermission: Permissions.LEGACY_MIXED_MERCANTILE_QUOTES,
    legacyQuoteLink: "",
  },
  {
    title: "Homeowners",
    value: QuoteTypes.HOME,
    underConstruction: process.env.NODE_ENV === "production",
    legacyPermission: Permissions.LEGACY_HO_QUOTES,
    legacyQuoteLink: process.env.REACT_APP_NEW_HOMEOWNER_QUOTE_LINK,
  },
];
if (Permissions.LEGACY_BOP_QUOTES) {
  quoteTypes.push({
    title: "Business Owners Program (BOP)",
    value: QuoteTypes.BOP,
    underConstruction: process.env.NODE_ENV === "production",
    legacyPermission: Permissions.LEGACY_BOP_QUOTES,
    legacyQuoteLink: process.env.REACT_APP_BOP_NEW_QUOTE_LINK,
  });
}
const MenuItemWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const RightIcon = styled(ChevronRightIcon)`
  width: 20px;
  height: 20px;
  margin: -3px 0;
`;

const DropDownIcon = styled(ChevronDownIcon)`
  width: 20px;
  height: 20px;
  margin: -3px 0 -3px 10px;
`;

const DropUpIcon = styled(ChevronUpIcon)`
  width: 20px;
  height: 20px;
  margin: -3px 0 -3px 10px;
`;

function NewQuoteButton() {
  const permissions = usePermissions();
  return (
    <MenuContainer>
      {({open}) => (
        <>
          <MenuButton>
            Rate / Quote / Bind
            {open ? <DropUpIcon /> : <DropDownIcon />}
          </MenuButton>
          <MenuItems width="300px" size="large">
            {quoteTypes.map((type, index) => {
              const showLegacyLink = permissions.includes(
                type.legacyPermission
              );
              const inactive = !showLegacyLink && type.underConstruction;
              return (
                <>
                  <MenuButtonItem
                    key={index}
                    to={
                      inactive
                        ? ""
                        : showLegacyLink
                        ? type.legacyQuoteLink
                        : `/new-quote?quoteType=${type.value}`
                    }
                    style={{cursor: inactive ? "not-allowed" : "pointer"}}
                  >
                    <MenuItemWrapper
                      css={(theme) => css`
                        ${inactive &&
                        css`
                          color: ${theme.color.grayscale[500]};
                        `}
                      `}
                    >
                      <span>{type.title}</span>
                      <RightIcon />
                    </MenuItemWrapper>
                  </MenuButtonItem>
                  <Divider />
                </>
              );
            })}
            {(permissions.includes(Permissions.VIEW_ALL_QUOTES) ||
              permissions.includes(Permissions.VIEW_AGENCY_QUOTES)) && (
              <MenuButtonItem key="quote_list" to="/quote">
                <MenuItemWrapper
                  css={css`
                    font-weight: 700;
                  `}
                >
                  <span>View Quotes</span>
                  <RightIcon />
                </MenuItemWrapper>
              </MenuButtonItem>
            )}
          </MenuItems>
        </>
      )}
    </MenuContainer>
  );
}

export default NewQuoteButton;
