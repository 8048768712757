/** @jsxImportSource @emotion/react */

import {css, keyframes} from "@emotion/react";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export function Spinner(props: {className?: string}) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      css={css`
        animation: ${spin} 2s linear infinite;
      `}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6V2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6ZM11 22V18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22ZM4.22289 5.63711L7.05289 8.46711C7.44342 8.85763 8.07658 8.85763 8.46711 8.46711C8.85763 8.07658 8.85763 7.44342 8.46711 7.05289L5.63711 4.22289C5.24658 3.83237 4.61342 3.83237 4.22289 4.22289C3.83237 4.61342 3.83237 5.24658 4.22289 5.63711ZM18.3629 19.7771L15.5329 16.9471C15.1424 16.5566 15.1424 15.9234 15.5329 15.5329C15.9234 15.1424 16.5566 15.1424 16.9471 15.5329L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771ZM2 13H6C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11H2C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13ZM22 13H18C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13ZM5.63711 19.7771L8.46711 16.9471C8.85763 16.5566 8.85763 15.9234 8.46711 15.5329C8.07658 15.1424 7.44342 15.1424 7.05289 15.5329L4.22289 18.3629C3.83237 18.7534 3.83237 19.3866 4.22289 19.7771C4.61342 20.1676 5.24658 20.1676 5.63711 19.7771ZM19.7771 5.63711L16.9471 8.46711C16.5566 8.85763 15.9234 8.85763 15.5329 8.46711C15.1424 8.07658 15.1424 7.44342 15.5329 7.05289L18.3629 4.22289C18.7534 3.83237 19.3866 3.83237 19.7771 4.22289C20.1676 4.61342 20.1676 5.24658 19.7771 5.63711Z"
      />
      <mask
        id="mask0_1739_22867"
        style={{maskType: "alpha"}}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 6V2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6ZM11 22V18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22ZM4.22289 5.63711L7.05289 8.46711C7.44342 8.85763 8.07658 8.85763 8.46711 8.46711C8.85763 8.07658 8.85763 7.44342 8.46711 7.05289L5.63711 4.22289C5.24658 3.83237 4.61342 3.83237 4.22289 4.22289C3.83237 4.61342 3.83237 5.24658 4.22289 5.63711ZM18.3629 19.7771L15.5329 16.9471C15.1424 16.5566 15.1424 15.9234 15.5329 15.5329C15.9234 15.1424 16.5566 15.1424 16.9471 15.5329L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771ZM2 13H6C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11H2C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13ZM22 13H18C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13ZM5.63711 19.7771L8.46711 16.9471C8.85763 16.5566 8.85763 15.9234 8.46711 15.5329C8.07658 15.1424 7.44342 15.1424 7.05289 15.5329L4.22289 18.3629C3.83237 18.7534 3.83237 19.3866 4.22289 19.7771C4.61342 20.1676 5.24658 20.1676 5.63711 19.7771ZM19.7771 5.63711L16.9471 8.46711C16.5566 8.85763 15.9234 8.85763 15.5329 8.46711C15.1424 8.07658 15.1424 7.44342 15.5329 7.05289L18.3629 4.22289C18.7534 3.83237 19.3866 3.83237 19.7771 4.22289C20.1676 4.61342 20.1676 5.24658 19.7771 5.63711Z"
        />
      </mask>
      <g mask="url(#mask0_1739_22867)">
        <rect width="24" height="24" />
      </g>
    </svg>
  );
}

export default Spinner;
