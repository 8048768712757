import {useCallback} from "react";
import {FieldRuleResults} from "api/quote";
import {useFieldsStatusContext} from "../../contexts/fieldsStatusContext";

export default function useShowAPIErrors() {
  const {setApiErrors} = useFieldsStatusContext();

  const showAPIErrors = useCallback(
    (field_errors: FieldRuleResults) => {
      let errorMessages = [];
      for (let sectionKey in field_errors) {
        for (let instaceKey in field_errors?.[sectionKey]) {
          for (let fieldKey in field_errors[sectionKey]?.[instaceKey]) {
            if (field_errors[sectionKey][instaceKey]?.[fieldKey]) {
              errorMessages.push(
                field_errors[sectionKey][instaceKey][fieldKey]!.message
              );
            }
          }
        }
      }
      setApiErrors(errorMessages);
    },
    [setApiErrors]
  );

  return showAPIErrors;
}
