import {PolicyPaymentInfo} from "api/payment";
import {
  PolicyBasicInfo,
  PolicyIds,
  PolicyModIds,
  PolicyStatuses,
} from "api/policy";
import {QuoteStatuses} from "api/quote";
import {formatDate} from "./format";
import {getFromSearchParamsToObject} from "utils/url";

export function inQuoteStatus(status?: QuoteStatuses) {
  if (
    status === QuoteStatuses.QUICK_QUOTE ||
    status === QuoteStatuses.FULL_QUOTE
  ) {
    return true;
  }
  return false;
}

export function inReviewStatus(status?: QuoteStatuses) {
  if (
    status === QuoteStatuses.REFERRED ||
    status === QuoteStatuses.DECLINED ||
    status === QuoteStatuses.UNDERWRITER_REVIEW ||
    status === QuoteStatuses.QUOTING_ERROR
  ) {
    return true;
  }
  return false;
}

export function inApprovedStatus(status?: QuoteStatuses) {
  if (
    status === QuoteStatuses.APPROVED ||
    status === QuoteStatuses.BINDING_IN_PROGRESS ||
    status === QuoteStatuses.BINDING_ERROR ||
    status === QuoteStatuses.BOUND
  ) {
    return true;
  }
  return false;
}

export function inBoundStatus(status?: QuoteStatuses) {
  if (
    status === QuoteStatuses.BINDING_IN_PROGRESS ||
    status === QuoteStatuses.BINDING_ERROR ||
    status === QuoteStatuses.BOUND
  ) {
    return true;
  }
  return false;
}

export function inFinalizedStatus(status?: QuoteStatuses) {
  if (status === QuoteStatuses.BOUND) {
    return true;
  }
  return false;
}

export function isPolicyModInEffect(policyMod: PolicyBasicInfo) {
  return policyMod.status === PolicyStatuses.IN_FORCE;
}

export function isPolicyModInForce(policyMod: PolicyBasicInfo) {
  return (
    new Date(policyMod.effective_date).getTime() < Date.now() &&
    new Date(policyMod.expiration_date).getTime() >= Date.now()
  );
}

export function getPolicyModInEffectOrLatest(policyModList: PolicyBasicInfo[]) {
  return (
    policyModList.find(isPolicyModInForce) ||
    policyModList.find(isPolicyModInEffect) ||
    policyModList[0]
  );
}

export function getPaymentAmounts(policyPaymentInfo: PolicyPaymentInfo) {
  const amounts = [
    {
      description: "Remaining balance",
      amount: policyPaymentInfo.remaining_balance,
    },
  ];
  if (policyPaymentInfo.amount_due && policyPaymentInfo.due_date) {
    amounts.unshift({
      description: `${
        policyPaymentInfo.is_due_today
          ? "Due by today "
          : policyPaymentInfo.is_past_due
          ? "Past due from "
          : "Due by "
      } ${formatDate(policyPaymentInfo.due_date)} ${
        policyPaymentInfo.cancellation_date &&
        policyPaymentInfo.policy_info.status !== PolicyStatuses.CANCELLED
          ? `(Cancels by ${formatDate(policyPaymentInfo.cancellation_date)})`
          : ""
      }`,
      amount: policyPaymentInfo.amount_due,
    });
  }
  return amounts;
}

export function openQuoteProposal(quoteProposalHtml: string) {
  try {
    const quoteProposalHtmlBlob = new Blob([quoteProposalHtml], {
      type: "text/html",
    });

    const quoteProposalUrl = URL.createObjectURL(quoteProposalHtmlBlob);
    const newWindow = window.open(quoteProposalUrl, "_blank");
    if (newWindow)
      newWindow.onload = function () {
        newWindow.print();
      };
  } catch (error) {
    console.error("Error downloading PDF file:", error);
  }
}

export function getPolicyIdsFromSearchParams(searchParams: URLSearchParams) {
  return getFromSearchParamsToObject(searchParams, dummpPolicyIds);
}

export function getPolicyModIdsFromSearchParams(searchParams: URLSearchParams) {
  return getFromSearchParamsToObject(searchParams, dummyPolicyModIds);
}

const dummpPolicyIds: PolicyIds = {
  pc_provider_name: "",
  policy_id: "",
  policy_number: "",
  display_policy_number: "",
  company_number: "",
  lob: "",
};

const dummyPolicyModIds: PolicyModIds = {...dummpPolicyIds, mod_number: ""};
