import {ChatIcon} from "@heroicons/react/solid";
import Button from "components/Button";
import Link from "components/Link";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

export default function Reports() {
  const permissions = usePermissions();

  return (
    <>
      {permissions.includes(Permissions.VIEW_DIARY_COMMENTS) && (
        <Link to={"/policy/diaryComments"}>
          <Button background={false} icon={ChatIcon}>
            Diary Comments
          </Button>
        </Link>
      )}

      {permissions.includes(Permissions.VIEW_ALL_COMMISSION_STATEMENTS) && (
        <Link to={"/commission-statements"}>
          <Button background={false} icon={ChatIcon}>
            Commission Statements
          </Button>
        </Link>
      )}
      {permissions.includes(Permissions.VIEW_AGENCY_EXPERIENCE) && (
        <Link to={"/agency-experience"}>
          <Button background={false} icon={ChatIcon}>
            Agency Experience
          </Button>
        </Link>
      )}
       {permissions.includes(Permissions.VIEW_AGENCY_EXPERIENCE) && (
        <Link to={"/accounting"}>
          <Button background={false} icon={ChatIcon}>
            Finance Reports
          </Button>
        </Link>
      )}
    </>
  );
}
