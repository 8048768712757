/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {
  FilterConfig,
  ReportConfig,
  ReportResult,
  getFilterListResults,
  getReportConfig,
  getReportHtmlUrl,
  getReportResult,
  runReportAction,
} from "api/reports";
import {DropDown} from "components/DropDown";
import Loading from "components/Loading";
import {TextInput} from "components/TextInput";
import {
  LoadingIndicator,
  PageContainer,
  ToolbarContainer,
} from "components/listPageUtils";
import {useApiMutation, useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import {DownloadButton, EmailButton} from "./ReportsList";
import {Body, Cell, Header, HeaderCell, Row, Table} from "components/Table";
import Pagination from "components/Pagination";
import links from "links";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Link from "components/Link";
import Surface from "components/Surface";
import PolicyNumberCombobox from "components/PolicyNumberCombobox";
import Button from "components/Button";
import {useNotification} from "hooks/notification";

const requriedFilterNotSelectedContext = createContext({
  requriedFilterNotSelected: false,
  setRequriedFilterNotSelected: (value: boolean) => {},
});

function RequiredFilterNotSelectedProvider({children}: {children: ReactNode}) {
  const [requriedFilterNotSelected, setRequriedFilterNotSelected] =
    useState(false);
  return (
    <requriedFilterNotSelectedContext.Provider
      value={{requriedFilterNotSelected, setRequriedFilterNotSelected}}
    >
      {children}
    </requriedFilterNotSelectedContext.Provider>
  );
}

function useRequiredFilterNotSelectedContext() {
  return useContext(requriedFilterNotSelectedContext);
}

export default function Report({
  reportName,
}: {
  reportName: string;
  reportTitle: string;
}) {
  const reportConfigQuery = useApiQuery(getReportConfig)(reportName);
  return reportConfigQuery.data ? (
    <LoadedReportConfig
      reportName={reportName}
      reportConfig={reportConfigQuery.data}
    />
  ) : reportConfigQuery.isLoading ? (
    <Loading size="medium" />
  ) : reportConfigQuery.error ? (
    <div>Error</div>
  ) : null;
}

function LoadedReportConfig({
  reportName,
  reportConfig,
}: {
  reportName: string;
  reportConfig: ReportConfig;
}) {
  const {location} = useNavigation();
  const params = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );
  const [paginateLoading, setPaginateLoading] = useState(false);
  return (
    <PageContainer>
      <RequiredFilterNotSelectedProvider>
        <ReportFilterBar
          reportName={reportName}
          reportConfig={reportConfig}
          params={params}
          paginateLoading={paginateLoading}
        />
        <ReportResults
          reportName={reportName}
          reportConfig={reportConfig}
          params={params}
          paginateLoading={paginateLoading}
          setPaginateLoading={setPaginateLoading}
        />
      </RequiredFilterNotSelectedProvider>
    </PageContainer>
  );
}

function ReportFilterBar({
  reportName,
  reportConfig,
  params,
  paginateLoading,
}: {
  reportName: string;
  reportConfig: ReportConfig;
  params: any;
  paginateLoading: boolean;
}) {
  const {requriedFilterNotSelected} = useRequiredFilterNotSelectedContext();
  return (
    <ToolbarContainer>
      <div
        css={css`
          display: flex;
          gap: 6px;
        `}
      >
        {reportConfig.filters.map((filterConfig) => {
          switch (filterConfig.type) {
            case "date":
              return (
                <DateFilterDropdown
                  filterConfig={filterConfig}
                  params={params}
                />
              );
            case "dropdown":
              return (
                <ListFilterDropdown
                  reportName={reportName}
                  filterConfig={filterConfig}
                  params={params}
                />
              );
            case "policy_number_search":
              return (
                <PolicyNumberFilterCombobox
                  filterConfig={filterConfig}
                  params={params}
                />
              );
            default:
              return null;
          }
        })}
      </div>
      {!requriedFilterNotSelected && (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 3px;
          `}
        >
          {paginateLoading && <LoadingIndicator />}
          <ActionButtons
            reportName={reportName}
            reportConfig={reportConfig}
            params={params}
          />
          <ViewPolicyButton params={params} />
          <DownloadButton reportName={reportName} params={params} />
          <EmailButton reportName={reportName} params={params} />
        </div>
      )}
    </ToolbarContainer>
  );
}

function ActionButtons({
  reportName,
  reportConfig,
  params,
}: {
  reportName: string;
  reportConfig: ReportConfig;
  params: any;
}) {
  const actionMutation = useApiMutation(runReportAction);
  const {notify} = useNotification();
  return (
    <>
      {reportConfig.actions.map((action) => (
        <Button
          background={false}
          size="small"
          isLoading={actionMutation.isLoading}
          onClick={async () => {
            const result = await actionMutation.mutateAsync([
              reportName,
              action.name,
              params,
            ]);
            notify(result.message);
          }}
        >
          {action.title}
        </Button>
      ))}
    </>
  );
}

function ViewPolicyButton({params}: {params: any}) {
  const policyModIds = getPolicyModIdsOptionFromParams(params);
  const {navigate} = useNavigation();
  return policyModIds ? (
    <Button
      background={false}
      size="small"
      onClick={() => navigate(links.policy({policyModIds: policyModIds.value}))}
    >
      View Policy
    </Button>
  ) : null;
}

function ReportResults({
  reportName,
  reportConfig,
  params,
  paginateLoading,
  setPaginateLoading,
}: {
  reportName: string;
  reportConfig: ReportConfig;
  params: any;
  paginateLoading: boolean;
  setPaginateLoading: (loading: boolean) => void;
}) {
  const {requriedFilterNotSelected} = useRequiredFilterNotSelectedContext();
  const reportResultsQuery = useApiQuery(getReportResult, {
    keepPreviousData: true,
    enabled: !requriedFilterNotSelected,
  })(reportName, params);
  useEffect(() => {
    setPaginateLoading(reportResultsQuery.isPreviousData);
  }, [reportResultsQuery.isPreviousData, setPaginateLoading]);
  if (requriedFilterNotSelected) {
    return <div>Please select all required filters</div>;
  }
  return reportResultsQuery.data ? (
    <LoadedReportResults
      reportName={reportName}
      reportConfig={reportConfig}
      reportResults={reportResultsQuery.data}
      params={params}
      paginateLoading={paginateLoading}
    />
  ) : reportResultsQuery.isLoading ? (
    <Loading size="medium" />
  ) : reportResultsQuery.error ? (
    <div>Error</div>
  ) : null;
}

function LoadedReportResults({
  reportName,
  reportConfig,
  reportResults,
  params,
  paginateLoading,
}: {
  reportName: string;
  reportConfig: ReportConfig;
  reportResults: ReportResult;
  params: any;
  paginateLoading: boolean;
}) {
  return reportConfig.html_report ? (
    <Surface>
      <iframe
        title={`Report ${reportName}`}
        src={getReportHtmlUrl(reportName, params)}
        css={css`
          height: calc(100vh - 260px);
          width: 100%;
          border: none;
        `}
      />
    </Surface>
  ) : (
    <ReportResultList
      reportName={reportName}
      reportConfig={reportConfig}
      reportResults={reportResults}
      params={params}
      pagainteLoading={paginateLoading}
    />
  );
}

function ReportResultList({
  reportName,
  reportConfig,
  reportResults,
  params,
  pagainteLoading,
}: {
  reportName: string;
  reportConfig: ReportConfig;
  reportResults: ReportResult;
  params: any;
  pagainteLoading: boolean;
}) {
  const ref = useRef(null);
  const paginatedResult = reportResults.paginated_result;

  if (!paginatedResult) {
    return <p>No results</p>;
  }

  const paginatedResultList = paginatedResult.result;
  const totalCount = paginatedResult.total_count;
  if (paginatedResultList.length === 0) {
    return <p>No {reportConfig.title}</p>;
  }
  return (
    <>
      <Table ref={ref} disabled={pagainteLoading}>
        {reportConfig.columns && (
          <Header>
            {reportConfig.columns.map((column) => (
              <HeaderCell>{column.title}</HeaderCell>
            ))}
          </Header>
        )}
        {paginatedResultList.map((resultListItem: any) => (
          <Body>
            <Row>
              {reportConfig.columns.map((column) =>
                column.policy_link ? (
                  <Cell>
                    <Link
                      to={links.policy({
                        policyModIds: resultListItem[column.name],
                      })}
                    >
                      {resultListItem[column.name].display_policy_number}
                    </Link>
                  </Cell>
                ) : (
                  <Cell
                    style={
                      column.line_break
                        ? {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-line",
                          }
                        : {}
                    }
                  >
                    {resultListItem[column.name]}
                  </Cell>
                )
              )}
            </Row>
          </Body>
        ))}
      </Table>
      <Pagination
        isCurrentPageLoaded={!pagainteLoading}
        scrollOffset={70}
        resultsElementRef={ref}
        pageNumber={params["pageNumber"] || paginatedResult.page_number}
        limit={params["limit"] || paginatedResult.limit}
        totalCount={totalCount}
      />
    </>
  );
}

function DateFilterDropdown({
  filterConfig,
  params,
}: {
  filterConfig: FilterConfig;
  params: any;
}) {
  const {updateSearchParams} = useNavigation();
  const filterName = filterConfig.name;
  const value = params[filterName] || filterConfig.default;
  useRequiredFilterSelection(filterConfig.required, !value);
  return (
    <TextInput
      key={filterName}
      type="date"
      background
      required={filterConfig.required && !value}
      label={filterConfig.title}
      value={value}
      onChange={(e) => {
        updateSearchParams({
          [filterName]: e.target.value,
          pageNumber: 1,
        });
      }}
    />
  );
}

function ListFilterDropdown({
  reportName,
  filterConfig,
  params,
}: {
  reportName: string;
  filterConfig: FilterConfig;
  params: any;
}) {
  const {updateSearchParams} = useNavigation();
  const filterName = filterConfig.name;
  const filterListQuery = useApiQuery(getFilterListResults, {
    enabled: !filterConfig.list,
  })(reportName, filterName);
  const value = params[filterName] || filterConfig.default || "";
  useRequiredFilterSelection(filterConfig.required, !value);
  return (
    <div
      css={css`
        min-width: 150px;
      `}
    >
      <DropDown
        required={filterConfig.required && !value}
        background={true}
        options={[
          {value: "", title: "All"},
          ...(filterConfig.list && Array.isArray(filterConfig.list)
            ? filterConfig.list
            : filterListQuery.data
            ? filterListQuery.data
            : []),
        ]}
        onChange={(value) => {
          updateSearchParams({[filterName]: value, pageNumber: 1});
        }}
        label={filterConfig.title}
        value={value}
      />
    </div>
  );
}

function PolicyNumberFilterCombobox({
  filterConfig,
  params,
}: {
  filterConfig: FilterConfig;
  params: any;
}) {
  const {updateSearchParams, removeParams} = useNavigation();
  const selectedOption = getPolicyModIdsOptionFromParams(params);
  useRequiredFilterSelection(filterConfig.required, !selectedOption);
  return (
    <PolicyNumberCombobox
      label={filterConfig.title}
      required={filterConfig.required && !selectedOption}
      selectedOption={selectedOption}
      setSelectedOption={(option) => {
        if (option?.value)
          updateSearchParams({
            policy_number: option.value.policy_number,
            display_policy_number: option.value.display_policy_number,
            pc_provider_name: option.value.pc_provider_name,
            policy_id: option.value.policy_id,
            company_number: option.value.company_number,
            lob: option.value.lob,
            mod_number: option.value.mod_number,
            pageNumber: 1,
          });
        else {
          removeParams([
            "policy_number",
            "display_policy_number",
            "pc_provider_name",
            "policy_id",
            "company_number",
            "lob",
            "mod_number",
          ]);
        }
      }}
    />
  );
}

function getPolicyModIdsOptionFromParams(params: any) {
  const policyModIdsFieldInParams = [
    "policy_number",
    "display_policy_number",
    "pc_provider_name",
    "company_number",
    "lob",
    "mod_number",
  ]
    .map((key) => key in params)
    .every((v) => v);
  return policyModIdsFieldInParams
    ? {
        title: params["display_policy_number"],
        value: {
          policy_number: params["policy_number"],
          display_policy_number: params["display_policy_number"],
          pc_provider_name: params["pc_provider_name"],
          policy_id: params["policy_id"],
          company_number: params["company_number"],
          lob: params["lob"],
          mod_number: params["mod_number"],
        },
      }
    : undefined;
}

function useRequiredFilterSelection(
  isRequriedFilter: boolean | undefined,
  notSelected: boolean
) {
  const {setRequriedFilterNotSelected} = useRequiredFilterNotSelectedContext();
  useEffect(() => {
    if (isRequriedFilter) {
      if (notSelected) setRequriedFilterNotSelected(true);
      else setRequriedFilterNotSelected(false);
    }
  }, [isRequriedFilter, notSelected, setRequriedFilterNotSelected]);
}
