import {apiUrl, get} from "./common";

export const baseUrl = `${apiUrl}/documents`;

export interface Document {
  file_link: string;
  file_name: string;
}

export async function getAllManuals() {
  return await get<Document[]>(`${baseUrl}/list`);
}

export async function getCommissionStatementsByMonth(
  year: String,
  month: String,
  officeCode: String
) {
  return await get<Document[]>(
    `${baseUrl}/commission_statements_by_month?year=${year}&month=${month}&officeCode=${officeCode}`
  );
}

export async function getAgencyExperienceByMonth(
  year: String,
  month: String,
  officeCode: String
) {
  return await get<Document[]>(
    `${baseUrl}/agency_experience?year=${year}&month=${month}&officeCode=${officeCode}`
  );
}

export async function getFinanceReportsByDateFolder(
  year: String,
  month: String,
  day: String,
  folderName:String
) {
  return await get<Document[]>(
    `${baseUrl}/accounting?year=${year}&month=${month}&day=${day}&folderName=${folderName}`
  );
}
