import {apiUrl, del, get, patch, post} from "./common";
import {PolicyBasicInfo, PolicyIds, PolicyModIds} from "./policy";

const baseUrl = `${apiUrl}/payment`;
const methodsBaseUrl = `${baseUrl}/methods`;

export async function getPolicyPaymentInfo(policyIds: PolicyIds) {
  return await get<PolicyPaymentInfo>(`${baseUrl}/payment_info`, {
    ...policyIds,
  });
}

export async function getPolicyPaymentProviderInfo(companyNumber: string) {
  return await get<PaymentProviderInfo>(`${baseUrl}/provider`, {
    company_number: companyNumber,
  });
}

export interface PolicyPaymentInfo {
  policy_info: PolicyBasicInfo;
  due_mod_number: string | null;
  payment_plan: string;
  due_date: string | null;
  amount_due: number;
  remaining_balance: number;
  is_due_today: boolean | null;
  is_past_due: boolean | null;
  pending_payments_total: number;
  cancellation_date: string | null;
}

export interface PaymentTransactionBasicInfo {
  policy_mod_ids: PolicyModIds;
  transaction_id: string;
  amount: number;
  date: string;
  timezone: string;
  emails: [];
  last_four_digits: string;
  name_holder: string;
  provider_transaction_error_status: string;
  authorized_by_user_info:any;
  recurring:boolean;
  provider_transaction_id:string;
}

export interface PaymentProviderInfo {
  name: string;
  session_id: string;
}

export interface RecurringPayment {
  enabled: boolean;
  receipt_email: string | null;
  payment_method_id: string | null;
  payment_method_description: string | null;
  old_portal_recurring_enabled: LegacyRecurring;
  failed_recurring_transaction: string;
  signup_date: string;
}

export interface LegacyRecurring {
  agent_recurring: boolean;
  insured_recurring: boolean;
}

export enum PaymentMethodBankAccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum PaymentMethodType {
  ACH = "ACH",
  CARD = "CARD",
}

export async function addPaymentMethod(data: {
  policyIds: PolicyIds;
  providerPaymentToken: string;
  paymentMethodType: PaymentMethodType;
  providerPaymentName: string;
  cardZipCode: string;
  cardType: string;
  nameHolder: string;
  cardAuthCode: string;
  bankAccountType: PaymentMethodBankAccountType;
  cardExpirationYear: string;
  cardExpirationMonth: string;
  lastFourDigits: string;
  bankName: string;
}) {
  return await post<string>(methodsBaseUrl, {
    data: {
      policy_ids: {...data.policyIds},
      provider_payment_token: data.providerPaymentToken,
      provider_payment_name: data.providerPaymentName,
      payment_method_type: data.paymentMethodType,
      provider_transaction_id: "provider_transaction_id", // will remove this once backend is fixed
      card_zip_code: data.cardZipCode,
      name_holder: data.nameHolder,
      card_auth_code: data.cardAuthCode,
      card_type: data.cardType,
      bank_account_type: data.bankAccountType,
      card_expiration_year: data.cardExpirationYear,
      card_expiration_month: data.cardExpirationMonth,
      last_four_digits: data.lastFourDigits,
      bank_name: data.bankName,
    },
  });
}

export async function getPaymentTransactions(policyModIds: PolicyModIds) {
  return await get<PaymentTransactionBasicInfo[]>(`${baseUrl}/transactions`, {
    ...policyModIds,
  });
}

export async function addPaymentTransaction(data: {
  policyModIds: PolicyModIds;
  paymentMethodType: string;
  providerPaymentName: string;
  providerTransactionId: string;
  cardZipCode: string;
  cardType: string;
  nameHolder: string;
  cardAuthCode: string;
  bankAccountType: string;
  cardExpirationYear: string;
  cardExpirationMonth: string;
  lastFourDigits: string;
  bankName: string;
  amount: number;
  date: string;
  timezone: string;
  email: string;
}) {
  return await post<string>(`${baseUrl}/transactions`, {
    data: {
      policy_mod_ids: {...data.policyModIds},
      provider_payment_name: data.providerPaymentName,
      payment_method_type: data.paymentMethodType,
      provider_transaction_id: data.providerTransactionId,
      card_zip_code: data.cardZipCode,
      name_holder: data.nameHolder,
      card_auth_code: data.cardAuthCode,
      card_type: data.cardType,
      bank_account_type: data.bankAccountType,
      card_expiration_year: data.cardExpirationYear,
      card_expiration_month: data.cardExpirationMonth,
      last_four_digits: data.lastFourDigits,
      bank_name: data.bankName,
      amount: data.amount,
      date: data.date,
      timezone: data.timezone,
      emails: [data.email],
    },
  });
}

export interface PaymentInfo {
  name: string;
  session_id: string;
  amount: number;
}

export interface PaymentStatus {
  succeeded: boolean;
  message: string;
  transaction_id: string | null;
  provider_transaction_id: string | null;
}

export interface SavedPaymentMethodBasicInfo {
  payment_method_id: string;
  description: string;
  name_holder: string;
  is_set_for_recurring: boolean;
}

export async function getPaymentMethods(policyIds: PolicyIds) {
  return await get<SavedPaymentMethodBasicInfo[]>(methodsBaseUrl, {
    ...policyIds,
  });
}

export async function deletePaymentMethod(paymentMethodId: string) {
  return await del(`${methodsBaseUrl}/${paymentMethodId}`);
}

export async function makePaymentWithSavedMethod({
  paymentMethodId,
  policyIds,
  amount,
  email,
}: {
  paymentMethodId: string;
  policyIds: PolicyIds;
  amount: number;
  email: string;
}) {
  return await post<PaymentStatus>(`${methodsBaseUrl}/${paymentMethodId}/pay`, {
    data: {
      policy_ids: {...policyIds},
      amount: amount,
      emails: [email],
    },
  });
}

export async function getRecurringPayment(policyIds: PolicyIds) {
  return await get<RecurringPayment>(
    `${baseUrl}/recurring/${policyIds.display_policy_number}`,
    {...policyIds}
  );
}

export async function addRecurringPayment(
  policyIds: PolicyIds,
  paymentMethodId: string,
  receiptEmail: string
) {
  return await post<boolean>(`${baseUrl}/recurring`, {
    data: {
      policy_ids: {...policyIds},
      payment_method_id: paymentMethodId,
      receipt_email: receiptEmail,
    },
  });
}

export async function updateRecurringPayment(
  policyIds: PolicyIds,
  paymentMethodId: string,
  receiptEmail: string
) {
  return await patch<boolean>(`${baseUrl}/recurring`, {
    data: {
      policy_ids: {...policyIds},
      payment_method_id: paymentMethodId,
      receipt_email: receiptEmail,
    },
  });
}

export async function removeRecurringPayment(policyIds: PolicyIds) {
  return await del<boolean>(`${baseUrl}/recurring`, {...policyIds});
}

export const ONEINC_PAYMENT_PROVIDER_NAME = "oneinc";
