/** @jsxImportSource @emotion/react */

import {Switch, Route, useLocation, Redirect} from "react-router-dom";
import LoginPage from "pages/Login";
import LoadingScreen from "components/LoadingScreen";
import GlobalCss from "../GlobalCss";
import PageRoute from "./PageRoute";
import pagesConfig from "routes";
import {useAuth} from "hooks/auth";
import {AppBoundary} from "./AppBoundary";
import SelectAgency from "pages/SelectAgency";
import ErrorScreen from "components/ErrorScreen";
import {H1} from "components/elements";
import {Toaster} from "react-hot-toast";

function App() {
  const {isLoggedIn, authSessionLoading} = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnUrl = searchParams.get("returnUrl");
  const currentUrl = encodeURIComponent(
    `${location.pathname}${location.hash}${location.search}`
  );

  return (
    <>
      <Toaster />
      <GlobalCss />
      {authSessionLoading ? (
        <LoadingScreen />
      ) : (
        <AppBoundary currentUrl={currentUrl}>
          <Switch>
            {!isLoggedIn && (
              <Route path="/login" exact>
                <LoginPage />
              </Route>
            )}
            {isLoggedIn && (
              <Route path="/selectAgency" exact>
                <SelectAgency />
              </Route>
            )}
            {returnUrl && (
              <Route>
                <Redirect to={returnUrl} />
              </Route>
            )}
            {pagesConfig.map((page) => (
              <Route
                key={page.path}
                path={page.path}
                exact={page.exact !== undefined ? page.exact : true}
              >
                <PageRoute
                  page={page}
                  searchParams={searchParams}
                  isLoggedIn={isLoggedIn}
                  currentUrl={currentUrl}
                />
              </Route>
            ))}
            <Route>
              <ErrorScreen>
                <H1>404 - Page not found</H1>
              </ErrorScreen>
            </Route>
          </Switch>
        </AppBoundary>
      )}
    </>
  );
}

export default App;
