import {QuoteForm} from "api/quote";
import {Form} from "pages/RateQuoteBind/RatePanel";
import HabitationalCoverageForm from "../shared/HabitationalCoverageForm";
import HabitationalGeneralLiability from "../shared/HabitationalGeneralLiability";
import UnderwritingForm from "../shared/UnderwritingForm";
import HabitationalLocationForm from "../shared/HabitationalLocationForm";

export default function habitational(quoteForm: QuoteForm): Form {
  return {
    sections: {
      "Risk Location": (
        <HabitationalLocationForm
          locations={quoteForm.locations}
          quoteId={`${quoteForm.quote_id}`}
        />
      ),
      "Coverage Options": (
        <HabitationalCoverageForm habitational={quoteForm.habitational} />
      ),
      "General Liability": (
        <HabitationalGeneralLiability habitational={quoteForm.habitational} />
      ),
    },
    "Underwriting Questions": (
      <UnderwritingForm
        underwriting={quoteForm.underwriting}
        habitational={quoteForm.habitational}
      />
    ),
  };
}
