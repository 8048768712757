import {
  Location,
  Building,
  QuoteTypes,
  getQuote,
  getMixedMercantileUnitOccupancyTypes,
} from "api/quote";
import {Box} from "components/Box";
import {GridContainer, GridItem} from "components/Grid";
import {formatAmount} from "utils/format";
import Field from "../../Field";
import {
  causeOfLoss,
  coinsurancePercents,
  constructionTypes,
  deductables,
} from "../../fieldValues";
import LocationsForm, {BuildingForm} from "../shared/LocationsForm";
import {useMutation, useQueryClient} from "react-query";
import {useQuoteContext} from "pages/RateQuoteBind/contexts/quoteContext";
import {ParamType} from "api/common";
import {useCallback} from "react";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import Loading from "components/Loading";
import {useApiQuery} from "hooks/api";
import { windHailOptions } from "../shared/HabitationalLocationForm";
const isBuildingSprinklered = [
  {label: "Fully Sprinklered", value: "Y"},
  {label: "Not Sprinklered", value: "N"},
  {label: "Partially Sprinklered", value: "P"},
];

const buildingValuation = [
  {label: "Replacement Cost", value: "RC"},
  {label: "Actual Cash Value", value: "ACV"},
];

const mercantileClassCodes = [
  {
    label:
      "61217 Buildings or Premises - bank or office - mercantile or manufacturing (lessor's risk only) - maintained by the insured - Other than Not-For-Profit  ",
    value: "61217",
    selectedTitle: "61217 Buildings or Premises",
  },
  {label: "Other", value: "other"},
];

function MixedMercantileBuildingForm({
  location,
  building,
  quoteType,
}: {
  location: Location;
  building: Building;
  quoteType: QuoteTypes;
}) {
  const queryClient = useQueryClient();
  const {add, quoteId} = useQuoteContext();

  const {mutateAsync} = useMutation(
    async (props: {objectName: string; params?: ParamType}) => add(props),
    {
      onSuccess: () =>
        queryClient.prefetchQuery([getQuote.name, quoteId.toString()], () =>
          getQuote(quoteId.toString())
        ),
    }
  );

  const handleSelect = useCallback(
    async (e: any) => {
      const selectedValue = e.target.defaultValue;

      await mutateAsync({
        objectName: "mixed_mercantile_unit",
        params: {
          mixed_mercantile_building_id: String(
            building.mixed_mercantile_building.id
          ),
          number_of_mercantile_units: selectedValue,
        },
      });
    },
    [mutateAsync, building.mixed_mercantile_building.id]
  );

  const occupancyTypesQuery = useApiQuery(
    getMixedMercantileUnitOccupancyTypes
  )();

  return (
    <>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={constructionTypes.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"construction_type"}
          label="Construction type"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={isBuildingSprinklered.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"is_building_sprinklered"}
          label="Is Building Sprinklered"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.mixed_mercantile_building!}
          field={"total_building_square_footage"}
          label="Total building square footage"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.mixed_mercantile_building!}
          field={"apartment_condo_number_of_units"}
          label="Apartment/Condo - Number of units"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          type="number"
          object={building.mixed_mercantile_building!}
          field={"year_built"}
          label="Year built"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          prefix="$"
          object={building.mixed_mercantile_building!}
          field={"building_limit"}
          label="Building limit"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        {location.residential_unit_type !==
          "residential_condominium_association" && (
          <Field.TextInput
            prefix="$"
            object={building.mixed_mercantile_building!}
            field={"business_income"}
            label="Business Income"
            isFormattedNumber
            wrapperProps={{
              growXXS: 12,
              growMD: 6,
            }}
          />
        )}
        <Field.DropDown
          options={deductables.map((item) => ({
            title: formatAmount(item),
            value: item,
          }))}
          object={building.mixed_mercantile_building!}
          field={"deductible"}
          label="Deductible"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.mixed_mercantile_building!}
          field={"number_of_stories"}
          label="Number Of Stories"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={buildingValuation.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"building_valuation"}
          label="Building Valuation"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={causeOfLoss.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"cause_of_loss"}
          label="Cause Of Loss"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          options={coinsurancePercents.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          field={"coinsurance_percent"}
          label="Coinsurance"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={location}
          options={mercantileClassCodes.map((item) => ({
            title: item.label,
            value: item.value,
            selectedTitle: item.selectedTitle,
          }))}
          field={"mercantile_exposure_class_code"}
          label="Select Mercantile Exposure Class Code"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.mixed_mercantile_building!}
          field={"mercantile_total_square_footage"}
          label="Mercantile Total Square Footage"
          isFormattedNumber
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={building.mixed_mercantile_building!}
          field={"number_of_mercantile_units"}
          label="Number of Mercantile Units"
          onBlurCapture={handleSelect}
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
      </GridContainer>
      <Box mx={32} pb={32}>
        <GridContainer rowGap={40}>
          {building.mixed_mercantile_building.mixed_mercantile_units &&
          occupancyTypesQuery.data &&
          building.mixed_mercantile_building.mixed_mercantile_units.length ===
            Number(
              building.mixed_mercantile_building.number_of_mercantile_units
            ) ? (
            building.mixed_mercantile_building.mixed_mercantile_units.map(
              (unit, index) => (
                <GridItem>
                  <Box mb={10}>
                    <FormTitle.MiniSubHeader>
                      Mercantile Unit {index + 1}
                    </FormTitle.MiniSubHeader>
                  </Box>
                  <Box width="700px">
                    <Field.DropDown
                      size="large"
                      object={unit}
                      options={occupancyTypesQuery.data.map((type) => ({
                        title: type.class_description,
                        value: type.class_description,
                      }))}
                      field={"occupancy_type"}
                      label="Mercantile Occupancy type"
                    />
                  </Box>
                </GridItem>
              )
            )
          ) : (
            <>
              <Loading size="small" />;
            </>
          )}
        </GridContainer>
      </Box>
      <Field.CheckBox
        label="Manual Wind/Hail Deductible - Field Only Available for AE Internal - UW"
        object={building.mixed_mercantile_building!}
        field={"manual_wind_hail_coverage_flag"}
        wrapperProps={{
          grow: 12,
          style: {
            backgroundColor: "rgba(255, 255, 125)",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            borderBottomLeftRadius: !building.mixed_mercantile_building!
              .manual_wind_hail_coverage_flag
              ? "6px"
              : "0px",
            borderBottomRightRadius: !building.mixed_mercantile_building!
              .manual_wind_hail_coverage_flag
              ? "6px"
              : "0px",
          },
        }}
      />
      {building.mixed_mercantile_building!.manual_wind_hail_coverage_flag && (
        <Field.DropDown
          object={building.mixed_mercantile_building!}
          field={"manual_wind_hail_coverage_percent"}
          label="Percentage"
          options={windHailOptions.map((option) => ({
            title: option.label,
            value: option.value,
          }))}
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
            style: {
              backgroundColor: "rgba(255, 255, 125)",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
            },
          }}
        />
      )}
      {location.buildings.length > 1 ? (
        <Box mb={16}>
          <Field.RadioGroup
            object={building}
            field="building_within_100_ft_of_another"
            label="Is this building within 100 ft of another building in this location"
            options={[
              {title: "Yes", value: 1},
              {title: "No", value: 0},
            ]}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default function MixedMercantileLocationsForm({
  locations,
  quoteId,
  quoteType = QuoteTypes.HAB,
}: {
  locations: Location[];
  quoteId: any;
  quoteType?: QuoteTypes;
}) {
  return (
    <LocationsForm
      quoteId={quoteId}
      locations={locations}
      quoteType={quoteType}
    >
      {({locationId, location, buildings}) => (
        <>
          <Box mt={16}>
            <GridContainer rowGap={24} columnGap={16}>
              <Field.DropDown
                object={location}
                options={[
                  {title: "Apartment Building", value: "apartment_building"},
                  {
                    title: "Residential Condominium Association",
                    value: "residential_condominium_association",
                  },
                ]}
                field={"residential_unit_type"}
                label="Type of Residential Units"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                }}
              />
            </GridContainer>
            {locations.length > 1 && (
              <Box mt={24}>
                <Field.RadioGroup
                  object={location}
                  field="building_within_100_ft_of_another"
                  label="Are any buildings within 100 ft of another building in a different location?"
                  options={[
                    {title: "Yes", value: 1},
                    {title: "No", value: 0},
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box mt={20}>
            <BuildingForm
              locationId={location.id}
              location={location}
              buildings={location.buildings}
            >
              {({locationId, location, building}) => (
                <MixedMercantileBuildingForm
                  location={location}
                  building={building}
                  quoteType={quoteType}
                />
              )}
            </BuildingForm>
          </Box>
        </>
      )}
    </LocationsForm>
  );
}
