/** @jsxImportSource @emotion/react */

import {useApiQuery} from "hooks/api";
import {Anchor} from "components/elements";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import {getQuoteList, QuoteListItem, QuoteTypes, searchQuotes} from "api/quote";
import {useNavigation} from "hooks/navigation";
import {
  formatAmount,
  formatDate,
  formatDateTime,
  formatQuoteType,
} from "utils/format";
import FilterBar from "./FilterBar";
import {useRef} from "react";
import Loading from "components/Loading";
import {getUrl} from "utils/url";
import StatusBadge from "components/StatusBadge";
import Pagination from "components/Pagination";
import {LinkTab, LinkTabList} from "components/Tabs";
import {usePermissions} from "hooks/auth";
import {permissionsContains} from "utils/auth";
import {Permissions} from "api/auth";
import {PageContainer} from "components/listPageUtils";
import {useSearchDebounce} from "hooks/debounce";

function QuoteList({
  searchQuery = "",
  agentSearchQuery = "",
  statusFilter = "",
  underwriterFilter = "",
  stateFilter = "",
  quoteTypeFilter = "",
  ownerFilter = "",
  limit,
  pageNumber,
}: {
  searchQuery?: string;
  agentSearchQuery?: string;
  statusFilter?: string;
  underwriterFilter?: string;
  stateFilter?: string;
  quoteTypeFilter?: string;
  ownerFilter?: string;
  limit: number;
  pageNumber: number;
}) {
  const quoteListQuery = useApiQuery(getQuoteList, {keepPreviousData: true})(
    limit.toString(),
    pageNumber.toString()
  );
  const searchResultsQuery = useApiQuery(searchQuotes, {
    keepPreviousData: true,
    enabled: false,
  })(
    limit.toString(),
    pageNumber.toString(),
    searchQuery,
    agentSearchQuery,
    statusFilter,
    underwriterFilter,
    stateFilter,
    quoteTypeFilter,
    ownerFilter
  );

  const search = useSearchDebounce({
    enabled: !!(
      searchQuery ||
      agentSearchQuery ||
      statusFilter ||
      underwriterFilter ||
      stateFilter ||
      quoteTypeFilter ||
      ownerFilter
    ),
    deps: [pageNumber],
    fetch: searchResultsQuery.refetch,
  });

  const {getMergedUrl} = useNavigation();
  const permissions = usePermissions();

  return (
    <PageContainer>
      {permissionsContains(permissions, "LEGACY") && (
        <LinkTabList primary={true} large={false}>
          <LinkTab
            to={
              permissions.includes(Permissions.LEGACY_HAB_QUOTES)
                ? process.env.REACT_APP_EXISTING_HABITATIONAL_QUOTES_LINK || ""
                : getMergedUrl({})
            }
          >
            Habitational
            {permissions.includes(Permissions.CREATE_MIXED_MERCANTILE_QUOTE)
              ? " / Mixed Mercantile"
              : ""}{" "}
          </LinkTab>
          <LinkTab to={process.env.REACT_APP_EXISTING_SKP_QUOTES_LINK || ""}>
            SKP & BOP
          </LinkTab>
          <LinkTab
            to={process.env.REACT_APP_EXISTING_HOMEOWNER_QUOTES_LINK || ""}
          >
            Homeowners
          </LinkTab>
        </LinkTabList>
      )}
      {!permissions.includes(Permissions.LEGACY_HAB_QUOTES) && (
        <>
          <FilterBar
            searchQuery={searchQuery}
            agentSearchQuery={agentSearchQuery}
            statusFilter={statusFilter}
            underwriterFilter={underwriterFilter}
            stateFilter={stateFilter}
            quoteTypeFilter={quoteTypeFilter}
            ownerFilter={ownerFilter}
            paginateLoading={
              quoteListQuery.isPreviousData || searchResultsQuery.isPreviousData
            }
            fetchSearch={search}
          />
          {searchQuery ||
          agentSearchQuery ||
          statusFilter ||
          underwriterFilter ||
          stateFilter ||
          quoteTypeFilter ||
          ownerFilter ? (
            searchResultsQuery.data ? (
              searchResultsQuery.data.result.length > 0 ? (
                <QuoteTable
                  quoteList={searchResultsQuery.data.result}
                  isCurrentPageLoaded={!searchResultsQuery.isPreviousData}
                  totalCount={searchResultsQuery.data.total_count}
                  limit={limit}
                  pageNumber={pageNumber}
                />
              ) : (
                "No results found"
              )
            ) : searchResultsQuery.isError ? (
              "Error"
            ) : (
              <Loading size="medium" />
            )
          ) : quoteListQuery.data ? (
            <QuoteTable
              quoteList={quoteListQuery.data.result}
              isCurrentPageLoaded={!quoteListQuery.isPreviousData}
              totalCount={quoteListQuery.data.total_count}
              limit={limit}
              pageNumber={pageNumber}
            />
          ) : quoteListQuery.error ? (
            "Error"
          ) : (
            <Loading size="medium" />
          )}
        </>
      )}
    </PageContainer>
  );
}

function QuoteTable({
  quoteList,
  isCurrentPageLoaded,
  totalCount,
  limit,
  pageNumber,
}: {
  quoteList: QuoteListItem[];
  isCurrentPageLoaded: boolean;
  totalCount: number;
  limit: number;
  pageNumber: number;
}) {
  const tableElementref = useRef<HTMLDivElement>(null);
  return (
    <>
      <Table ref={tableElementref} disabled={!isCurrentPageLoaded}>
        <Header>
          <HeaderCell>Quote Number</HeaderCell>
          <HeaderCell>Quote Type</HeaderCell>
          <HeaderCell>Insured Name</HeaderCell>
          <HeaderCell>Premium Amount</HeaderCell>
          <HeaderCell>Effective Dates</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Agency</HeaderCell>
          <HeaderCell>State</HeaderCell>
          <HeaderCell>Last Updated</HeaderCell>
          <HeaderCell></HeaderCell>
        </Header>

        {quoteList.map((quoteItem, index) => (
          <QuoteListItemRow quote={quoteItem} key={index} />
        ))}
      </Table>
      <Pagination
        isCurrentPageLoaded={isCurrentPageLoaded}
        scrollOffset={70}
        resultsElementRef={tableElementref}
        pageNumber={pageNumber}
        limit={limit}
        totalCount={totalCount}
      />
    </>
  );
}

function QuoteListItemRow({quote}: {quote: QuoteListItem}) {
  const {navigate} = useNavigation();
  const quoteUrl = getUrl({
    path: `/rate-quote-bind/${quote.id}`,
    searchParamsObject: {
      backendDisplayQuoteNumber: quote.backend_display_quote_number,
    },
  });
  return (
    <Body>
      <Row onClick={() => navigate(quoteUrl)}>
        <Cell>
          <Anchor href={quoteUrl} onClick={(e) => e.preventDefault()}>
            {quote.backend_display_quote_number || quote.backend_quote_id}
          </Anchor>
        </Cell>
        <Cell>{formatQuoteType(quote.quote_type as QuoteTypes)}</Cell>
        <Cell
          style={{
            maxWidth: "250px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={quote.insured_name}
        >
          {quote.insured_name}
        </Cell>
        <Cell>
          {quote.rate_indication ? formatAmount(quote.rate_indication) : ""}
        </Cell>
        <Cell>
          {quote.effective_date ? formatDate(quote.effective_date) : ""}
        </Cell>
        <Cell>
          <StatusBadge value={quote.status} />
        </Cell>
        <Cell>{quote.agency_name}</Cell>
        <Cell>{quote.state}</Cell>
        <Cell>{formatDateTime(quote.updated_at, false)}</Cell>
        <Cell></Cell>
      </Row>
    </Body>
  );
}

export default QuoteList;
