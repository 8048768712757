import {RecurringPayment} from "api/payment";
import {PolicyBasicInfo, PolicyStatuses} from "api/policy";
import {Permissions} from "api/auth";

export function legacyRecurringPaymentsExist(
  recurringPayment: RecurringPayment | undefined
) {
  let legacyPaymentsExist = false;
  let bothLegacyPortalsHaveRecurringPayments = false;
  let agentRecurringHasRecurringPayments = false;
  let insuredRecurringHasRecurringPayments = false;
  if (recurringPayment) {
    legacyPaymentsExist =
      recurringPayment.old_portal_recurring_enabled.agent_recurring ||
      recurringPayment.old_portal_recurring_enabled.insured_recurring;
    bothLegacyPortalsHaveRecurringPayments =
      recurringPayment.old_portal_recurring_enabled.agent_recurring &&
      recurringPayment.old_portal_recurring_enabled.insured_recurring;
    agentRecurringHasRecurringPayments =
      recurringPayment.old_portal_recurring_enabled.agent_recurring;
    insuredRecurringHasRecurringPayments =
      recurringPayment.old_portal_recurring_enabled.insured_recurring;
  }

  return {
    legacyPaymentsExist,
    bothLegacyPortalsHaveRecurringPayments,
    agentRecurringHasRecurringPayments,
    insuredRecurringHasRecurringPayments,
  };
}

export function isPaymentOnCancelledPolicyWithoutPermission(
  policy: PolicyBasicInfo,
  permissions: Permissions[]
) {
  return (
    policy.status === PolicyStatuses.CANCELLED &&
    !permissions.includes(Permissions.MAKE_PAYMENT_ON_CANCELLED_POICY)
  );
}
