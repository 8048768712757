/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {FieldRuleResults, RuleResultType} from "api/quote";
import Button from "components/Button";
import {usePermissions} from "hooks/auth";
import {useMemo} from "react";
import {Permissions} from "api/auth";
import {ruleResultsToList} from ".";

function DeclinedContent({
  fieldRuleResults,
  unsubmittingQuote,
  submittingReview,
  onSubmitReview,
  onUnSubmitQuote,
}: {
  fieldRuleResults: FieldRuleResults;
  unsubmittingQuote: boolean;
  submittingReview: boolean;
  onSubmitReview: (approve: boolean) => void;
  onUnSubmitQuote: () => void;
}) {
  const permissions = usePermissions();
  const declinations = useMemo(
    () => ruleResultsToList(fieldRuleResults, RuleResultType.DECLINATION),
    [fieldRuleResults]
  );

  return (
    <>
      {permissions.includes(Permissions.UNSUBMIT_QUOTE_ALWAYS) ? (
        <Button
          size="large"
          isLoading={unsubmittingQuote}
          css={css`
            margin-bottom: 20px;
          `}
          onClick={() => onUnSubmitQuote()}
        >
          Unsubmit Quote
        </Button>
      ) : (
        <></>
      )}

      {/* Main Part */}
      <div
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          min-height: 300px;
          width: 600px;
          background: ${theme.color.brand.tertiary};
          border-radius: 8px;
          padding: 40px 32px;
        `}
      >
        <h2
          css={(theme) =>
            css`
              ${theme.text.header1}
              margin-top: 0px;
              text-align: center;
            `
          }
        >
          {declinations.length
            ? "This submission was declined for the following reason"
            : "This risk was declined."}
        </h2>
        {declinations.length ? (
          <div
            css={(theme) =>
              css`
                ${theme.text.p2}
                margin: 20px 0px 40px;
                border-radius: 8px;
                min-height: 100px;
                max-height: 300px;
                background: white;
                width: 100%;
                display: flex;
                text-align: center;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                overflow-y: auto;
              `
            }
          >
            {declinations.map((declination, index) => (
              <div key={index}>{declination.message}</div>
            ))}
          </div>
        ) : (
          <></>
        )}
        {permissions.includes(Permissions.UNDERWRITE_QUOTE) && (
          <Button
            isLoading={submittingReview}
            onClick={() => onSubmitReview(true)}
            fullwidth
          >
            Approve Quote
          </Button>
        )}
      </div>
    </>
  );
}

export default DeclinedContent;
