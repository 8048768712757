import {
  getAuthSession,
  getUnderwritingStatesAndCompanies,
  login,
  logout,
  setAgency,
} from "api/auth";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useApiQuery} from "./api";

interface Creds {
  username: string;
  password: string;
}

export function useAuth() {
  const queryClient = useQueryClient();
  const authSessionQuery = useQuery("auth_session", getAuthSession, {
    useErrorBoundary: true,
    refetchInterval: 60000,
  });
  const loginMutation = useMutation(
    (creds: Creds) => login(creds.username, creds.password),
    {
      onSuccess: () => queryClient.invalidateQueries("auth_session"),
    }
  );
  const logoutMutation = useMutation(logout, {
    onSuccess: () => {
      queryClient.setQueryData("auth_session", null);
      queryClient.removeQueries();
    },
  });
  const setAgencyMutation = useMutation(setAgency, {
    onSuccess: () => {
      queryClient.clear();
      return queryClient.invalidateQueries("auth_session");
    },
  });
  const getUnderwritingStatesAndCompaniesQuery = useApiQuery(
    getUnderwritingStatesAndCompanies,
    {enabled: !!authSessionQuery.data}
  )();
  return {
    authSessionLoading: authSessionQuery.isLoading,
    isLoggingIn: loginMutation.isLoading,
    isLoggingOut: logoutMutation.isLoading,
    login: loginMutation.mutateAsync,
    isloggingOut: logoutMutation.isLoading,
    logout: logoutMutation.mutateAsync,
    authSession: authSessionQuery.data,
    isLoggedIn: !!authSessionQuery.data?.user_id,
    agencyId: authSessionQuery.data && authSessionQuery.data.agency_id,
    redirectToSelectAgency:
      authSessionQuery.data &&
      authSessionQuery.data.has_multiple_agencies &&
      !authSessionQuery.data.agency_id,
    setAgency: (agencyId?: string | null) =>
      setAgencyMutation.mutateAsync(agencyId),
    setAgencyLoading: setAgencyMutation.isLoading,
    assignedStates: getUnderwritingStatesAndCompaniesQuery.data?.states || [],
    assignedCompanies:
      getUnderwritingStatesAndCompaniesQuery.data?.companies || [],
  };
}

export function useUser() {
  const {authSession} = useAuth();
  if (!authSession?.user_fullname || !authSession.user_id) {
    throw Error("this hook can only be mounted in authenticated scope");
  }
  return {
    userId: authSession.user_id,
    userFullName: authSession.user_fullname,
    userEmail: authSession.user_email,
    hasMultipleAgencies: authSession.has_multiple_agencies,
    agencyId: authSession.agency_id,
    agencyFullname: authSession.agency_fullname,
    agencyOfficeCode: authSession.agency_office_code,
    isUnderwriter: authSession.is_underwriter,
  };
}

export function usePermissions() {
  const {authSession} = useAuth();
  if (!authSession) {
    return [];
  }
  return authSession.permissions;
}
