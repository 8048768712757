import {ReactNode, Suspense} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {QueryErrorResetBoundary} from "react-query";
import LoadingScreen from "components/LoadingScreen";
import {createLog} from "api/log";
import ErrorScreen from "components/ErrorScreen";

export function AppBoundary({
  currentUrl,
  children,
}: {
  currentUrl: string;
  children: ReactNode;
}) {
  return (
    <QueryErrorResetBoundary>
      {({reset}) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({error, resetErrorBoundary}) => {
            // ToDo: I think we need separate pages for 4xx and 5xx errors.
            createLog(error.stack ?? error.message, currentUrl);

            return (
              <ErrorScreen onReset={resetErrorBoundary}>
                Something went wrong.
              </ErrorScreen>
            );
          }}
        >
          <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
