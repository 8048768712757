import {Habitationial, Skp, Underwriting} from "api/quote";
import {Box} from "components/Box";
import FormTitle from "../components/FormTitle";
import {DoesApplicantEngagInAnyFollowingOperationsActivitiesModal} from "../RatePanel/forms/skp/UnderwritingForm";
import InfoRow from "./InfoRow";

function UnderwritingInfo({
  underwriting,
  habitational,
  skp,
}: {
  underwriting?: Underwriting;
  habitational?: Habitationial;
  skp?: Skp;
}) {
  if (!underwriting) return <></>;
  if (habitational) {
    return (
      <Box mb={100}>
        <FormTitle.Header>Underwriting Questions</FormTitle.Header>
        <InfoRow
          object={underwriting.has_prior_insurance}
          label="Has this building(s) been under current ownership for the past three years with prior insurance?"
          labelGrow={8}
          boolean
        />
        {!underwriting.has_prior_insurance ? (
          <InfoRow
            object={underwriting.is_this_new_purchase}
            label="Is this a new purchase?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.is_outstanding_violation}
          label="Are there any outstanding building violations?"
          labelGrow={8}
          boolean
        />

        {habitational &&
        new Date().getFullYear() -
          habitational.habitational_buildings[0].year_built >
          25 ? (
          <InfoRow
            object={underwriting.is_roof_old}
            label="Was the roof, plumbing, wiring, and heating updated within the last 25 years?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.has_electric_panels}
          label="Does the building have knob and tube wiring, aluminum wiring or Federal pacific/Stab-Lok/Challenger/Zinsco/Pushmatic electrical panels?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.with_obvious_defects}
          label="For all buildings, are steps, walkways and parking lots free of any obvious defects?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.with_local_codes}
          label="Are the premises in compliance with local codes?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.been_crime}
          label="During the last five years has any applicant been indicted for or convicted of any degree of crime?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.had_judgement}
          label="Has the applicant had a foreclosure, repossession, bankruptcy, judgment, or lien during the last five years?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.none_policy_coverage}
          label="Has any policy coverage been declined, cancelled or non-renewed during the past three years?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.had_loss}
          label="Where any losses for the object(s) reported over the last three years?"
          labelGrow={8}
          boolean
        />
        {underwriting.had_loss ? (
          <InfoRow
            object={underwriting.loss_description}
            label="Please explain the circumstance of the loss(es)"
            labelGrow={8}
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.have_AEIG}
          label="Does the applicant have any insurance with American European Insurance Group or its affiliated companies?"
          labelGrow={8}
          boolean
        />
        {underwriting.have_AEIG ? (
          <InfoRow
            object={underwriting.policy_number}
            label="Please indicate the policy numbers"
            labelGrow={8}
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.is_occupied}
          label="Are the premises fully occupied?"
          labelGrow={8}
          boolean
        />
        {!underwriting.is_occupied ? (
          <InfoRow
            object={underwriting.vacant_units}
            label="How many units are vacant?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.has_fuel_tank}
          label="Is there a fuel tank?"
          labelGrow={8}
          boolean
        />
        {underwriting.has_fuel_tank ? (
          <InfoRow
            object={underwriting.fuel_tank_location}
            label="Where is the fuel tank?"
            labelGrow={8}
          />
        ) : (
          <></>
        )}
        <InfoRow
          object={underwriting.require_signed_lease}
          label="Does applicant require a signed lease with all tenants for at least 6 months?"
          labelGrow={8}
          boolean
        />
        {underwriting.require_signed_lease ? (
          <>
            <InfoRow
              object={underwriting.require_liability_insurance}
              label="Do the leases require tenants to carry liability insurance?"
              labelGrow={8}
              boolean
            />

            <InfoRow
              object={underwriting.require_500k}
              label="Are the occurrence limits of the required liability insurance at least $500,000?"
              labelGrow={8}
              boolean
            />

            <InfoRow
              object={underwriting.require_renewal_certificates}
              label="Does the applicant maintain Tenant Certificate of Insurance on file and require yearly renewal certificates?"
              labelGrow={8}
              boolean
            />

            <InfoRow
              object={underwriting.is_harmless_for_owner}
              label="Is there hold harmless or indemnification language in the lease in favor of the tenant or the applicant (building owner)?"
              labelGrow={8}
              boolean
            />
            {underwriting.is_harmless_for_owner ? (
              <InfoRow
                object={underwriting.type_of_hold}
                label="Type of hold"
                labelGrow={8}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.has_policy_prohibiting_pets}
          label="Does the applicant have a policy prohibiting pets?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.have_students}
          label="Is more than 5% of the building occupied by students or subsidized tenants?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.has_trampoline}
          label="Do any of the buildings have a trampoline?"
          labelGrow={8}
          boolean
        />
        <InfoRow
          object={underwriting.has_swimming_pool}
          label="Do any of the buildings have a swimming pool?"
          labelGrow={8}
          boolean
        />
        {underwriting.has_swimming_pool ? (
          <InfoRow
            object={
              underwriting.swimming_pool_has_diving_board_or_sliding_board
            }
            label="Does the swimming pool have a diving board or sliding board?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}

        {underwriting.has_swimming_pool ? (
          <InfoRow
            object={underwriting.swimming_pool_completely_fenced}
            label="Is the swimming pool completely fenced?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.is_entrance_secured}
          label="Are the building entrances secured and do they have exterior lighting?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.have_handrail}
          label="Do all stairways with more than two risers have a handrail?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.have_smoke_detector}
          label="Is the building equipped with smoke and carbon  monoxide detectors?"
          labelGrow={8}
          boolean
        />

        <InfoRow
          object={underwriting.have_parking}
          label="Do any of the locations have a parking lot?"
          labelGrow={8}
          boolean
        />
        {underwriting.have_parking ? (
          <InfoRow
            object={underwriting.number_of_parking}
            label="How many parking spaces are there?"
            labelGrow={8}
          />
        ) : (
          <></>
        )}

        <InfoRow
          object={underwriting.snow_removal_person}
          label="Who is responsible for snow  and ice removal?"
          labelGrow={8}
        />
        {underwriting.snow_removal_person === "applicant" ? (
          <InfoRow
            object={underwriting.applicant_do_document}
            label="Does the applicant document who is responsible and when surface have been plowed, treated etc.?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}
        {underwriting.snow_removal_person === "vendor" ? (
          <InfoRow
            object={underwriting.vendor_liability_snow_removal}
            label="Does the contractor carry a minimum of $1,000,000 in liability, indemnify the Applicant in the snow removal contract and add the Applicant as as an Additional Insured on the contractors' policy?"
            labelGrow={8}
            boolean
          />
        ) : (
          <></>
        )}
      </Box>
    );
  }
  if (skp) {
    return (
      <Box mb={100}>
        <FormTitle.Header>Underwriting Questions</FormTitle.Header>

        <InfoRow
          object={underwriting.annual_gross_receipts}
          label="What are your annual gross receipts?"
        />
        <InfoRow
          object={underwriting.gross_annual_payroll}
          label="What is the gross annual payroll (including compensation to owners and partners)?"
        />
        <InfoRow
          object={underwriting.applicant_subcontractor_cost_of_total_receipts}
          label="What is the Applicant subcontractor cost of total receipts?"
        />
        <InfoRow
          object={underwriting.have_atleast_3_years_in_the_trade}
          label="Does the applicant have at least three years of experience in the trade applicant is requesting coverage for?"
          boolean
        />
        <InfoRow
          object={underwriting.applicant_required_license_in_jurisdictions}
          label="Are license(s) required in the jurisdictions where the applicant performs work?"
          boolean
        />
        {underwriting.applicant_required_license_in_jurisdictions && (
          <InfoRow
            object={underwriting.applicant_have_required_license}
            label="Does the applicant have the required license(s) in those jurisdictions?"
            boolean
          />
        )}
        <InfoRow
          object={
            underwriting.perform_work_related_to_the_american_disabilities_act
          }
          label="Does the applicant perform work related to the American Disabilities Act involving installation or repair of chair lifts?"
          boolean
        />
        <InfoRow
          object={underwriting.work_related_to_any_of_the_following}
          label={<DoesApplicantEngagInAnyFollowingOperationsActivitiesModal />}
          boolean
        />
        <InfoRow
          object={underwriting.engage_in_exterior_work_3_stories}
          label="Does the business engage in exterior work involving more than 3 stories?"
          boolean
        />
        <InfoRow
          object={underwriting.perform_snow_ice_removal}
          label="Does Applicant perform snow and/or ice removal?"
          boolean
        />
        {underwriting.perform_snow_ice_removal && (
          <InfoRow
            object={underwriting.separate_liability_policy_another_carrier}
            label="For applicants performing snow and/ or ice removal, does the applicant have a separate liability policy with another carrier?"
            boolean
          />
        )}
        {underwriting.separate_liability_policy_another_carrier && (
          <InfoRow
            object={underwriting.separate_liability_policy_another_carrier}
            label="Carrier Name"
          />
        )}
        {underwriting.separate_liability_policy_another_carrier && (
          <InfoRow
            object={underwriting.separate_liability_policy_another_carrier}
            label="General Liability Limits"
          />
        )}
        <InfoRow
          object={underwriting.applicant_performs_work_in_the_following_areas}
          label="Does the applicant perform any work in any of the following areas: new residential construction, new single family homes, condominiums, cooperatives, or town house involving foundation work, framing, or roofing, without insured subcontractors with proper risk transfer in favor of applicant; or new residentials developments of more than five (5) single family homes, condominiums, cooperatives, or townhouses in the same development involving plastering or stucco, siding or metal door and window installation; or new residential developments of more than twenty (20) single family homes, condominiums, cooperatives, or town houses in the same development? (Does not apply to new apartments or apartment buildings.)"
          boolean
        />
        <InfoRow
          object={
            underwriting.three_years_current_ownership_and_prior_insurance
          }
          label="Is Insured's business three years under the current ownership with three years prior insurance?"
          boolean
        />
        <InfoRow
          object={underwriting.any_other_insurance}
          label="Does the applicant have any insurance with American European Insurance Group or its affiliated companies?"
          boolean
        />
        {underwriting.any_other_insurance && (
          <InfoRow
            object={underwriting.other_insurance_policy_number}
            label="Please provide the policy number."
            boolean
          />
        )}
        <InfoRow
          object={underwriting.any_policy_or_coverage_declined_within_3_years}
          label="Any Policy or coverage declined, cancelled or non-renewed during the prior 3 years?"
          boolean
        />
        {underwriting.any_policy_or_coverage_declined_within_3_years && (
          <InfoRow
            object={
              underwriting.policy_or_coverage_declined_within_3_years_details
            }
            label="Please provide details."
          />
        )}
        <InfoRow
          object={underwriting.subcontracters_with_no_insurance}
          label="Within the last 5 years, has the Applicant been involved with work performed by subcontractors who carry no GL insurance; carry GL with limits less than the Applicant's; or who do not carry workers compensation insurance?"
          boolean
        />
        {underwriting.subcontracters_with_no_insurance && (
          <InfoRow
            object={underwriting.subcontracters_with_no_insurance_description}
            label="Please describe in remarks."
            boolean
          />
        )}
        <InfoRow
          object={underwriting.perform_work_related_to_the_ada_within_5_years}
          label="Within the last 5 years, has the Applicant performed any work related to the American Disabilities Act involving installation or repair of chair lifts?"
          boolean
        />
        <InfoRow
          object={
            underwriting.performed_work_related_to_these_areas_within_5_years
          }
          label="Within the last 5 years, has the Applicant performed work related to any of the areas listed in this list?"
          boolean
        />
        <InfoRow
          object={underwriting.performed_work_with_more_than_20_homes}
          label="Within the last 5 years, has Applicant performed any new residential developments of more than 20 single family homes, condos, cooperatives, or townhouses in the same development?"
          boolean
        />
        {underwriting.performed_work_with_more_than_20_homes && (
          <InfoRow
            object={
              underwriting.description_of_performed_work_with_more_than_20_homes
            }
            label="Please describe."
          />
        )}
        <InfoRow
          object={
            underwriting.performs_work_on_property_converted_to_multi_tenant
          }
          label="Does the Applicant perform work on any property that is converted to multi-tenant residential ownership including townhouses, cooperatives, or condominiums (does not include multi-tenant apartments)?"
          boolean
        />
        <InfoRow
          object={underwriting.performs_hot_work}
          label='Does the Applicant perform any "Hot Work" involving welding, torching, soldering, etc.?'
          boolean
        />
        <InfoRow
          object={underwriting.lapse_of_insurance}
          label="Has the Applicant had a lapse of insurance coverage during the previous three years of 30 days or longer?"
          boolean
        />
        {underwriting.lapse_of_insurance && (
          <InfoRow
            object={underwriting.details_of_lapse_of_insurance}
            label="Please provide details."
          />
        )}
      </Box>
    );
  }
  return <></>;
}
export default UnderwritingInfo;
