/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import {StyledComponent} from "@emotion/styled/types/base";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import AddIcon from "@heroicons/react/solid/PlusIcon";
import {Permissions} from "api/auth";
import {ThreadListFilter, Thread, ThreadsPagination} from "api/message";
import Button from "components/Button";
import Loading from "components/Loading";
import MessagingFilterDropdown from "components/messagingFilterDropdown";
import {usePermissions} from "hooks/auth";
import {useCallback, useEffect, useRef, useState} from "react";
import ThreadListItem from "./ThreadListItem";

export const ThreadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const ScrollDetectContainer = styled.div`
  height: 1px;
`;
export const ThreadList = styled.div`
  padding: 0 15px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 15px 0;
  background: ${(props) => props.theme.color.grayscale.white};
`;

export const SortContainer: StyledComponent<any> = styled.div`
  display: flex;
  justify-content: ${(props: any) =>
    props.isMessageTab ? "space-between" : "flex-start"};
`;

export const ThreadIcon = styled(AddIcon)`
  width: 14px;
  height: 12px;
  cursor: pointer;
`;

export const Input = styled.input`
  flex: 1;
  outline: none;
  height: 45px;
  background: ${(props) => props.theme.color.brand.tertiary};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.color.grayscale[600]};
  padding: 5px 25px;
  &::placeholder {
    color: ${(props) => props.theme.color.grayscale[600]};
  }
`;

const ThreadNameInputContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;
const ThreadsContainer = styled.div``;

const Actions = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
  gap: 6px;
`;

const NewThreadContainer = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.brand.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div``;

function ThreadListing({
  isMessageTab,
  onChangeThread,
  selectedThread,
  threadsPagination,
  handlePageChange,
  total,
  isLoading,
  pageNumber,
  error,
  handleFilterChange,
  selectedFilter,
}: {
  isMessageTab?: boolean;
  onChangeThread: Function;
  selectedThread?: Thread | null;
  threadsPagination: ThreadsPagination;
  handlePageChange?: Function;
  isLoading?: boolean;
  total?: number;
  pageNumber?: number;
  error: string | any;
  handleFilterChange: (value: ThreadListFilter) => void;
  selectedFilter?: string;
}) {
  const threads = threadsPagination.items;
  const observer = useRef<IntersectionObserver>();
  const containerBody = useRef<HTMLDivElement>(null);
  const [addThread, setAddThread] = useState(false);
  const [newThreadName, setNewThreadName] = useState("");

  const lastThread = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (Number(total) <= threads?.length) {
            console.log("EOF reached in thread listing");
          } else {
            handlePageChange && handlePageChange();
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threads]
  );

  useEffect(() => {
    var objDiv: HTMLDivElement | null = containerBody.current;
    if (threads?.length && threads?.length <= 10 && pageNumber === 1) {
      setTimeout(() => {
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 0);
    } else {
      setTimeout(() => {
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollTop + 100;
        }
      }, 0);
    }
  }, [threads, pageNumber]);

  function showContent() {
    if (threads) {
      if (!threads.length) {
        return <>No threads found.</>;
      }
      return (
        <>
          {threads.map((thread: Thread, index: number) => {
            return (
              <ThreadListItem
                key={index}
                thread={thread}
                onClickThread={() => onChangeThread(thread)}
                active={selectedThread?.id === thread?.id}
                quoteNumber={thread?.quote?.backend_display_quote_number}
              />
            );
          })}
          <ScrollDetectContainer ref={lastThread} />
        </>
      );
    }
    if (error) {
      return <div>Error</div>;
    }
    return <Loading size="medium" />;
  }
  const permissions = usePermissions();

  return (
    <ThreadContainer>
      <ThreadList>
        {!addThread && (
          <FilterContainer>
            <Wrapper>
              {!isMessageTab && (
                <NewThreadContainer onClick={() => setAddThread(true)}>
                  <ThreadIcon />
                  New Thread
                </NewThreadContainer>
              )}
            </Wrapper>
            <MessagingFilterDropdown
              selectedFilter={selectedFilter || ""}
              handleFilterChange={handleFilterChange}
              hasAllThreadPermission={permissions.includes(
                Permissions.VIEW_ALL_THREADS
              )}
            />
          </FilterContainer>
        )}
        {addThread && (
          <ThreadNameInputContainer>
            <Input
              value={newThreadName}
              placeholder="Enter thread title"
              onChange={(e) => setNewThreadName(e.target.value)}
            />{" "}
            <Actions>
              <Button
                isLoading={false}
                color={"primary"}
                icon={CheckIcon}
                onClick={() => {}}
                disabled={!newThreadName}
              />
              <Button
                color={"secondary"}
                icon={XIcon}
                onClick={() => setAddThread(false)}
              />
            </Actions>
          </ThreadNameInputContainer>
        )}
        <ThreadsContainer ref={containerBody}>{showContent()}</ThreadsContainer>
      </ThreadList>
    </ThreadContainer>
  );
}

export default ThreadListing;
