import Button, {ButtonProps} from "components/Button";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import {useCallback} from "react";
import {useQuoteContext} from "../contexts/quoteContext";
import {ParamType} from "api/common";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import {useMutation, useQueryClient} from "react-query";
import {getQuote} from "api/quote";
import {useAuth} from "hooks/auth";
import {Permissions} from "api/auth";

function AddButton({
  objectName,
  children,
  params,
  disabled,
  ...props
}: {objectName: string; params?: ParamType} & ButtonProps) {
  const queryClient = useQueryClient();
  const {fieldsDisabled} = useFieldsStatusContext();
  const {add, quoteId, isBackendQuoting} = useQuoteContext();
  const {authSession} = useAuth();

  const {mutateAsync, isLoading} = useMutation(
    async (props: {objectName: string; params?: ParamType}) => add(props),
    {
      onSuccess: () =>
        queryClient.prefetchQuery([getQuote.name, quoteId.toString()], () =>
          getQuote(quoteId.toString())
        ),
    }
  );

  const handleClick = useCallback(async () => {
    mutateAsync({objectName, params});
  }, [mutateAsync, objectName, params]);

  return (
    <Button
      {...props}
      disabled={
        disabled ||
        fieldsDisabled ||
        isBackendQuoting ||
        !authSession?.permissions.includes(Permissions.EDIT_QUOTE)
      }
      isLoading={isLoading}
      icon={PlusIcon}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}

function DeleteButton({
  objectName,
  objectId,
  children,
  disabled,
  ...props
}: {objectName: string; objectId: string} & ButtonProps) {
  const queryClient = useQueryClient();
  const {fieldsDisabled} = useFieldsStatusContext();
  const {del, quoteId, isBackendQuoting} = useQuoteContext();
  const {authSession} = useAuth();
  const {mutateAsync, isLoading} = useMutation(
    async (props: {objectName: string; objectId: string}) => del(props),
    {
      onSuccess: () =>
        queryClient.prefetchQuery([getQuote.name, quoteId.toString()], () =>
          getQuote(quoteId.toString())
        ),
    }
  );

  const handleClick = useCallback(() => {
    mutateAsync({objectName, objectId});
  }, [mutateAsync, objectName, objectId]);

  return (
    <Button
      {...props}
      disabled={
        disabled ||
        fieldsDisabled ||
        isBackendQuoting ||
        !authSession?.permissions.includes(Permissions.EDIT_QUOTE)
      }
      isLoading={isLoading}
      icon={TrashIcon}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}

const ActionButton = {
  Add: AddButton,
  Delete: DeleteButton,
};

export default ActionButton;
