import {Global, css} from "@emotion/react";
import CSSReset from "./CssReset";

function GlobalCss() {
  return (
    <>
      <CSSReset />
      <Global
        styles={css`
          @font-face {
            font-family: "Helvetica";
            font-style: normal;
            font-weight: 700;
            src: local(""), url("/fonts/Helvetica.ttf") format("ttf");
          }

          * {
            font-family: Helvetica;
            box-sizing: border-box;
          }

          html,
          body,
          #root {
            height: 100%;
            margin: 0;
          }

          .headlessDialog {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      />
    </>
  );
}

export default GlobalCss;
