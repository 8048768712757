/** @jsxImportSource @emotion/react */

import {Fragment, useEffect, useMemo} from "react";
import InternalNotes from "pages/QuoteInternalNotes";
import {useMutation, useQueryClient} from "react-query";
import {css} from "@emotion/react";
import RefreshIcon from "@heroicons/react/outline/RefreshIcon";
import LinkIcon from "@heroicons/react/outline/LinkIcon";
import LockClosedIcon from "@heroicons/react/outline/LockClosedIcon";
import ServerIcon from "@heroicons/react/solid/ServerIcon";
import {
  FieldRuleResults,
  getQuote,
  getQuoteDetails,
  getQuotePolicy,
  QuoteResponse,
  QuoteStatuses,
  RuleResultType,
  setBackendQuotingMode,
} from "api/quote";
import RateQuoteBindTopBar, {
  rateQuoteTabs,
  RateTabs,
} from "./components/RateQuoteBindTopBar";
import RatePanel from "./RatePanel";
import UnderwritingPanel from "./UnderwritingPanel";
import SummaryPanel from "./SummaryPanel";
import {
  QuoteContextProvider,
  QuoteContext,
  QuoteConextInerface,
  useQuoteContext,
} from "./contexts/quoteContext";
import MessageSlideOver from "components/MessageSlideOver";
import {RuleResultsContextProvider} from "./contexts/ruleResultsContextProvider";
import {SectionErrorContextProvider} from "./contexts/sectionErrorContext";
import {FieldsStatusContextProvider} from "./contexts/fieldsStatusContext";
import Box from "components/Box";
import {formatDateTime, formatQuoteType} from "utils/format";
import {useNavigation} from "hooks/navigation";
import {FieldMetaContextProvider} from "./contexts/fieldMetaContext";
import {RateContextProvider} from "./contexts/rateContext";
import {
  inApprovedStatus,
  inFinalizedStatus,
  inQuoteStatus,
  inReviewStatus,
} from "utils/quotePolicy";
import IconButton from "components/IconButton";
import {Anchor} from "components/elements";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {
  PopoverButton,
  PopoverContainer,
  PopoverPanel,
} from "components/Popover";
import Button from "components/Button";
import {EyeOffIcon, InformationCircleIcon} from "@heroicons/react/outline";
import StatusBadge from "components/StatusBadge";
import {getHtmlTitle} from "utils";
import TopbarShellTitle from "components/TopbarShellTitle";
import InternalNotesSlideOver from "components/InternalNotesSlideOver";
import {PencilAltIcon} from "@heroicons/react/solid";
import {useApiQuery} from "hooks/api";
import IsUnderwriterQuotingButton from "components/IsUnderwritingQuotingButton";
import styled from "@emotion/styled";

export default function RateQuoteBindContainer({
  id,
  tab,
}: {
  id: string;
  tab?: string | null;
}) {
  const {
    data: quoteData,
    refetch,
    isFetching,
  } = useApiQuery(getQuote, {
    refetchOnWindowFocus: true,
    useErrorBoundary: true,
    suspense: true,
    refetchInterval: 60000,
  })(id);

  useEffect(() => {
    window.document.title = getHtmlTitle(
      `Rate/Quote/Bind - ${quoteData?.backend_display_quote_number}`
    );
  }, [quoteData?.backend_display_quote_number]);

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery(["quote/details", id], () => getQuoteDetails(id));
    queryClient.prefetchQuery(["quote/policy", id], () => getQuotePolicy(id));
    // eslint-disable-next-line
  }, [id]);

  const {updateSearchParams} = useNavigation();

  const activeTab = useMemo(() => {
    if (tab && tab.length) return tab;
    return rateQuoteTabs[0].query;
  }, [tab]);

  const tabComplete = useMemo(
    () => ({
      rate: quoteData?.status ? !inQuoteStatus(quoteData.status) : false,
      underwriting: inApprovedStatus(quoteData?.status),
      summary: inFinalizedStatus(quoteData?.status),
      bind: false,
    }),
    [quoteData?.status]
  );

  const navigateTab = (tabName: string) => updateSearchParams({tab: tabName});

  useEffect(() => {
    if (quoteData?.status) {
      if (inQuoteStatus(quoteData.status) && tab !== RateTabs.RATE) {
        navigateTab(RateTabs.RATE);
      }
      if (
        inReviewStatus(quoteData.status) &&
        (!tab?.length || tab === RateTabs.SUMMARY)
      ) {
        navigateTab(RateTabs.UNDERWRITING);
      }
      if (inApprovedStatus(quoteData.status) && !tab?.length) {
        navigateTab(RateTabs.SUMMARY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permissions = usePermissions();

  const hasReferralPreRate = useMemo(
    () =>
      findResultType(
        quoteData?.field_rule_results,
        RuleResultType.REFERRED_PRERATE
      ),
    [quoteData?.field_rule_results]
  );

  const hasImmediateDeclination = useMemo(
    () =>
      findResultType(
        quoteData?.field_rule_results,
        RuleResultType.IMMEDIATE_DECLINATION
      ),
    [quoteData?.field_rule_results]
  );

  if (!quoteData) {
    return <div>Data is not available</div>;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `}
    >
      <QuoteContextProvider
        quoteId={quoteData.quote_id}
        status={quoteData.status}
        isBackendQuoting={quoteData.is_backend_quoting}
        underwriterIsQuoting={quoteData.is_underwriter_quoting}
        hasReferralPreRate={hasReferralPreRate}
        hasImmediateDeclination={hasImmediateDeclination}
      >
        <QuoteContext.Consumer>
          {({updating}: QuoteConextInerface) => (
            <>
              <RateQuoteBindTopBar
                activeTab={activeTab}
                tabComplete={tabComplete}
                quoteData={quoteData}
              >
                <Box display="flex" alignItems="center" gap={5} mr={24}>
                  {permissions.includes(Permissions.EDIT_QUOTE_ALWAYS) &&
                    !quoteData.is_backend_quoting &&
                    quoteData.status === QuoteStatuses.UNDERWRITER_REVIEW && (
                      <IsUnderwriterQuotingButton quoteData={quoteData} />
                    )}
                  <IconButton
                    isLoading={isFetching || updating}
                    background={false}
                    icon={RefreshIcon}
                    onClick={() => refetch()}
                  />
                  <BackendQuoteLinkButton quoteData={quoteData} />
                  <BackendQuotingButton quoteData={quoteData} />
                  <FieldVisibilityControlToggleButton />
                  <MessagesSlideOverButton quoteData={quoteData} />
                  <InternalNotesSlideOverButton quoteData={quoteData} />
                  <QuoteInfoButton quoteData={quoteData} />
                </Box>
              </RateQuoteBindTopBar>
              {activeTab === rateQuoteTabs[0].query && (
                <FieldMetaContextProvider
                  field_permissions={quoteData.field_permissions}
                >
                  <RuleResultsContextProvider
                    fieldRuleResults={quoteData.field_rule_results}
                  >
                    <SectionErrorContextProvider>
                      <FieldsStatusContextProvider
                        last_rated_at={quoteData.last_rated_at}
                        rate_error={quoteData.rate_error}
                      >
                        <RateContextProvider
                          rateIndication={quoteData.rate_indication}
                        >
                          <RatePanel
                            quoteForm={quoteData.form_data}
                            quoteType={quoteData.quote_type}
                            backendQuoteID={
                              quoteData.backend_display_quote_number ||
                              quoteData.backend_quote_id
                            }
                            hasReferralPreRate={hasReferralPreRate}
                            hasImmediateDeclination={hasImmediateDeclination}
                          />
                        </RateContextProvider>
                      </FieldsStatusContextProvider>
                    </SectionErrorContextProvider>
                  </RuleResultsContextProvider>
                </FieldMetaContextProvider>
              )}
              {activeTab === rateQuoteTabs[1].query && (
                <UnderwritingPanel
                  quoteID={quoteData.quote_id}
                  userID={quoteData.user_id}
                  uwID={quoteData.review_underwriter_id}
                  status={quoteData.status}
                  fieldRuleResults={quoteData.field_rule_results}
                />
              )}
              {activeTab === rateQuoteTabs[2].query && (
                <SummaryPanel
                  quoteID={quoteData.quote_id}
                  quoteForm={quoteData.form_data}
                  bindingForm={quoteData.binding_form}
                  status={quoteData.status}
                  policyNumber={quoteData.backend_display_policy_number}
                  quoteProposalLink={quoteData.quote_proposal_download_url}
                />
              )}
            </>
          )}
        </QuoteContext.Consumer>
      </QuoteContextProvider>
    </div>
  );
}

function BackendQuoteLinkButton({quoteData}: {quoteData: QuoteResponse}) {
  const permissions = usePermissions();
  if (
    !permissions.includes(Permissions.BACKEND_QUOTING) ||
    !process.env.REACT_APP_API_URL
  ) {
    return null;
  }
  return (
    <Anchor
      title="Majesco Link"
      href={`${process.env.REACT_APP_API_URL}/majesco/quote/${quoteData.backend_quote_id}`}
      target="_blank"
    >
      <IconButton background={false} icon={LinkIcon} />
    </Anchor>
  );
}

function QuoteInfoButton({quoteData}: {quoteData: QuoteResponse}) {
  return (
    <PopoverContainer>
      <PopoverButton as={Fragment}>
        <Button
          title="Info"
          background={false}
          icon={InformationCircleIcon}
          css={css`
            width: 32px;
            padding: 0;
            height: 32px;
            border-radius: 50%;
          `}
        />
      </PopoverButton>
      <PopoverPanel padding="20px" width="200px" direction="right">
        <OverviewHeader>Info</OverviewHeader>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 28px;
          `}
        >
          <span>
            <SectionTitle>Created at</SectionTitle>
            <div>{formatDateTime(quoteData.created_at, false)}</div>
          </span>
          <span>
            <SectionTitle>Updated last</SectionTitle>
            <div>{formatDateTime(quoteData.updated_at, false)}</div>
          </span>
          <span>
            <SectionTitle>Underwriters</SectionTitle>
            {quoteData.underwriters.map((underwriter) => (
              <div>{underwriter}</div>
            ))}
          </span>
        </div>
      </PopoverPanel>
    </PopoverContainer>
  );
}

const OverviewHeader = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  margin: 0;
  margin-bottom: 25px;
  color: ${({theme}) => theme.color.brand.primary};
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

function BackendQuotingButton({quoteData}: {quoteData: QuoteResponse}) {
  const queryClient = useQueryClient();
  const permissions = usePermissions();
  const backendQuotingModeMutation = useMutation(
    (flag: boolean) => setBackendQuotingMode(quoteData.quote_id, flag),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          getQuote.name,
          quoteData.quote_id.toString(),
        ]),
    }
  );

  if (
    inApprovedStatus(quoteData.status) ||
    !permissions.includes(Permissions.BACKEND_QUOTING)
  ) {
    return <></>;
  }

  const canNotTurnOffBackendQuoting =
    quoteData.is_backend_quoting &&
    !permissions.includes(Permissions.TURN_BACKEND_QUOTING_OFF);

  return (
    <PopoverContainer>
      <PopoverButton as={Fragment}>
        <Button
          title="Backend Quoting"
          background={quoteData.is_backend_quoting}
          icon={LockClosedIcon}
          css={css`
            width: 32px;
            padding: 0;
            height: 32px;
            border-radius: 50%;
          `}
        />
      </PopoverButton>
      <PopoverPanel padding="10px" width="fit-content" direction="right">
        <div
          css={css`
            display: flex;
            gap: 8px;
          `}
        >
          <Button
            size="small"
            isLoading={backendQuotingModeMutation.isLoading}
            disabled={canNotTurnOffBackendQuoting}
            onClick={() =>
              backendQuotingModeMutation.mutateAsync(
                !quoteData.is_backend_quoting
              )
            }
            icon={ServerIcon}
            background={true}
            title={
              canNotTurnOffBackendQuoting
                ? "You do not have permission to turn off backend quoting"
                : undefined
            }
          >
            Turn Backend Quoting Mode{" "}
            {quoteData.is_backend_quoting ? "Off" : "On"}
          </Button>
        </div>
        <P>
          Once agents submits quote, use this feature when work in Majesco is
          necessary:
        </P>
        <P>
          <b>Turn Backend Quoting Mode On</b> in order to disable quoting on
          this form. Agent and underwriter will not have the ability to make
          changes to the form.
        </P>
        <P>
          When done doing changes in the Majesco, make sure to click in Majesco{" "}
          <b>Calculate Premium</b> to make sure there is no errors. Then click
          on our Portal <b>Get Rate Indication</b> which will get the new rate
          from Majesco and also sync additional data points.
        </P>
        <P>
          If changes on quote form entries are necessary, click{" "}
          <b>
            Turn Backend Quoting Mode Off.{" "}
            <span style={{color: "red"}}>
              Important: contact IT prior to selecting this option. This may
              cause errors with the quote.
            </span>
          </b>
        </P>
      </PopoverPanel>
    </PopoverContainer>
  );
}

function FieldVisibilityControlToggleButton() {
  const permissions = usePermissions();
  const {fieldVisibilityControlEnabled, toggleFieldVisibilityControl} =
    useQuoteContext();

  if (!permissions.includes(Permissions.HIDE_QUOTE_FIELDS)) {
    return <></>;
  }

  return (
    <Button
      title="Show/Hide Hidden Fields Controls"
      background={fieldVisibilityControlEnabled}
      icon={EyeOffIcon}
      css={css`
        width: 32px;
        padding: 0;
        height: 32px;
        border-radius: 50%;
      `}
      onClick={() => toggleFieldVisibilityControl()}
    />
  );
}

export function RateQuoteBindPageHeader({id}: {id: string}) {
  const {data: quoteData} = useApiQuery(getQuote)(id);
  return (
    <>
      {quoteData && (
        <>
          <TopbarShellTitle>{`New ${formatQuoteType(
            quoteData.quote_type
          )} Quote: ${
            quoteData.backend_display_quote_number || quoteData.backend_quote_id
          }`}</TopbarShellTitle>
          <StatusBadge value={quoteData.status} />
        </>
      )}
    </>
  );
}

export function MessagesSlideOverButton({
  quoteData,
}: {
  quoteData: QuoteResponse;
}) {
  const permissions = usePermissions();
  const hasViewThreadsPermission =
    permissions.includes(Permissions.VIEW_ALL_THREADS) ||
    permissions.includes(Permissions.VIEW_MY_THREADS);
  return hasViewThreadsPermission ? (
    <div title="Messages">
      <MessageSlideOver
        title={`New Quote: ${
          quoteData.backend_display_quote_number || quoteData.backend_quote_id
        }`}
        threadId={quoteData.messages_thread_id}
      />
    </div>
  ) : null;
}

export function InternalNotesSlideOverButton({
  quoteData,
}: {
  quoteData: QuoteResponse;
}) {
  const permissions = usePermissions();
  const hasViewQuoteInternalNotesPermission = permissions.includes(
    Permissions.VIEW_QUOTE_INTERNAL_NOTES
  );
  return hasViewQuoteInternalNotesPermission && quoteData.quote_id ? (
    <div title="Internal Notes">
      <InternalNotesSlideOver
        icon={PencilAltIcon}
        title={quoteData.backend_display_quote_number}
        content={<InternalNotes quoteId={quoteData.quote_id} />}
      />
    </div>
  ) : null;
}

const P = styled.p`
  ${({theme}) => theme.text.p2};
`;

function findResultType(
  field_rule_results: FieldRuleResults | undefined,
  resultType: RuleResultType,
  includeOverwritten = false
) {
  if (field_rule_results) {
    for (let sectionKey in field_rule_results) {
      for (let instaceKey in field_rule_results?.[sectionKey]) {
        for (let fieldKey in field_rule_results[sectionKey]?.[instaceKey]) {
          const ruleResult =
            field_rule_results[sectionKey][instaceKey]?.[fieldKey];

          if (
            ruleResult?.result_type === resultType &&
            (!includeOverwritten ? !ruleResult?.overriden : true)
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
