import {ObjectBase, RuleResultType} from "api/quote";
import {useEffect, useMemo} from "react";
import {useRuleResultsContext} from "../../contexts/ruleResultsContextProvider";
import {useSectionErrorContext} from "../../contexts/sectionErrorContext";
import {useSectionName} from "../../contexts/sectionNameContext";
import {useFieldsStatusContext} from "../../contexts/fieldsStatusContext";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

const nonErrorResultTypes = [
  RuleResultType.SUCCESS,
  RuleResultType.REFERRED,
  RuleResultType.REFERRED_PRERATE,
  RuleResultType.MESSAGE,
];

export function useFieldRuleResult<T extends ObjectBase>(
  object: T,
  field: keyof T
) {
  const permissions = usePermissions();
  const {getRuleResult} = useRuleResultsContext();
  const {addSectionError, removeSectionError} = useSectionErrorContext();
  const {showRequiredErrors, setApiErrors} = useFieldsStatusContext();
  const fieldRuleResult = useMemo(
    () => getRuleResult(object.object_name, object.id, field as string),
    [field, getRuleResult, object.id, object.object_name]
  );
  const isRequiredError =
    fieldRuleResult?.result_type === RuleResultType.REQUIRED;
  const ruleResult = useMemo(() => {
    if (!fieldRuleResult) return null;

    if (
      fieldRuleResult.overriden &&
      !permissions.includes(Permissions.OVERRIDE_QUOTE_RULES)
    )
      return null;

    if (
      (fieldRuleResult.is_prev_declination ||
        fieldRuleResult.is_prev_referral) &&
      !permissions.includes(Permissions.UNDERWRITE_QUOTE)
    )
      return null;

    if (isRequiredError && !showRequiredErrors) return null;

    return fieldRuleResult;
  }, [permissions, fieldRuleResult, showRequiredErrors, isRequiredError]);

  const isError = Boolean(
    ruleResult &&
      !ruleResult.overriden &&
      !nonErrorResultTypes.includes(ruleResult.result_type)
  );

  const sectionName = useSectionName();

  useEffect(() => {
    const objectPath = `${object.object_name}.${object.id}.${String(field)}`;
    if (
      fieldRuleResult &&
      !fieldRuleResult.overriden &&
      !nonErrorResultTypes.includes(fieldRuleResult.result_type)
    ) {
      addSectionError(sectionName, objectPath, fieldRuleResult);
    } else {
      removeSectionError(sectionName, objectPath);
    }

    return () => {
      removeSectionError(sectionName, objectPath);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRuleResult]);

  const handleAPIError = (error: any, field: string) => {
    if (typeof error.then === "function") {
      error.then((errorData: string) => {
        setApiErrors([`An error occured while updating ${field}`]);
      });
    } else {
      const errorData = error as Error;
      setApiErrors([errorData.message]);
    }
  };

  return {
    ruleResult,
    isError,
    isRequiredError,
    handleAPIError,
  };
}
