import styled from "@emotion/styled";
import {Dialog as HuiDialog} from "@headlessui/react";
import {ReactNode} from "react";
import {DialogBackdrop, DialogContainer, DialogScrollable} from "./Dialog";
import IconButton from "./IconButton";
import {css} from "@emotion/react";
import {XIcon} from "@heroicons/react/solid";

const ContainerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 9;
  top: 0;
  width: 100%;
  background: ${(props) => props.theme.color.grayscale.white};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.span`
  ${(props) => props.theme.text.header1}
  color: ${(props) => props.theme.color.brand.primary};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const DialogPanel = styled(HuiDialog.Panel)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
` as typeof HuiDialog.Panel;

const DialogPanelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
`;

export function DialogFullWidthPanel({
  title,
  open,
  onClose,
  children,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  return (
    <DialogContainer open={open} as="div" onClose={onClose}>
      <DialogBackdrop />
      <DialogScrollable>
        <DialogPanelContainer>
          <DialogPanel>
            <ContainerHeader>
              <Header>{title}</Header>
              <Actions>
                <IconButton
                  onClick={onClose}
                  css={css`
                    cursor: pointer;
                    outline: 0;
                    margin-left: 10px;
                    width: 22px !important;
                    height: 22px !important;
                    padding: 0px !important;
                    color: #000;
                    border-radius: 0;
                    border: 0;
                    background: transparent;
                    &:hover {
                      background: transparent !important;
                    }
                  `}
                >
                  <XIcon />
                </IconButton>
              </Actions>
            </ContainerHeader>
            {children}
          </DialogPanel>
        </DialogPanelContainer>
      </DialogScrollable>
    </DialogContainer>
  );
}
