/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {useNavigation} from "hooks/navigation";
import {ReactNode} from "react";

export default function ErrorScreen({
  onReset,
  children,
}: {
  onReset?: () => void;
  children: ReactNode;
}) {
  const {navigate} = useNavigation();

  const onReturn = () => {
    onReset?.();
    navigate("/");
  };

  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={(theme) => css`
          ${theme.text.header1}
          font-weight: bold;
          margin-bottom: 16px;
          color: ${theme.color.state.red};
        `}
      >
        Oops!
      </div>

      <div
        css={(theme) => css`
          ${theme.text.header2}
          font-weight: bold;
          margin-bottom: 30px;
        `}
      >
        {children}
      </div>

      <Button color="primary" size="large" onClick={onReturn}>
        Return to Home Page
      </Button>
    </div>
  );
}
