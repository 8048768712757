import {RuleResult} from "api/quote";
import {createContext, ReactNode, useContext} from "react";

export const RuleResultsContext = createContext({
  getRuleResult(
    objectName: string,
    objectId: string,
    field: string
  ): RuleResult | null {
    return null;
  },
});

export function RuleResultsContextProvider({
  fieldRuleResults,
  children,
}: {
  fieldRuleResults: any;
  children: ReactNode;
}) {
  function getRuleResult(objectName: string, objectId: string, field: string) {
    return fieldRuleResults?.[objectName]?.[objectId]?.[field] || null;
  }

  return (
    <RuleResultsContext.Provider value={{getRuleResult}}>
      {children}
    </RuleResultsContext.Provider>
  );
}

export function useRuleResultsContext() {
  return useContext(RuleResultsContext);
}
