import {css} from "@emotion/react";
import DownloadIcon from "@heroicons/react/solid/DownloadIcon";
import AtSymbolIcon from "@heroicons/react/solid/AtSymbolIcon";
import {
  ReportConfig,
  emailReport,
  getPdfReport,
  getReportsList,
} from "api/reports";
import Button from "components/Button";
import Loading from "components/Loading";
import Pagination from "components/Pagination";
import SearchBox from "components/SearchBox";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  IconButtonsCellContainer,
  Row,
  Table,
} from "components/Table";
import {Anchor} from "components/elements";
import {
  LoadingIndicator,
  PageContainer,
  ToolbarContainer,
} from "components/listPageUtils";
import {useApiMutation, useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import links from "links";
import {useRef, useState} from "react";
import {downloadFile} from "utils/dom";
import {useSearchDebounce} from "hooks/debounce";
import {useNotification} from "hooks/notification";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

export function ReportsList({
  searchQuery = "",
  limit,
  pageNumber,
}: {
  searchQuery?: string;
  limit: number;
  pageNumber: number;
}) {
  const reportsListQuery = useApiQuery(getReportsList, {
    keepPreviousData: true,
    enabled: !searchQuery,
  })(searchQuery, limit.toString(), pageNumber.toString());
  const {updateSearchParams} = useNavigation();
  const tableElementref = useRef<HTMLDivElement>(null);
  const search = useSearchDebounce({
    enabled: !!searchQuery,
    deps: [pageNumber],
    fetch: reportsListQuery.refetch,
  });
  return (
    <PageContainer>
      <ToolbarContainer>
        <div
          css={css`
            flex-grow: 1;
            max-width: 350px;
            display: none; // Hide search box for now until legacy reports are removed
          `}
        >
          <SearchBox
            placeholder="Search reports list"
            searchValue={searchQuery}
            handleSearchInput={(v) => {
              updateSearchParams({searchQuery: v, pageNumber: 1});
              search();
            }}
            height={38}
          />
        </div>
        {reportsListQuery.isPreviousData && <LoadingIndicator />}
      </ToolbarContainer>
      {reportsListQuery.data?.result ? (
        <>
          <Table
            ref={tableElementref}
            disabled={reportsListQuery.isPreviousData}
          >
            <Header>
              <HeaderCell></HeaderCell>
              <HeaderCell></HeaderCell>
            </Header>
            {reportsListQuery.data.result.map((report) => (
              <ReportRow
                reportName={report.name}
                reportTitle={report.title}
                reportConfig={report.config}
              />
            ))}
            <LegacyReportsList />
          </Table>
          <Pagination
            isCurrentPageLoaded={!reportsListQuery.isPreviousData}
            scrollOffset={70}
            resultsElementRef={tableElementref}
            pageNumber={pageNumber}
            limit={limit}
            totalCount={reportsListQuery.data.total_count}
          />
        </>
      ) : reportsListQuery.error ? (
        "Error"
      ) : (
        <Loading size="medium" />
      )}
    </PageContainer>
  );
}

function ReportRow({
  reportName,
  reportTitle,
  reportConfig,
}: {
  reportName: string;
  reportTitle: string;
  reportConfig: ReportConfig;
}) {
  const {navigate} = useNavigation();
  const reportLink = links.report({reportName, reportTitle});
  const hasRequiredFilters = Object.entries(reportConfig.filters).some(
    ([name, filterConfig]) => filterConfig.required
  );
  return (
    <Body>
      <Row onClick={() => navigate(reportLink)}>
        <Cell>
          <Anchor href={reportLink} onClick={(e) => e.preventDefault()}>
            {reportTitle}
          </Anchor>
        </Cell>
        <Cell>
          {!hasRequiredFilters && (
            <IconButtonsCellContainer direction="end">
              <DownloadButton reportName={reportName} />
              <EmailButton reportName={reportName} />
            </IconButtonsCellContainer>
          )}
        </Cell>
      </Row>
    </Body>
  );
}

function LegacyReportsList() {
  const permissions = usePermissions();
  return (
    <>
      {permissions.includes(Permissions.VIEW_ALL_COMMISSION_STATEMENTS) && (
        <LegacyReportRow
          reportTitle="Commission Statements"
          link="/commission-statements"
        />
      )}
      {permissions.includes(Permissions.VIEW_AGENCY_EXPERIENCE) && (
        <LegacyReportRow
          reportTitle="Agency Experience"
          link="/agency-experience"
        />
      )}
      {permissions.includes(Permissions.VIEW_FINANCE_REPORTS) && (
        <LegacyReportRow
          reportTitle="Finance Reports"
          link="/accounting"
        />
      )}
    </>
  );
}

function LegacyReportRow({
  reportTitle,
  link,
}: {
  reportTitle: string;
  link: string;
}) {
  const {navigate} = useNavigation();
  return (
    <Body>
      <Row onClick={() => navigate(link)}>
        <Cell>
          <Anchor href={link} onClick={(e) => e.preventDefault()}>
            {reportTitle}
          </Anchor>
        </Cell>
        <Cell></Cell>
      </Row>
    </Body>
  );
}

export function DownloadButton({
  reportName,
  params,
}: {
  reportName: string;
  params?: any;
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <Button
      onClick={async () => {
        setIsDownloading(true);
        const {url, filename} = await getPdfReport(reportName, params || {});
        downloadFile(url, filename).finally(() => setIsDownloading(false));
      }}
      isLoading={isDownloading}
      size="small"
      background={false}
      icon={DownloadIcon}
    >
      Download
    </Button>
  );
}

export function EmailButton({
  reportName,
  params,
}: {
  reportName: string;
  params?: any;
}) {
  const {notify} = useNotification();
  const emailReportMutation = useApiMutation(emailReport, {
    onSuccess: () => {
      notify("Report sent successfully to your email");
    },
    onError: () => {
      notify("Error sending email. Please try again.");
    },
  });
  return (
    <Button
      onClick={() => emailReportMutation.mutate([reportName, params || {}])}
      isLoading={emailReportMutation.isLoading}
      size="small"
      background={false}
      icon={AtSymbolIcon}
    >
      Email
    </Button>
  );
}
