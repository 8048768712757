import states from "assets/data/state.json";
import stateCountiesMap from "assets/data/state-counties.json";
import {Insured} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import Field from "./Field";
import {PlacesAutocomplete} from "./PlacesAutoComplete";
import {useMemo} from "react";

export const INSURED_INFO_SECTION_NAME = "Insured Info";
const sortedStates = states.sort((a, b) => a.name.localeCompare(b.name));
const LegalEntityOptions = [
  {
    title: "LLC",
    value: "Limited Liability Company (LLC)",
  },
  {
    title: "CORPORATION",
    value: "Corporation",
  },
  {
    title: "PARTNERSHIP",
    value: "Partnership",
  },
  {
    title: "OTHER",
    value: "Other",
  },
  {
    title: "JOINT VENTURE",
    value: "Joint Venture",
  },
  {
    title: "INDIVIDUAL",
    value: "Individual",
  },
];

function InsuredInfoForm({
  insureds,
  quoteId,
}: {
  insureds: Insured[];
  quoteId: string;
}) {
  const counties: string[] = useMemo(
    () =>
      insureds[0].state
        ? stateCountiesMap[insureds[0].state as keyof typeof stateCountiesMap]
        : [],
    [insureds]
  );
  return (
    <Box mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.TextInput
          object={insureds[0]}
          field={"name"}
          label="Name"
          wrapperProps={{
            growXXS: 12,
            growMD: 8,
          }}
        />
        <Field.DropDown
          object={insureds[0]}
          options={LegalEntityOptions}
          field="legal_entity"
          label="Legal entity"
          wrapperProps={{
            growXXS: 12,
            growMD: 4,
          }}
        />
        <Field.TextInput
          type="email"
          object={insureds[0]}
          field={"email"}
          label="Email"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.TextInput
          object={insureds[0]}
          field={"phone"}
          label="Phone"
          type={"tel"}
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
      </GridContainer>
      <FormTitle.SubHeader> Mailing address </FormTitle.SubHeader>
      <PlacesAutocomplete object={insureds[0]} quoteId={quoteId} />
      <GridContainer rowGap={24} columnGap={16}>
        <Field.TextInput
          object={insureds[0]}
          field={"line_1"}
          label="Address line 1"
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.TextInput
          object={insureds[0]}
          field={"line_2"}
          label="Address line 2"
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.TextInput
          object={insureds[0]}
          field={"city"}
          label="City"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={insureds[0]}
          options={sortedStates.map((state) => ({
            title: state.name,
            value: state.abbreviation,
          }))}
          field={"state"}
          label="Select State"
          wrapperProps={{
            growXXS: 12,
            growMD: 6,
          }}
        />
        <Field.DropDown
          object={insureds[0]}
          options={
            counties?.map((county) => ({
              title: `${county} county`,
              value: county,
            })) ?? []
          }
          disabled={!counties?.length}
          field={"county"}
          label="Select County"
          wrapperProps={{
            growXXS: 12,
            growMD: 8,
          }}
        />
        <Field.TextInput
          object={insureds[0]}
          field={"zip"}
          label="Zip"
          wrapperProps={{
            growXXS: 12,
            growMD: 4,
          }}
        />
      </GridContainer>
    </Box>
  );
}

export default InsuredInfoForm;
