import {PolicyModIds, getPolicyModIds} from "api/policy";
import {Combobox, OptionType, ComboboxProps} from "components/Combobox";
import {useApiQuery} from "hooks/api";
import {useSearchDebounce} from "hooks/debounce";
import {useState} from "react";

export default function PolicyNumberCombobox({
  selectedOption,
  setSelectedOption,
  onEnterKey,
  label,
  placeholder,
  required,
  ...props
}: {
  selectedOption: OptionType<PolicyModIds> | undefined;
  setSelectedOption: (option: OptionType<PolicyModIds> | undefined) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
} & Partial<ComboboxProps<PolicyModIds>>) {
  const [searchQuery, setSearchQuery] = useState("");
  const searchResultsQuery = useApiQuery(getPolicyModIds, {
    enabled: false,
  })(searchQuery, true);
  const search = useSearchDebounce({
    enabled: !!searchQuery,
    deps: [],
    fetch: searchResultsQuery.refetch,
  });

  const listSlice = searchResultsQuery.data?.slice(0, 10);
  return (
    <Combobox
      {...props}
      label={label ? label : !placeholder ? "Policy Number" : undefined}
      placeholder={placeholder}
      required={required}
      background
      value={selectedOption}
      optionLoading={searchResultsQuery.isLoading}
      onSearch={(value) => {
        setSearchQuery(value.toString());
        search();
      }}
      onChange={(option) => {
        setSelectedOption(option);
      }}
      onEnterKey={listSlice && listSlice.length > 0 ? undefined : onEnterKey}
      options={
        listSlice
          ? listSlice.map((policy) => ({
              title: policy.display_policy_number,
              value: policy,
            }))
          : []
      }
    />
  );
}
