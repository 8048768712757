import {PolicyModIds} from "api/policy";
import {PaymentConfirmationPageParams} from "pages/PaymentConfirmation";
import {PolicyPageParams} from "pages/Policy/policyPageParams";
import {RecurringPaymentPageParams} from "pages/RecurringPayment";
import {objectKeysToCamelCase} from "utils";
import {getUrl} from "utils/url";

const links = {
  policies: () => getUrl({path: "/policies"}),
  policy: ({policyModIds, tab, showAllMods}: PolicyPageParams) =>
    getUrl({
      path: `/policy/${policyModIds.display_policy_number}`,
      searchParamsObject: {
        ...objectKeysToCamelCase(policyModIds),
        tab: tab || "",
        showAllMods,
      },
    }),
  policyQuery: (policyNumberQuery: string) =>
    getUrl({path: `/policy/${policyNumberQuery}?query=1`}),
  paymentComfirmation: ({
    policyModIds,
    amount,
    paymentMethodDescription,
    succeeded,
    message,
    transactionId,
  }: PaymentConfirmationPageParams) =>
    getUrl({
      path: "/payments/confirmation",
      searchParamsObject: {
        ...objectKeysToCamelCase(policyModIds),
        amount,
        paymentMethodDescription,
        succeeded,
        message: encodeURIComponent(message),
        transactionId,
      },
    }),
  recurringPayments: ({policyModIds, setupView}: RecurringPaymentPageParams) =>
    getUrl({
      path: "/recurring-payment",
      searchParamsObject: {
        ...objectKeysToCamelCase(policyModIds),
        setupView,
      },
    }),
  payment: ({policyModIds}: {policyModIds: PolicyModIds}) =>
    getUrl({
      path: `/payments`,
      searchParamsObject: objectKeysToCamelCase(policyModIds),
    }),
  report: ({
    reportName,
    reportTitle,
  }: {
    reportName: string;
    reportTitle: string;
  }) =>
    getUrl({path: `/report/${reportName}`, searchParamsObject: {reportTitle}}),
};

export default links;
