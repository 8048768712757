/** @jsxImportSource @emotion/react */

import {Box} from "components/Box";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import FormTitle from "../components/FormTitle";
import {QuoteNote} from "api/quote";
import {formatDate} from "utils/format";
import UserName from "components/UserName";

function NotesSection({notes}: {notes?: QuoteNote[] | null}) {
  if (!notes) return null;

  return (
    <Box mb={100}>
      <FormTitle.Header>Notes</FormTitle.Header>

      <Table
        marginBottom={10}
        borderRadius={6}
        noBodyShadow
        checkeredBackground
      >
        <Header>
          <HeaderCell>Date</HeaderCell>
          <HeaderCell>User</HeaderCell>
          <HeaderCell>Note</HeaderCell>
        </Header>
        <Body>
          {notes.map((note) => (
            <Row>
              <Cell>{formatDate(note.created_at)}</Cell>
              <Cell>
                <UserName userId={Number(note.user_id)} />
              </Cell>
              <Cell>{note.text}</Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </Box>
  );
}

export default NotesSection;
