import {Permissions} from "api/auth";
import {
  addPaymentMethod,
  getPolicyPaymentProviderInfo,
  makePaymentWithSavedMethod,
  ONEINC_PAYMENT_PROVIDER_NAME,
  PaymentProviderInfo,
  addPaymentTransaction,
  PaymentMethodType,
  PaymentMethodBankAccountType,
} from "api/payment";
import {useApiMutation, useApiQuery} from "./api";
import {usePermissions} from "./auth";
import {useNavigation} from "./navigation";
import {PaymentModalTransaction, usePortalOneModal} from "./oneinc";
import {PolicyModIds} from "api/policy";
import links from "links";

export function usePayment({
  policyModIds,
  oneTimePaymentInfo: paymentInfo,
  newPaymentMethodInfo,
  paymentProviderInfo,
  amountDue,
}: {
  policyModIds: PolicyModIds;
  oneTimePaymentInfo?: {
    selectedAmount: number;
    showSaveOption: boolean;
    newPaymentMethodType?: "BANK" | "CREDIT";
    savedPaymentMethodData?: {
      method_id: string;
      description: string;
    };
    isError: boolean;
    onPaymentComplete?: (token?: string | null) => void;
    receiptEmail?: string;
  };
  newPaymentMethodInfo?: {
    userId: number;
    agencyId: string;
    onSaveComplete?: (paymentMethodId: string) => void;
  };
  paymentProviderInfo?: PaymentProviderInfo;
  amountDue?: number;
}) {
  const permissions = usePermissions();
  const paymentProviderInfoQuery = useApiQuery(getPolicyPaymentProviderInfo, {
    enabled: !paymentProviderInfo,
  })(policyModIds.company_number);
  paymentProviderInfo = paymentProviderInfo || paymentProviderInfoQuery.data;
  const makePaymentWithSavedMethodMutation = useApiMutation(
    makePaymentWithSavedMethod
  );
  const addPaymentMethodMutation = useApiMutation(addPaymentMethod);
  function addPaymentMethodFromPaymentModel(data: PaymentModalTransaction) {
    return addPaymentMethodMutation.mutateAsync([
      {
        policyIds: policyModIds,
        providerPaymentToken: data.tokenId || "",
        providerPaymentName: paymentProviderInfo?.name || "",
        cardType: data.cardType,
        bankAccountType:
          data.accountType === "Checking"
            ? PaymentMethodBankAccountType.CHECKING
            : PaymentMethodBankAccountType.SAVINGS,
        paymentMethodType:
          data.paymentCategory === "ECheck"
            ? PaymentMethodType.ACH
            : PaymentMethodType.CARD,
        cardZipCode: data.holderZip,
        nameHolder: data.customerName,
        cardAuthCode: data.authCode,
        cardExpirationYear: data.cardExpirationYear,
        cardExpirationMonth: data.cardExpirationMonth,
        lastFourDigits: data.lastFourDigits,
        bankName: data.bankName,
      },
    ]);
  }
  const portalOneModal = usePortalOneModal({
    sessionId: paymentProviderInfo?.session_id || "",
    paymentMethodType: paymentInfo?.newPaymentMethodType,
    selectedAmount: paymentInfo?.selectedAmount,
    showSaveOption: paymentInfo?.showSaveOption,
    clientReferenceData: getClientReferenceData(policyModIds),
    onPaymentComplete: async (data) => {
      if (data.tokenId) {
        addPaymentMethodFromPaymentModel(data);
      }
      registerTransaction(data);
      if (paymentInfo?.onPaymentComplete) {
        paymentInfo.onPaymentComplete(data.tokenId);
      }
    },
    onSaveComplete: async (data) => {
      if (data.tokenId) {
        const paymentMethodId = await addPaymentMethodFromPaymentModel(data);
        if (newPaymentMethodInfo?.onSaveComplete) {
          newPaymentMethodInfo?.onSaveComplete(paymentMethodId);
        }
      }
    },
  });
  const {navigate} = useNavigation();

  function goToPaymentConfirmation(
    success: boolean,
    amount: number,
    message: string,
    paymentMethodDescription: string,
    transactionId: string | null
  ) {
    navigate({
      url: links.paymentComfirmation({
        policyModIds: {...policyModIds},
        amount,
        paymentMethodDescription,
        succeeded: success,
        message: encodeURIComponent(message),
        transactionId,
      }),
      replace: true,
    });
  }
  function addPaymentTransactionHandler(data: PaymentModalTransaction) {
    return addPaymentTransaction({
      policyModIds: {...policyModIds},
      providerPaymentName: paymentProviderInfo?.name || "",
      providerTransactionId: data.transactionId,
      bankAccountType:
        data.accountType === "Checking"
          ? PaymentMethodBankAccountType.CHECKING
          : PaymentMethodBankAccountType.SAVINGS,
      paymentMethodType:
        data.paymentCategory === "ECheck"
          ? PaymentMethodType.ACH
          : PaymentMethodType.CARD,
      cardType: data.cardType,
      cardZipCode: data.holderZip,
      nameHolder: data.customerName,
      cardAuthCode: data.authCode,
      cardExpirationYear: data.cardExpirationYear,
      cardExpirationMonth: data.cardExpirationMonth,
      lastFourDigits: data.lastFourDigits,
      bankName: data.bankName,
      amount: Number(data.paymentAmount),
      date: data.transactionDate,
      timezone: data.timeZone,
      email: paymentInfo?.receiptEmail || "",
    });
  }
  async function registerTransaction(data: PaymentModalTransaction) {
    await addPaymentTransactionHandler(data);
    data.acknowledge();
    goToPaymentConfirmation(
      true,
      paymentInfo?.selectedAmount || 0,
      "",
      data.description,
      data.transactionId
    );
  }

  function makePaymentWithSavedPaymentMethod() {
    if (paymentInfo && paymentInfo.savedPaymentMethodData)
      makePaymentWithSavedMethodMutation
        .mutateAsync([
          {
            policyIds: policyModIds,
            paymentMethodId: paymentInfo.savedPaymentMethodData.method_id,
            amount: paymentInfo.selectedAmount,
            email: paymentInfo.receiptEmail || "",
          },
        ])
        .then((response) =>
          goToPaymentConfirmation(
            response.succeeded,
            paymentInfo?.selectedAmount || 0,
            response.message,
            paymentInfo?.savedPaymentMethodData?.description || "",
            response.provider_transaction_id
          )
        );
  }

  const isUnderpaymentWithoutPermission = Boolean(
    amountDue &&
      paymentInfo &&
      paymentInfo.selectedAmount < amountDue &&
      paymentInfo.selectedAmount > 0 &&
      !permissions.includes(Permissions.MAKE_PAYMENTS_FOR_LESS_THAN_MINIMUM_DUE)
  );

  const isIncomplete =
    !paymentInfo?.selectedAmount ||
    paymentInfo?.selectedAmount === 0 ||
    (!paymentInfo.savedPaymentMethodData && !paymentInfo.newPaymentMethodType);

  const paymentDisabled =
    paymentInfo?.isError ||
    isIncomplete ||
    isUnderpaymentWithoutPermission ||
    paymentInfo?.selectedAmount < 0.01;

  const isLoading =
    makePaymentWithSavedMethodMutation.isLoading ||
    portalOneModal.isLoading ||
    addPaymentMethodMutation.isLoading;

  return {
    isLoading,
    paymentDisabled,
    isUnderpaymentWithoutPermission,
    makeOneTimePayment: () => {
      if (paymentProviderInfo?.name === ONEINC_PAYMENT_PROVIDER_NAME) {
        portalOneModal.makePayment();
      }
    },
    makePaymentWithSavedPaymentMethod,
    addNewPaymentMethod: () => {
      if (paymentProviderInfo?.name === ONEINC_PAYMENT_PROVIDER_NAME) {
        portalOneModal.addNewMethod();
      }
    },
  };
}

function getClientReferenceData(policyModIds: PolicyModIds) {
  const policyModIdsEndcoded = {
    pcn: policyModIds.pc_provider_name,
    lob: policyModIds.lob,
    pn: policyModIds.policy_number,
    cn: policyModIds.company_number,
    mn: policyModIds.mod_number,
  };
  const clientReferenceData1 = policyModIds.display_policy_number;
  return {
    clientReferenceData1: clientReferenceData1,
    clientReferenceData2: btoa(JSON.stringify(policyModIdsEndcoded)),
    clientReferenceData3: "",
    clientReferenceData4: "",
  };
}
