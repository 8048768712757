import {
  getQuoteInternalNotes,
  postQuoteInternalNote,
  UploadQuoteInternalNote,
} from "api/internalNote";
import {useApiMutation, useApiQuery} from "hooks/api";
import styled from "@emotion/styled";
import QuoteInternalNoteInput from "./QuoteInternalNoteInput";
import {useEffect, useRef, useState} from "react";
import InternalNote from "./InternalNote";
import Loading from "components/Loading";

export const StyledUl = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
`;

export const StyledLi = styled.li`
  margin-bottom: 15px;
  gap: 25px;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const MessageContainerBody = styled.div`
  flex: auto;
  position: relative;
  padding-left: 11px;
  padding-right: 17px;
  padding-top: 11px;
  background-color: rgba(0, 32, 110, 0.03);
  background-image: url(chat-watermark.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  overflow-y: auto;
`;

const MainNoteContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
  border-radius: 6px;
  margin-bottom: 10px;
`;

export default function InternalNotes({quoteId}: {quoteId: number}) {
  const [editModeFocus, setEditModeFocus] = useState(false);
  const getQuoteInternalNotesQuery = useApiQuery(getQuoteInternalNotes)(
    quoteId
  );

  const postQuoteInternalNoteMutation = useApiMutation(postQuoteInternalNote);

  async function onClickSend(uploadQuoteInternalNote: UploadQuoteInternalNote) {
    await postQuoteInternalNoteMutation.mutateAsync([uploadQuoteInternalNote]);
  }

  const ref = useRef<HTMLDivElement | null>(null);

  let lastNoteId;

  if (
    getQuoteInternalNotesQuery.data &&
    getQuoteInternalNotesQuery.data.length
  ) {
    lastNoteId =
      getQuoteInternalNotesQuery.data[
        getQuoteInternalNotesQuery.data.length - 1
      ].id;
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
      });
    }
  }, [lastNoteId]);

  return (
    <MainContainer>
      <MessageContainerBody ref={ref}>
        <StyledUl>
          {getQuoteInternalNotesQuery.data ? (
            getQuoteInternalNotesQuery.data.map((note) => {
              return (
                <MainNoteContainer>
                  <InternalNote
                    setEditModeFocus={(value) => setEditModeFocus(value)}
                    note={note}
                  />
                </MainNoteContainer>
              );
            })
          ) : getQuoteInternalNotesQuery.isLoading ? (
            <Loading size="small" />
          ) : (
            <div>error</div>
          )}
        </StyledUl>
      </MessageContainerBody>
      <QuoteInternalNoteInput
        editModeFocus={editModeFocus}
        onClickSend={onClickSend}
        quoteId={quoteId}
      />
    </MainContainer>
  );
}
