import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Popover} from "@headlessui/react";
import {HTMLAttributes} from "react";
import Surface from "./Surface";

const PopoverWrapper = styled(Popover)`
  position: relative;
`;

type HuiPopoverProps = Parameters<typeof Popover>[0];

export function PopoverContainer(
  props: HTMLAttributes<HTMLDivElement> & HuiPopoverProps
) {
  return <PopoverWrapper as="div" {...props} />;
}

export const PopoverButton = Popover.Button;

interface StyledPopoverPanelProps {
  width?: string;
  minWidth?: string;
  offset?: number;
  direction: "left" | "right";
}

const PopoverPanelWrapper = styled.div<StyledPopoverPanelProps>`
  position: absolute;
  ${({direction}) =>
    direction === "left" &&
    css`
      left: 0;
    `}
  ${({direction}) =>
    direction === "right" &&
    css`
      right: 0;
    `}
  top: ${({offset}) => offset || 30}px;
  z-index: 25;
  width: ${({width}) => width || "fit-content"};
  min-width: ${({minWidth}) => minWidth || "0px"};
`;

type HuiPopoverPanelProps = Parameters<typeof Popover.Panel>[0];

export function PopoverPanel({
  children,
  offset,
  direction,
  width,
  minWidth,
  padding,
}: {
  padding?: string;
} & StyledPopoverPanelProps &
  HuiPopoverPanelProps) {
  return (
    <PopoverPanelWrapper
      width={width}
      minWidth={minWidth}
      offset={offset}
      direction={direction}
    >
      <Popover.Panel>
        {typeof children === "function" ? (
          (...args) => (
            <Surface
              style={{
                maxHeight: "50vh",
              }}
              padding={padding}
            >
              {children(...args)}
            </Surface>
          )
        ) : (
          <Surface
            style={{
              maxHeight: "50vh",
            }}
            padding={padding}
          >
            {children}
          </Surface>
        )}
      </Popover.Panel>
    </PopoverPanelWrapper>
  );
}
