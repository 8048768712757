/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {
  deletePaymentMethod,
  SavedPaymentMethodBasicInfo,
  getPaymentMethods,
} from "api/payment";
import PlusIcon from "@heroicons/react/outline/PlusIcon";
import TrashIcon from "@heroicons/react/solid/TrashIcon";
import Button from "components/Button";
import {Body, Cell, Header, HeaderCell, Row, Table} from "components/Table";
import IconButton from "components/IconButton";
import {permissionsContains} from "utils/auth";
import {usePermissions, useUser} from "hooks/auth";
import {useApiMutation, useApiQuery} from "hooks/api";
import {usePayment} from "hooks/payment";
import {PolicyModIds} from "api/policy";

function SavedPaymentMethods({policyModIds}: {policyModIds: PolicyModIds}) {
  const user = useUser();
  const permissions = usePermissions();
  const methods = useApiQuery(getPaymentMethods)(policyModIds);
  const payment = usePayment({
    policyModIds,
    newPaymentMethodInfo: {
      userId: user.userId,
      agencyId: user.agencyId,
    },
  });
  return (
    <>
      {methods.data && methods.data.length > 0 && (
        <Table checkeredBackground>
          <Header>
            <HeaderCell>Method Description</HeaderCell>
            <HeaderCell>Name Holder</HeaderCell>
            <HeaderCell></HeaderCell>
          </Header>
          <Body>
            {methods.data.map((method, index) => (
              <SavedMethodRow key={index} method={method} />
            ))}
          </Body>
        </Table>
      )}
      {permissionsContains(permissions, "SAVE_PAYMENT_METHOD") && (
        <div
          css={css`
            margin-top: 12px;
            display: flex;
          `}
        >
          <Button
            isLoading={payment.isLoading}
            onClick={payment.addNewPaymentMethod}
            icon={PlusIcon}
          >
            {!payment.isLoading ? "Add New Saved Payment Method" : "Adding..."}
          </Button>
        </div>
      )}
    </>
  );
}

function SavedMethodRow({method}: {method: SavedPaymentMethodBasicInfo}) {
  const deletePaymentMethodMutation = useApiMutation(deletePaymentMethod);

  function onDelete(selectedMethod: SavedPaymentMethodBasicInfo) {
    const wantRemoval = window.confirm(
      `Are you sure to remove ${selectedMethod.description}?`
    );
    if (wantRemoval) {
      deletePaymentMethodMutation.mutate([selectedMethod.payment_method_id]);
    }
  }

  return (
    <Row>
      <Cell>
        {method.description}
        {method.is_set_for_recurring && " (set for recurring)"}
      </Cell>
      <Cell>{method.name_holder}</Cell>
      <Cell
        css={css`
          display: flex;
          align-items: center;
          grid-gap: 8px;
          width: fit-content;
          float: right;
        `}
      >
        {!method.is_set_for_recurring ? (
          <IconButton
            isLoading={deletePaymentMethodMutation.isLoading}
            onClick={() => {
              onDelete(method);
            }}
            background={false}
            icon={TrashIcon}
          />
        ) : null}
      </Cell>
    </Row>
  );
}

export default SavedPaymentMethods;
