/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import XIcon from "@heroicons/react/solid/XIcon";
import {ReactComponent as AeigLogoWhite} from "assets/images/AeigLogoWhite.svg";
import {useNavigation} from "hooks/navigation";
import styled from "@emotion/styled";
import Button from "components/Button";
import UserButton from "components/UserButton";
import {ReactNode, useContext} from "react";
import PageContainer from "./PageContainer";
import {navigationContext} from "contexts/navigation";
import TopbarShellTitle from "components/TopbarShellTitle";

const ScrollContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

export default function TopbarShell({
  title,
  loggedInMode,
  containered,
  pageHeader,
  children,
}: {
  title: string;
  loggedInMode: boolean;
  containered: boolean;
  pageHeader: ReactNode;
  children: ReactNode;
}) {
  const {navigate, startingPoint} = useNavigation();
  const {lastShellUrl} = useContext(navigationContext);

  function handleBack() {
    if (startingPoint) navigate("/");
    else navigate(lastShellUrl);
  }

  return (
    <>
      <div
        css={(theme) => css`
          display: flex;
          background: ${theme.color.brand.primary};
          align-items: center;
          justify-content: space-between;
          color: white;
          padding: 0 20px;
          height: 60px;
          min-height: 60px;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 24px;
            align-items: center;
          `}
        >
          <AeigLogoWhite
            css={css`
              cursor: pointer;
            `}
            onClick={() => navigate("/")}
          />
          {pageHeader !== undefined ? (
            pageHeader
          ) : (
            <TopbarShellTitle>{title}</TopbarShellTitle>
          )}
        </div>
        <div
          css={css`
            display: flex;
            gap: 18px;
          `}
        >
          {loggedInMode && <UserButton background={true} />}
          {loggedInMode && (
            <Button
              size="small"
              color="secondary"
              onClick={handleBack}
              icon={XIcon}
            />
          )}
        </div>
      </div>
      <ScrollContainer>
        {containered ? <PageContainer>{children}</PageContainer> : children}
      </ScrollContainer>
    </>
  );
}
