import {QuoteForm, QuoteTypes} from "api/quote";
import {Form} from "pages/RateQuoteBind/RatePanel";
import UnderwritingForm from "../shared/UnderwritingForm";
import MixedMercantileLocationsForm from "./MixedMercantileLocationForm";
import MixedMercantileCoverageForm from "./MixedMercantileCoverageForm";
import MixedMercantileGeneralLiability from "./MixedMercantileGeneralLiability";

export default function mixedMercantile(quoteForm: QuoteForm): Form {
  return {
    sections: {
      "Risk Location": (
        <MixedMercantileLocationsForm
          locations={quoteForm.locations}
          quoteId={`${quoteForm.quote_id}`}
          quoteType={QuoteTypes.MIXED_MERCANTILE}
        />
      ),
      "Coverage Options": (
        <MixedMercantileCoverageForm
          mixedMercantile={quoteForm.mixed_mercantile}
        />
      ),
      "General Liability": (
        <MixedMercantileGeneralLiability
          mixedMercantile={quoteForm.mixed_mercantile}
        />
      ),
    },
    "Underwriting Questions": (
      <UnderwritingForm
        underwriting={quoteForm.underwriting}
        habitational={quoteForm.habitational}
        mixedMercantile={quoteForm.mixed_mercantile}
      />
    ),
  };
}
