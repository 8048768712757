import states from "assets/data/state.json";

export const sortedStates = states.sort((a, b) => a.name.localeCompare(b.name));
export const causeOfLoss = [
  {label: "SPECIAL", value: "Special"},
  {label: "BASIC", value: "Basic"},
  {label: "BROAD", value: "Broad"},
];
export const protectionClasses = [
  {label: "1", value: "1"},
  {label: "2", value: "2"},
  {label: "3", value: "3"},
  {label: "4", value: "4"},
  {label: "5", value: "5"},
  {label: "6", value: "6"},
  {label: "7", value: "7"},
  {label: "8", value: "8"},
  {label: "9", value: "9"},
  {label: "10", value: "10"},
];
export const constructionTypes = [
  {label: "Fire Resistive", value: "6"},
  {label: "Frame", value: "1"},
  {label: "Joisted Masonry", value: "2"},
  {label: "Masonry Non-Combustible", value: "4"},
  {label: "Modified Fire Resistive", value: "5"},
  {label: "Non-Combustible", value: "3"},
];
export const coinsurancePercents = [
  {label: "80%", value: "80"},
  {label: "90%", value: "90"},
  {label: "100%", value: "100"},
  {label: "Agreed Value", value: "Agreed Value"},
];
export const deductables = ["2500", "5000", "10000"];

export const companyNameMap = [
  {
    title: "American European Insurance Company",
    value: "10",
  },
  {
    title: "Falls Lake Insurance Company",
    value: "12",
  },
];
