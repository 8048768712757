import {ParamType} from "api/common";
import {
  addQuoteObject,
  QuoteResponse,
  updateQuoteObject,
  deleteQuoteObject,
  QuoteStatuses,
  getQuote,
  updateQuote,
} from "api/quote";
import produce from "immer";
import {createContext, ReactNode, useContext, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import merge from "deepmerge";

export interface QuoteConextInerface {
  quoteId: number;
  status: QuoteStatuses;
  isBackendQuoting: boolean;
  underwriterIsQuoting: boolean;
  hasReferralPreRate: boolean;
  hasImmediateDeclination: boolean;
  updating: boolean;
  fieldVisibilityControlEnabled: boolean;
  toggleFieldVisibilityControl: () => void;
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  update: (
    objectName: string,
    objectPath: any[],
    objectId: string,
    field: string,
    value: string | number | boolean
  ) => void;
  add: (props: {objectName: string; params?: ParamType}) => any;
  del: (props: {objectName: string; objectId: string}) => any;
}

export const QuoteContext = createContext({
  quoteId: 0,
  status: QuoteStatuses.QUICK_QUOTE,
  isBackendQuoting: false,
  underwriterIsQuoting: false,
  hasReferralPreRate: false,
  hasImmediateDeclination: false,
  updating: false,
  dirty: false,
  fieldVisibilityControlEnabled: false,
  toggleFieldVisibilityControl: () => {},
  setDirty: (dirty: boolean) => {},
  async update(
    objectName: string,
    objectPath: any[],
    objectId: string,
    field: string,
    value: string | number | boolean | null
  ) {},
  async add(props: {objectName: string; params?: ParamType}) {
    return {object: null, field_rule_results: null};
  },
  async del(props: {objectName: string; objectId: string}) {
    return {};
  },
});

function getObjectPathValue(object: {[key: string]: any}, path: string[]) {
  let value = object;
  path.forEach((pathValue) => (value = value[pathValue]));
  return value;
}

export function QuoteContextProvider({
  quoteId,
  status,
  isBackendQuoting,
  underwriterIsQuoting,
  hasReferralPreRate,
  hasImmediateDeclination,
  children,
}: {
  quoteId: number;
  status: QuoteStatuses;
  isBackendQuoting: boolean;
  underwriterIsQuoting: boolean;
  hasReferralPreRate: boolean;
  hasImmediateDeclination: boolean;
  children: ReactNode;
}) {
  const [dirty, setDirty] = useState(false);
  const [fieldVisibilityControlEnabled, setFieldVisibilityControlEnabled] =
    useState(false);
  const [updating, setUpdating] = useState(false);
  const queryClient = useQueryClient();
  const {mutateAsync: mutateUpdateQuoteObject} = useMutation(updateQuoteObject);

  async function update(
    objectName: string,
    objectPath: any[],
    objectId: string,
    field: string,
    value: string | number | boolean
  ) {
    setUpdating(true);
    const {field_rule_results} = await mutateUpdateQuoteObject({
      quoteId,
      objectName,
      objectId,
      field,
      value,
    });
    if (objectName !== "underwriting") {
      setDirty(true);
    }
    await queryClient.invalidateQueries([getQuote.name, quoteId.toString()]);
    setUpdating(false);
    function updater(quoteResponse: any) {
      return produce(quoteResponse, (draftState: QuoteResponse) => {
        const object = getObjectPathValue(draftState.form_data, objectPath);
        object[field] = value;
        draftState.field_rule_results = merge(
          draftState.field_rule_results,
          field_rule_results
        );
      });
    }
    queryClient.setQueryData([getQuote.name, quoteId.toString()], updater);
    if (objectName !== "underwriting") {
      updateQuote(quoteId);
    }
  }

  async function add({
    objectName,
    params,
  }: {
    objectName: string;
    params?: ParamType;
  }) {
    setDirty(true);
    updateQuote(quoteId);
    return addQuoteObject(quoteId, objectName, params);
  }

  async function del({
    objectName,
    objectId,
  }: {
    objectName: string;
    objectId: string;
  }) {
    setDirty(true);
    updateQuote(quoteId);
    return deleteQuoteObject(quoteId, objectName, objectId);
  }

  function toggleFieldVisibilityControl() {
    setFieldVisibilityControlEnabled((v) => !v);
  }

  return (
    <QuoteContext.Provider
      value={{
        quoteId,
        status,
        isBackendQuoting,
        hasReferralPreRate,
        hasImmediateDeclination,
        underwriterIsQuoting,
        fieldVisibilityControlEnabled,
        toggleFieldVisibilityControl,
        dirty,
        setDirty,
        update,
        add,
        del,
        updating,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
}

export function useQuoteContext() {
  return useContext(QuoteContext);
}
