/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {HTMLAttributes} from "react";

function Center({children, ...props}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1ch;
      `}
    >
      {children}
    </div>
  );
}

export default Center;
