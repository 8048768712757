/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {QuoteStatuses} from "api/quote";
import Button from "components/Button";
import {inBoundStatus, inQuoteStatus} from "utils/quotePolicy";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

function StartAndStopReviewButtons({
  submittingReview,
  activatingReview,
  unsubmittingQuote,
  onActivateReview,
  status,
}: {
  activatingReview: boolean;
  submittingReview: boolean;
  unsubmittingQuote: boolean;
  onActivateReview: (activate: boolean) => void;
  status: QuoteStatuses;
}) {
  const permissions = usePermissions();
  return (
    <>
      {permissions.includes(Permissions.UNDERWRITE_QUOTE) ? (
        !inQuoteStatus(status) &&
        !inBoundStatus(status) &&
        status !== QuoteStatuses.UNDERWRITER_REVIEW ? (
          <Button
            disabled={submittingReview || unsubmittingQuote}
            size="large"
            isLoading={activatingReview}
            css={css`
              margin-bottom: 20px;
            `}
            onClick={() => onActivateReview(true)}
          >
            Start Review
          </Button>
        ) : status === QuoteStatuses.UNDERWRITER_REVIEW ? (
          <Button
            disabled={submittingReview}
            size="large"
            isLoading={activatingReview}
            color="secondary"
            css={css`
              margin-top: 49px;
              margin-bottom: 20px;
            `}
            onClick={() => onActivateReview(false)}
          >
            Stop Review
          </Button>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default StartAndStopReviewButtons;
