/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {getPolicyModIds, getPolicyMods} from "api/policy";
import {LinkTab, LinkTabList} from "components/Tabs";
import PolicyDocuments from "./PolicyDocuments";
import PolicyBilling from "./PolicyBilling";
import PolicyPaymentOverview from "./PolicyPaymentOverview";
import PolicyDetails from "./PolicyDetails";
import Loading from "components/Loading";
import styled from "@emotion/styled";
import {usePermissions} from "hooks/auth";
import {permissionsContains} from "utils/auth";
import {NavigationObject, useNavigation} from "hooks/navigation";
import {PolicyPageParams} from "./policyPageParams";
import {useApiQuery} from "hooks/api";
import {useEffect} from "react";
import links from "links";

const TABS = {
  DETAILS: "details",
  DOCUMENTS: "documents",
  BILLING: "billing",
};

export function PolicyLoading({
  policyNumberQuery,
}: {
  policyNumberQuery: string;
}) {
  const {navigate} = useNavigation();
  const {data: policyModIds} = useApiQuery(getPolicyModIds)(policyNumberQuery);
  useEffect(() => {
    if (policyModIds && policyModIds.length)
      navigate({
        url: links.policy({
          policyModIds: policyModIds[0],
          tab: "details",
        }),
        replace: true,
      });
  }, [policyModIds, navigate, policyNumberQuery]);
  if (policyModIds === null) return <p>Not Found</p>;
  return <Loading />;
}

function Policy({params}: {params: PolicyPageParams}) {
  const permissons = usePermissions();
  const {error, data: policyModList} = useApiQuery(getPolicyMods)(
    params.policyModIds
  );
  const {getMergedUrl} = useNavigation();
  const linkToTab = (tab: string): NavigationObject => ({
    url: getMergedUrl({searchParamsObject: {tab}}),
    replace: true,
  });
  if (policyModList) {
    return (
      <PageConatiner>
        <div
          css={css`
            flex: 1 1 0%;
            width: 0;
          `}
        >
          <div
            css={css`
              margin-bottom: 10px;
            `}
          >
            <LinkTabList primary={true} large={true}>
              <LinkTab to={linkToTab(TABS.DETAILS)}>Details</LinkTab>
              <LinkTab to={linkToTab(TABS.DOCUMENTS)}>Documents</LinkTab>
              <LinkTab to={linkToTab(TABS.BILLING)}>Billing</LinkTab>
            </LinkTabList>
          </div>
          <MainSectionSurface>
            {params.tab === TABS.DETAILS && (
              <PolicyDetails params={params} policyModList={policyModList} />
            )}
            {params.tab === TABS.DOCUMENTS && (
              <PolicyDocuments params={params} policyModList={policyModList} />
            )}
            {params.tab === TABS.BILLING && (
              <PolicyBilling params={params} policyModList={policyModList} />
            )}
          </MainSectionSurface>
        </div>
        {permissionsContains(permissons, "PAYMENT") &&
          params.tab !== TABS.BILLING && (
            <SidebarContainer>
              <SidebarSurface>
                <PolicyPaymentOverview policyModIds={params.policyModIds} />
              </SidebarSurface>
            </SidebarContainer>
          )}
      </PageConatiner>
    );
  }
  if (error) return <div>Error</div>;
  return <Loading size="medium" />;
}

const PageConatiner = styled.div`
  display: flex;
  gap: 16px;
`;

const MainSectionSurface = styled.div`
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
  padding: 24px;
`;

const SidebarContainer = styled.div`
  flex: 1 1 0%;
  max-width: 354px;
  display: none;
  @media (min-width: ${({theme}) => theme.breakpoint.lg}px) {
    display: block;
  }
`;

const SidebarSurface = styled.div`
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
  padding: 24px;
  min-width: 354px;
`;

export default Policy;
