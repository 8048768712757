import {useEffect} from "react";

let debounceTimeout: number;

export function useSearchDebounce({
  enabled,
  deps,
  fetch,
}: {
  enabled: boolean;
  deps: any[];
  fetch: () => void;
}) {
  useEffect(() => {
    if (enabled) fetch();
    // eslint-disable-next-line
  }, deps);
  function search() {
    window.clearTimeout(debounceTimeout);
    debounceTimeout = window.setTimeout(() => {
      fetch();
    }, 300);
  }
  return search;
}
