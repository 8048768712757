import {apiUrl, get, PaginatedResult, post} from "./common";

const baseUrl = `${apiUrl}/policy`;

export async function getPolicyList(limit: string, pageNumber: string) {
  return await get<PaginatedResult<PolicyBasicInfo[]>>(`${baseUrl}`, {
    limit,
    page_number: pageNumber,
  });
}

export async function getPolicyMods(policyIds: PolicyIds) {
  return await get<PolicyBasicInfo[]>(
    `${baseUrl}/${policyIds.display_policy_number}/mods`,
    {...policyIds}
  );
}

export async function searchPolicies(
  limit: string,
  pageNumber: string,
  searchQuery?: string | null
) {
  return await get<PaginatedResult<PolicyBasicInfo[]>>(`${baseUrl}/search`, {
    search_query: searchQuery || "",
    limit,
    page_number: pageNumber,
  });
}

export async function getPolicyModIds(
  policyNumberQuery: string,
  allPolicies?: boolean
) {
  return await get<PolicyModIds[]>(`${baseUrl}/ids/${policyNumberQuery}`, {
    all_policies: allPolicies ? "1" : "0",
  });
}

export async function getPolicyDetails(policyModIds: PolicyModIds) {
  return await get<PolicyDetails>(
    `${baseUrl}/${policyModIds.display_policy_number}/details`,
    {...policyModIds}
  );
}

export async function getPolicyDocuments(policyIds: PolicyIds) {
  return await get<PolicyDocument[]>(
    `${baseUrl}/${policyIds.display_policy_number}/documents`,
    {...policyIds}
  );
}

export async function getPolicyForms(policyModIds: PolicyModIds) {
  return await get<PolicyForm[]>(
    `${baseUrl}/${policyModIds.display_policy_number}/forms`,
    {...policyModIds}
  );
}

export async function getPolicyInstallments(policyModIds: PolicyModIds) {
  return await get<PolicyInstallment[]>(
    `${baseUrl}/${policyModIds.display_policy_number}/installments`,
    {...policyModIds}
  );
}

export async function getPolicyBillingTransactions(policyModIds: PolicyModIds) {
  return await get<PolicyInstallmentTransactions[]>(
    `${baseUrl}/${policyModIds.display_policy_number}/billing_transactions`,
    {...policyModIds}
  );
}

export async function addInsuredPolicy(
  policyNumberQuery: string,
  zipCode: string
) {
  return post<PolicyBasicInfo>(`${baseUrl}/insured_policies`, {
    data: {
      policy_number_query: policyNumberQuery,
      zip_code: zipCode,
    },
  });
}

export async function getAllPoliciesDocumentsByDate(
  fromDate: string,
  toDate: string,
  limit: string,
  pageNumber: string
) {
  return await get<PaginatedResult<PolicyDocument[]>>(`${baseUrl}/documents`, {
    from_date: fromDate,
    to_date: toDate,
    limit,
    page_number: pageNumber,
  });
}

export async function getAllPoliciesDiaryCommentsByDate(
  fromDate: string,
  toDate: string,
  limit: string,
  pageNumber: string,
  destinationInitials: string
) {
  return await get<PaginatedResult<PolicyDiaryComment[]>>(
    `${baseUrl}/diary_comments`,
    {
      from_date: fromDate,
      to_date: toDate,
      limit,
      page_number: pageNumber,
      destination_initials: destinationInitials,
    }
  );
}

export async function getAllPoliciesDiaryCommentsDestinationInitials() {
  return await get<string[]>(`${baseUrl}/diary_comments_destination_initials`);
}

export enum LOB_CODES {
  CP = "CP",
  CA = "CA",
}

export const INSURANCE_LINES_CODES = {
  PR: "PR",
  GL: "GL",
  CR: "CR",
  CA: "CA",
  BO: "BO",
};

export interface PolicyIds {
  pc_provider_name: string;
  policy_id: string;
  policy_number: string;
  display_policy_number: string;
  company_number: string;
  lob: string;
}

export interface PolicyModIds extends PolicyIds {
  mod_number: string;
  display_policy_number: string;
}

export interface PolicyDiaryComment {
  policy_mod_ids: PolicyModIds;
  comment: string;
  comment_requestor: string;
  due_date: string;
  comment_destination: string;
  comment_type: string;
}
export interface PolicyBasicInfo {
  policy_mod_ids: PolicyModIds;
  insured_name: string;
  effective_date: string;
  expiration_date: string;
  premium_amount: string;
  status: string;
  payment_status: boolean;
  remaining_balance: number | null;
  balance_due: boolean | null;
}

export interface GeneralLiability extends Named {
  premium: string;
  aggregate_limit: string;
  occurrence_limit: string;
}

export interface PrBuildingCoverage {
  name: string;
  limit: string;
  premium: string;
}

export interface PrBuilding {
  number: string;
  building_valuation: string;
  cause_of_loss: string;
  coinsusrance: string;
  building_coverages: PrBuildingCoverage[];
  deductible: string;
}

export interface PrLocation {
  number: string;
  address_info: AddressInfo;
  deductible: string;
  windhail_deductible: string;
  buildings: PrBuilding[];
}

export interface Named {
  name: string;
  code: string;
  terrorism_coverage: boolean;
  terrorism_coverage_premium: string | number;
}

export interface CommercialProperty extends Named {
  terrorism_coverage: boolean;
  premium: string;
  locations: PrLocation[];
  terrorism_coverage_premium: string | number;
  credit_and_debits_flag: boolean;
}

export interface InsuranceLines {
  [key: string]: Named;
}

export interface CommercialCrime extends Named {
  name: string;
  limit: string;
  premium: string;
}

export interface CommercialPackageLines extends InsuranceLines {
  general_liability: GeneralLiability;
  commercial_property: CommercialProperty;
  commercial_crime: CommercialCrime;
  commercial_auto: CommercialAuto;
}

export interface CaVehicleCoverage {
  name: string;
  limit: string;
  limit_desc: string;
  premium: string;
  deductible: string;
}

export interface CaLiabCoverage {
  name: string;
  limit: string;
  premium: string;
}

export interface CaVehicle {
  number: string;
  address_info: AddressInfo;
  year: string;
  make_and_model: string;
  state: string;
  class_code: string;
  type: string;
  vin_number: string;
  dmv_plate_number: string;
  premium: string;
  vehicle_coverages: CaVehicleCoverage[];
}

export interface CommercialAuto extends Named {
  premium: string;
  liab_coverages: CaLiabCoverage[];
  drivers: CaDriver[];
  vehicles: CaVehicle[];
}

export interface CommercialAutoLines extends InsuranceLines {
  commercial_auto: CommercialAuto;
}

export interface PolicyDetails {
  policy_mod_ids: PolicyModIds;
  policy_information: PolicyInfo;
  insurance_lines?: InsuranceLines;
  forms?: PolicyForm[];
}

export interface PolicyDocument {
  policy_mod_ids: PolicyModIds;
  insured_name: string;
  url: string;
  type: string;
  catergory: string;
  version: string;
  effective_date: string;
  generated_date: string;
  generated_date_label: string;
}

export interface PolicyForm {
  name: string;
  description: string;
  edition: string;
}

export interface PolicyInfo {
  status: string;
  effective_date: string;
  expiration_date: string;
  lob_name: string;
  insured_info: InsuredInfo;
  agent_info: AgentInfo;
  premium_amount: string;
  payment_plan: string;
  total_annual_surcharge: string;
}

interface CaDriver {
  name: string;
  address_info: AddressInfo;
}

interface InsuredInfo {
  name: string;
  doing_bussines: string;
  address_info: AddressInfo;
  phone_number: string;
}

interface AgentInfo {
  name: string;
  address_info: AddressInfo;
  phone_number: string;
}

interface AddressInfo {
  line_1: string;
  state: string;
  city: string;
  zip: string;
  line_2?: string;
}

export interface PolicyBillingTransaction {
  seq_number: number;
  transaction_name: string;
  transaction_document_url: string | null;
  transaction_date: string;
  notice_amount: string;
  amount: string;
  balance: string;
  entered_date: string;
  effective_date: string;
}

export interface PolicyInstallment {
  installment_number: number;
  installment_due_date: string;
  amount: string;
}

export interface PolicyInstallmentTransactions {
  installment_number: number;
  installment_due_date: string;
  balance: string;
  transactions: PolicyBillingTransaction[];
  transactions_only: boolean;
}

export enum PolicyStatuses {
  IN_FORCE = "IN FORCE",
  RENEWED = "RENEWED",
  NON_RENEWED = "NON RENEWED",
  RENEWAL = "RENEWAL",
  CANCELLED = "CANCELLED",
  NEW_BUSINESS = "NEW BUSINESS",
}
