const theme = {
  text: {
    header1: "font-size: 28px; line-height: 120%;",
    header2: "font-size: 24px; line-height: 120%;",
    header3: "font-size: 16px; line-height: 120%;",
    table:
      "font-size: 10px; line-height: 120%;letter-spacing: 0.03em;  text-transform: uppercase; font-weight: bold;",
    p1: "font-size: 16px; line-height: 140%;",
    p2: "font-size: 14px; line-height: 140%;",
    p3: "font-size: 12px; line-height: 120%;",
    button1: "font-size: 14px; line-height: 120%;",
    button2: "font-size: 12px; line-height: 120%;",
  },
  color: {
    grayscale: {
      900: "#191a1a",
      800: "#252626",
      600: "#757676",
      500: "#a3a3a3",
      300: "#e5e5e5",
      100: "#f7f7f796",
      white: "#ffffff",
    },
    brand: {
      primary: "#00206e",
      secondary: "#d1eaff",
      tertiary: "#f7f9fd",
    },
    state: {
      blue: "#304ffe",
      green: "#4caf50",
      orange: "#ff9100",
      red: "#ff5252",
    },
  },
  breakpoint: {
    xxs: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1440,
    xxxl: 1920,
  },
};

export type AeigTheme = typeof theme;

declare module "@emotion/react" {
  export interface Theme extends AeigTheme {}
}

export default theme;
