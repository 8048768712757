import {RadioGroup} from "@headlessui/react";
import styled from "@emotion/styled";
import {ReactComponent as CircleIcon} from "assets/images/circle.svg";
import {ReactComponent as CheckCircleIcon} from "assets/images/check-circle.svg";
import classNames from "classnames";
import {Fragment} from "react";
import {HuiToFC} from "typeUtils";
import Spacer from "./Spacer";

export const SelectionGroupContainer = styled(
  RadioGroup as HuiToFC<typeof RadioGroup>
)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SelectionGroupLabel = styled(RadioGroup.Label)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
` as unknown as typeof RadioGroup.Label;

type RadioGroupOptionProps = Parameters<typeof RadioGroup.Option>[0];

export const StyledSelectionGroupOption = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.color.brand.tertiary};
  border-radius: 4px;
  height: 65px;
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  padding: 15px 15px 15px 70px;

  :hover {
    background-color: #eff8ff;
  }

  &.checked {
    background-color: ${({theme}) => theme.color.brand.secondary};

    :hover {
      background-color: ${({theme}) => theme.color.brand.secondary}C9;
    }
  }
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${({theme}) => theme.color.brand.primary};
  width: 20px;
  position: absolute;
  right: 92%;
`;

const StyledCircleIcon = styled(CircleIcon)`
  color: ${({theme}) => theme.color.brand.primary};
  width: 20px;
  position: absolute;
  right: 92%;
`;

export function SelectionGroupOption({
  value,
  children,
  ...props
}: RadioGroupOptionProps) {
  return (
    <RadioGroup.Option as={Fragment} value={value} {...props}>
      {({checked, active, disabled}) => (
        <StyledSelectionGroupOption className={classNames({active, checked})}>
          {checked ? <StyledCheckCircleIcon /> : <StyledCircleIcon />}
          {typeof children == "function"
            ? children({checked, active, disabled})
            : children}
        </StyledSelectionGroupOption>
      )}
    </RadioGroup.Option>
  );
}

export function SelectionGroupOptionTitle({
  title,
  secondary,
  swapOrder,
  message,
}: {
  title: string;
  secondary?: string;
  swapOrder?: boolean;
  message?: string;
}) {
  const titleElement = <Title>{title}</Title>;
  const secondaryElement = <Secondary>{secondary}</Secondary>;
  const mainElement = [
    titleElement,
    secondary && <Spacer height={3} />,
    secondary && secondaryElement,
  ];
  if (swapOrder) mainElement.reverse();
  return (
    <>
      {mainElement}
      {message && <Message>{message}</Message>}
    </>
  );
}

const Title = styled.div`
  color: ${({theme}) => theme.color.brand.primary};
  ${({theme}) => theme.text.p1};
  font-weight: bold;
`;

const Secondary = styled.div`
  color: ${({theme}) => theme.color.brand.primary};
`;

const Message = styled.div`
  color: ${({theme}) => theme.color.brand.primary};
  font-size: 12px;
`;
