/** @jsxImportSource @emotion/react */

import {registerInsured} from "api/auth";
import Button from "components/Button";
import {Anchor, H1, Text} from "components/elements";
import {useNavigation} from "hooks/navigation";
import {useMutation, useQueryClient} from "react-query";
import {css} from "@emotion/react";
import SplitScreen from "components/SplitScreen";
import buildingsImage from "assets/images/buildings.jpg";
import {useForm} from "react-hook-form";
import {ReactComponent as AeigLogo} from "assets/images/AeigLogo.svg";
import AlertBar from "components/AlertBar";
import ControlledTextInput from "components/ControlledTextInput";
import {validatePasswordStrength} from "utils/auth";

interface InsuredRegisterFormFields {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  policyNumber: string;
  zipCode: string;
}

export default function InsuredRegister() {
  const queryClient = useQueryClient();
  const {navigate} = useNavigation();

  const {getValues, handleSubmit, control} =
    useForm<InsuredRegisterFormFields>();

  const insuredRegisterMutation = useMutation(
    (formData: InsuredRegisterFormFields) =>
      registerInsured(
        formData.name,
        formData.email,
        formData.password,
        formData.policyNumber,
        formData.zipCode
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("auth_session");
        navigate("/policy");
      },
    }
  );

  const onSubmit = handleSubmit((formData) =>
    insuredRegisterMutation.mutateAsync(formData)
  );

  return (
    <SplitScreen imageurl={buildingsImage}>
      <form
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
        `}
        onSubmit={onSubmit}
        onFocus={insuredRegisterMutation.reset}
      >
        <Anchor href={process.env.REACT_APP_AEIG_INSURANCE_WEBSITE_LINK}>
          <AeigLogo />
        </Anchor>
        <H1>Register</H1>
        <ControlledTextInput
          name={"name"}
          control={control}
          label={"Name"}
          required
          rules={{
            required: true,
          }}
        />
        <ControlledTextInput
          name={"email"}
          control={control}
          label={"Email"}
          type="email"
          required
          rules={{
            required: true,
          }}
        />
        <ControlledTextInput
          type="password"
          name={"password"}
          control={control}
          label={"Password"}
          rules={{
            required: true,
            validate: (value: string) => {
              const rulesResultList = validatePasswordStrength(value);
              return rulesResultList.length === 0 || rulesResultList.join("; ");
            },
          }}
        />
        <ControlledTextInput
          type="password"
          name={"confirmPassword"}
          control={control}
          label={"Confirm Password"}
          required
          rules={{
            validate: (value: string) =>
              value === getValues("password") || "The passwords do not match",
            required: true,
          }}
        />
        <div
          css={css`
            padding: 12px 0px;
          `}
        >
          <Text size={1}>
            For verification please enter your policy number and billing zip
            code:
          </Text>
          <div
            css={css`
              display: flex;
              align-content: stretch;
              padding: 16px 0px;
              gap: 10px;
            `}
          >
            <div
              css={css`
                flex-grow: 1;
              `}
            >
              <ControlledTextInput
                name={"policyNumber"}
                control={control}
                label={"Policy Number"}
                required
                rules={{
                  required: true,
                }}
              />
            </div>
            <ControlledTextInput
              name={"zipCode"}
              control={control}
              label={"Zip Code"}
              required
              rules={{
                required: true,
              }}
            />
          </div>
          <div
            css={css`
              font-size: 12px;
            `}
          >
            After registering you will have the ability to add more policies to
            your account.
          </div>
        </div>
        {/* This is only a temporary way to handle API error messages */}
        {insuredRegisterMutation.isError &&
          typeof insuredRegisterMutation.error === "string" &&
          !insuredRegisterMutation.error.includes("Traceback") && (
            <AlertBar status="error">{insuredRegisterMutation.error}</AlertBar>
          )}
        <Button
          fullwidth
          size="large"
          type="submit"
          isLoading={insuredRegisterMutation.isLoading}
        >
          Submit
        </Button>
      </form>
    </SplitScreen>
  );
}
