import {useEffect, useState} from "react";

declare global {
  interface Window {
    $: any;
  }
}

function useOneincDialog(
  initEventHandlers: (container: OneincContainer) => void
): OneincDialog | null {
  const [dialog, setDialog] = useState<OneincDialog | null>(null);
  useEffect(() => {
    const container = window.$("#portalPaymentContainer");
    container.portalOne();
    // remove all old handlers for case when dialog is reopened to avoid double handling
    container.off();
    initEventHandlers(container);
    setDialog(container.data("portalOne"));
    return () => {
      container.off();
    };
  }, [initEventHandlers]);
  return dialog;
}

interface OneincContainer {
  off: () => void;
  on: (eventName: string, callback: (e: any, data: any) => void) => void;
}

interface OneincDialog {
  makePayment: (...args: any[]) => any;
  savePaymentMethod: (...args: any[]) => any;
}

export interface PaymentModalTransaction {
  tokenId: string | null;
  description: string;
  paymentCategory: string;
  accountType: string;
  cardType: string;
  holderZip: string;
  customerName: string;
  authCode: string;
  cardExpirationYear: string;
  cardExpirationMonth: string;
  lastFourDigits: string;
  bankName: string;
  transactionId: string;
  sessionId: string;
  paymentAmount: string;
  transactionDate: string;
  timeZone: string;
  acknowledge: () => void;
}

export interface OneInceClientReferenceData {
  clientReferenceData1: string;
  clientReferenceData2: string;
  clientReferenceData3: string;
  clientReferenceData4?: string;
}

function getPaymentModalTransaction(data: any) {
  const source = data.cardType ? data.cardType : data.accountType;
  const description = `${source} ending in ${data.lastFourDigits}`;
  return {
    source,
    description,
    ...data,
  };
}

export function usePortalOneModal({
  sessionId,
  paymentMethodType,
  selectedAmount,
  showSaveOption,
  clientReferenceData,
  onPaymentComplete,
  onSaveComplete,
}: {
  sessionId: string;
  paymentMethodType?: "BANK" | "CREDIT";
  selectedAmount?: number;
  showSaveOption?: boolean;
  clientReferenceData: OneInceClientReferenceData;
  onPaymentComplete?: (data: PaymentModalTransaction) => void;
  onSaveComplete?: (data: PaymentModalTransaction) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const dialog = useOneincDialog((container) => {
    container.on("portalOne.load", function () {
      setTimeout(() => setIsLoading(false), 2000);
    });
    container.on("portalOne.paymentComplete", function (e, data) {
      onPaymentComplete && onPaymentComplete(getPaymentModalTransaction(data));
    });
    container.on(
      "portalOne.saveComplete",
      async (e, data) =>
        onSaveComplete && onSaveComplete(getPaymentModalTransaction(data))
    );
    container.on("portalOne.unload", () => setIsLoading(false));
    container.on("portalOne.error", function (e, data) {});
  });

  function makePayment() {
    setIsLoading(true);
    dialog?.makePayment({
      paymentCategory: paymentMethodType === "BANK" ? "ECheck" : "CreditCard",
      feeContext:
        paymentMethodType === "BANK" ? "PaymentWithoutFee" : "PaymentWithFee",
      amountContext: "AmountDueOnly",
      amountContextDefault: "minimumAmountDue",
      minAmountDue: `${selectedAmount}`,
      ...clientReferenceData,
      confirmationDisplay: "false",
      saveOption: showSaveOption ? "UserSelect" : "DoNotSave",
      acknowledgmentRequired: "true",
      sessionId: sessionId,
    });
  }

  function addNewMethod() {
    setIsLoading(true);
    dialog?.savePaymentMethod({
      paymentCategory: "UserSelect",
      ...clientReferenceData,
      confirmationDisplay: "false",
      saveOption: "UserSelect",
      acknowledgmentRequired: "true",
      sessionId,
    });
  }

  return {
    isLoading,
    makePayment,
    addNewMethod,
  };
}
