/** @jsxImportSource @emotion/react */

import {TextInput} from "components/TextInput";
import {css} from "@emotion/react";
import {formatAmount} from "utils/format";
import {
  SelectionGroupContainer,
  SelectionGroupLabel,
  SelectionGroupOption,
  SelectionGroupOptionTitle,
} from "components/SelectionGroup";

export default function PaymentAmountSelector({
  availableAmounts,
  selectedAmountDescription,
  customAmount,
  setCustomAmount,
  onChange,
  onError,
  disabled,
}: {
  availableAmounts: {
    amount: number;
    description: string;
  }[];
  customAmount: number;
  setCustomAmount: (value: number) => void;
  selectedAmountDescription: string;
  onChange: (value: string) => void;
  onError: (value: boolean) => void;
  disabled: boolean;
}) {
  return (
    <SelectionGroupContainer
      disabled={disabled}
      value={selectedAmountDescription}
      onChange={onChange}
    >
      <SelectionGroupLabel>Choose Amount:</SelectionGroupLabel>
      {availableAmounts.map((amount) => (
        <SelectionGroupOption value={amount.description}>
          <SelectionGroupOptionTitle
            title={formatAmount(amount.amount, {
              showZero: true,
            })}
            secondary={amount.description}
            swapOrder
          />
        </SelectionGroupOption>
      ))}
      <SelectionGroupOption value="customAmount">
        {(s) => (
          <div
            css={css`
              width: 300px;
            `}
          >
            <TextInput
              type="number"
              prefix="$"
              placeholder="Enter amount"
              background={true}
              disabled={!s.checked}
              value={customAmount}
              onBlur={(e) => {
                setCustomAmount(Number(e.target.value));
              }}
            />
          </div>
        )}
      </SelectionGroupOption>
    </SelectionGroupContainer>
  );
}
