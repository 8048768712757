/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";

function Divider(props: any) {
  return (
    <div
      css={(theme) => css`
        height: 1px;
        background-color: ${theme.color.grayscale[900]};
        opacity: 0.1;
      `}
      {...props}
    ></div>
  );
}

export default Divider;
