/** @jsxImportSource @emotion/react */

import {useNavigation} from "hooks/navigation";
import {css} from "@emotion/react";
import {
  QuoteStatuses,
  activateQuoteReview,
  submitQuoteReview,
  unsubmitQuote,
  FieldRuleResults,
  RuleResultType,
  getQuote,
} from "api/quote";
import {useMutation, useQueryClient} from "react-query";
import {RateTabs} from "../components/RateQuoteBindTopBar";
import ApprovedContent from "./ApprovedContent";
import ReferredContent from "./ReferredContent";
import DeclinedContent from "./DeclinedContent";
import QuotingErrorContent from "./QuotingErrorContent";
import {inApprovedStatus} from "utils/quotePolicy";
import AlertBar from "components/AlertBar";
import Box from "components/Box";
import StartAndStopReviewButtons from "./StartAndStopReviewButtons";

export default function UnderwritingPanel({
  quoteID,
  userID,
  uwID,
  status,
  fieldRuleResults,
}: {
  quoteID: number;
  userID: number;
  uwID: number;
  status: QuoteStatuses;
  fieldRuleResults: FieldRuleResults;
}) {
  const {updateSearchParams} = useNavigation();
  const queryClient = useQueryClient();

  const {mutateAsync: mutateActivateReview, isLoading: activatingReview} =
    useMutation((flag: boolean) => activateQuoteReview(quoteID, flag), {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          getQuote.name,
          quoteID.toString(),
        ]);
      },
    });

  const {mutateAsync: mutateSubmitReview, isLoading: submittingReview} =
    useMutation((flag: boolean) => submitQuoteReview(quoteID, flag), {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          getQuote.name,
          quoteID.toString(),
        ]);

        if (data.status === QuoteStatuses.DECLINED) {
          updateSearchParams({tab: RateTabs.RATE});
        }

        if (data.status === QuoteStatuses.APPROVED) {
          updateSearchParams({tab: RateTabs.SUMMARY});
        }
      },
    });

  const {mutateAsync: mutateUnSubmitQuote, isLoading: unsubmittingQuote} =
    useMutation(() => unsubmitQuote(quoteID), {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          getQuote.name,
          quoteID.toString(),
        ]);
        updateSearchParams({tab: RateTabs.RATE});
      },
    });

  

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      {status === QuoteStatuses.BOUND && (
        <Box width="600px" my={20}>
          <AlertBar status="success">This is a bound quote.</AlertBar>
        </Box>
      )}
      <StartAndStopReviewButtons
        submittingReview={submittingReview}
        activatingReview={activatingReview}
        unsubmittingQuote={unsubmittingQuote}
        onActivateReview={mutateActivateReview}
        status={status}
      />
      {inApprovedStatus(status) ? (
        <ApprovedContent     
          unsubmittingQuote={unsubmittingQuote}
          status={status}
          onSummary={() => updateSearchParams({tab: RateTabs.SUMMARY})}
          onUnSubmitQuote={mutateUnSubmitQuote}
        />
      ) : [QuoteStatuses.REFERRED, QuoteStatuses.UNDERWRITER_REVIEW].includes(
          status
        ) ? (
        <ReferredContent
          status={status}
          uwID={uwID}
          userID={userID}
          fieldRuleResults={fieldRuleResults}
          activatingReview={activatingReview}
          submittingReview={submittingReview}
          unsubmittingQuote={unsubmittingQuote}
          onActivateReview={mutateActivateReview}
          onSubmitReview={mutateSubmitReview}
          onUnSubmitQuote={mutateUnSubmitQuote}
        />
      ) : status === QuoteStatuses.DECLINED ? (
        <DeclinedContent
          fieldRuleResults={fieldRuleResults}
          unsubmittingQuote={unsubmittingQuote}
          submittingReview={submittingReview}
          onSubmitReview={mutateSubmitReview}
          onUnSubmitQuote={mutateUnSubmitQuote}
        />
      ) : status === QuoteStatuses.QUOTING_ERROR ? (
        <QuotingErrorContent
          unsubmittingQuote={unsubmittingQuote}
          onUnSubmitQuote={mutateUnSubmitQuote}
        />
      ) : null}
    </div>
  );
}

export function ruleResultsToList(
  fieldRuleResults: FieldRuleResults,
  resultType?: RuleResultType
) {
  const messages = [];
  for (let object in fieldRuleResults) {
    for (let objectId in fieldRuleResults[object]) {
      for (let fieldKey in fieldRuleResults[object][objectId]) {
        const ruleResult = fieldRuleResults[object][objectId][fieldKey];
        if (
          ruleResult &&
          (!resultType || ruleResult.result_type === resultType)
        ) {
          messages.push(ruleResult);
        }
      }
    }
  }
  return messages;
}
