import {FieldMeta, ObjectBase, getQuote, updateQuoteFieldMeta} from "api/quote";
import {useMemo} from "react";
import {useQuoteContext} from "../../contexts/quoteContext";
import {useFieldMetaContext} from "../../contexts/fieldMetaContext";
import {useMutation, useQueryClient} from "react-query";

export function useFieldMeta<T extends ObjectBase>(object: T, field: keyof T) {
  const queryClient = useQueryClient();
  const {quoteId} = useQuoteContext();
  const {getFieldPermission} = useFieldMetaContext();

  const permission = useMemo(
    () => getFieldPermission(object.object_name, object.id, field as string),
    [field, getFieldPermission, object.id, object.object_name]
  );

  const {mutateAsync: mutateUpdateFieldMeta, isLoading: updatingFieldMeta} =
    useMutation(
      (data: FieldMeta) =>
        updateQuoteFieldMeta(
          quoteId,
          object.object_name,
          object.id,
          field as string,
          data
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getQuote.name, String(quoteId)]);
        },
      }
    );

  return {
    permission,
    mutateUpdateFieldMeta,
    updatingFieldMeta,
  };
}
