import styled from "@emotion/styled";
import {getFinanceReportsByDateFolder} from "api/document";
import {Anchor} from "components/elements";
import {useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import {DropDown} from "components/DropDown";
import {Combobox} from "components/Combobox";
import {getDateRelativeToToday} from "utils/date";
import {useMemo} from "react";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const year_list = () => {
  const years = [];
  for (
    let year = getDateRelativeToToday(0).getFullYear();
    year > 1980;
    year--
  ) {
    years.push(year.toString());
  }
  return years;
};
const day_list = () => {
  const days = [];
  for (let day = 1; day < 32; day++) {
    days.push(day.toString().padStart(2, "0"));
  }
  return days;
};
const getListOfFolders = (result_list: any) => {
  const unique: any = [""];
  result_list.forEach((key_object: any) => {
    const folder_parts = key_object.file_name.split("/");
    const folder_name = folder_parts[folder_parts.length - 2];
    if (!unique.includes(folder_name) && folder_name.split("-").length < 2) {
      unique.push(folder_name);
    }
  });
  return unique.map((folder_name: any) => {
    return {
      name: folder_name || "Folders",
      value: folder_name,
    };
  });
};
const getFileName = (file_path: string) => {
  const parts = file_path.split("/");
  const fileNameWithExtension =
    parts[parts.length - 2] + " " + parts[parts.length - 1];
  return fileNameWithExtension;
};

const getFolderName = (file_path: string) => {
  const parts = file_path.split("/");
  return parts[parts.length - 2];
};

const StyledLink = styled.h4`
  margin-left: 20px;
`;

export default function FinanceReports({
  year,
  month,
  folderName,
  day,
}: {
  year: string;
  month: string;
  folderName: string;
  day: string;
}) {
  const financeReportsQuery = useApiQuery(getFinanceReportsByDateFolder, {
    keepPreviousData: true,
  })(year, month, day, folderName);

  const {updateSearchParams} = useNavigation();
  const result_list = getListOfFolders(financeReportsQuery?.data || []);
  const folderNameWithTitle = useMemo(() => {
    return result_list.find((folder_name: any) => {
      return folder_name.value === folderName;
    });
  }, [result_list, folderName]);
  return (
    <PageContainer>
      <ToolbarContainer>
        <DropDown
          background={true}
          options={year_list().map((yearOption) => ({
            title: yearOption,
            value: yearOption,
          }))}
          onChange={(value) =>
            updateSearchParams({
              year: value,
            })
          }
          label={"Year"}
          value={year.toString()}
        />
        <DropDown
          background={true}
          options={[
            {value: "01", title: "January"},
            {value: "02", title: "February"},
            {value: "03", title: "March"},
            {value: "04", title: "April"},
            {value: "05", title: "May"},
            {value: "06", title: "June"},
            {value: "07", title: "July"},
            {value: "08", title: "August"},
            {value: "09", title: "September"},
            {value: "10", title: "October"},
            {value: "11", title: "November"},
            {value: "12", title: "December"},
          ]}
          onChange={(value) =>
            updateSearchParams({
              month: value,
            })
          }
          label={"Month"}
          value={month.toString()}
        />
        <DropDown
          background={true}
          options={day_list().map((dayOption) => ({
            title: dayOption,
            value: dayOption,
          }))}
          onChange={(value) =>
            updateSearchParams({
              day: value,
            })
          }
          label={"Day"}
          value={day.toString()}
        />
      </ToolbarContainer>
      <Combobox
        background={true}
        label="Folder"
        disabled={result_list.length === 0}
        options={
          result_list.map((folder: any) => ({
            title: folder.name,
            value: folder.value,
          })) || []
        }
        onChange={(value: any) => {
          updateSearchParams({
            folderName: value ? value.value : "",
          });
        }}
        value={{
          title: folderNameWithTitle?.name,
          value: folderNameWithTitle?.value,
        }}
        autoComplete="off"
      />
      {financeReportsQuery.data?.map((financeReports: any) => {
        if (financeReports.file_name.endsWith("/")) {
          return <h4>{getFolderName(financeReports.file_name)}</h4>;
        } else {
          return (
            <StyledLink>
              <Anchor href={`${financeReports.file_link}`} target="_blank">
                {getFileName(financeReports.file_name)}
              </Anchor>
            </StyledLink>
          );
        }
      })}
    </PageContainer>
  );
}
