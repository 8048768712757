import {PaginatedResult, ParamType, apiUrl, get, post} from "./common";

const baseUrl = `${apiUrl}/reports`;

export async function getReportsList(
  searchQuery: string,
  limit: string,
  pageNumber: string
) {
  return await get<
    PaginatedResult<{name: string; title: string; config: ReportConfig}[]>
  >(baseUrl, {
    report_name_query: searchQuery || "",
    page_number: pageNumber,
    limit,
  });
}

export async function getReportConfig(reportName: string) {
  return get<ReportConfig>(`${baseUrl}/${reportName}/config`);
}

export async function getReportResult(reportName: string, params: ParamType) {
  return get<ReportResult>(
    `${baseUrl}/${reportName}/results`,
    getParams(params)
  );
}

export function getReportHtmlUrl(reportName: string, params: ParamType) {
  return `${baseUrl}/${reportName}/html/view?${new URLSearchParams(
    getParams(params)
  )}`;
}

export async function getReportHtml(reportName: string, params: ParamType) {
  const res = await fetch(getReportHtmlUrl(reportName, params), {
    credentials: "include",
  });
  return res.text();
}

export async function getFilterListResults(
  reportName: string,
  listName: string
) {
  return get<FilterListItem[] | null>(
    `${baseUrl}/${reportName}/filter_list/${listName}`
  );
}

export async function emailReport(reportName: string, params: ParamType) {
  return post<{sent: boolean}>(
    `${baseUrl}/${reportName}/email?${new URLSearchParams(getParams(params))}`,
    {}
  );
}

export async function getPdfReport(reportName: string, params: ParamType) {
  return get<{url: string; filename: string}>(
    `${baseUrl}/${reportName}/pdf?${new URLSearchParams(getParams(params))}`
  );
}

export function getReportPdfDownloadUrl(reportName: string, params: ParamType) {
  return `${baseUrl}/${reportName}/pdf/download?${new URLSearchParams(
    getParams(params)
  )}`;
}

export function runReportAction(
  reportName: string,
  actionName: string,
  params: ParamType
) {
  return post<{message: string}>(
    `${baseUrl}/${reportName}/actions/${actionName}`,
    params
  );
}

export interface ColumnConfig {
  name: string;
  title: string;
  policy_link?: boolean;
  line_break?: boolean;
}

export interface ActionConfig {
  name: string;
  title: string;
}

interface FilterListItem {
  title: string;
  value: string;
}

export interface FilterConfig {
  name: string;
  title: string;
  type: "date" | "dropdown" | "policy_number_search";
  default?: string | number;
  required?: boolean;
  list?: string | FilterListItem[];
}

export interface ReportConfig {
  title: string;
  columns: ColumnConfig[];
  filters: FilterConfig[];
  actions: ActionConfig[];
  html_report?: boolean;
}

export interface ReportResult {
  paginated_result?: PaginatedResult<any> | null;
  report_totals?: {[key: string]: string | number} | null;
  html_result?: string | null;
}

function getParams(params: ParamType) {
  if (params.pageNumber) params.page_number = params.pageNumber;

  return params;
}
