/** @jsxImportSource @emotion/react */

import {ObjectBase} from "api/quote";
import {css} from "@emotion/react";
import {DOMAttributes} from "react";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {useFieldMeta} from "./hooks/useFieldMeta";
import Button from "components/Button";
import {useQuoteContext} from "../contexts/quoteContext";
import {inApprovedStatus} from "utils/quotePolicy";

export function HiddenMetaButton<T extends ObjectBase>({
  object,
  field,
  focus,
  top = 8,
  right = 12,
  ...props
}: {
  object: T;
  field: keyof T;
  focus: boolean;
  top?: number;
  right?: number;
} & DOMAttributes<HTMLButtonElement>) {
  const {status, fieldVisibilityControlEnabled} = useQuoteContext();
  const {permission, mutateUpdateFieldMeta, updatingFieldMeta} = useFieldMeta(
    object,
    field
  );
  const permissions = usePermissions();

  const toggleHiddenMeta = () => {
    mutateUpdateFieldMeta({hidden: !permission?.hidden});
  };

  if (
    !fieldVisibilityControlEnabled ||
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS) ||
    (!permission?.hidden && !focus) ||
    inApprovedStatus(status)
  ) {
    return <></>;
  }

  return (
    <Button
      {...props}
      background={false}
      size="xsmall"
      color={permission?.hidden ? "danger" : "primary"}
      isLoading={updatingFieldMeta}
      css={css`
        position: absolute;
        top: ${top}px;
        right: ${right}px;
      `}
      onClick={toggleHiddenMeta}
    >
      {permission?.hidden ? "Hidden" : "Visible"}
    </Button>
  );
}

export default HiddenMetaButton;
