import {apiUrl, del, get, ParamType, post, put} from "./common";

const baseUrl = `${apiUrl}/messages`;
const THREAD = "thread";
const MESSAGE = "message";

export async function postMessage(data: UploadMessage) {
  return await post<{message_id: number}>(
    `${baseUrl}/${THREAD}/${data.thread_id}/${MESSAGE}`,
    {
      data,
    }
  );
}

export async function getThreadMessages({
  threadId,
  pageNo,
  messageId,
}: {
  threadId: number;
  pageNo?: number;
  messageId?: number | null;
}) {
  return await get<ThreadMessagesPagination>(
    `${baseUrl}/${THREAD}/${threadId}/${MESSAGE}`,
    {
      pageNo: pageNo ? pageNo.toString() : "",
      message_id: messageId ? messageId.toString() : "",
      limit: "50",
    }
  );
}

export enum ThreadListFilter {
  ALL = "ALL",
  UNREAD = "UNREAD",
  MY_THREADS = "MY_THREADS",
}

export async function getThreads({
  searchValue,
  selectedFilter,
  pageNumber,
}: {
  selectedFilter?: ThreadListFilter;
  searchValue?: string;
  pageNumber?: number;
} = {}) {
  const params: ParamType = {
    page: pageNumber ? pageNumber.toString() : "1",
    limit: "150",
  };
  if (
    selectedFilter === ThreadListFilter.ALL ||
    selectedFilter === ThreadListFilter.UNREAD
  ) {
    params["unread"] = selectedFilter === ThreadListFilter.ALL ? "0" : "1";
  }
  if (selectedFilter === ThreadListFilter.MY_THREADS) {
    params["my_threads"] = "1";
  }
  if (searchValue) {
    params["search"] = searchValue;
  }
  return await get<ThreadsPagination>(`${baseUrl}/${THREAD}`, params);
}

export async function getThread(threadId: number) {
  return await get<Thread>(`${baseUrl}/${THREAD}/${threadId}`);
}

export async function markAsRead({messagesIds}: {messagesIds: number[]}) {
  return await put<{messages_ids: number[]}>(`${baseUrl}/read`, {
    data: {messages_ids: messagesIds},
  });
}

export async function uploadAttachments(data: {files: string[]}) {
  return await post<UploadAttachments>(`${baseUrl}/upload_attachments`, {
    data,
  });
}

export async function uploadFileToS3(uploadUrl: string, file: File) {
  return fetch(uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
}

export async function deleteAttachment(s3Key: string) {
  return await del<string>(`${baseUrl}/delete_attachment?s3_key=${s3Key}`);
}

export interface Thread {
  id: number;
  title: string;
  last_message: ThreadMessage | null;
  last_unread_message_id?: number | null;
  unread_messages_count?: number | null;
  quote?: Quote | null;
}

export interface Quote {
  id: number;
  backend_display_quote_number: string;
  backend_quote_id: string;
}

export interface ThreadMessage {
  content: string;
  created_at: string;
  user: {
    id: number;
    first_name: string;
    time?: string;
  };
  id: number;
  attachments: Attachment[];
}

export interface ThreadMessagesPagination {
  items: ThreadMessage[];
  total: number;
  page: number;
  limit: number;
}

export interface ThreadsPagination {
  items: Thread[];
  total: number;
}

export interface Attachment {
  file_name: string;
  id: number;
  url: string;
}

export interface UploadAttachment {
  filename: string;
  content_type?: string;
  file: string;
  key: string;
}

export type UploadMessage = {
  content: string;
  attachments: UploadMessageAttachment[];
  thread_id: number;
};

export type UploadMessageAttachment = {
  file_name: string;
  file_path: string;
};

export interface UploadAttachments {
  filename: string;
  key: string;
  url: string;
  content_type?: string;
  file?: string;
}
