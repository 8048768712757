import styled from "@emotion/styled";
import {Dialog as HuiDialog} from "@headlessui/react";
import {ReactNode} from "react";
import {HuiToFC} from "typeUtils";

export const DialogContainer = styled(HuiDialog as HuiToFC<typeof HuiDialog>)`
  position: relative;
  z-index: 1000;
`;

export const DialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({theme}) => theme.color.grayscale[800]};
  opacity: 0.3;
`;

export const DialogScrollable = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;

export const DialogPanelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  height: 100%;
  width: 100%;
`;

export const DialogPanel = styled(HuiDialog.Panel)`
  margin-left: auto;
  margin-right: auto;
  width: 546px;
  background-color: white;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
` as typeof HuiDialog.Panel;

export const DialogTitle = styled(HuiDialog.Title)`
  ${({theme}) => theme.text.header2}
  padding: 0;
  margin: 0;
` as typeof HuiDialog.Title;

export const DialogBody = styled.div``;

export const DialogButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export function Dialog({
  title,
  open,
  onClose,
  children,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  return (
    <DialogContainer open={open} as="div" onClose={onClose}>
      <DialogBackdrop />
      <DialogScrollable>
        <DialogPanelContainer>
          <DialogPanel>
            {title && <DialogTitle>{title}</DialogTitle>}
            {children}
          </DialogPanel>
        </DialogPanelContainer>
      </DialogScrollable>
    </DialogContainer>
  );
}
