import {ObjectBase} from "api/quote";
import {CheckBox, CheckBoxProps} from "components/CheckBox";
import {DropDown, DropDownProps} from "components/DropDown";
import {RadioGroup, RadioGroupProps} from "components/RadioGroup";
import {TextArea, TextAreaProps} from "components/TextArea";
import {TextInput, TextInputProps} from "components/TextInput";
import styled from "@emotion/styled";
import {useEffect, useRef, useCallback, ChangeEvent, useState} from "react";
import {useQuoteContext} from "../contexts/quoteContext";
import {useFieldsStatusContext} from "../contexts/fieldsStatusContext";
import {GridItem, GridItemProps} from "components/Grid";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {useFieldMeta} from "./hooks/useFieldMeta";
import HiddenMetaButton from "./HiddenMetaButton";
import {useFieldRuleResult} from "./hooks/useFieldRuleResult";
import {useTextInputUpdate} from "./hooks/useTextInputUpdate";
import {FieldMessage} from "./FieldMessage";
import {useSectionName} from "../contexts/sectionNameContext";
import {UNDERWRITER_SECTION_NAME} from ".";

function useIsUnderwriterSection() {
  const sectionName = useSectionName();
  return sectionName === UNDERWRITER_SECTION_NAME;
}

const FieldWrapper = styled(GridItem)`
  position: relative;
`;

export function TextInputField<T extends ObjectBase>({
  object,
  field,
  disabled,
  wrapperProps,
  disableVisibleControl = false,
  ...props
}: {
  object: T;
  field: keyof T;
  disableVisibleControl?: boolean;
  wrapperProps?: GridItemProps;
} & TextInputProps) {
  const {value, inputRef, onBlur, onChange} = useTextInputUpdate(object, field);
  const {isError, isRequiredError} = useFieldRuleResult(object, field);
  const {permission} = useFieldMeta(object, field);
  const {fieldsDisabled} = useFieldsStatusContext();
  const {isBackendQuoting} = useQuoteContext();
  const permissions = usePermissions();
  const [focus, setFocus] = useState(false);
  const isUnderwriterSection = useIsUnderwriterSection();

  if (
    permission?.hidden &&
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS)
  ) {
    return <></>;
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <TextInput
        {...props}
        ref={inputRef}
        defaultValue={value}
        error={isError}
        onBlur={onBlur}
        onChange={onChange}
        required={isRequiredError}
        disabled={
          disabled ||
          fieldsDisabled ||
          (isBackendQuoting && !isUnderwriterSection) ||
          !permissions.includes(Permissions.EDIT_QUOTE)
        }
      />
      <FieldMessage object={object} field={field} />
      {!disableVisibleControl ? (
        <HiddenMetaButton object={object} field={field} focus={focus} />
      ) : (
        <></>
      )}
    </FieldWrapper>
  );
}

export function TextAreaField<T extends ObjectBase>({
  object,
  field,
  disabled,
  wrapperProps,
  disableVisibleControl = false,
  ...props
}: {
  object: T;
  field: keyof T;
  disableVisibleControl?: boolean;
  wrapperProps?: GridItemProps;
} & TextAreaProps) {
  const {value, inputRef, onBlur, onChange} = useTextInputUpdate(object, field);
  const {isError, isRequiredError} = useFieldRuleResult(object, field);
  const {permission} = useFieldMeta(object, field);
  const {fieldsDisabled} = useFieldsStatusContext();
  const {isBackendQuoting} = useQuoteContext();
  const permissions = usePermissions();
  const [focus, setFocus] = useState(false);
  const isUnderwriterSection = useIsUnderwriterSection();

  if (
    permission?.hidden &&
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS)
  ) {
    return <></>;
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <TextArea
        {...props}
        ref={inputRef}
        defaultValue={value}
        error={isError}
        required={isRequiredError}
        disabled={
          disabled ||
          fieldsDisabled ||
          (isBackendQuoting && !isUnderwriterSection) ||
          !permissions.includes(Permissions.EDIT_QUOTE)
        }
        onBlur={onBlur}
        onChange={onChange}
      />
      <FieldMessage object={object} field={field} />
      {!disableVisibleControl ? (
        <HiddenMetaButton object={object} field={field} focus={focus} />
      ) : (
        <></>
      )}
    </FieldWrapper>
  );
}

export function CheckBoxField<T extends ObjectBase>({
  object,
  field,
  disabled,
  wrapperProps,
  disableVisibleControl = false,
  ...props
}: {
  object: T;
  field: keyof T;
  disableVisibleControl?: boolean;
  wrapperProps?: GridItemProps;
} & CheckBoxProps) {
  const {update} = useQuoteContext();
  const {fieldsDisabled} = useFieldsStatusContext();
  const {isRequiredError, handleAPIError} = useFieldRuleResult(object, field);
  const {permission} = useFieldMeta(object, field);
  const {isBackendQuoting} = useQuoteContext();
  const permissions = usePermissions();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isFirstRender = useRef(true);
  const value: any = object[field];
  const [focus, setFocus] = useState(false);
  const isUnderwriterSection = useIsUnderwriterSection();

  useEffect(() => {
    const inputElement = inputRef.current as any;
    if (!isFirstRender) inputElement.value = value;
    else isFirstRender.current = false;
  }, [value]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.checked;
    if (newValue !== value)
      update(
        object.object_name,
        object.object_path,
        object.id,
        field.toString(),
        newValue
      ).catch((error) => {
        handleAPIError(error, field.toString());
        e.target.value = value;
      });
  }

  if (
    permission?.hidden &&
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS)
  ) {
    return <></>;
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <CheckBox
        {...props}
        ref={inputRef}
        checked={value}
        required={isRequiredError}
        disabled={
          disabled ||
          fieldsDisabled ||
          (isBackendQuoting && !isUnderwriterSection) ||
          !permissions.includes(Permissions.EDIT_QUOTE)
        }
        onChange={onChange}
      />
      <FieldMessage object={object} field={field} />
      {!disableVisibleControl ? (
        <HiddenMetaButton object={object} field={field} focus={focus} />
      ) : (
        <></>
      )}
    </FieldWrapper>
  );
}

export function DropDownField<T extends ObjectBase>({
  object,
  field,
  disabled,
  disableVisibleControl = false,
  wrapperProps,
  onChange,
  ...props
}: {
  object: T;
  field: keyof T;
  disabled?: boolean;
  disableVisibleControl?: boolean;
  wrapperProps?: GridItemProps;
} & DropDownProps) {
  const {update} = useQuoteContext();
  const {isError, isRequiredError, handleAPIError} = useFieldRuleResult(
    object,
    field
  );
  const {permission} = useFieldMeta(object, field);
  const {fieldsDisabled} = useFieldsStatusContext();
  const {isBackendQuoting} = useQuoteContext();
  const permissions = usePermissions();
  const value: any = object[field];
  const [focus, setFocus] = useState(false);
  const isUnderwriterSection = useIsUnderwriterSection();

  const handleChange = useCallback(
    async (newValue: string | number | boolean) => {
      if (newValue !== value)
        await update(
          object.object_name,
          object.object_path,
          object.id,
          field.toString(),
          newValue
        ).catch((error) => {
          handleAPIError(error, field.toString());
        });

      if (onChange) onChange(String(newValue));
    },
    [value, update, object, field, handleAPIError, onChange]
  );

  if (
    permission?.hidden &&
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS)
  ) {
    return <></>;
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <DropDown
        {...props}
        value={value}
        error={isError}
        disabled={
          disabled ||
          fieldsDisabled ||
          (isBackendQuoting && !isUnderwriterSection) ||
          !permissions.includes(Permissions.EDIT_QUOTE)
        }
        required={isRequiredError}
        onChange={handleChange}
      />
      <FieldMessage object={object} field={field} />
      {!disableVisibleControl ? (
        <HiddenMetaButton object={object} field={field} focus={focus} />
      ) : (
        <></>
      )}
    </FieldWrapper>
  );
}

export function RadioGroupField<T extends ObjectBase>({
  object,
  field,
  disabled,
  disableVisibleControl = false,
  wrapperProps,
  ...props
}: {
  object: T;
  field: keyof T;
  disabled?: boolean;
  disableVisibleControl?: boolean;
  wrapperProps?: GridItemProps;
} & RadioGroupProps) {
  const {update} = useQuoteContext();
  const {isRequiredError, handleAPIError} = useFieldRuleResult(object, field);
  const {permission} = useFieldMeta(object, field);
  const {fieldsDisabled} = useFieldsStatusContext();
  const {isBackendQuoting} = useQuoteContext();
  const permissions = usePermissions();
  const value: any = object[field];
  const [focus, setFocus] = useState(false);
  const isUnderwriterSection = useIsUnderwriterSection();

  const handleChange = useCallback(
    (newValue: string | number) => {
      if (newValue !== value)
        update(
          object.object_name,
          object.object_path,
          object.id,
          field.toString(),
          newValue
        ).catch((error) => {
          handleAPIError(error, field.toString());
        });
    },
    [value, update, object, field, handleAPIError]
  );

  if (
    permission?.hidden &&
    !permissions.includes(Permissions.HIDE_QUOTE_FIELDS)
  ) {
    return <></>;
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <RadioGroup
        {...props}
        value={value}
        required={isRequiredError}
        disabled={
          disabled ||
          fieldsDisabled ||
          (isBackendQuoting && !isUnderwriterSection) ||
          !permissions.includes(Permissions.EDIT_QUOTE)
        }
        onChange={handleChange}
      />
      <FieldMessage object={object} field={field} showSpace />
      {!disableVisibleControl ? (
        <HiddenMetaButton
          object={object}
          field={field}
          focus={focus}
          top={0}
          right={0}
        />
      ) : (
        <></>
      )}
    </FieldWrapper>
  );
}

const Field = {
  TextInput: TextInputField,
  TextArea: TextAreaField,
  CheckBox: CheckBoxField,
  DropDown: DropDownField,
  RadioGroup: RadioGroupField,
};

export default Field;
