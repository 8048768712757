/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {useNavigation} from "hooks/navigation";
import {createContext, MutableRefObject, useContext, useEffect} from "react";
import Button from "./Button";
import {DropDown} from "./DropDown";
import IconButton from "./IconButton";

interface PaginationProps {
  scrollOffset: number;
  pageNumber: number;
  limit: number;
  totalCount: number;
  resultsElementRef: MutableRefObject<HTMLElement | null>;
  isCurrentPageLoaded: boolean;
}

const paginationContext = createContext<PaginationProps>({
  scrollOffset: 0,
  limit: 0,
  totalCount: 0,
  resultsElementRef: {current: null},
  pageNumber: 0,
  isCurrentPageLoaded: false,
});

export default function Pagination(props: PaginationProps) {
  const {pageNumber, limit, totalCount} = props;
  const {updateSearchParams} = useNavigation();

  const lastPageNumber = Math.ceil(totalCount / limit);

  useEffect(() => {
    if (pageNumber > lastPageNumber && lastPageNumber > 0)
      updateSearchParams({pageNumber: 1});
  }, [pageNumber, lastPageNumber, updateSearchParams]);

  const currentPagesLength = 5;
  const lastCurrentPagesLengthNumber =
    pageNumber % currentPagesLength
      ? Math.floor(pageNumber / currentPagesLength) * currentPagesLength
      : pageNumber - currentPagesLength;
  const nextCurrentPagesLengthNumber =
    pageNumber % currentPagesLength
      ? Math.ceil(pageNumber / currentPagesLength) * currentPagesLength
      : pageNumber;
  const currentPages = Array.from(
    {
      length:
        lastPageNumber < nextCurrentPagesLengthNumber
          ? lastPageNumber - lastCurrentPagesLengthNumber
          : currentPagesLength,
    },
    (v, i) => i + 1 + lastCurrentPagesLengthNumber
  );
  const firstCurrentPages = currentPages[0];
  const lastCurrentPages = currentPages[currentPages.length - 1];

  return totalCount > limit ? (
    <paginationContext.Provider value={props}>
      <PaginationContainer>
        <AbsolutedSideElement side="left">
          <span
            css={css`
              display: flex;
              gap: 7px;
              align-items: center;
            `}
          >
            Limit:
            <DropDown
              background
              size="small"
              value={limit.toString()}
              onChange={(v) => {
                updateSearchParams({limit: v, pageNumber: 1});
                scrollUp(props);
              }}
              options={["10", "25", "50", "100"].map((v) => ({
                title: v,
                value: v,
              }))}
            />
          </span>
        </AbsolutedSideElement>
        <PaginationButtonsWrapper>
          {pageNumber !== 1 && (
            <Button
              color="secondary"
              icon={ChevronLeftIcon}
              onClick={() => {
                updateSearchParams({pageNumber: pageNumber - 1});
                scrollUp(props);
              }}
            />
          )}
          {firstCurrentPages !== 1 && (
            <>
              <PageButton pageNumber={1} />
              <IconButton
                background={false}
                onClick={() => {
                  updateSearchParams({
                    pageNumber: firstCurrentPages - currentPagesLength,
                  });
                  scrollUp(props);
                }}
              >
                ...
              </IconButton>
            </>
          )}
          {currentPages.map((i) => (
            <PageButton pageNumber={i} />
          ))}
          {lastCurrentPages !== lastPageNumber && (
            <>
              <IconButton
                background={false}
                onClick={() => {
                  updateSearchParams({
                    pageNumber: firstCurrentPages + currentPagesLength,
                  });
                  scrollUp(props);
                }}
              >
                ...
              </IconButton>
              <PageButton pageNumber={lastPageNumber} />
            </>
          )}
          {pageNumber !== lastPageNumber && (
            <Button
              color="secondary"
              icon={ChevronRightIcon}
              onClick={() => {
                updateSearchParams({pageNumber: pageNumber + 1});
                scrollUp(props);
              }}
            />
          )}
        </PaginationButtonsWrapper>
        <AbsolutedSideElement side="right">
          {limit * pageNumber - (limit - 1)}-
          {Math.min(limit * pageNumber, totalCount)} of {totalCount}
        </AbsolutedSideElement>
      </PaginationContainer>
    </paginationContext.Provider>
  ) : null;
}

const PaginationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
`;

const PaginationButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: bottom;
  gap: 5px;
`;

const AbsolutedSideElement = styled.span<{side: "right" | "left"}>`
  ${(props) => props.theme.text.p2};
  padding: 0px 6px;
  position: absolute;
  ${(props) => props.side}: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

function PageButton({pageNumber}: {pageNumber: number}) {
  const {updateSearchParams} = useNavigation();
  const paginationProps = useContext(paginationContext);
  return (
    <IconButton
      color="primary"
      isLoading={
        paginationProps.pageNumber === pageNumber &&
        !paginationProps.isCurrentPageLoaded
      }
      background={String(paginationProps.pageNumber) === String(pageNumber)}
      onClick={() => {
        setTimeout(() => scrollUp(paginationProps), 0);
        updateSearchParams({pageNumber});
      }}
    >
      {pageNumber}
    </IconButton>
  );
}

function scrollUp({resultsElementRef, scrollOffset}: PaginationProps) {
  const el = resultsElementRef.current;
  if (!el) return;

  const elementPosition = el.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - scrollOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}
