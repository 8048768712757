import {CommercialProperty, PrLocation} from "api/policy";
import {Box} from "components/Box";
import {H3} from "components/elements";
import {formatAmount} from "utils/format";
import {Accordian, AccordianButton, AccordianPanel} from "components/Accordian";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import FormTitle from "../components/FormTitle";
import InfoRow from "./InfoRow";

function CommercialPropertyInfo({
  locations,
  commercialProperty,
}: {
  locations?: PrLocation[];
  commercialProperty?: CommercialProperty;
}) {
  if (!locations?.length) return null;
  return (
    <Box mb={100}>
      <FormTitle.Header>Commercial Property</FormTitle.Header>
      {locations.length > 1 ? (
        locations.map((location) => (
          <Accordian>
            <AccordianButton css={{lineHeight: 1.5}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px 0px 10px",
                  fontSize: 14,
                }}
              >
                <span style={{color: "#808080"}}>{location.number}</span>
                <span>
                  {`${location.address_info.line_1} ${
                    location.address_info.city &&
                    `${location.address_info.city}, 
                ${location.address_info.state} ${location.address_info.zip}`
                  }`}
                </span>
                <span></span>
              </div>
            </AccordianButton>
            <AccordianPanel>
              <DetailedLocationInfo
                location={location}
                commercialProperty={commercialProperty}
              />
            </AccordianPanel>
          </Accordian>
        ))
      ) : (
        <>
          <InfoRow
            label="Address"
            object={`${locations[0].address_info.line_1} ${
              locations[0].address_info.city &&
              `${locations[0].address_info.city}, 
                ${locations[0].address_info.state} ${locations[0].address_info.zip}`
            }`}
          />
          <DetailedLocationInfo
            location={locations[0]}
            commercialProperty={commercialProperty}
          />
          {commercialProperty?.premium && (
            <InfoRow
              label="Total Premium
              (Includes Minimum)"
              object={formatAmount(commercialProperty.premium)}
            />
          )}
        </>
      )}
    </Box>
  );
}

function DetailedLocationInfo({
  location,
  commercialProperty,
}: {
  location: PrLocation;
  commercialProperty?: CommercialProperty;
}) {
  const terrorism_coverage_premium =
    commercialProperty?.terrorism_coverage_premium;
  const terrorism_coverage = commercialProperty?.terrorism_coverage;

  return (
    <>
      {location.buildings.map((building) => (
        <>
          {location.buildings.length > 1 && (
            <H3>Building #{building.number}</H3>
          )}
          <InfoRow
            label="Deductible"
            object={formatAmount(building.deductible)}
          />
          <InfoRow
            label="Building Valuation"
            object={building.building_valuation}
          />
          <InfoRow label="Cause of loss" object={building.cause_of_loss} />
          <InfoRow label="Coinsurance" object={building.coinsusrance} />
          <Table checkeredBackground>
            <Header>
              <HeaderCell>Coverage</HeaderCell>
              <HeaderCell>Limit</HeaderCell>
              <HeaderCell>Premium</HeaderCell>
            </Header>
            <Body>
              {building.building_coverages.map((coverage) => (
                <>
                  {coverage.name === "Equipment Breakdown" ||
                  coverage.name === "Property Enhancement" ? (
                    <Row>
                      <Cell>{coverage.name}</Cell>
                      <Cell>Selected</Cell>
                      <Cell>{formatAmount(coverage.premium)}</Cell>
                    </Row>
                  ) : (
                    <Row>
                      <Cell>{coverage.name}</Cell>
                      <Cell>{formatAmount(coverage.limit)}</Cell>
                      <Cell>{formatAmount(coverage.premium)}</Cell>
                    </Row>
                  )}
                </>
              ))}
              {terrorism_coverage && terrorism_coverage_premium && (
                <Row>
                  <Cell>Terrorism Coverage</Cell>
                  <Cell>Selected</Cell>
                  <Cell>{formatAmount(terrorism_coverage_premium)}</Cell>
                </Row>
              )}
            </Body>
          </Table>
        </>
      ))}
    </>
  );
}

export default CommercialPropertyInfo;
