/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {useNavigation} from "hooks/navigation";
import {NavHashLink} from "react-router-hash-link";

export interface Section {
  id: string;
  title: string;
  errors?: number;
}

const SectionItem = styled(NavHashLink)`
  ${({theme}) => theme.text.table}
  color: ${({theme}) => theme.color.grayscale[500]};
  text-decoration: none;
  margin-bottom: 20px;
  border-left: 2px solid transparent;
  margin-left: -1px;
  padding: 10px 20px;

  &.errors {
    color: ${({theme}) => theme.color.state.red};
  }

  &.active {
    color: ${({theme}) => theme.color.brand.primary};
    border-left: 2px solid ${({theme}) => theme.color.brand.primary};
  }

  :last-child {
    margin-bottom: 0px;
  }
`;

function SectionsList({sections}: {sections: Section[]}) {
  const {getMergedUrl} = useNavigation();
  return (
    <div
      css={(theme) => css`
        border-left: 1px solid ${theme.color.grayscale[300]};
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px;
      `}
    >
      {sections.map((section) => (
        <SectionItem
          key={section.id}
          exact
          replace
          smooth
          to={getMergedUrl({hash: section.id})}
          activeClassName="active"
          className={classNames({errors: section.errors})}
        >
          {section.title}
          {section.errors ? (
            <span
              css={(theme) => css`
                background: ${theme.color.state.red};
                color: ${theme.color.grayscale.white};
                border-radius: 100%;
                margin-left: 4px;
                width: 20px;
                height: 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              `}
            >
              {section.errors}
            </span>
          ) : null}
        </SectionItem>
      ))}
    </div>
  );
}

export default SectionsList;
