/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import DownloadIcon from "@heroicons/react/solid/DownloadIcon";
import DotsHorizontalIcon from "@heroicons/react/solid/DotsHorizontalIcon";
import {getAllPoliciesDocumentsByDate, PolicyDocument} from "api/policy";
import Button from "components/Button";
import {Anchor} from "components/elements";
import Loading from "components/Loading";
import Pagination from "components/Pagination";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  IconButtonsCellContainer,
  Row,
  Table,
} from "components/Table";
import {TextInput} from "components/TextInput";
import {useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import {useRef, useState} from "react";
import {downloadFile, getFilenameFromUrl} from "utils/dom";
import {formatDate, toTitleCase} from "utils/format";
import {PaginatedResult} from "api/common";
import {UseQueryResult} from "react-query";
import {
  MenuButton,
  MenuButtonItem,
  MenuContainer,
  MenuItems,
} from "components/Menu";
import links from "links";
import { DateRangeWrapper, LoadingIndicator, PageContainer, ToolbarContainer } from "components/listPageUtils";

export interface PoliciesDocumentsPageParams {
  fromDate: string;
  toDate: string;
  limit: number;
  pageNumber: number;
}

export default function PoliciesDocuments({
  fromDate,
  toDate,
  limit,
  pageNumber,
}: PoliciesDocumentsPageParams) {
  const {updateSearchParams} = useNavigation();
  const policiesDocumentsQuery = useApiQuery(getAllPoliciesDocumentsByDate, {
    keepPreviousData: true,
  })(fromDate, toDate, limit.toString(), pageNumber.toString());
  return (
    <PageContainer>
      <ToolbarContainer>
        <DateRangeWrapper>
          <TextInput
            type="date"
            background
            label="From"
            value={fromDate}
            onChange={(e) =>
              updateSearchParams({fromDate: e.target.value, pageNumber: 1})
            }
          />
          <TextInput
            type="date"
            background
            label="To"
            value={toDate}
            onChange={(e) =>
              updateSearchParams({toDate: e.target.value, pageNumber: 1})
            }
          />
        </DateRangeWrapper>
        {policiesDocumentsQuery.isPreviousData && <LoadingIndicator />}
      </ToolbarContainer>
      <PoliciesDocumentsList
        fromDate={fromDate}
        toDate={toDate}
        limit={limit}
        pageNumber={pageNumber}
        policiesDocumentsQuery={policiesDocumentsQuery}
      />
    </PageContainer>
  );
}

function PoliciesDocumentsList({
  limit,
  pageNumber,
  policiesDocumentsQuery,
}: PoliciesDocumentsPageParams & {
  policiesDocumentsQuery: UseQueryResult<PaginatedResult<PolicyDocument[]>>;
}) {
  const {navigate} = useNavigation();
  const ref = useRef(null);

  if (policiesDocumentsQuery.data) {
    const policiesDocuments = policiesDocumentsQuery.data.result;
    const totalCount = policiesDocumentsQuery.data.total_count;
    if (policiesDocuments.length === 0) {
      return <p>No documents</p>;
    }

    return (
      <>
        <Table ref={ref} disabled={policiesDocumentsQuery.isPreviousData}>
          <Header>
            <HeaderCell>Upload Date</HeaderCell>
            <HeaderCell>Type</HeaderCell>
            <HeaderCell>Policy Number</HeaderCell>
            <HeaderCell>Insured</HeaderCell>
            <HeaderCell>Version</HeaderCell>
            <HeaderCell></HeaderCell>
          </Header>
          {policiesDocuments.map((doc) => (
            <Body>
              <Row
                css={css`
                  cursor: pointer;
                `}
                onClick={() => navigate(doc.url, true)}
              >
                <Cell>{formatDate(doc.generated_date)}</Cell>
                <Cell>
                  <Anchor
                    href={doc.url}
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    {doc.type}
                  </Anchor>
                </Cell>
                <Cell>{doc.policy_mod_ids.display_policy_number}</Cell>
                <Cell>{doc.insured_name}</Cell>
                <Cell>{toTitleCase(doc.version)}</Cell>
                <Cell>
                  <IconButtonsCellContainer direction="end">
                    <DownloadButton url={doc.url} />
                    <MenuContainer size={"small"}>
                      <MenuButton
                        background={false}
                        icon={DotsHorizontalIcon}
                      />
                      <MenuItems>
                        <MenuButtonItem onClick={() => navigate(doc.url, true)}>
                          View
                        </MenuButtonItem>
                        <MenuButtonItem
                          onClick={() =>
                            downloadFile(
                              doc.url,
                              getFilenameFromUrl(doc.url) || "download"
                            )
                          }
                        >
                          Download
                        </MenuButtonItem>
                        <MenuButtonItem
                          onClick={() =>
                            navigate(
                              links.policy({
                                policyModIds: doc.policy_mod_ids,
                              })
                            )
                          }
                        >
                          View Policy Details
                        </MenuButtonItem>
                      </MenuItems>
                    </MenuContainer>
                  </IconButtonsCellContainer>
                </Cell>
              </Row>
            </Body>
          ))}
        </Table>
        <Pagination
          isCurrentPageLoaded={!policiesDocumentsQuery.isPreviousData}
          scrollOffset={70}
          resultsElementRef={ref}
          pageNumber={pageNumber}
          limit={limit}
          totalCount={totalCount}
        />
      </>
    );
  }

  if (policiesDocumentsQuery.isError) {
    return <p>Error</p>;
  } else {
    return <Loading size="medium" />;
  }
}

function DownloadButton({url}: {url: string}) {
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <Button
      onClick={() => {
        setIsDownloading(true);
        downloadFile(url, getFilenameFromUrl(url) || "download").finally(() =>
          setIsDownloading(false)
        );
      }}
      isLoading={isDownloading}
      size="small"
      background={false}
      icon={DownloadIcon}
    >
      Download
    </Button>
  );
}
