import {QuoteForm, SkpClass} from "api/quote";
import {useQuoteContext} from "pages/RateQuoteBind/contexts/quoteContext";
import {useCallback, useEffect, useMemo} from "react";
import Field from "../../Field";
import Box from "components/Box";

const classCodes = [
  {
    label: "Air Conditioning/ Heating Systems Work",
    value: "95647 Air Conditioning/Heating Systems",
    states: ["NJ"],
    companies: {
      "10": [],
      "12": ["NJ"],
    },
  },
  {
    label: "Air Conditioning/ Heating Systems Work",
    value: "95648 Air Conditioning/Heating Systems",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY"],
    },
  },
  {
    label:
      "Appliances and Accessories - Installation, servicing or repair - household",
    value: "91155",
    states: ["NY"],
    companies: {
      "10": ["NY"],
      "12": ["NY"],
    },
  },
  {
    label: "Carpentry NOC",
    value: "91342 Carpentry NOC",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Cabinet Makers",
    value: "91342 Cabinet Makers",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": ["NJ"],
    },
  },
  {
    label: "Carpet/Floor Installation",
    value: "94569",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label:
      "Carpet, Rug, Furniture or Upholstery Cleaning - on customers' premises",
    value: "91405",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Concrete Construction",
    value: "91560",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Driveway, Parking Area or Sidewalk - paving or repaving",
    value: "92215",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Dry Wall Installation",
    value: "92338",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Electrical HH Appliance Installation Repair",
    value: "91155 Electrical HH Appliance Installation Repair",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": ["NJ"],
    },
  },
  {
    label: "Electrical Wiring",
    value: "92478",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Fence Installation",
    value: "92476",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Gas HH Appliance Installation Repair",
    value: "91155 Gas HH Appliance Installation Repair",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": ["NJ"],
    },
  },
  {
    label: "Household Furnishings Installation/Repair",
    value: "96053",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Landscape Gardening",
    value: "97047 Landscape Gardening",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Lawn Sprinkler Installation",
    value: "97047 Lawn Sprinkler Installation",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": ["NJ"],
    },
  },
  {
    label: "Masonry",
    value: "97447",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Office Machine Installation and Repair",
    value: "98111",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Painting and Paperhanging - interior - buildings or structures",
    value: "98304",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label:
      "Painting - exterior - buildings or structures - three stories or less in height",
    value: "98304",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Plaster/Stucco",
    value: "98344",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Plumbing",
    value: "98483",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
  {
    label: "Sheet Metal Work",
    value: "95647 Sheet Metal Work",
    states: ["NJ"],
    companies: {
      "10": [],
      "12": ["NJ"],
    },
  },
  {
    label: "Sheet Metal Work",
    value: "95648 Sheet Metal Work",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": [],
    },
  },
  {
    label: "Siding Installation",
    value: "98967",
    states: ["NY"],
    companies: {
      "10": ["NY"],
      "12": ["NY"],
    },
  },
  {
    label: "Siding Installation",
    value: "91581",
    states: ["NJ"],
    companies: {
      "10": ["NJ"],
      "12": ["NJ"],
    },
  },
  {
    label: "Tile, Stone, Marble, Terrazzo Work",
    value: "99746",
    states: ["NY", "NJ"],
    companies: {
      "10": ["NY", "NJ"],
      "12": ["NY", "NJ"],
    },
  },
];

function SkpClassForm({
  skp_class,
  quoteForm,
}: {
  skp_class: SkpClass;
  quoteForm: QuoteForm;
}) {
  const {update} = useQuoteContext();
  const companyNumber = quoteForm.company_number;
  const state = quoteForm.locations[0].state;

  const updateClass = useCallback(
    async (fieldNames: string[], newValue: string | number | null) => {
      for (let fieldName of fieldNames) {
        await update("skp_class", ["skp"], skp_class.id, fieldName, newValue);
      }
    },
    [skp_class.id, update]
  );

  const filteredOptions = useMemo(() => {
    return classCodes
      .filter((item) => {
        // Cast companyNumber.toString() to '10' | '12' so TypeScript understands the key type
        const companyKey =
          companyNumber.toString() as keyof typeof item.companies;

        return (
          item.companies[companyKey] &&
          item.companies[companyKey].includes(state)
        );
      })
      .map((item) => ({
        title: item.label,
        value: item.value,
      }));
  }, [companyNumber, state]);

  useEffect(() => {
    const selectedOption = classCodes.find(
      (item) => item.value === skp_class.class_code
    );

    if (selectedOption) {
      const isOptionInFiltered = filteredOptions.some(
        (option) =>
          option.value === selectedOption.value &&
          option.title === selectedOption.label
      );

      if (!isOptionInFiltered) {
        // Reset class_code if no matching option is found
        updateClass(["class_code"], null);
      }
    } else {
      // If no selectedOption is found (class_code might be null), do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNumber, state, skp_class.class_code]);

  return (
    <Box mt={-12}>
      <Field.DropDown
        object={skp_class}
        options={filteredOptions}
        field={"class_code"}
        label="Select Class"
        wrapperProps={{
          grow: 12,
        }}
      />
    </Box>
  );
}

export default SkpClassForm;
