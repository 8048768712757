import {FieldPermission} from "api/quote";
import {createContext, ReactNode, useContext} from "react";

export const FieldMetaContext = createContext({
  getFieldPermission(
    objectName: string,
    objectId: string | undefined,
    field: string
  ): FieldPermission | undefined {
    return;
  },
});

export function FieldMetaContextProvider({
  field_permissions,
  children,
}: {
  field_permissions: FieldPermission[];
  children: ReactNode;
}) {
  function getFieldPermission(
    objectName: string,
    objectId: string | undefined,
    field: string
  ) {
    const permissionByObjectId = field_permissions.find(
      (item) =>
        item.field_object_name === objectName.toString() &&
        item.field_object_id === objectId?.toString() &&
        item.field_name === field.toString()
    );
    if (permissionByObjectId) {
      return permissionByObjectId;
    } else {
      return field_permissions.find(
        (item) =>
          item.field_object_name === objectName.toString() &&
          item.field_name === field.toString()
      );
    }
  }

  return (
    <FieldMetaContext.Provider value={{getFieldPermission}}>
      {children}
    </FieldMetaContext.Provider>
  );
}

export function useFieldMetaContext() {
  return useContext(FieldMetaContext);
}
