/** @jsxImportSource @emotion/react */

import {PolicyBasicInfo} from "api/policy";
import {DropDown} from "components/DropDown";
import {useNavigation} from "hooks/navigation";
import Button from "components/Button";
import {css} from "@emotion/react";
import {formatEffectiveDates} from "utils/format";
import CheckIcon from "@heroicons/react/outline/CheckIcon";

function PolicyModSelector({
  currentModNumber,
  policyModList,
  showAllModsButton = false,
  showAllMods = false,
  updateShowAllMods = true,
  width,
  setShowAllMods,
}: {
  currentModNumber?: string | null;
  policyModList: PolicyBasicInfo[];
  showAllModsButton?: boolean;
  showAllMods?: boolean;
  updateShowAllMods?: boolean;
  width?: string | null;
  setShowAllMods?: (v: boolean) => void;
}) {
  const {updateSearchParams} = useNavigation();
  function handlePolicyModChange(modNumber: string) {
    setShowAllMods && setShowAllMods(false);
    const mod = policyModList.find(
      (mod) => mod.policy_mod_ids.mod_number === modNumber
    )!;
    if (updateShowAllMods)
      updateSearchParams({
        modNumber: mod.policy_mod_ids.mod_number,
        policyId: mod.policy_mod_ids.policy_id,
        pcProviderName: mod.policy_mod_ids.pc_provider_name,
        showAllMods: false,
      });
    else
      updateSearchParams({
        modNumber: mod.policy_mod_ids.mod_number,
        policyId: mod.policy_mod_ids.policy_id,
      });
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 15px;
      `}
    >
      <div
        css={css`
          width: ${width || "250px"};
        `}
      >
        <DropDown
          label="Period"
          title={showAllMods ? "All periods" : ""}
          options={policyModList.map((mod) => ({
            title: formatEffectiveDates(
              mod.effective_date,
              mod.expiration_date
            ),
            value: mod.policy_mod_ids.mod_number,
          }))}
          value={currentModNumber!}
          onChange={handlePolicyModChange}
        />
      </div>
      {showAllModsButton && (
        <Button
          icon={showAllMods ? CheckIcon : null}
          background={showAllMods}
          onClick={() =>
            updateSearchParams({
              showAllMods: !showAllMods,
            })
          }
        >
          Show all periods
        </Button>
      )}
    </div>
  );
}

export default PolicyModSelector;
