/** @jsxImportSource @emotion/react */

import {ReactNode, RefObject, useEffect, useRef, useState} from "react";
import {useNavigation} from "hooks/navigation";

export function useSection() {
  const {
    location: {hash},
  } = useNavigation();

  useEffect(() => {
    const hashSectionElement = document.getElementById(hash.substring(1));
    hashSectionElement?.scrollIntoView();
    // eslint-disable-next-line
  }, []);
}

function useOnScreen(ref: RefObject<HTMLDivElement>) {
  const [isIntersecting, setIntersecting] = useState(false);
  const timeoutId = useRef<number>();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        window.clearTimeout(timeoutId.current);
        timeoutId.current = window.setTimeout(
          () => setIntersecting(entry.isIntersecting),
          100
        );
      },
      {rootMargin: "-30% 0% -70% 0%", threshold: 0}
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return isIntersecting;
}

export default function ScrollToSection({
  sectionName,
  children,
}: {
  sectionName: string;
  children: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isVisisble = useOnScreen(ref);
  const {setHash} = useNavigation();
  useEffect(() => {
    if (isVisisble) setHash(formatSectionNameId(sectionName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisisble]);
  return (
    <div ref={ref} id={formatSectionNameId(sectionName)}>
      {children}
    </div>
  );
}

export function formatSectionNameId(sectionName: string) {
  return sectionName.replace(" ", "-");
}
