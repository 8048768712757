import styled from "@emotion/styled";
import Button from "components/Button";
import {FunctionComponent, SVGProps} from "react";
import {ReactComponent as BuildingSvg} from "assets/images/building.svg";
import {ReactComponent as HouseSvg} from "assets/images/house.svg";
import {ReactComponent as ShopFrontSvg} from "assets/images/shop-front.svg";
import {ReactComponent as ConstructionHatSvg} from "assets/images/construction-hat.svg";
import {ReactComponent as MixedMercantileSvg} from "assets/images/mixed-mercantile.svg";
import Link from "components/Link";
import {QuoteTypes} from "api/quote";
import {usePermissions, useUser} from "hooks/auth";
import {Permissions} from "api/auth";
import {Anchor} from "components/elements";

const gap = 12;
const contactSectionWidth = 450;
const padding = 20;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${gap}px;
  height: calc(100vh - 195px);
`;

const MainSectionContainer = styled.div`
  height: 100%;
  width: calc(100% - ${contactSectionWidth + gap}px);
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
  border-radius: 5px;
`;

const ContactInfoContainer = styled.div`
  width: ${contactSectionWidth}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 5px;
`;

const WelcomeSectionContainer = styled.div`
  ${({theme}) => theme.text.p1};
  font-weight: 700;
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 5px;
  padding: ${padding}px;
`;

const BlankSection = styled.div`
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 5px;
  flex-grow: 1;
`;

const GetCoverageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
  flex-grow: 1;
`;

const GetQuoteBoxesContainer = styled.div`
  display: flex;
  gap: ${gap}px;
`;

const GetQuoteBoxesColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
  flex: 1;
`;

const GetQuoteBoxContainer = styled.div<{disabled?: boolean}>`
  height: 156px;
  padding: 5px;
  background-color: ${({theme, disabled}) =>
    disabled ? theme.color.grayscale[300] : theme.color.grayscale.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;
const GetDoubledQuoteBoxContainer = styled.div<{disabled?: boolean}>`
  height: 156px;
  padding: 5px;
  background-color: ${({theme, disabled}) =>
    disabled ? theme.color.grayscale[300] : theme.color.grayscale.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const GetSmallQuoteBoxContainer = styled.div<{disabled?: boolean}>`
  height: 156px;
  padding: 5px;

  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const GetDoubleQuoteBoxContainer = styled.div<{disabled?: boolean}>`
  height: 100px;
  padding: 5px;
  background-color: ${({theme, disabled}) =>
    disabled ? theme.color.grayscale[300] : theme.color.grayscale.white};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const AddionnalCoverageSectionContainer = styled.div`
  flex-grow: 25;
  background-color: ${({theme}) => theme.color.grayscale.white};
  border-radius: 5px;
  padding: ${padding}px;
`;

const P = styled.p`
  ${({theme}) => theme.text.p2};
`;

const CoverageName = styled.span<{disabled?: boolean}>`
  ${({theme}) => theme.text.p2};
  color: ${({theme, disabled}) =>
    disabled ? theme.color.grayscale[600] : theme.color.grayscale[800]};
  font-weight: 700;
`;

function GetQuoteBox({
  name,
  quoteType,
  SvgComponent,
  inactive,
  inactiveButtonText = "Under Construction",
  legacyNewQuoteLink = "",
  legacyExistingQuotesLink = "",
}: {
  name: string;
  quoteType: QuoteTypes;
  SvgComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  inactive?: boolean;
  inactiveButtonText?: string;
  legacyNewQuoteLink?: string;
  legacyExistingQuotesLink?: string;
}) {
  return (
    <GetQuoteBoxContainer disabled={inactive}>
      <CoverageName disabled={inactive}>{name}</CoverageName>
      <SvgComponent style={{height: 40, opacity: inactive ? 0.6 : 1}} />
      <Link
        to={
          legacyNewQuoteLink
            ? legacyNewQuoteLink
            : `/new-quote?quoteType=${quoteType}`
        }
      >
        <Button size="small" disabled={inactive}>
          {inactive ? inactiveButtonText : "Get Quote"}
        </Button>
      </Link>
      {legacyExistingQuotesLink && (
        <Link to={legacyExistingQuotesLink}>View Existing Quotes</Link>
      )}
    </GetQuoteBoxContainer>
  );
}

function GetSmallQuoteBox({
  name,
  quoteType,
  SvgComponent,
  inactive,
  inactiveButtonText = "Under Construction",
  legacyNewQuoteLink = "",
  legacyExistingQuotesLink = "",
}: {
  name: string;
  quoteType: QuoteTypes;
  SvgComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  inactive?: boolean;
  inactiveButtonText?: string;
  legacyNewQuoteLink?: string;
  legacyExistingQuotesLink?: string;
}) {
  return (
    <GetSmallQuoteBoxContainer disabled={inactive}>
      <CoverageName disabled={inactive}>{name}</CoverageName>
      <SvgComponent style={{height: 40, opacity: inactive ? 0.6 : 1}} />
      <Link
        to={
          legacyNewQuoteLink
            ? legacyNewQuoteLink
            : `/new-quote?quoteType=${quoteType}`
        }
      >
        <Button size="small" disabled={inactive}>
          {inactive ? inactiveButtonText : "Get Quote"}
        </Button>
      </Link>
      {legacyExistingQuotesLink && (
        <Link to={legacyExistingQuotesLink}>View Existing Quotes</Link>
      )}
    </GetSmallQuoteBoxContainer>
  );
}

function GetDoubleQuoteBox({
  titleName,
  subNames,
  quoteTypes,
  svgComponents,
}: {
  titleName: string;
  subNames: string[];
  quoteTypes: any;
  svgComponents: any;
}) {
  return (
    <GetDoubledQuoteBoxContainer>
      <CoverageName>{titleName}</CoverageName>
      <GetDoubleQuoteBoxContainer>
        {subNames.map((subName) => {
          return (
            <GetSmallQuoteBox
              name={subName}
              quoteType={quoteTypes[subName]}
              SvgComponent={svgComponents[subName]}
            ></GetSmallQuoteBox>
          );
        })}
      </GetDoubleQuoteBoxContainer>
    </GetDoubledQuoteBoxContainer>
  );
}

export default function Home() {
  const permissions = usePermissions();
  const user = useUser();
  return (
    <Container>
      <MainSectionContainer>
        <WelcomeSectionContainer>
          Welcome, {user.userFullName}!
        </WelcomeSectionContainer>
        {permissions.includes(Permissions.CREATE_QUOTE) ? (
          <GetCoverageContainer>
            <GetQuoteBoxesContainer>
              <GetQuoteBoxesColumnContainer>
                <GetQuoteBox
                  name="SkillPac Artisan Policy (SKP)"
                  quoteType={QuoteTypes.SKP}
                  SvgComponent={ConstructionHatSvg}
                  legacyNewQuoteLink={
                    permissions.includes(Permissions.LEGACY_SKP_QUOTES)
                      ? process.env.REACT_APP_SKP_NEW_QUOTE_LINK
                      : undefined
                  }
                />
                {permissions.includes(Permissions.LEGACY_BOP_QUOTES) && (
                  <GetQuoteBox
                    name="Business Owners Program (BOP)"
                    quoteType={QuoteTypes.BOP}
                    SvgComponent={ShopFrontSvg}
                    legacyNewQuoteLink={
                      permissions.includes(Permissions.LEGACY_BOP_QUOTES)
                        ? process.env.REACT_APP_BOP_NEW_QUOTE_LINK
                        : undefined
                    }
                  />
                )}
              </GetQuoteBoxesColumnContainer>
              <GetQuoteBoxesColumnContainer>
                {permissions.includes(
                  Permissions.CREATE_MIXED_MERCANTILE_QUOTE
                ) ? (
                  <GetDoubleQuoteBox
                    titleName={"Commercial Package"}
                    subNames={["Habitational", "Mixed Mercantile"]}
                    quoteTypes={{
                      Habitational: QuoteTypes.HAB,
                      "Mixed Mercantile": QuoteTypes.MIXED_MERCANTILE,
                    }}
                    svgComponents={{
                      Habitational: BuildingSvg,
                      "Mixed Mercantile": MixedMercantileSvg,
                    }}
                  ></GetDoubleQuoteBox>
                ) : (
                  <GetQuoteBox
                    name="Commercial Package"
                    quoteType={QuoteTypes.HAB}
                    SvgComponent={BuildingSvg}
                  />
                )}
                <GetQuoteBox
                  name="Homeowners"
                  quoteType={QuoteTypes.HOME}
                  SvgComponent={HouseSvg}
                  legacyNewQuoteLink={
                    permissions.includes(Permissions.LEGACY_HO_QUOTES)
                      ? process.env.REACT_APP_NEW_HOMEOWNER_QUOTE_LINK
                      : undefined
                  }
                />
              </GetQuoteBoxesColumnContainer>
            </GetQuoteBoxesContainer>
            <AddionnalCoverageSectionContainer>
              <CoverageName>Additional Coverage</CoverageName>
              <P>
                If you are seeking another type of policy, please view our other
                coverage.{" "}
                <Link to="class-coverage-search">Class and Coverage</Link>
              </P>
              <P>
                If you still don't see what you are looking for, please email us
                ACORD forms.{" "}
                <Anchor
                  href={`mailto:${process.env.REACT_APP_EMAIL_US_ADDRESS}`}
                >
                  Email Us
                </Anchor>
              </P>
            </AddionnalCoverageSectionContainer>
          </GetCoverageContainer>
        ) : (
          <BlankSection />
        )}
      </MainSectionContainer>
      <ContactInfoContainer />
    </Container>
  );
}
