/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import CurrencyDollarIcon from "@heroicons/react/outline/CurrencyDollarIcon";
import DotsHorizontalIcon from "@heroicons/react/solid/DotsHorizontalIcon";
import {
  addInsuredPolicy,
  getPolicyList,
  getPolicyMods,
  PolicyBasicInfo,
  searchPolicies,
} from "api/policy";
import {useNavigation} from "hooks/navigation";
import {useRef, useState} from "react";
import {formatAmount, formatDate} from "utils/format";
import {Anchor} from "components/elements";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import ChevronToggle from "components/ChevronToggle";
import StatusBadge from "components/StatusBadge";
import Loading from "components/Loading";
import {
  MenuContainer,
  MenuButton,
  MenuItems,
  MenuButtonItem,
} from "components/Menu";
import IconButton from "components/IconButton";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import SearchBox from "components/SearchBox";
import Button from "components/Button";
import {Dialog, DialogBody, DialogButtonsContainer} from "components/Dialog";
import PlusIcon from "@heroicons/react/outline/PlusIcon";
import ControlledTextInput from "components/ControlledTextInput";
import {useForm} from "react-hook-form";
import AlertBar from "components/AlertBar";
import {isPaymentOnCancelledPolicyWithoutPermission} from "utils/payment";
import {useApiMutation, useApiQuery} from "hooks/api";
import Pagination from "components/Pagination";
import links from "links";
import {
  LoadingIndicator,
  PageContainer,
  ToolbarContainer,
} from "components/listPageUtils";
import {useSearchDebounce} from "hooks/debounce";

export default function PolicyList({
  searchQuery = "",
  limit,
  pageNumber,
}: {
  searchQuery?: string;
  limit: number;
  pageNumber: number;
}) {
  const permissions = usePermissions();
  const {updateSearchParams} = useNavigation();

  const policyListQuery = useApiQuery(getPolicyList, {
    keepPreviousData: true,
  })(limit.toString(), pageNumber.toString());

  const searchResultsQuery = useApiQuery(searchPolicies, {
    enabled: false,
    keepPreviousData: true,
  })(limit.toString(), pageNumber.toString(), searchQuery);

  const search = useSearchDebounce({
    enabled: !!searchQuery,
    deps: [pageNumber],
    fetch: searchResultsQuery.refetch,
  });

  return (
    <PageContainer>
      <ToolbarContainer>
        <div
          css={css`
            flex-grow: 1;
            max-width: 350px;
          `}
        >
          {permissions.includes(Permissions.VIEW_ALL_POLICIES) ||
          permissions.includes(Permissions.VIEW_AGENCY_POLICIES) ? (
            <SearchBox
              placeholder="Search policy number, name etc."
              searchValue={searchQuery}
              handleSearchInput={(v) => {
                updateSearchParams({searchQuery: v, pageNumber: 1});
                search();
              }}
              height={38}
            />
          ) : (
            permissions.includes(Permissions.VIEW_INSURED_POLICIES) && (
              <AddInsuredPolicyDialog
                refresh={() => policyListQuery.refetch()}
              />
            )
          )}
        </div>
        {(policyListQuery.isPreviousData ||
          searchResultsQuery.isPreviousData) && <LoadingIndicator />}
      </ToolbarContainer>
      {searchQuery ? (
        searchResultsQuery.data ? (
          searchResultsQuery.data.result?.length > 0 ? (
            <PolicyTable
              policyList={searchResultsQuery.data.result}
              isCurrentPageLoaded={!searchResultsQuery.isPreviousData}
              totalCount={searchResultsQuery.data.total_count}
              limit={limit}
              pageNumber={pageNumber}
              expandableMods={false}
            />
          ) : !searchResultsQuery.isPreviousData ? (
            "No results found"
          ) : (
            <Loading size="medium" />
          )
        ) : searchResultsQuery.isError ? (
          "Error"
        ) : (
          <Loading size="medium" />
        )
      ) : policyListQuery.data?.result ? (
        <PolicyTable
          policyList={policyListQuery.data.result}
          isCurrentPageLoaded={!policyListQuery.isPreviousData}
          totalCount={policyListQuery.data.total_count}
          limit={limit}
          pageNumber={pageNumber}
        />
      ) : policyListQuery.error ? (
        "Error"
      ) : (
        <Loading size="medium" />
      )}
    </PageContainer>
  );
}

function PolicyTable({
  policyList,
  isCurrentPageLoaded,
  totalCount,
  limit,
  pageNumber,
  expandableMods = true,
}: {
  policyList: PolicyBasicInfo[];
  isCurrentPageLoaded: boolean;
  totalCount: number;
  limit: number;
  pageNumber: number;
  expandableMods?: boolean;
}) {
  const tableElementref = useRef<HTMLDivElement>(null);
  return (
    <>
      <Table ref={tableElementref} disabled={!isCurrentPageLoaded}>
        <Header>
          <HeaderCell></HeaderCell>
          <HeaderCell>Policy Number</HeaderCell>
          <HeaderCell>Insured Name</HeaderCell>
          <HeaderCell>Policy Period</HeaderCell>
          <HeaderCell>Premium Amount</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell></HeaderCell>
        </Header>
        {policyList.map((policy) => (
          <PolicyGroup
            policy={policy}
            key={
              policy.policy_mod_ids.pc_provider_name +
              policy.policy_mod_ids.lob +
              policy.policy_mod_ids.policy_number +
              policy.policy_mod_ids.mod_number
            }
            expandableMods={expandableMods}
          />
        ))}
      </Table>
      <Pagination
        isCurrentPageLoaded={isCurrentPageLoaded}
        scrollOffset={70}
        resultsElementRef={tableElementref}
        pageNumber={pageNumber}
        limit={limit}
        totalCount={totalCount}
      />
    </>
  );
}

function getPolicyDetailsTabUrlNavigator(policy: PolicyBasicInfo) {
  return (tab?: string) =>
    links.policy({
      policyModIds: policy.policy_mod_ids,
      tab: tab || "details",
    });
}

function PolicyListItemRow({
  policy,
  isCurrentMod,
  isLoading,
  showChevron,
  chevronUp,
  onChevronClick,
}: {
  policy: PolicyBasicInfo;
  isCurrentMod: boolean;
  isLoading?: boolean;
  showChevron: boolean;
  chevronUp: boolean;
  onChevronClick: () => void;
}) {
  const {navigate} = useNavigation();
  const permissions = usePermissions();
  const getUrl = getPolicyDetailsTabUrlNavigator(policy);
  const policyUrl = getUrl();
  const paymentEnabled =
    permissions.includes(Permissions.MAKE_PAYMENT) &&
    !isPaymentOnCancelledPolicyWithoutPermission(policy, permissions);
  return (
    <Row onClick={() => navigate(policyUrl)}>
      <Cell>
        {isLoading ? (
          <IconButton color="secondary" isLoading={true} />
        ) : (
          showChevron && (
            <ChevronToggle up={chevronUp} onClick={onChevronClick} />
          )
        )}
      </Cell>
      <Cell>
        <Anchor href={policyUrl} onClick={(e) => e.preventDefault()}>
          {policy.policy_mod_ids.display_policy_number}
        </Anchor>
      </Cell>
      <Cell
        style={{
          maxWidth: "350px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        title={policy.insured_name}
      >
        {policy.insured_name}
      </Cell>
      <Cell>
        {formatDate(policy.effective_date)} -{" "}
        {formatDate(policy.expiration_date)}
      </Cell>
      <Cell>{formatAmount(policy.premium_amount)}</Cell>
      <Cell>
        <StatusBadge value={policy.status} />
      </Cell>
      <Cell>
        {isCurrentMod && (
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              gap: 5px;
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {paymentEnabled && (
              <IconButton
                background={false}
                onClick={() =>
                  navigate(links.payment({policyModIds: policy.policy_mod_ids}))
                }
                icon={CurrencyDollarIcon}
              />
            )}
            <MenuContainer size={"small"}>
              <MenuButton background={false} icon={DotsHorizontalIcon} />
              <MenuItems>
                <MenuButtonItem to={policyUrl}>Details</MenuButtonItem>
                <MenuButtonItem to={getUrl("documents")}>
                  Documents
                </MenuButtonItem>
                <MenuButtonItem to={getUrl("billing")}>Billing</MenuButtonItem>
              </MenuItems>
            </MenuContainer>
          </div>
        )}
      </Cell>
    </Row>
  );
}

function PolicyGroup({
  policy,
  expandableMods,
}: {
  policy: PolicyBasicInfo;
  expandableMods: boolean;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const modsQuery = useApiQuery(getPolicyMods, {enabled: isExpanded})(
    policy.policy_mod_ids
  );
  const policyGroupList =
    isExpanded && modsQuery.data ? modsQuery.data : [policy];
  return (
    <Body highlighedBorder={isExpanded}>
      {policyGroupList.map((policyMod, i) => (
        <PolicyListItemRow
          key={
            policyMod.policy_mod_ids.policy_number +
            policyMod.policy_mod_ids.mod_number
          }
          policy={policyMod}
          isCurrentMod={
            policy.policy_mod_ids.mod_number ===
            policyMod.policy_mod_ids.mod_number
          }
          showChevron={
            expandableMods &&
            i === 0 &&
            ((policy.policy_mod_ids.pc_provider_name === "point" &&
              Number(policy.policy_mod_ids.mod_number) > 10) ||
              (policy.policy_mod_ids.pc_provider_name === "majesco" &&
                Number(policy.policy_mod_ids.mod_number) > 0))
          }
          isLoading={modsQuery.isLoading}
          chevronUp={isExpanded}
          onChevronClick={() => setIsExpanded(!isExpanded)}
        />
      ))}
    </Body>
  );
}

interface AddPolicyFormFields {
  policyNumber: string;
  zipCode: string;
}

function AddInsuredPolicyDialog({refresh}: {refresh: () => Promise<any>}) {
  const [isOpen, setIsOpen] = useState(false);
  const {handleSubmit, control, reset} = useForm<AddPolicyFormFields>();

  const addInsuredPolicyMutation = useApiMutation(addInsuredPolicy, {
    onSuccess: async () => {
      await refresh();
      setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((formData) =>
    addInsuredPolicyMutation.mutateAsync([
      formData.policyNumber,
      formData.zipCode,
    ])
  );

  function closeDialog() {
    reset();
    setIsOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        background={false}
        icon={PlusIcon}
        size="small"
      >
        Add Policy
      </Button>
      <Dialog open={isOpen} title="Add Policy" onClose={() => closeDialog()}>
        <form
          css={css`
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
          `}
          onSubmit={onSubmit}
          onFocus={addInsuredPolicyMutation.reset}
        >
          <DialogBody
            css={css`
              display: flex;
              flex-direction: column;
              gap: 10px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-content: stretch;
                gap: 20px;
              `}
            >
              <div
                css={css`
                  flex-grow: 1;
                `}
              >
                <ControlledTextInput
                  name={"policyNumber"}
                  control={control}
                  label={"Policy Number"}
                  required
                  rules={{
                    required: true,
                  }}
                />
              </div>
              <ControlledTextInput
                name={"zipCode"}
                control={control}
                label={"Zip Code"}
                required
                rules={{
                  required: true,
                }}
              />
            </div>
            {addInsuredPolicyMutation.isError && (
              <AlertBar status="error">Invalid Policy Info</AlertBar>
            )}
          </DialogBody>
          <DialogButtonsContainer>
            <Button
              minWidth={100}
              type="submit"
              isLoading={addInsuredPolicyMutation.isLoading}
            >
              Add
            </Button>
            <Button
              minWidth={100}
              onClick={() => closeDialog()}
              color="secondary"
            >
              Cancel
            </Button>
          </DialogButtonsContainer>
        </form>
      </Dialog>
    </>
  );
}
