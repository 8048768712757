/** @jsxImportSource @emotion/react */

import {ReactNode} from "react";
import {css} from "@emotion/react";
import Link from "components/Link";
import {
  PencilIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import {NavigationObject, To, useNavigation} from "hooks/navigation";
import {QuoteResponse} from "api/quote";

export enum RateTabs {
  RATE = "rate",
  UNDERWRITING = "underwriting",
  SUMMARY = "summary",
  BIND = "bind",
}

export const rateQuoteTabs: {
  title: string;
  query: RateTabs;
  icon: JSX.Element;
}[] = [
  {
    title: "Rate",
    query: RateTabs.RATE,
    icon: <CurrencyDollarIcon width="20" />,
  },
  {
    title: "Underwriting",
    query: RateTabs.UNDERWRITING,
    icon: <PencilIcon width="20" />,
  },
  {
    title: "Summary",
    query: RateTabs.SUMMARY,
    icon: <DocumentTextIcon width="20" />,
  },
];

export type TabComplete = {
  rate: boolean;
  underwriting: boolean;
  summary: boolean;
  bind: boolean;
};

function StepTab({
  checked = false,
  selected = false,
  disabled = false,
  to,
  children,
}: {
  checked?: boolean;
  selected?: boolean;
  disabled?: boolean;
  to: To;
  children: ReactNode;
}) {
  return (
    <Link
      to={to}
      css={(theme) => css`
        color: ${theme.color.brand.primary};
        font-weight: ${selected ? "bold" : "normal"};
        font-size: 16px;
        background-color: ${selected
          ? theme.color.brand.secondary + "80"
          : "transparent"};
        cursor: pointer;
        border-radius: 0px;
        text-align: left;
        outline: none;
        display: flex;
        height: 100%;
        align-items: center;
        border-right: 1px solid ${theme.color.brand.secondary};
        position: relative;
        ${checked &&
        css`
          & > svg {
            color: ${theme.color.state.green};
          }
        `}
        :hover {
          color: ${theme.color.brand.primary}A0;
        }
        ${disabled &&
        css`
          cursor: not-allowed;
          color: ${theme.color.grayscale[500]};

          :hover {
            color: ${theme.color.grayscale[500]};
          }
        `}
        @media (min-width: ${theme.breakpoint.xxs}px) {
          padding: 10px 20px;
        }
        @media (min-width: ${theme.breakpoint.xs}px) {
          padding: 10px 20px;
          min-width: 100px;
          justify-content: center;
        }
        @media (min-width: ${theme.breakpoint.sm}px) {
          padding: 10px 20px;
          min-width: 150px;
          justify-content: start;
        }
        @media (min-width: ${theme.breakpoint.md}px) {
          padding: 10px 30px;
          min-width: 200px;
        }
      `}
      disabled={disabled}
    >
      {children}
    </Link>
  );
}

function RateQuoteBindTopBar({
  activeTab,
  tabComplete,
  children,
  quoteData,
}: {
  activeTab: string;
  tabComplete: TabComplete;
  children?: React.ReactNode;
  quoteData: QuoteResponse;
}) {
  const {getMergedUrl} = useNavigation();
  const linkToTab = (tab: string): NavigationObject => ({
    url: getMergedUrl({searchParamsObject: {tab}}),
    replace: true,
  });

  return (
    <div
      css={(theme) => css`
        background: ${theme.color.brand.tertiary};
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 65px;
        border-bottom: 1px solid ${theme.color.brand.secondary};
      `}
    >
      <div
        css={css`
          display: flex;
          height: 65px;
        `}
      >
        <div
          css={(theme) => css`
            background: ${theme.color.brand.secondary}80;
            border-right: 1px solid ${theme.color.brand.secondary};
            border-bottom: 1px solid ${theme.color.brand.secondary};
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width: ${theme.breakpoint.xxs}px) {
              display: none;
            }
            @media (min-width: ${theme.breakpoint.lg}px) {
              display: block;
              width: 280px;
            }
            @media (min-width: ${theme.breakpoint.xl}px) {
              display: block;
              width: 378px;
            }
          `}
        >
          {
            <div
              css={css`
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-left: 30px;
                margin-top: 15px;
                gap: 3px;
              `}
            >
              <div
                css={(theme) => css`
                  color: ${theme.color.brand.primary};

                  font-weight: 700;
                  font-size: small;
                `}
              >
                {quoteData.agency_name}
              </div>
              <div
                css={css`
                  font-size: 12;
                `}
              >
                {quoteData.producer}
              </div>
            </div>
          }
        </div>
        {rateQuoteTabs.map((tabItem, index) => (
          <StepTab
            key={index}
            to={linkToTab(tabItem.query)}
            selected={tabItem.query === activeTab}
            disabled={index > 0 && !tabComplete[rateQuoteTabs[index - 1].query]}
            checked={tabComplete[tabItem.query]}
          >
            {tabComplete[tabItem.query] ? (
              <CheckIcon width="20" />
            ) : (
              tabItem.icon
            )}
            <span
              css={(theme) => css`
                margin-left: 10px;
                @media (min-width: ${theme.breakpoint.xxs}px) {
                  display: none;
                }
                @media (min-width: ${theme.breakpoint.sm}px) {
                  display: block;
                }
              `}
            >
              {tabItem.title}
            </span>
          </StepTab>
        ))}
      </div>
      {children}
    </div>
  );
}

export default RateQuoteBindTopBar;
