import styled from "@emotion/styled";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import Button from "./Button";

interface SearchBoxContainerProps {
  height?: number;
  showAlwaysBackground?: boolean;
}

const SearchContainer = styled.input<SearchBoxContainerProps>`
  ${(props) => props.theme.text.p2}
  width: 100%;
  height: ${(props) => props.height || 52}px;
  outline: none;
  background-color: ${(props) =>
    props.showAlwaysBackground
      ? props.theme.color.grayscale.white
      : "transparent"};
  border-radius: 8px;
  border: none;
  color: ${(props) => props.theme.color.grayscale[800]};
  padding: 10px 20px 10px 40px;

  &:not(:placeholder-shown),
  :focus,
  :hover {
    background-color: ${(props) => props.theme.color.grayscale.white};
  }

  &::placeholder {
    color: ${(props) => props.theme.color.grayscale[600]};
  }
`;

const SearchWrap = styled.div`
  position: relative;
`;

const SearchImg = styled(SearchIcon)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 10px;
  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.color.brand.primary};
`;

const ClearButton = styled(Button)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 10px;
`;

function SearchBox({
  placeholder,
  searchValue,
  handleSearchInput,
  showAlwaysBackground,
  height,
}: {
  placeholder: string;
  searchValue: string;
  handleSearchInput: (value: string) => void;
} & SearchBoxContainerProps) {
  return (
    <SearchWrap>
      <SearchImg />
      <SearchContainer
        showAlwaysBackground={showAlwaysBackground}
        height={height}
        value={searchValue}
        onChange={(e) => handleSearchInput(e.target.value)}
        placeholder={placeholder}
      />
      {searchValue && (
        <ClearButton
          background={false}
          icon={XIcon}
          onClick={() => handleSearchInput("")}
        />
      )}
    </SearchWrap>
  );
}

export default SearchBox;
