/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {ReactNode} from "react";

export default function PageContainer({children}: {children: ReactNode}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin: 30px 30px 60px 30px;
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 550px;
        `}
      >
        {children}
      </div>
    </div>
  );
}
