/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {PolicyForm} from "api/policy";
import {Box} from "components/Box";
import {Header, Row, Body, Table, Cell, HeaderCell} from "components/Table";
import {useState} from "react";
import FormTitle from "../components/FormTitle";

function FormListSection({forms}: {forms?: PolicyForm[] | null}) {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore((flag) => !flag);

  if (!forms) return null;

  return (
    <Box mb={100}>
      <FormTitle.Header>Forms</FormTitle.Header>

      <Table
        marginBottom={10}
        borderRadius={6}
        noBodyShadow
        checkeredBackground
      >
        <Header>
          <HeaderCell>Form Number</HeaderCell>
          <HeaderCell>Form Name</HeaderCell>
          <HeaderCell>Edition Date</HeaderCell>
        </Header>
        <Body>
          {forms.slice(0, showMore ? undefined : 5).map((formData) => (
            <Row>
              <Cell>{formData.name}</Cell>
              <Cell>{formData.description}</Cell>
              <Cell>{formData.edition}</Cell>
            </Row>
          ))}
        </Body>
      </Table>

      <span
        css={(theme) => css`
          ${theme.text.button2}
          cursor: pointer;
          color: ${theme.color.state.orange};
          font-weight: bold;
        `}
        onClick={toggleShowMore}
      >
        {showMore ? "Show less" : "Show more"}
      </span>
    </Box>
  );
}

export default FormListSection;
