/** @jsxImportSource @emotion/react */

import Button from "./Button";
import {useMutation, useQueryClient} from "react-query";
import {QuoteResponse, getQuote, toggleIsUnderwriterQuoting} from "api/quote";
import {useQuoteContext} from "pages/RateQuoteBind/contexts/quoteContext";
import {PencilIcon} from "@heroicons/react/solid";

function IsUnderwriterQuotingButton({quoteData}: {quoteData: QuoteResponse}) {
  const queryClient = useQueryClient();
  const quoteContext = useQuoteContext();
  const {mutateAsync: mutateIsUnderwriterQuoting, isLoading: isLoadingToggle} =
    useMutation(() => toggleIsUnderwriterQuoting(quoteContext.quoteId), {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([
          getQuote.name,
          String(quoteData.quote_id),
        ]);
      },
    });

  return (
    <Button
      background={quoteData.is_underwriter_quoting}
      onClick={() => mutateIsUnderwriterQuoting()}
      size={"small"}
      isLoading={isLoadingToggle}
      icon={PencilIcon}
    >
      Edit Mode {quoteData.is_underwriter_quoting ? "On" : "Off"}
    </Button>
  );
}

export default IsUnderwriterQuotingButton;
