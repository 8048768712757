/** @jsxImportSource @emotion/react */

import {RuleResult, ObjectBase, RuleResultType} from "api/quote";
import {css, Theme} from "@emotion/react";
import {DOMAttributes, useMemo, useState} from "react";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {useFieldMeta} from "./hooks/useFieldMeta";
import {useFieldRuleResult} from "./hooks/useFieldRuleResult";
import Button from "components/Button";
import {useQuoteContext} from "../contexts/quoteContext";
import {inApprovedStatus} from "utils/quotePolicy";
import {toTitleCase} from "utils/format";

function getErrorLabel(resultType: RuleResultType) {
  if (resultType === RuleResultType.REQUIRED) return "";

  if (resultType === RuleResultType.REFERRED_PRERATE)
    return `${toTitleCase(RuleResultType.REFERRED)}: `;

  if (resultType === RuleResultType.IMMEDIATE_DECLINATION)
    return `${toTitleCase(RuleResultType.DECLINATION)}: `;

  if (resultType === RuleResultType.VALIDATION) return "";

  return `${toTitleCase(resultType)}: `;
}

function getRuleResultMessage(ruleResult: RuleResult) {
  let message: string;

  if (
    ruleResult.result_type === RuleResultType.SUCCESS &&
    (ruleResult.is_prev_declination || ruleResult.is_prev_referral)
  )
    message = `${getErrorLabel(RuleResultType.REFERRED)}Field previously ${
      ruleResult.is_prev_declination ? "declined" : "referred"
    }`;
  else if (ruleResult.result_type === RuleResultType.REQUIRED) {
    message = "Field is required";
  } else {
    message =
      `${getErrorLabel(ruleResult.result_type)}${ruleResult.message}` || "";
  }

  if (ruleResult.overriden) {
    message = "(Overriden) " + message;
  }

  return message;
}

export function FieldMessage<T extends ObjectBase>({
  object,
  field,
  showSpace = false,
  ...props
}: {
  object: T;
  field: keyof T;
  showSpace?: boolean;
} & DOMAttributes<HTMLDivElement>) {
  const {status} = useQuoteContext();
  const {mutateUpdateFieldMeta, updatingFieldMeta} = useFieldMeta(
    object,
    field
  );
  const permissions = usePermissions();
  const {ruleResult} = useFieldRuleResult(object, field);
  const [focus, setFocus] = useState(false);

  const message = useMemo(() => {
    if (ruleResult) return getRuleResultMessage(ruleResult);
    return "";
  }, [ruleResult]);

  const toggleOverrideMeta = () => {
    mutateUpdateFieldMeta({override: !ruleResult?.overriden});
  };
  const hideMessage =
    !ruleResult ||
    (ruleResult.result_type === RuleResultType.SUCCESS &&
      !ruleResult.is_prev_declination &&
      !ruleResult.is_prev_referral) ||
    inApprovedStatus(status);

  function getMessageColor(theme: Theme) {
    if (!ruleResult || ruleResult.overriden) return theme.color.grayscale[600];
    if (
      ruleResult.result_type === RuleResultType.REFERRED ||
      ruleResult.result_type === RuleResultType.REFERRED_PRERATE ||
      (ruleResult.result_type === RuleResultType.SUCCESS &&
        (ruleResult.is_prev_declination || ruleResult.is_prev_referral))
    )
      return theme.color.state.orange;
    if (ruleResult.result_type === RuleResultType.MESSAGE)
      return theme.color.grayscale[600];
    return theme.color.state.red;
  }

  return !hideMessage ? (
    <div
      {...props}
      css={(theme) => css`
        ${theme.text.p2}
        position: relative;
        margin-top: 5px;
        color: ${getMessageColor(theme)};
        display: block;
      `}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      {focus && permissions.includes(Permissions.OVERRIDE_QUOTE_RULES) && (
        <Button
          css={css`
            position: absolute;
          `}
          size="xsmall"
          color={ruleResult?.overriden ? "danger" : "primary"}
          isLoading={updatingFieldMeta}
          onClick={toggleOverrideMeta}
        >
          {ruleResult?.overriden ? "Remove Override" : "Override"}
        </Button>
      )}
      {message}
    </div>
  ) : showSpace ? (
    <div
      css={css`
        height: 24.59px;
      `}
    />
  ) : null;
}
