import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {Menu} from "@headlessui/react";
import classNames from "classnames";
import Button, {ButtonProps} from "components/Button";
import {useNavigation} from "hooks/navigation";
import {createContext, HTMLAttributes, ReactNode, useContext} from "react";
import Surface from "./Surface";

const MenuWrapper = styled(Menu)`
  position: relative;
` as unknown as typeof Menu;

type HuiMenuProps = Parameters<typeof Menu>[0];

export function MenuContainer({children, ...props}: HuiMenuProps) {
  return (
    <MenuWrapper as="div" {...props}>
      {children}
    </MenuWrapper>
  );
}

type HuiMenuButtonProps = Parameters<typeof Menu.Button>[0];

export function MenuButton(props: ButtonProps & HuiMenuButtonProps) {
  return <Menu.Button as={Button} {...props} />;
}

type menuSizeOptions = "small" | "medium" | "large" | undefined | null;

const menuSizeContext = createContext<menuSizeOptions>("medium");

interface StyledMenuItemsProps {
  width?: string;
  minWidth?: string;
}

const MenuItemsWrapper = styled.div<StyledMenuItemsProps>`
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 25;
  width: ${({width}) => width || "max-content"};
  min-width: ${({minWidth}) => minWidth || "175px"};
`;

export function MenuItems({
  children,
  width,
  size,
  minWidth,
  padding,
}: {
  children: ReactNode;
  padding?: string;
  size?: menuSizeOptions;
} & StyledMenuItemsProps) {
  return (
    <menuSizeContext.Provider value={size}>
      <MenuItemsWrapper width={width} minWidth={minWidth}>
        <Menu.Items>
          <Surface
            style={{
              maxHeight: "50vh",
            }}
            padding={padding}
          >
            {children}
          </Surface>
        </Menu.Items>
      </MenuItemsWrapper>
    </menuSizeContext.Provider>
  );
}

interface StyledMenuItemProps {
  size?: menuSizeOptions;
  ident?: boolean;
}

function sharedItemStyle({
  theme,
  size,
  ident,
}: StyledMenuItemProps & {theme: Theme}) {
  return css`
    ${theme.text.p2}
    color: ${theme.color.brand.primary};
    ${(size === "medium" || !size) &&
    css`
      padding: 12px;
    `};
    ${size === "small" &&
    css`
      padding: 8px;
    `};
    ${size === "large" &&
    css`
      padding: 16px;
    `};
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background: ${theme.color.grayscale.white};
    outline: none;
    width: 100%;
    ${ident &&
    css`
      padding-left: 32px;
    `};
  `;
}

const TextItem = styled.div<StyledMenuItemProps>`
  ${sharedItemStyle}
`;

const ButtonItem = styled.button<StyledMenuItemProps>`
  ${sharedItemStyle}
  cursor: pointer;
  &.active {
    background-color: ${({theme}) => theme.color.grayscale[100]};
  }
`;

export function MenuButtonItem({
  to,
  icon: Icon,
  children,
  onClick,
  size,
  ident,
  ...props
}: {
  icon?: ((props: any) => JSX.Element) | null;
  to?: string;
} & HTMLAttributes<HTMLButtonElement> &
  StyledMenuItemProps) {
  const sizeFromContext = useContext(menuSizeContext);
  const {navigate} = useNavigation();
  return (
    <Menu.Item>
      {({active}) => (
        <ButtonItem
          size={size || sizeFromContext}
          ident={Icon ? false : ident}
          onClick={(e) => (to ? navigate(to) : onClick && onClick(e))}
          {...props}
          className={classNames({active: active})}
        >
          {Icon && <Icon style={{width: 16}} />}
          {children}
        </ButtonItem>
      )}
    </Menu.Item>
  );
}

export function MenuTextItem(props: HTMLAttributes<HTMLDivElement>) {
  const size = useContext(menuSizeContext);
  return (
    <Menu.Item>
      {({active}) => (
        <TextItem
          size={size}
          {...props}
          className={classNames({active: active})}
        />
      )}
    </Menu.Item>
  );
}
