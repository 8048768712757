export const apiUrl = process.env.REACT_APP_API_URL;

export async function handleResponse<T>(response: Response): Promise<T> {
  let data: any = null;
  let error = null;
  try {
    const json = await response.clone().json();
    if (json.success) data = json.data;
    else {
      console.error(`[api.error] ${json.error}`);
      error = json.error;
    }
  } catch (e) {
    console.error(e);
    error = response.text();
  }
  if (error) throw error;
  else return data;
}

export type ObjectType = {
  [key: string]: string | number | boolean;
};

type JSONValue =
  | string
  | number
  | boolean
  | {[x: string]: JSONValue}
  | Array<JSONValue>;

export type JsonObjectType = {
  [key: string]: JSONValue;
};
export type ParamType = {[key: string]: string};

function getUrlWithParams(url: string, params: ParamType = {}) {
  const queryParamsStringefied = new URLSearchParams(params).toString();
  if (queryParamsStringefied) url = `${url}?${queryParamsStringefied}`;
  return url;
}

type FetchMethodOptions = {
  data?: JsonObjectType | FormData | ReadableStream | File;
  params?: ParamType;
  headers?: ObjectType;
};

export async function fetchMethod<T>(
  method: string,
  url: string,
  {data, params, headers}: FetchMethodOptions = {
    data: {},
    params: {},
    headers: {},
  }
) {
  const urlWithParams = getUrlWithParams(url, params);
  const staticHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const updatedHeaders = {
    ...staticHeaders,
    ...headers,
  };
  const response = await fetch(urlWithParams, {
    method: method,
    headers: updatedHeaders,
    body:
      data instanceof ReadableStream ||
      data instanceof FormData ||
      data instanceof File
        ? data
        : JSON.stringify(data),
    credentials: "include",
  });
  return handleResponse<T>(response);
}

export async function get<T>(url: string, params: ParamType = {}) {
  const urlWithParams = getUrlWithParams(url, params);
  const response = await fetch(urlWithParams, {
    credentials: "include",
  });
  return handleResponse<T>(response);
}

export function post<T>(url: string, options: FetchMethodOptions) {
  return fetchMethod<T>("POST", url, options);
}

export function put<T>(url: string, options: FetchMethodOptions) {
  return fetchMethod<T>("PUT", url, options);
}

export function patch<T>(url: string, options: FetchMethodOptions) {
  return fetchMethod<T>("PATCH", url, options);
}

export async function del<T>(url: string, params: ParamType = {}) {
  const urlWithParams = getUrlWithParams(url, params);
  const response = await fetch(urlWithParams, {
    credentials: "include",
    method: "delete",
  });
  return handleResponse<T>(response);
}
export interface PaginatedResult<T> {
  page_number: number;
  limit: number;
  total_count: number;
  result: T;
}

export interface UnderwriterListItem {
  id: number;
  full_name: string;
}
