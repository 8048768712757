import Button from "./Button";
import {MenuContainer, MenuButton, MenuItems, MenuButtonItem} from "./Menu";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import {OptionType} from "./DropDown";

export function FilterDropDown({
  title,
  options,
  value,
  onChange,
}: {
  title: string;
  options: OptionType[];
  value: string | number | boolean;
  onChange: (value: string | number | boolean) => void;
}) {
  return (
    <MenuContainer>
      <MenuButton
        size="small"
        color={!!value ? "secondary" : "primary"}
        background={!!value}
        icon={value ? CheckIcon : null}
      >
        {`${title}${
          value
            ? `: ${
                options.find(({value: optionValue}) => optionValue === value)
                  ?.title
              }`
            : ""
        }`}
      </MenuButton>
      <MenuItems size="small">
        {options.map(({title, value: optionValue}) => (
          <MenuButtonItem
            icon={optionValue === value ? CheckIcon : null}
            onClick={() =>
              optionValue !== value ? onChange(optionValue) : onChange("")
            }
            ident={optionValue !== value}
          >
            {title}
          </MenuButtonItem>
        ))}
      </MenuItems>
    </MenuContainer>
  );
}

export function FilterToggle({
  title,
  checked,
  onClick,
}: {
  title: string;
  checked: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      size="small"
      color={checked ? "secondary" : "primary"}
      background={checked}
      icon={checked ? CheckIcon : null}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
