import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {DocumentTextIcon, DownloadIcon} from "@heroicons/react/outline";
import {Attachment, ThreadMessage} from "api/message";
import UserIcon from "assets/images/user.svg";
import Avatar from "components/Avatar";
import IconButton from "components/IconButton";
import {formatDateTime} from "utils/format";
import AEIGLogo from "assets/images/company.svg";
import {downloadFile} from "utils/dom";

const MainContainer = styled.div`
  padding: 15px 15px 20px;
  display: flex;
  background: ${(props) => props.theme.color.grayscale.white};
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
`;

const Name = styled.h3`
  ${(props) => props.theme.text.header3}
  font-weight: bold;
  margin: 0 0 5px;
  color: ${(props) => props.theme.color.brand.primary};
  display: flex;
  justify-content: space-between;
`;

const MessageText = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale[800]};
  padding-top: 15px;
  word-break: break-word;
`;

const SideContent = styled.div`
  width: calc(100% - 32px);
  padding-left: 20px;
`;

const Time = styled.div`
  ${(props) => props.theme.text.p3}
  color: ${(props) => props.theme.color.grayscale[600]};
  margin-left: 10px;
`;

const AttachmentContainer = styled.div`
  background: ${(props) => props.theme.color.grayscale.white};
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
  margin: 15px 15px 0 0;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
`;

const AttachmentName = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.brand.primary};
  font-weight: bold;
  padding-left: 10px;
`;

const MessageAttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function Message({message}: {message: ThreadMessage}) {
  return (
    <MainContainer key={message.id} id={`${message.id}`}>
      <Avatar src={message.user?.first_name ? UserIcon : AEIGLogo} />
      <SideContent>
        <Name>
          {" "}
          {message.user?.first_name || "American European Insurance Group"}
          <Time>{formatDateTime(message.created_at)}</Time>
        </Name>
        <MessageText dangerouslySetInnerHTML={{__html: message.content}} />
        <MessageAttachmentContainer>
          {message.attachments?.map((singleAttachment: Attachment, index) => {
            return (
              <AttachmentContainer key={index}>
                <NameContainer
                  onClick={() => window.open(singleAttachment.url, "_blank")}
                >
                  <IconButton
                    css={(theme) => css`
                      cursor: pointer;
                      outline: 0;
                      width: 24px !important;
                      height: 24px !important;
                      border-radius: 0;
                      border: 0;
                      padding: 0px;
                      color: ${theme.color.brand.primary};
                      background: transparent;
                      &:hover {
                        background: transparent !important;
                      }
                    `}
                  >
                    <DocumentTextIcon />
                  </IconButton>
                  <AttachmentName>{singleAttachment.file_name}</AttachmentName>
                </NameContainer>
                <IconButton
                  onClick={() =>
                    downloadFile(
                      singleAttachment.url,
                      singleAttachment.file_name
                    )
                  }
                  css={(theme) => css`
                    cursor: pointer;
                    outline: 0;
                    width: 18px !important;
                    height: 18px !important;
                    border-radius: 0;
                    border: 0;
                    background: transparent;
                    padding: 0px;
                    color: ${theme.color.brand.primary};
                    &:hover {
                      background: transparent !important;
                    }
                  `}
                >
                  <DownloadIcon />
                </IconButton>
              </AttachmentContainer>
            );
          })}
        </MessageAttachmentContainer>
      </SideContent>
    </MainContainer>
  );
}

export default Message;
