/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";

function Spacer({width, height}: {width?: number; height?: number}) {
  return (
    <div
      css={css`
        width: ${width || 0}px;
        height: ${height || 0}px;
      `}
    />
  );
}

export default Spacer;
