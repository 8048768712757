export const classCodes = [
  {label: "Concrete Construction (91560)", value: "91560"},
  {
    label:
      "Appliances and Accessories - installation,servicing or repair - household (91155)",
    value: "91155",
  },
  {label: "Carpentry - NOC (91342)", value: "91342"},
  {
    label:
      "Carpet, Rug, Furniture or Upholstery Cleaning - on customers' premises  (91405)",
    value: "91405",
  },
  {
    label:
      "Contractors - subcontracted work - in connection with construction, reconstruction, erection or repair - not buildings (91581)",
    value: "91581",
  },
  {
    label: "Driveway, Parking Area or Sidewalk - paving or repaving (92215)",
    value: "92215",
  },
  {label: "Drywall or Wallboard Installation (92338)", value: "92338"},
  {label: "Electrical Work - within buildings  (92478)", value: "92478"},
  {label: "Fence Erection Contractors (94276)", value: "94276"},
  {
    label: "Floor Covering Installation - not ceramic tile or stone (94569)",
    value: "94569",
  },
  {
    label:
      "Heating or Combined Heating and Air Conditioning Systems or Equipment - dealers or distributors and installation, servicing or repair -no liquefied petroleum gas (LPG) equipment sales or work (95647)",
    value: "95647",
  },
  {
    label: "Heating or Combined w/AC – install/service/repair (95648)",
    value: "95648",
  },
  {label: "House Furnishings Installation (96053)", value: "96053"},
  {label: "Interior Decorators (96611)", value: "96611"},
  {label: "Janitorial Services (96816)", value: "96816"},
  {label: "Landscape Gardening (97047)", value: "97047"},
  {label: "Masonry (97447)", value: "97447"},
  {
    label:
      "Office Machines or Appliances - installation, inspection, adjustment or repair (98111)",
    value: "98111",
  },
  {
    label:
      "Painting - exterior - buildings or structures - three stories or less in height (98304)",
    value: "98304",
  },
  {label: "Paperhanging (98344)", value: "98344"},
  {label: "Plastering or Stucco Work (98449)", value: "98449"},
  {label: "Plumbing - residential or domestic (98483)", value: "98483"},
  {
    label:
      "Refrigeration Systems or Equipment - dealers and distributors and installation, servicing or repair - commercial(98636)",
    value: "98636",
  },
  {label: "Siding Installation (98967)", value: "98967"},
  {
    label:
      "Tile, Stone, Marble, Mosaic or Terrazzo Work - interior construction (99746)",
    value: "99746",
  },
];
