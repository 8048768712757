/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Center from "./Center";
import Button from "./Button";
import {Anchor} from "./elements";
import DocumentTextIcon from "@heroicons/react/outline/DocumentTextIcon";
import DownloadIcon from "@heroicons/react/outline/DownloadIcon";
import {downloadFile, getFilenameFromUrl} from "utils/dom";
import {useState} from "react";
import {useNavigation} from "hooks/navigation";

function Document({
  name,
  header,
  footer,
  url,
}: {
  name: string;
  header?: string | null;
  footer?: string | null;
  url?: string | null;
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const {navigate} = useNavigation();
  return (
    <div
      css={(theme) => css`
        display: flex;
        justify-content: space-between;
        height: ${header && footer ? 90 : 72}px;
        box-shadow: 0px 2px 8px rgba(127, 134, 160, 0.05);
        border-radius: 6px;
        background-color: ${theme.color.brand.tertiary};
        padding: ${header && footer ? 12 : 8}px;
        margin-bottom: 8px;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <Center
          css={css`
            cursor: pointer;
          `}
          onClick={() => url && navigate(url, true)}
        >
          <DocumentIconBox />
        </Center>
        <span
          css={css`
            line-height: 140%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          {header && (
            <span
              css={(theme) => css`
                font-size: 14px;
                color: ${theme.color.grayscale[600]};
              `}
            >
              {header}
            </span>
          )}
          {url ? (
            <Anchor
              href={url}
              target="_blank"
              css={css`
                font-size: 16px;
              `}
            >
              {name}
            </Anchor>
          ) : (
            <span
              css={css`
                font-size: 16px;
              `}
            >
              {name}
            </span>
          )}
          {footer && (
            <span
              css={(theme) => css`
                font-size: 13px;
                color: ${theme.color.grayscale[600]};
              `}
            >
              {footer}
            </span>
          )}
        </span>
      </div>
      {url && (
        <Center>
          <Button
            onClick={() => {
              setIsDownloading(true);
              downloadFile(url, getFilenameFromUrl(url) || "download").finally(
                () => setIsDownloading(false)
              );
            }}
            isLoading={isDownloading}
            size="small"
            background={false}
            icon={DownloadIcon}
          >
            Download
          </Button>
        </Center>
      )}
    </div>
  );
}

function DocumentIconBox() {
  return (
    <span
      css={(theme) => css`
        width: 56px;
        height: 56px;
        background: ${theme.color.brand.secondary};
        border-radius: 6px;
      `}
    >
      <Center>
        <DocumentTextIcon
          css={(theme) => css`
            width: 20px;
            height: 20px;
            color: ${theme.color.brand.primary};
          `}
        />
      </Center>
    </span>
  );
}

export default Document;
