import styled from "@emotion/styled";
import {getAllManuals} from "api/document";
import {Anchor} from "components/elements";
import {useApiQuery} from "hooks/api";

const getFileName = (file_path: string) => {
  const parts = file_path.split("/");
  const fileNameWithExtension = parts[parts.length - 1];
  return fileNameWithExtension.split(".")[0];
};

const getFolderName = (file_path: string) => {
  const parts = file_path.split("/");
  return parts[parts.length - 2];
};

const StyledLink = styled.h4`
  margin-left: 20px;
`;

export default function Manuals() {
  const manualsQuery = useApiQuery(getAllManuals, {
    keepPreviousData: true,
  })();

  return (
    <>
      {manualsQuery.data?.map((manual) => {
        if (manual.file_name.endsWith("/")) {
          return <h4>{getFolderName(manual.file_name)}</h4>;
        } else {
          return (
            <StyledLink>
              <Anchor href={`${manual.file_link}`} target="_blank">
                {getFileName(manual.file_name)}
              </Anchor>
            </StyledLink>
          );
        }
      })}
    </>
  );
}
