import styled from "@emotion/styled";
import {getAgencyExperienceByMonth} from "api/document";
import {Anchor} from "components/elements";
import {useApiQuery} from "hooks/api";
import {useNavigation} from "hooks/navigation";
import {DropDown} from "components/DropDown";
import {Combobox} from "components/Combobox";
import {getDateRelativeToToday} from "utils/date";
import {useMemo} from "react";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const year_list = () => {
  const years = [];
  for (
    let year = getDateRelativeToToday(0).getFullYear();
    year > 1980;
    year--
  ) {
    years.push(year.toString());
  }
  return years;
};
const getListOfOfficeCodes = (result_list: any) => {
  const unique: any = [""];
  result_list.forEach((key_object: any) => {
    const office_code_parts = key_object.file_name.split("/");
    const office_code = office_code_parts[5];
    const office_code_name =
      office_code +
      "-" +
      office_code_parts[office_code_parts.length - 1].split("_")[0];
    if (
      !unique.includes(office_code_name) &&
      office_code &&
      !["aggregator","Rollup"].includes(office_code)
    ) {
      unique.push(office_code_name);
    }
  });
  return unique.map((office_code: any) => {
    return {
      name: office_code || "Agencies",
      value: office_code.split("-")[0],
    };
  });
};
const getFileName = (file_path: string) => {
  const parts = file_path.split("/");
  const fileNameWithExtension =
    parts[parts.length - 2] +
    " " +
    parts[parts.length - 1].split("_")[0].split(".")[0];
  return fileNameWithExtension;
};

const getFolderName = (file_path: string) => {
  const parts = file_path.split("/");
  return parts[parts.length - 2];
};

const StyledLink = styled.h4`
  margin-left: 20px;
`;

export default function AgencyExperience({
  year,
  month,
  officeCode,
}: {
  year: string;
  month: string;
  officeCode: string;
}) {
  const agencyExperienceQuery = useApiQuery(getAgencyExperienceByMonth, {
    keepPreviousData: true,
  })(year, month, officeCode);

  const {updateSearchParams} = useNavigation();
  const result_list = getListOfOfficeCodes(agencyExperienceQuery?.data || []);
  const officeCodeWithTitle = useMemo(() => {
    return result_list.find((office_code: any) => {
      return office_code.value === officeCode;
    });
  }, [result_list, officeCode]);
  return (
    <PageContainer>
      <ToolbarContainer>
        <DropDown
          background={true}
          options={year_list().map((yearOption) => ({
            title: yearOption,
            value: yearOption,
          }))}
          onChange={(value) =>
            updateSearchParams({
              year: value,
            })
          }
          label={"Year"}
          value={year.toString()}
        />
        <DropDown
          background={true}
          options={[
            {value: "01", title: "January"},
            {value: "02", title: "February"},
            {value: "03", title: "March"},
            {value: "04", title: "April"},
            {value: "05", title: "May"},
            {value: "06", title: "June"},
            {value: "07", title: "July"},
            {value: "08", title: "August"},
            {value: "09", title: "September"},
            {value: "10", title: "October"},
            {value: "11", title: "November"},
            {value: "12", title: "December"},
          ]}
          onChange={(value) =>
            updateSearchParams({
              month: value,
            })
          }
          label={"Month"}
          value={month.toString()}
        />
      </ToolbarContainer>
      <Combobox
        background={true}
        label="Office Code"
        disabled={result_list.length === 0}
        options={
          result_list.map((office_code: any) => ({
            title: office_code.name,
            value: office_code.value,
          })) || []
        }
        onChange={(value: any) => {
          updateSearchParams({
            officeCode: value ? value.value : "",
          });
        }}
        value={{
          title: officeCodeWithTitle?.name,
          value: officeCodeWithTitle?.value,
        }}
        autoComplete="off"
      />
      {agencyExperienceQuery.data?.map((agencyExperience: any) => {
        if (agencyExperience.file_name.endsWith("/")) {
          return <h4>{getFolderName(agencyExperience.file_name)}</h4>;
        } else {
          return (
            <StyledLink>
              <Anchor href={`${agencyExperience.file_link}`} target="_blank">
                {getFileName(agencyExperience.file_name)}
              </Anchor>
            </StyledLink>
          );
        }
      })}
    </PageContainer>
  );
}
