/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";
import {QuoteStatuses} from "api/quote";

function ApprovedContent({
  status,
  unsubmittingQuote,
  onSummary,
  onUnSubmitQuote,
}: {
  status: QuoteStatuses;
  unsubmittingQuote: boolean;
  onSummary: () => void;
  onUnSubmitQuote: () => void;
}) {
  const permissions = usePermissions();

  return (
    <>
      {status === QuoteStatuses.APPROVED &&
      (permissions.includes(Permissions.UNSUBMIT_QUOTE_ON_APPROVED) ||
        permissions.includes(Permissions.UNSUBMIT_QUOTE_ALWAYS)) ? (
        <Button
          size="large"
          isLoading={unsubmittingQuote}
          css={css`
            margin-bottom: 20px;
          `}
          onClick={() => onUnSubmitQuote()}
        >
          Unsubmit Quote
        </Button>
      ) : (
        <></>
      )}
      <div
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          min-height: 300px;
          width: 600px;
          background: ${theme.color.brand.tertiary};
          border-radius: 8px;
          padding: 40px 32px;
        `}
      >
        <h2
          css={(theme) =>
            css`
              ${theme.text.header1}
              margin-top: 0px;
            `
          }
        >
          This submission was approved
        </h2>
        <Button size="large" onClick={onSummary} disabled={unsubmittingQuote}>
          Quote Summary
        </Button>
      </div>
    </>
  );
}

export default ApprovedContent;
