/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import Button from "components/Button";
import {TextInput} from "components/TextInput";
import {useAuth} from "hooks/auth";
import {useEffect, useRef, useState} from "react";
import {getHtmlTitle} from "utils";
import {ReactComponent as AeigLogo} from "assets/images/AeigLogo.svg";
import buildingsImage from "assets/images/buildings.jpg";
import SplitScreen from "components/SplitScreen";
import {Anchor, H1} from "components/elements";
import AlertBar from "components/AlertBar";
import Link from "components/Link";

export default function Login() {
  const {login, isLoggingIn} = useAuth();
  const [loginError, setLoginError] = useState(false);
  const usernameRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const resetLink = process.env.REACT_APP_REGISTER_RESET_LINK;
  const agentRegisterLink = process.env.REACT_APP_REGISTER_AGENT_LINK;
  useEffect(() => {
    window.document.title = getHtmlTitle("Login");
  });
  return (
    <SplitScreen imageurl={buildingsImage}>
      <form
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          overflow: auto;
        `}
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await login({
              username: usernameRef.current.value,
              password: passwordRef.current.value,
            });
          } catch (error) {
            setLoginError(true);
          }
        }}
      >
        <Anchor href={process.env.REACT_APP_AEIG_INSURANCE_WEBSITE_LINK}>
          <AeigLogo />
        </Anchor>
        <H1>Login</H1>
        <TextInput
          label="Email"
          ref={usernameRef}
          type="username"
          name="username"
          error={loginError}
          autoFocus
          onFocus={() => setLoginError(false)}
        />
        <TextInput
          label="Password"
          ref={passwordRef}
          type="password"
          name="password"
          error={loginError}
          onFocus={() => setLoginError(false)}
        />
        <Button type="submit" fullwidth isLoading={isLoggingIn}>
          Log in
        </Button>
        {loginError && <AlertBar status="error">Invalid Credentials</AlertBar>}
        <div>
          <Anchor href={resetLink}>Forgot Password</Anchor>
        </div>
        <div>
          <Anchor href={agentRegisterLink}>Agent Register</Anchor>
        </div>
        <div>
          <Link to={"/insured/register"}>Insured Register</Link>
        </div>
        <div>
          <Link to={"/payments/guest"}>Express Pay</Link>
        </div>
        {process.env.REACT_APP_CURRENT_VERSION && (
          <p
            css={(theme) =>
              css`
                ${theme.text.p3}
              `
            }
          >
            Version {process.env.REACT_APP_CURRENT_VERSION}
          </p>
        )}
      </form>
    </SplitScreen>
  );
}
