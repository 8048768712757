import styled from "@emotion/styled";

const ImageContainer = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

export default ImageContainer;
