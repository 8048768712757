import styled from "@emotion/styled";
import {Menu} from "@headlessui/react";
import classNames from "classnames";
import {ReactComponent as FiltersIcon} from "assets/images/filter-icon.svg";
import {HuiToFC} from "typeUtils";
import {ThreadListFilter} from "api/message";

const MenuWrapper = styled(Menu as HuiToFC<typeof Menu>)`
  position: relative;
`;

const MenuButton = styled(Menu.Button)`
  color: ${(props) => props.theme.color.brand.primary};
  background: ${(props) => props.theme.color.grayscale.white};
  font-weight: 700;
  border-radius: 0px;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  padding-right: 20px;
  position: relative;
` as typeof Menu.Button;

const MenuItems = styled(Menu.Items)`
  position: absolute;
  right: 0;
  top: 30px;
  border: 1px solid ${(props) => props.theme.color.grayscale[300]};
  box-shadow: 0px 2px 4px ${(props) => props.theme.color.grayscale[300]};
  z-index: 2;
  border-radius: 4px;
  background: white;
  padding: 0 10px;
  min-width: 100px;
` as typeof Menu.Items;

const ButtonItem = styled.div`
  ${(props) => props.theme.text.p2}
  color: ${(props) => props.theme.color.grayscale["500"]};
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  outline: none;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &.selected {
    color: ${(props) => props.theme.color.brand.primary};
    pointer-events: none;
    font-weight: bold;
  }
`;

const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterIcon = styled(FiltersIcon)`
  margin-left: 5px;
  position: absolute;
  right: 0;
  pointer-events: none;
`;

function MessagingFilterDropdown({
  selectedFilter,
  handleFilterChange,
  hasAllThreadPermission,
}: {
  selectedFilter: string;
  handleFilterChange: (value: ThreadListFilter) => void;
  hasAllThreadPermission: boolean;
}) {
  const filterTypes: ThreadListFilter[] = [];
  if (hasAllThreadPermission) filterTypes.push(ThreadListFilter.ALL);
  filterTypes.push(ThreadListFilter.MY_THREADS, ThreadListFilter.UNREAD);
  const filterTitleMap: {[key: string]: string} = {
    [ThreadListFilter.ALL]: "All",
    [ThreadListFilter.MY_THREADS]: hasAllThreadPermission
      ? "My threads"
      : "All",
    [ThreadListFilter.UNREAD]: "Unread",
  };
  return (
    <MenuWrapper as="div">
      {({open}) => (
        <>
          <MenuButtonContainer>
            <MenuButton>{filterTitleMap[selectedFilter]}</MenuButton>
            <FilterIcon />
          </MenuButtonContainer>
          <MenuItems>
            {filterTypes.map((type, index) => (
              <Menu.Item key={index}>
                {({active}) => (
                  <ButtonItem
                    className={classNames({
                      active: active,
                      selected: selectedFilter === type,
                    })}
                    onClick={() => handleFilterChange(type)}
                  >
                    {filterTitleMap[type]}
                  </ButtonItem>
                )}
              </Menu.Item>
            ))}
          </MenuItems>
        </>
      )}
    </MenuWrapper>
  );
}

export default MessagingFilterDropdown;
