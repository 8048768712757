/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {GuidlinesProps} from ".";
import GuidelinesSection from "./GuidelinesSection";
import YesNoButtons from "./YesNoButtons";
import approvedSvg from "assets/images/approved.svg";
import workerSvg from "assets/images/worker.svg";
import Ul from "./Ul";
import ButtonsContainer from "./ButtonsContainer";
import QuestionTitle from "./QuestionTitle";

export default function SkpGuidelines({onYes, onNo}: GuidlinesProps) {
  return (
    <div
      css={css`
        display: flex;
        gap: 5px;
        flex-direction: column;
      `}
    >
      <QuestionTitle>
        Does this risk location meet the guidelines outlined below?{" "}
      </QuestionTitle>
      <GuidelinesSection title="Eligibility requirements" svgUrl={approvedSvg}>
        <Ul>
          <li>Up to 7 employees (part time count as ½ employee)</li>
          <li>Up to 3 classes per risk</li>
          <li>
            Max sales (including receipts for work subcontracted to others) -
            $1.5MM
          </li>
          <li>Max payroll - $500K </li>
          <li>No exterior work over 3 stories </li>
          <li>NO GC's - Subcontracted work cannot exceed 20% of receipts</li>
          <li>
            Minimum of 3 years' experience required (start-ups are allowed)
          </li>
        </Ul>
      </GuidelinesSection>
      <GuidelinesSection title="Contractor Classes" svgUrl={workerSvg}>
        <Ul>
          <li>Electrical HH Appliance Installation Repair</li>
          <li>Gas HH Appliance Installation Repair</li>
          <li>Carpentry</li>
          <li>Cabinet Makers</li>
          <li>Carpet, Rug, Furniture or Upholstery Cleaning – on customers' premises</li>
          <li>Concrete Construction</li>
          <li>Siding Installation</li>
          <li>Driveway, Parking Area or Sidewalk – paving or repaving</li>
          <li>Dry Wall Installation</li>
          <li>Electrical Wiring</li>
          <li>Carpet/Floor Installation</li>
          <li>Air Conditioning/ Heating Systems Work</li>
          <li>Sheet Metal Work</li>
          <li>Household Furnishings Installation/Repair</li>
          <li>Landscape Gardening</li>
          <li>Lawn Sprinkler Installation</li>
          <li>Masonry</li>
          <li>Office Machine Installation and Repair</li>
          <li>Painting - exterior – buildings or structures – three stories or less in height</li>
          <li>Painting and Paperhanging - interior – buildings or structures</li>
          <li>Plaster/Stucco</li>
          <li>Plumbing</li>
          <li>Tile, Stone, Marble, Terrazzo Work</li>
          <li>Fence Installation</li>
        </Ul>
      </GuidelinesSection>
      <ButtonsContainer>
        <YesNoButtons onYes={onYes} onNo={onNo} />
      </ButtonsContainer>
    </div>
  );
}
