/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import {ReactNode} from "react";
import ErrorIcon from "@heroicons/react/outline/ExclamationCircleIcon";
import WarningIcon from "@heroicons/react/outline/ExclamationIcon";
import InfoIcon from "@heroicons/react/outline/InformationCircleIcon";
import {Status} from "./types";

function AlertBar({status, children}: {children: ReactNode; status: Status}) {
  let Icon = InfoIcon;
  if (status === "error") Icon = ErrorIcon;
  if (status === "warning") Icon = WarningIcon;

  return (
    <div
      css={(theme) => css`
        width: 100%;
        color: ${status !== "info"
          ? theme.color.grayscale.white
          : theme.color.grayscale[900]};
        padding: 11px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        background-color: ${theme.color.grayscale[300]};

        ${status === "error" &&
        css`
          background-color: ${theme.color.state.red};
        `}

        ${status === "warning" &&
        css`
          background-color: ${theme.color.state.orange};
        `}

        ${status === "success" &&
        css`
          background-color: ${theme.color.state.green};
        `}
      `}
    >
      <Icon
        css={css`
          width: 20px;
          flex-shrink: 0;
          margin-right: 8px;
        `}
      />
      <div>{children}</div>
    </div>
  );
}

export default AlertBar;
