/** @jsxImportSource @emotion/react */

import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Tab as HuiTab} from "@headlessui/react";
import {extractUrlAndReplaceFromTo, useNavigation} from "hooks/navigation";
import {
  Fragment,
  ReactNode,
  createContext,
  useContext,
  forwardRef,
} from "react";
import {toTitleCase} from "utils/format";
import Link, {LinkProps} from "./Link";

const tabsContext = createContext({
  primary: false,
  large: false,
});

const BaseTab = forwardRef(
  (
    {
      selected,
      children,
      ...props
    }: {
      selected: boolean;
      children: ReactNode;
    },
    ref: any
  ) => {
    const {primary, large} = useContext(tabsContext);
    return (
      <button
        ref={ref}
        {...props}
        css={(theme) => css`
          color: ${theme.color.grayscale[500]};
          ${!primary &&
          !selected &&
          css`
            color: #001f6e75;
          `}
          ${selected &&
          css`
            color: ${theme.color.brand.primary};
          `}
          transition: all 100ms ease-out;
          :hover {
            color: ${theme.color.grayscale[500]}A0;
            ${!primary &&
            !selected &&
            css`
              color: #001f6e75A0;
            `}
            ${selected &&
            css`
              color: ${theme.color.brand.primary}A0;
            `}
          }
          font-weight: bold;
          font-size: ${primary && large ? 18 : 14}px;
          line-height: ${primary && large ? 100 : 140}%;
          background-color: transparent;
          border: none;
          cursor: pointer;
          padding: 24px 12px;
          border-radius: 0px;
          border-bottom: 1px solid ${theme.color.grayscale.white};
          ${primary &&
          !large &&
          css`
            padding: 16px 12px;
          `}
          ${!primary &&
          css`
            padding: 14px 16px 13px 16px;
          `}
          ${!primary &&
          selected &&
          css`
            border-color: ${theme.color.brand.primary};
          `}
          position: relative;
          top: 1px;
        `}
      >
        {children}
      </button>
    );
  }
);

interface TabListProps {
  children: ReactNode;
  primary?: boolean;
  large?: boolean;
}

const BaseTabList = forwardRef(
  ({children, large = true, primary = false}: TabListProps, ref: any) => {
    return (
      <tabsContext.Provider value={{large, primary}}>
        <div
          ref={ref}
          css={(theme) => css`
            display: flex;
            padding-left: 12px;
            ${primary &&
            css`
              background-color: ${theme.color.grayscale.white};
              border-radius: 6px;
            `}
            ${!primary &&
            css`
              padding-left: 0px;
              background-color: rgba(209, 234, 255, 0.3);
            `}
          `}
        >
          {children}
        </div>
      </tabsContext.Provider>
    );
  }
);

export function Tab({children}: {children: ReactNode}) {
  return (
    <HuiTab as={Fragment}>
      {({selected}) => <BaseTab selected={selected}>{children}</BaseTab>}
    </HuiTab>
  );
}

export function LinkTab({to, children}: LinkProps) {
  const {
    location: {pathname, search},
  } = useNavigation();
  const {url} = extractUrlAndReplaceFromTo(to);
  return (
    <Link to={to}>
      <BaseTab selected={url === pathname + search}>{children}</BaseTab>
    </Link>
  );
}

export function TabList({
  children,
  large = true,
  primary = false,
}: TabListProps) {
  return (
    <HuiTab.List as={Fragment}>
      <BaseTabList large={large} primary={primary}>
        {children}
      </BaseTabList>
    </HuiTab.List>
  );
}

export function LinkTabList({
  children,
  large = true,
  primary = false,
}: TabListProps) {
  return (
    <BaseTabList large={large} primary={primary}>
      {children}
    </BaseTabList>
  );
}

export const Tabs = HuiTab.Group;

export const TabPanels = HuiTab.Panels;

export const TabPanel = styled(HuiTab.Panel)`` as typeof HuiTab.Panel;

export function FilterTabs({
  tabs,
  children,
}: {
  tabs: string[];
  children: (tab: string) => ReactNode;
}) {
  return (
    <Tabs>
      <div
        css={css`
          margin-bottom: 24px;
        `}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab>{toTitleCase(tab)}</Tab>
          ))}
        </TabList>
      </div>
      <TabPanels>
        {({selectedIndex}) => (
          <>{selectedIndex !== null && children(tabs[selectedIndex])}</>
        )}
      </TabPanels>
    </Tabs>
  );
}
