import {Habitationial} from "api/quote";
import {Box} from "components/Box";
import {GridContainer} from "components/Grid";
import Field from "../../Field";
import FormTitle from "pages/RateQuoteBind/components/FormTitle";
import DebitsAndCreditsSection from "./DebitsAndCredits";
import {usePermissions} from "hooks/auth";
import {Permissions} from "api/auth";

function HabitationalCoverageForm({
  habitational,
}: {
  habitational: Habitationial;
}) {
  const permissions = usePermissions();

  return (
    <Box mb={100}>
      <GridContainer rowGap={24} columnGap={16}>
        <Field.CheckBox
          label="Terrorism Coverage"
          object={habitational}
          field={"terrorism_coverage_flag"}
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.CheckBox
          label="Equipment Breakdown"
          object={habitational}
          field={"equipment_breakdown"}
          wrapperProps={{
            grow: 12,
          }}
        />
        <Field.CheckBox
          label="Property Enhancement"
          object={habitational}
          field={"property_enhancement"}
          wrapperProps={{
            grow: 12,
          }}
        />

        {permissions.includes(Permissions.BACKEND_QUOTING) && (
          <Field.CheckBox
            label="Debits/Credits- Field Only Available for AE Internal - UW"
            object={habitational}
            field={"credit_and_debits_flag"}
            wrapperProps={{
              grow: 12,
              style: {
                backgroundColor: "rgba(255, 255, 125)",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottomLeftRadius: !habitational.credit_and_debits_flag
                  ? "6px"
                  : "0px",
                borderBottomRightRadius: !habitational.credit_and_debits_flag
                  ? "6px"
                  : "0px",
              },
            }}
          />
        )}
        {habitational.credit_and_debits_flag &&
          permissions.includes(Permissions.BACKEND_QUOTING) &&
          !habitational.debits_and_credits.length && (
            <>
              <Field.TextInput
                object={habitational!}
                field={"gl_credit_and_debits_percentage"}
                label="Schedule Rating Percentage"
                prefix="%"
                type="number"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomLeftRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={habitational!}
                field={"gl_credit_and_debits_reason"}
                label="Schedule Rating Reason"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomRightRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={habitational!}
                field={"pr_credit_and_debits_percentage"}
                label="IRPM Percentage"
                prefix="%"
                type="number"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomLeftRadius: "6px",
                  },
                }}
              />
              <Field.TextInput
                object={habitational!}
                field={"pr_credit_and_debits_reason"}
                label="IRPM Reason"
                wrapperProps={{
                  growXXS: 12,
                  growMD: 6,
                  style: {
                    backgroundColor: "rgba(255, 255, 125)",
                    borderBottomRightRadius: "6px",
                  },
                }}
              />
            </>
          )}
      </GridContainer>
      {habitational.credit_and_debits_flag &&
      permissions.includes(Permissions.BACKEND_QUOTING) &&
      habitational.debits_and_credits.length ? (
        <div>
          <FormTitle.Header2>Debits and Credits</FormTitle.Header2>
          <DebitsAndCreditsSection
            debitsAndCredits={habitational.debits_and_credits}
          />
        </div>
      ) : null}
    </Box>
  );
}

export default HabitationalCoverageForm;
